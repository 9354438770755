import { useEffect, useState } from 'react'
import { course, hi, iconlogo, tivaAccademi } from '../../../../components/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import api from "../../../../config/Config"
import { Pagination, Spin } from 'antd'
import HeaderVendor from '../../../../components/header/IndexVendor'
import { GrLanguage } from 'react-icons/gr'

const VendorLearningCourseVideo = () => {
    const navigate = useNavigate();
    const isMobileScreen = window.innerWidth <= 768;
    const { idSubCateg } = useParams()
    const data = "";
    const [loadCategory, setLoadCategory] = useState<ResponseLearning[]>([])
    const [totalPage, setTotalPage] = useState(1);
    const [idvideo, setVideo] = useState("")
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [description, setDescription] = useState("")

    useEffect(() => {
        loadCategoryLeaning(1)
    }, [idvideo])
    const loadCategoryLeaning = (page: number) => {
        api
            .getHttpRequestV3(`/user/training/${idSubCateg}/${selectedLanguage}?page=${page}&limit=3`, data, "GET")
            .then((response) => {
                const json = response.data.data;
                setLoadCategory(json.rows)
                setTotalPage(json.count)
            })
            .catch((error) => {
                console.log(error)
            });
    };
    // const selectedCategory = loadCategory.find((category) => category.id === idSubCateg);

    // const redirectionTraining = () => {
    //     return navigate("/training-vendor")
    // }

    const handleGetTrainingIdVideo = (idvideo: string, description: string) => {
        setVideo(idvideo)
        setDescription(description)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleLanguageChange = (event: any) => {
        setSelectedLanguage(event.target.value);
    };

    return (
        <div id="wrapper">
            <HeaderVendor />
            <section>
                <div className="container-fluid p-0 course-banner-section">
                    <div
                        style={{
                            backgroundImage: `url(${course})`,
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            minHeight: '500px',
                            position: 'relative',
                            marginTop: '25px',
                            paddingTop: '93px',

                        }}
                        className="item-image img-padding course-banner" >
                        <div className="container">
                            <div className="col-md-8 mx-auto">

                                <div className="tiva-course-header " >
                                    <div className="tiva-course-header-1">
                                        <div className="logo-brand-tiva">
                                            <img src={iconlogo} alt="" className="img-fluid" />
                                        </div></div>
                                    <div className="tiva-course-header-1">
                                        <div className="integrations-top-content" style={{ color: 'white' }}>
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb" >
                                                    <li className="breadcrumb-item" style={{ color: 'white', fontFamily: `"Noto Sans", sans-serif` }}>
                                                        <Link style={{color:'white'}} to={`/vendor`}>Home</Link></li>
                                                    <li  className="breadcrumb-item active" aria-current="page" style={{
                                                        color: 'white', fontFamily: `"Noto Sans", sans-serif`, fontWeight: 'bold',
                                                        cursor: 'pointer'
                                                    }}>Training</li>
                                                </ol>
                                            </nav>
                                            <div className="heading-xlarge margin-bottom-xxsmall w-embed">
                                                {description}
                                            </div>

                                            <div >
                                                <p
                                                    className={`pull-left text-white font-14 custom-description`}
                                                    style={{ fontFamily: `"Noto Sans", sans-serif` }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">

                                </div>
                                <div className="col-sm-12 col-md-8">

                                </div>


                                <div className="video-container">
                                    <iframe width="100%" height="415" src={`https://www.youtube.com/embed/${idvideo}`}
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section >

            {/* <section>
                <div className="container-fluid p-0" style={{ marginTop: '70px' }}>
                    <div
                        style={{
                            backgroundImage: `url(${tivaAccademi})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: 0,
                            paddingBottom: '27%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        className="item-image img-padding support-banner"
                    >
                        <div style={{ width: '66.66%', paddingTop: '20%' }}>
                            <h2
                                className="how-can-help font-weight-500"
                                style={{ margin: '0', color: 'white', fontFamily: `"Noto Sans", sans-serif` }}
                            >
                                <span><img src={hi} alt="" /></span> Hey, how can we help?
                            </h2>
                            <div className="support-search s003">
                                <form>
                                    <div className="inner-form" style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                                        <div className="input-field first-wrap">
                                            <div className="input-select">
                                                <div className="dropdown-toggle" data-toggle="dropdown" style={{ color: '#017DF3' }}>
                                                    <a href="members.html"><GrLanguage style={{ fontSize: 35, color: '#017DF3' }} /> <span className="caret"></span></a>
                                                </div>
                                                <ul className="dropdown-menu countriesflag text-left" style={{ zIndex: 900, width: '10%', marginTop: '-50px' }}>
                                                    <div className="row m-0">
                                                        <ul className="col-sm-3 mb-5">
                                                            <li><h4>Language</h4></li>
                                                            <li style={{ display: 'flex', alignItems: 'center' }}>
                                                                <input
                                                                    type="radio"
                                                                    id="english"
                                                                    name="language"
                                                                    value="en"
                                                                    onChange={handleLanguageChange}
                                                                    checked={selectedLanguage === 'en'}
                                                                />
                                                                <label htmlFor="english" style={{ marginLeft: '8px' }}>English</label>
                                                            </li>
                                                            <li style={{ display: 'flex', alignItems: 'center' }}>
                                                                <input
                                                                    type="radio"
                                                                    id="french"
                                                                    name="language"
                                                                    value="fr"
                                                                    onChange={handleLanguageChange}
                                                                    checked={selectedLanguage === 'fr'}
                                                                />
                                                                <label htmlFor="french" style={{ marginLeft: '8px' }}>French</label>
                                                            </li>
                                                            <li style={{ display: 'flex', alignItems: 'center' }}>
                                                                <input
                                                                    type="radio"
                                                                    id="swahili"
                                                                    name="language"
                                                                    value="sw"
                                                                    onChange={handleLanguageChange}
                                                                    checked={selectedLanguage === 'sw'}
                                                                />
                                                                <label htmlFor="swahili" style={{ marginLeft: '8px' }}>Swahili</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="input-field second-wrap">
                                            <input id="search" type="text" placeholder="Search for answers" />
                                        </div>
                                        <div className="input-field third-wrap">
                                            <button className="btn-search" type="button">
                                                <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section> */}

            <section>

                <div className="container py-5">
                    <div className="row trining-category2">
                        <div className="col-lg-12">
                            <h2 className="text-center we-help-heading text-center py-3">Explore other lessons</h2>
                        </div>

                        {loadCategory && loadCategory.length > 0 ? (
                            loadCategory.map((item, i) => (


                                <div key={i} className="col-12 col-md-4 col-lg-4  d-flex align-self-stretch mb-3"
                                onClick={() => handleGetTrainingIdVideo(item?.training?.youtube_id, item.name? item.name : item?.training?.slug)}
                                >
                                    <div className="card  mb-4" style={{ height: '100%', borderRadius: '5px', marginBottom: 10 }}>
                                        <div className="position-relative">

                                            <img src={`https://dealer.tiva.co.tz/tiva/images/training/` + item?.training?.banner} className="card-img-top" alt="..." />
                                            <div className="duration" style={{ fontFamily: `"Noto Sans", sans-serif` }}>
                                                {item?.training?.duration} minutes
                                            </div>
                                        </div>
                                        <div className="card-body d-flex flex-column">
                                            <h5 className="card-title " style={{ fontFamily: `"Noto Sans", sans-serif` }}>
                                                {item.name? item.name : item?.training?.slug}
                                            </h5>
                                            <p className="text-muted" style={{ fontFamily: `"Noto Sans", sans-serif` }}>
                                                {item.short_description}
                                            </p>
                                            <div className="mt-auto ">
                                                <div className="card-footer">
                                                    <div className="font-16"
                                                        style={{ color: '#1576ED', fontFamily: `"Noto Sans", sans-serif`, cursor: 'pointer' }}
                                                        
                                                    > See Tutorial  <i className="fas fa-long-arrow-alt-right"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-12 d-flex justify-content-center">
                                <Spin />No data found
                            </div>
                        )}

                        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: 10 }}>
                            <Pagination
                                style={{ paddingTop: 0, paddingBottom: 10 }}
                                pageSize={3}
                                total={totalPage}
                                onChange={(page) => loadCategoryLeaning(page)}
                            />
                        </div>
                    </div>
                </div>

            </section>

            {/* <div className="content-page" style={{marginTop:'-50%'}}>
                <div className="content">

                    <Footer />
                </div>
            </div> */}

        </div >
    )
}

export default VendorLearningCourseVideo