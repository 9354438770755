import api from '../../../config/Config'
import { Deconne, page404 } from "../../../components/icons"


const ErrorOfLine = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            background: '#f5f5f5',
        }}>
            <img src={Deconne} alt="Page non trouvée" style={styles.image} />
            <div style={{
                
                justifyContent: 'center'
            }}>
            <h6 style={styles.text}>PleYour account is offline, click to log in again</h6>
            </div>
            
            <div style={{
                borderRadius: 100, border: '1px solid #1152D9', marginTop: 5, textAlign: 'center', display: 'flex',
                justifyContent: 'center', cursor: 'pointer',
            }} onClick={() => api.RedirectionLogin()}>
                <div>
                    <div style={{ color: '#1576ED', padding: 10 }}>Log in to your account</div>
                </div>
            </div>
        </div>
    );
};

const styles = {

    image: {
        width: '400px',
    },
    text: {
        fontSize: '16px',
        color: '#333',
        
    },
};

export default ErrorOfLine;