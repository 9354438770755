import { LegacyRef, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { Select } from 'antd';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../../config/Config'
import requestRow from '../../../controller/RequestRow';
import toast, { Toaster } from 'react-hot-toast';
import Popup from '../../../components/popup';
import PhoneInput from 'react-phone-input-2'
import { Alert, Space, notification } from 'antd'
import Select from 'react-select';
import Swal from 'sweetalert2'
import Footer from '../../../components/footer';

declare module 'react' {
    interface HTMLAttributes<T extends HTMLDivElement> {
        show?: () => void;
        hide?: () => void;
    }
}


const LinkPaymentMethod = () => {

    const options = [
        { label: "United States", value: "usa1" },
        { label: "Tanzania", value: "tnz" },
        { label: "Dr Congo", value: "dcn" },
        { label: "Zambia", value: "zamb" },
        { label: "Angola", value: "angl" },
        { label: "Zimbabwe", value: "zambwa" },
        { label: "Ethiopia", value: "ethop" },
        { label: "Nigeria", value: "ngra" },
        { label: "South Africa", value: "safrc" },
    ];

    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleOptions = (option: any) => {
        setSelectedOption(option);
    };

    const handleClear = () => {
        setSelectedOption(options[0]);
    };

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);

    const data = '';
    const [loadOperator, setLoadOperator] = useState<requestRow[]>([]);
    const [loadPayement, setLoadPayement] = useState<requestRow[]>([]);
    const [phone, setPhone] = useState<string>('');
    const [cardMobile, setCardMobile] = useState('');
    const [codeVal, setCodeVal] = useState('');
    const [id, setId] = useState('');
    const [idOtp, setIdOtp] = useState('');
    // variable to receive the phone number after user make the Mobile number
    const [receivePhoneNumber, setReceivePhoneNumber] = useState('');
    const [viewPopup, SetViewPopup] = useState(false)
    const [viewPopup1, SetViewPopup1] = useState(false)
    const [viewPopupUpdate, SetViewPopupUpdate] = useState(false)

    const [showOptions, setShowOptions] = useState<Record<number, boolean>>({});
    const toggleShowOptions = (i: number): void => { setShowOptions({ ...showOptions, [i]: !showOptions[i] }); };

    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [message, setMessage] = useState("");

    function ViewPop() {
        SetViewPopup(true)
    }

    function ViewPop1() {
        SetViewPopup1(true)
    }
    function ViewPopUpdate1(element: any) {
        SetViewPopupUpdate(true);
        setPhone(element.phone);
        setId(element.id);
    }


    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }

        loadMobileOperator();
        payementMobile();
    }, []);

    const loadMobileOperator = () => {
        api.getHttpRequest("/parameters/mobile-operator/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setLoadOperator(json.data);
            }
        }).catch((error) => {
            api.getMessageError(error)
        })
    }

    const payementMobile = () => {
        api.getHttpRequest("/method-payment/phone/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setLoadPayement(json.data);
            }
        }).catch((error: any) => {
            api.getMessageError(error)
        })
    }

    //Add link mobile operator
    const AddMobileOperator = async () => {
        let data = JSON.stringify({
            "phone": phone,
            "id_telecom": cardMobile
        });

        setLoading(true);

        if (phone === '' || cardMobile === '') {
            setShowAlertDanger(true);
            setMessage("Make sure you've filled in all the fields");
        }
        else {
            api.getHttpRequest("/method-payment/phone/create", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setReceivePhoneNumber(phone)
                    setIdOtp(json.data)
                    api.Toast1.fire({
                        icon: 'success',
                        title: `${json.message}`
                    })
                    // toast.success(json.message);
                    SetViewPopup1(false);
                    ViewPop()
                    setPhone('')
                    setCardMobile('')
                    payementMobile();
                    // setShowVerifyOTP(true);
                } else {
                    setLoading(false)
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                setLoading(false)
                api.getMessageError(error)
            })
        }

        setTimeout(() => {
            setLoading(false);
        }, 2500);
    }

    const UpdateMobileOperator = async (id: any) => {

        setLoading(true);

        api.getHttpRequest(`/method-payment/phone/set-default/${id}`, {}, 'PUT').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setReceivePhoneNumber(phone)
                api.Toast1.fire({
                    icon: 'success',
                    title: `${json.message}`
                })
                // toast.success(json.message);
                SetViewPopupUpdate(false);
                setId('');
                setPhone('')
                setCardMobile('')
                payementMobile();
                // setShowVerifyOTP(true);
            }else {
                setLoading(false)
                api.Toast1.fire({
                    icon: 'error',
                    title: `${json.message}`
                })
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response && error.response.data && error.response.data.message) {
                const errorMessage = error.response.data.message;
                Swal.fire({
                  text: errorMessage,
                  icon: 'warning',
                  showConfirmButton: false,
                  showCancelButton: true,
                  cancelButtonColor: '#d33',
                  cancelButtonText: 'Close this message',
                  customClass: 'swal-height-10',
                });}
        })
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    const VerifyMobileOTP = async () => {
        let data = JSON.stringify({
            "verificationCode": codeVal
        });

        setLoading(true);

        if (codeVal === '') {
            setShowAlertDanger(true);
            setMessage('No field should be empty');
        }
        else {
            api.getHttpRequest("/method-payment/phone/verify", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {

                    toast.success(json.message);
                    SetViewPopup(false);
                    setReceivePhoneNumber('')
                    setCodeVal('');
                    payementMobile();
                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                // setLoading(false)       
                // api.getMessageError(error)
            })
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }

    const ResendOtp = async () => {
        api.getHttpRequest(`/method-payment/phone/resend-verification/${idOtp}`, {}, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                toast.success(json.message);
            } else {
                setShowAlertDanger(true);
                setMessage(json.message);
            }
        }).catch((error) => {
            setLoading(false)
            api.getMessageError(error)
        })
    }

    const ResendOtpAfter = async (element: any) => {
        api.getHttpRequest(`/method-payment/phone/resend-verification/${element.id}`, {}, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                SetViewPopup(true)
                toast.success(json.message);
            } else {
                setShowAlertDanger(true);
                setMessage(json.message);
            }
        }).catch((error) => {
            setLoading(false)
            api.getMessageError(error)
        })
    }

    //Change phone number
    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    function handleChange(value: string) {
        // console.log(`selected ${value}`);
        setSelectedValue(value);
    }

    const [selectedValue1, setSelectedValue1] = useState("");

    const handleChange1 = (event: any) => {
        setSelectedValue1(event.target.value as string);
    };

    const [popVisible, setPopVisible] = useState<Record<number, boolean>>({});

    const [selectedValue, setSelectedValue] = useState('tnz');

    const [isSearchable, setIsSearchable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);

    const [api1, contextHolder] = notification.useNotification();


    const showSweetAlert = (element: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Want to remove this link mobile operator ${element.phone} / ${element.MobileOperator.name}`,
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            customClass: 'swal-height-10',

        }).then((result) => {
            if (result.isConfirmed) {

                api.getHttpRequest(`/method-payment/phone/delete/${element.id}`, {}, 'Delete').then((response) => {
                    const json = response.data;
                    if (json.status == 200) {
                        payementMobile();
                        // setShowVerifyOTP(true);
                        api.Toast1.fire({
                            icon: 'success',
                            title: `${json.message}`
                        })
                    } else {
                        setShowAlertDanger(true);
                        setMessage(json.message);
                    }
                }).catch((error) => {
                    setLoading(false)
                    api.getMessageError(error)
                })
            }
        })

    }


    return (
        <>
            <div className="content-page">
                <div className="content">
                    <div className="container-fluid1">

                        <div className="row ">
                            <div className="col-12">
                                <div>
                                    <h2 className="header-title pb-0 font-30 border-none">Payment method
                                    </h2>
                                    <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 1 }}>
                                        <label style={{
                                            backgroundColor: 'red',
                                            height: 20,
                                            width: 6,
                                            borderRadius: 10
                                        }}></label>
                                        <p style={{ paddingLeft: 2 }}>Select your preferred payment method</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-12 card stacked-2 py-5 pt-2">
                            <div>
                                <Toaster
                                    position="top-center"
                                    reverseOrder={false}

                                />
                            </div>
                            <div className="row col-sm-12">
                                <div className="col-xl-6">
                                    <h4 className="fs-24 text-black pb-2">Link your payments

                                    </h4>
                                    <div className="col-lg-7">
                                        <div className="bank-links-wrapper">
                                            <div className="bank-links-common">
                                                <a className="l-b-c" href="#" data-toggle="modal" data-target="#bank_link_modal">
                                                    <span className="bank-link-icon"><img
                                                        src="assets/images/link-a-bank.png" /></span>
                                                    <span className="bank-link-text">Link a bank</span>
                                                </a>
                                                <div className="modal fade" id="bank_link_modal" tabIndex={-1}
                                                    aria-labelledby="bank_link_modalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"
                                                                    id="exampleModalLabel font-weight-500">Link a Card
                                                                </h5>
                                                                <button type="button" className="close" data-dismiss="modal"
                                                                    aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="modal-body">

                                                                    <div className="basic-form country-select pb-2 col-md-12">
                                                                        <label htmlFor="nom" className="form-label font-18 mb-2">Choose
                                                                            your Country</label>

                                                                        {/* <Select showSearch style={{ width: '100%' }} dropdownStyle={{ height: 300 }} placeholder="Select country" optionFilterProp="children" onChange={handleChange} filterOption={(input: string, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                } className="custom_selectbox selectpicker fill_selectbtn_in own_selectbox">
                                                    <Option value="usa1">United States
                                                    </Option>
                                                    <Option value="tnz">Tanzania</Option>
                                                    <Option value="dcn">Dr Congo</Option>
                                                    <Option value="zamb">Zambia</Option>
                                                    <Option value="angl">Angola</Option>
                                                    <Option value="zambwa">Zimbabwe</Option>
                                                    <Option value="ethop">Ethiopia</Option>
                                                    <Option value="ngra">Nigeria</Option>
                                                    <Option value="safrc">South Africa</Option>
                                                </Select> */}

                                                                        {/* <MultiSelect
                                                    options={options}
                                                    value={selected}
                                                    onChange={setSelected}
                                                    labelledBy="Select"
                                                />*/}

                                                                        <Select
                                                                            className="selectpicker fill_selectbtn_in own_selectbox"
                                                                            classNamePrefix="select"
                                                                            isLoading={isLoading}
                                                                            // isClearable={isClearable}
                                                                            isRtl={isRtl}
                                                                            isSearchable={isSearchable}
                                                                            name="color"
                                                                            options={options}
                                                                            defaultValue={options[0]}
                                                                            onChange={handleOptions}
                                                                            placeholder="Select"
                                                                            isClearable={false}
                                                                        />
                                                                    </div>

                                                                    {/** bank list USA */}
                                                                    {selectedOption.value === 'usa1' &&
                                                                        <div id="usa1" className="hide">
                                                                            <div className="banks-list-wrapper ">
                                                                                <div className="banks-list-container">
                                                                                    <div className="bank-list-hide-show us-banks">
                                                                                        <div className="bank-list-row"
                                                                                            data-toggle="modal"
                                                                                            data-target="#link_bank_account">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-1.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Chase
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-2.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Bank of
                                                                                                America
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-3.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Wells
                                                                                                Fargo
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-4.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">PNC Bank
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-5.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">U.S.
                                                                                                Bank
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-6.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">TD Bank
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-7.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">USAA
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-8.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Citi
                                                                                                Bank
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-9.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Regions
                                                                                                Bank
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-10.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">
                                                                                                Huntington
                                                                                                Bank(Personal)</div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-11.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Navy
                                                                                                Federal
                                                                                                Credit Union</div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-12.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Truist
                                                                                                (Formerly
                                                                                                BB&amp;T)</div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-13.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Citizens
                                                                                                bank
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-14.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">capital
                                                                                                one
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/bank-logo-15.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Key Bank
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }


                                                                    {/** bank list Tanzania  */}
                                                                    {selectedOption.value === 'tnz' &&
                                                                        <div id="tnz" className="hide">
                                                                            <div className="banks-list-wrapper ">
                                                                                <div className="banks-list-container">
                                                                                    <div
                                                                                        className="bank-list-hide-show tanzania-banks">


                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/absa.png" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Absa
                                                                                                Bank</div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/access-bank.png" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Access
                                                                                                Bank</div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/atlas-mara.png" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Atlas
                                                                                                Mara Bank</div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/ecobank.png" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Eco Bank
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/first-alliance.png" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">First
                                                                                                Alliance Bank</div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    }


                                                                    {/** bank list Dr Congo */}
                                                                    {selectedOption.value === 'dcn' &&
                                                                        <div id='dcn' className="hide">
                                                                            <div className="banks-list-wrapper ">
                                                                                <div className="banks-list-container">
                                                                                    <div className="bank-list-hide-show dr_congo">


                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/equity.png" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Equity
                                                                                                BCDC</div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/First_Bank.png" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">FBN Bank
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/citi-bank.jpg" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Citi
                                                                                                Bank</div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/standard-bank.png" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Standard
                                                                                                Bank</div>
                                                                                        </div>
                                                                                        <div className="bank-list-row">
                                                                                            <div className="back-list-img"><img
                                                                                                src="assets/images/raw-bank.png" />
                                                                                            </div>
                                                                                            <div className="bank-list-name">Raw Bank
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    }

                                                                </div>

                                                                {/** bank list Zambia */}
                                                                {selectedOption.value === 'zamb' &&
                                                                    <div id="zamb" className="hide">
                                                                        <div className="banks-list-wrapper ">
                                                                            <div className="banks-list-container">
                                                                                <div
                                                                                    className="bank-list-hide-show zambia-banks">
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/absa.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Absa
                                                                                            Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/access-bank.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Access
                                                                                            Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/atlas-mara.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Atlas
                                                                                            Mara Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/ecobank.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Eco Bank
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/first-alliance.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">First
                                                                                            Alliance Bank</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {/** bank list Angola */}
                                                                {selectedOption.value === 'angl' &&
                                                                    <div id="angl" className="hide">
                                                                        <div className="banks-list-wrapper ">
                                                                            <div className="banks-list-container">
                                                                                <div
                                                                                    className="bank-list-hide-show angola-banks">
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/bai.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Banco
                                                                                            Angolano de Investimentos</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/bir.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Banco de
                                                                                            Investmento Rural, S.A.</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/bic.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Banco
                                                                                            BIC Angola</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/bca.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Banco
                                                                                            Comercial Angolano</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/bci.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Banco de
                                                                                            Comércio e Indústria</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {/** bank list Zimbabwe */}
                                                                {selectedOption.value === 'zambwa' &&
                                                                    <div id="zambwa" className="hide">
                                                                        <div className="banks-list-wrapper ">
                                                                            <div className="banks-list-container">
                                                                                <div
                                                                                    className="bank-list-hide-show zimbabwe-banks">
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/BancABC.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">BancABC
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/cbz.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">CBZ Bank
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/atlas-mara.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">First
                                                                                            Capital Bank Zimbabwe Limited
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/ecobank.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Eco Bank
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/fbc.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">FBC Bank
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                }

                                                                {/** bank list Ethiopia */}
                                                                {selectedOption.value === 'ethop' &&
                                                                    <div id="ethop" className="hide">
                                                                        <div className="banks-list-wrapper ">
                                                                            <div className="banks-list-container">
                                                                                <div
                                                                                    className="bank-list-hide-show ethopia-banks">
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/awash.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Awash
                                                                                            International Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/cbe.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">
                                                                                            Commercial Bank of Ethiopia
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/bo.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">
                                                                                            Cooperative Bank of Oromia</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/lion.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Lion
                                                                                            International Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/wegagen.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Wegagen
                                                                                            Bank</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {/** bank list Nigeria */}
                                                                {selectedOption.value === 'ngra' &&
                                                                    <div id="ngra" className="hide">
                                                                        <div className="banks-list-wrapper ">
                                                                            <div className="banks-list-container">
                                                                                <div
                                                                                    className="bank-list-hide-show ethopia-banks">
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/awash.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Awash
                                                                                            International Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/cbe.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">
                                                                                            Commercial Bank of Ethiopia
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/bo.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">
                                                                                            Cooperative Bank of Oromia</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/lion.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Lion
                                                                                            International Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/wegagen.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Wegagen
                                                                                            Bank</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }


                                                                {/** bank list South Africa */}
                                                                {selectedOption.value === 'safrc' &&
                                                                    <div id="safrc" className="hide">
                                                                        <div className="banks-list-wrapper ">
                                                                            <div className="banks-list-container">
                                                                                <div className="bank-list-hide-show sa-banks"
                                                                                    style={{ display: 'block' }}>
                                                                                    <div className="bank-list-row" >
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/african-bank.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">African
                                                                                            Bank Limited</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/bidvest.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Bidvest
                                                                                            Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/capitec.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Capitec
                                                                                            Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/fnb.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">First
                                                                                            National Bank</div>
                                                                                    </div>
                                                                                    <div className="bank-list-row">
                                                                                        <div className="back-list-img"><img
                                                                                            src="assets/images/Imperial_Bank.png" />
                                                                                        </div>
                                                                                        <div className="bank-list-name">Imperial
                                                                                            Bank</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }



                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="bank-links-common">
                                                <a href="#" data-toggle="modal" data-target="#card_link">
                                                    <span className="bank-link-icon"><img
                                                        src="assets/images/link-a-card.png" /></span>
                                                    <span className="bank-link-text">Link a Card</span>
                                                </a>

                                                <div className="modal fade" id="card_link" tabIndex={-1}
                                                    aria-labelledby="linkcardLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"
                                                                    id="exampleModalLabel font-weight-500">Link a Card
                                                                </h5>
                                                                <button type="button" className="close" data-dismiss="modal"
                                                                    aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="modal-body">
                                                                    <div className="link-a-card-img text-center">
                                                                        <img height="150" src="assets/images/card_link.png" />
                                                                    </div>

                                                                    <div className="basic-form">
                                                                        <div className="mb-2">
                                                                            {/** <label className="form-label">Debit or Credit Card Number</label>  */}
                                                                            <input type="text"
                                                                                className="default-select form-control wide mb-3"
                                                                                placeholder="Debit or Credit Card Number" />
                                                                        </div>
                                                                        <div className="mb-4 card-type-select">
                                                                            <div className="basic-form country-select mb-2">
                                                                                <label htmlFor="nom" className="form-label ">Card
                                                                                    Type</label>
                                                                                <select className="form-control"
                                                                                    id="payement-mode">
                                                                                    <option value="usa1">Select
                                                                                        your card
                                                                                    </option>
                                                                                    <option value="">card Type 1</option>
                                                                                    <option value="">card Type 2</option>
                                                                                    <option value="">card Type 3</option>
                                                                                </select>


                                                                            </div>
                                                                        </div>
                                                                        <div className="mb-4">
                                                                            {/** <label className="form-label">Expiration Date</label> */}
                                                                            <input type="text"
                                                                                className="default-select form-control wide"
                                                                                placeholder="Expiration Date" />
                                                                        </div>
                                                                        <div className="mb-4 security-code">




                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-10">
                                                                                    <input type="text"
                                                                                        className="default-select form-control wide"
                                                                                        placeholder="Security Code" />
                                                                                </div>
                                                                                <div className="form-group col-md-2">
                                                                                    <img src="assets/images/security-card.png"
                                                                                        height="50" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            {/** <label className="form-label">Billing Address</label> */}
                                                                            <input type="text"
                                                                                className="default-select form-control wide"
                                                                                placeholder="Billing Address" />
                                                                        </div>

                                                                    </div>



                                                                    <div className="modal-footer1">
                                                                        <button type="button"
                                                                            className="btn btn-primary agree-link"
                                                                            data-bs-dismiss="modal">Link Card</button>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="bank-links-common">
                                                <a href="#" data-toggle="modal" data-target="#link_Mobile_no" onClick={ViewPop1}>
                                                    <span className="bank-link-icon"><img
                                                        src="assets/images/mobile-a-account.png" /></span>
                                                    <span className="bank-link-text">Link Mobile Account</span>
                                                </a>

                                                {/** Link a mobile  Modal */}
                                                {/* {
                            showModal && (
                                <div className="modal fade" id="link_Mobile_no" tabIndex={-1}
                                    aria-labelledby="linkcardLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"
                                                    id="exampleModalLabel font-weight-500">Link Your Mobile
                                                    Account

                                                </h5>
                                                <button type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="modal-body">
                                                    <div className="form-group">
                                                        <label htmlFor="nom" className="form-label ">Phone Number</label>
                                                        <PhoneInput
                                                            inputStyle={{
                                                                width: '100%',

                                                            } as React.CSSProperties}
                                                            inputClass={'form-control'}
                                                            country={'tz'}
                                                            value={phone}
                                                            onChange={changePhoneNumber}
                                                            enableSearch={true}
                                                            countryCodeEditable={true}
                                                        />
                                                    </div>
                                                    <label htmlFor="nom" className="form-label ">Choose your Mobile Operator</label>
                                                    <select className="form-control" name='cardMobile' value={cardMobile} onChange={(e) => { setCardMobile(e.target.value) }}>
                                                        <option  value="usa1">Select
                                                            your card
                                                        </option>
                                                        {
                                                            loadOperator.map((item, i) => (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>

                                                    <div className="pt-5">
                                                        {!Loading && (
                                                            <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={() => { AddMobileOperator() }}>Save</button>
                                                        )}

                                                        {Loading && (

                                                            <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Processing...</span>
                                                            </button>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        } */}

                                                {/** Lick a mobile validate phone number */}
                                                {/* {
                            receivePhoneNumber && (
                                <div  className="modal fade" id="link_Mobile_no" tabIndex={-1}
                                    aria-labelledby="linkcardLabel" aria-hidden="false">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"
                                                    id="exampleModalLabel font-weight-500">Enter the validation code

                                                </h5>
                                                <button type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="modal-body">
                                                    <div className="form-group">
                                                        <label htmlFor="nom" className="form-label ">Enter code</label>
                                                        <input type="text" id="mobile_code"
                                                            className="form-control" placeholder="Enter code"
                                                            name="codeVal" value={codeVal} onChange={(e) => { setCodeVal(e.target.value) }} />


                                                    </div>

                                                    <div className="pt-5">
                                                        {!Loading && (
                                                            <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={() => { VerifyMobileOTP() }}>Verify</button>
                                                        )}

                                                        {Loading && (

                                                            <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Processing...</span>
                                                            </button>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        } */}

                                            </div>
                                            <div className="bank-links-common">
                                                <a href="#" data-toggle="modal" data-target="#paypal-account">
                                                    <span className="bank-link-icon"><img src="assets/images/paypal.png" /></span>
                                                    <span className="bank-link-text">Paypal</span>
                                                </a>

                                                { /** Link a Card  Modal */}
                                                <div className="modal fade" id="paypal-account" tabIndex={-1}
                                                    aria-labelledby="linkcardLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title"
                                                                    id="exampleModalLabel font-weight-500">Link Your Paypal Account

                                                                </h5>
                                                                <button type="button" className="close" data-dismiss="modal"
                                                                    aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="modal-body">
                                                                    <div className="basic-form">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">E-mail Address</label>
                                                                            <input type="email" className="default-select form-control wide" placeholder="E-mail Address" />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Password</label>
                                                                            <input type="password" className="default-select form-control wide" placeholder="Password" />
                                                                        </div>
                                                                    </div>
                                                                    <button type="button" className="btn btn-primary agree-link mt-5">Link Paypal</button>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>




                                </div >
                                <div className='row col-md-12 flex'>
                                    {contextHolder}
                                    {
                                        isMatch ? (
                                            <>
                                                {
                                                    loadPayement.map((item, i) => (
                                                        <div key={i} className='mb-1'>
                                                            <div className="select-card-container showing active " onClick={() => toggleShowOptions(i)}

                                                            >
                                                                <div>
                                                                    <div className="select-card-img" >
                                                                        <img src={item.MobileOperator.icon} width={50} />
                                                                    </div>
                                                                    <div className="select-card-detail" >
                                                                        <div className="select-card-name name-txt">
                                                                            <div >{item.phone}</div>
                                                                        </div>
                                                                        <div className="card-name" style={{ visibility: 'hidden', height: 0, width: 0, opacity: 0 }}>
                                                                        </div> <div className="select-card-debit c-debt d-flex">
                                                                            <div className='mr-2'>{item.MobileOperator.name}</div>
                                                                            <div className="select-card-preffer mt-0 mr-100" style={{ display: item.isDefault == true ? 'block' : 'none' }}><span className="badge light badge-success p-2">Preffered </span></div>
                                                                        </div>
                                                                        <div className="setup-preffered-wrapper" style={{ display: showOptions[i] ? 'block' : 'none' }}>
                                                                            {
                                                                                item.isDefault == false ? (
                                                                                    <div className="setup-this-prefered setup-this-prefered-first mb-1" style={{ display: 'flex' }}>
                                                                                        <span>Setup this as a <span className="badge light badge-success">Preffered ?</span></span>
                                                                                        <div className='set-pref-yes' onClick={() => UpdateMobileOperator(item.id)} >Yes
                                                                                        </div >
                                                                                    </div >
                                                                                ):("")
                                                                            }

                                                                            <div className='setup-this-prefered setup-this-prefered-first' style={{ display: 'flex' }}>
                                                                                <span className="badge badge-danger p-1" style={{ color: 'white' }}> Or Delete this ? </span>
                                                                                <div className='set-pref-yes btn btn-danger' onClick={() => showSweetAlert(item)}>Yes</div>
                                                                            </div >

                                                                            <div style={{ display: 'flex' }}>
                                                                                <div onClick={() => ResendOtpAfter(item)} style={{
                                                                                    border: '1px solid #f5f5f5',
                                                                                    borderRadius: 10, padding: 10, marginTop: 10,
                                                                                    color: "#1576ED", textAlign: 'center', fontSize: 14, width: '75%', cursor: 'pointer'
                                                                                }}
                                                                                >Resend Otp</div>
                                                                                <div onClick={() => SetViewPopup(true)} style={{
                                                                                    border: '1px solid #f5f5f5',
                                                                                    borderRadius: 10, padding: 10, marginTop: 10,
                                                                                    color: "#1576ED", textAlign: 'center', fontSize: 14, width: '75%', cursor: 'pointer'
                                                                                }}
                                                                                >Enter Otp</div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    loadPayement.map((item, i) => (
                                                        <div key={i} className='mb-1 col-md-4  flex'>
                                                            <div className="select-card-container showing active" onClick={() => toggleShowOptions(i)}

                                                            >
                                                                <div className="select-card-img" >
                                                                    <img src={item.MobileOperator.icon} width={50} />
                                                                </div>
                                                                <div className="select-card-detail" style={{ flex: 1, overflowY: "auto", overflowX: "hidden" }}>
                                                                    <div className="select-card-name name-txt">
                                                                        <div >{item.phone}</div>
                                                                    </div>
                                                                    <div className="card-name" style={{ visibility: 'hidden', height: 0, width: 0, opacity: 0 }}>
                                                                    </div> <div className="select-card-debit c-debt d-flex">
                                                                        <div className='mr-2'>{item.MobileOperator.name}</div>
                                                                        <div className="select-card-preffer mt-0" style={{ display: item.isDefault == true ? 'block' : 'none' }}><span className="badge light badge-success p-2">Preffered </span></div>
                                                                    </div>
                                                                    <div className="setup-preffered-wrapper" style={{ display: showOptions[i] ? 'block' : 'none' }}>

                                                                        {
                                                                            item.isDefault == false ? (
                                                                                <div className="setup-this-prefered setup-this-prefered-first mb-1" style={{ display: 'flex' }}>
                                                                                    <span>Setup this as a <span className="badge light badge-success">Preffered ?</span></span>
                                                                                    <div className='set-pref-yes' onClick={() => UpdateMobileOperator(item.id)} >Yes
                                                                                    </div >
                                                                                </div >
                                                                            ):("")
                                                                        }

                                                                        <div className='setup-this-prefered setup-this-prefered-first' style={{ display: 'flex' }}> <span className="badge badge-danger p-1" style={{ color: 'white' }}> Or Delete this ? </span>
                                                                            <div className='set-pref-yes btn btn-danger' onClick={() => showSweetAlert(item)}>Yes</div>
                                                                        </div >
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div onClick={() => ResendOtpAfter(item)} style={{
                                                                                border: '1px solid #f5f5f5',
                                                                                borderRadius: 10, padding: 10, marginTop: 10,
                                                                                color: "#1576ED", textAlign: 'center', fontSize: 14, width: '75%',
                                                                                cursor: 'pointer',
                                                                                marginRight: 2, fontFamily: `"Noto Sans", sans-serif`
                                                                            }}
                                                                            >Resend Otp</div>
                                                                            <div onClick={() => SetViewPopup(true)} style={{
                                                                                border: '1px solid #f5f5f5',
                                                                                borderRadius: 10, padding: 10, marginTop: 10,
                                                                                color: "#1576ED", textAlign: 'center', fontSize: 14, width: '75%',
                                                                                cursor: 'pointer', fontFamily: `"Noto Sans", sans-serif`
                                                                            }}
                                                                            >Enter Otp</div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                </div>

                            </div >

                        </div >
                    </div >

                    {/** Modal content for the above example */}
                    < div className="modal fade" id="link_bank_account" tabIndex={- 1} aria-labelledby="exampleModalLabel" aria-hidden="true" >
                        <div className="modal-dialog modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Bank</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="card-link-trigger" style={{ display: 'block' }}>
                                        <div className="link-a-bank">
                                            <span className="link-a-bank-h" style={{ alignContent: 'center' }}>Link a U.S. bank account</span>
                                            <div className="link-a-bank-sub-h">Account Type</div>
                                            <div className="link-a-bank-radio-wrapper" style={{ display: 'flex', margin: 10 }}>
                                                <div className="link-a-bank-radio" style={{ marginRight: 20 }}>
                                                    <input type="radio" id="checking" name="acc-type" />
                                                    <label htmlFor="checking">Checking</label>

                                                </div>
                                                <div className="link-a-bank-radio">
                                                    <input type="radio" id="saving" name="acc-type" />
                                                    <label htmlFor="saving">Savings</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="basic-form">

                                            <div className="mb-3">
                                                <label className="form-label">Routing Number</label>
                                                <input type="text" className=" form-control " placeholder="Routing Number" />

                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Account Number</label>
                                                <input type="text" className=" form-control " placeholder="Account Number" />
                                            </div><span className="be-sure">Be sure to double-check your account number. Banks may not flag errors until you transfer money.</span>

                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary agree-link" data-bs-dismiss="modal">Agree and Link</button>
                                </div>
                            </div>
                        </div>
                    </div >
                    <Footer />
                </div>
            </div>


            {/**Add link mobile operator */}
            <Popup
                title='Link Your Mobile
                Account'
                openPupop={viewPopup1}
                setOpenPopup={SetViewPopup1}
                style={{ zIndex: 1500 }}
            >
                <div className="row">

                    <div className='mb-2 col-md-12'>
                        {
                            message && (
                                <div className='col-md-12 mb-1 mt-1'>
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        {
                                            showAlertDanger &&
                                            <Alert type="error" message={message} showIcon closable />
                                        }
                                    </Space>
                                </div>
                            )
                        }
                        <label htmlFor="nom" className="form-label ">Phone Number</label>
                        <PhoneInput
                            inputStyle={{
                                width: '100%',

                            } as React.CSSProperties}
                            inputClass={'form-control'}
                            country={'tz'}
                            value={phone}
                            onChange={changePhoneNumber}
                            enableSearch={true}
                            countryCodeEditable={true}
                        />
                    </div>

                    <div className=' col-md-12'>
                        <label htmlFor="nom" className="form-label ">Choose your Mobile Operator</label>
                        <select className="form-control" name='cardMobile' value={cardMobile} onChange={(e) => { setCardMobile(e.target.value) }}>
                            <option value="">Select
                                your card
                            </option>
                            {
                                loadOperator.map((item, i) => (
                                    <option key={i} value={item.id}>{item.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className='col-md-12'>
                        <div className="pt-5">
                            {!Loading && (
                                <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={() => { AddMobileOperator() }}>Save your mobile</button>
                            )}

                            {Loading && (

                                <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Processing...</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

            </Popup>

            {/**Verify code validate */}
            <Popup
                title='Enter the validation code'
                openPupop={viewPopup}
                setOpenPopup={SetViewPopup}
                style={{ zIndex: 1500 }}
            >
                <div className="row">

                    <div className='col-md-12'>
                        {
                            message && (
                                <div className='col-md-12 mb-1 mt-1'>
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        {
                                            showAlertDanger &&
                                            <Alert type="error" message={message} showIcon closable />
                                        }
                                    </Space>
                                </div>
                            )
                        }
                        <label htmlFor="nom" className="form-label ">Enter code</label>

                        <input type="text" id="mobile_code"
                            className="form-control" placeholder="Enter code"
                            name="codeVal" value={codeVal} onChange={(e) => { setCodeVal(e.target.value) }} />

                        <div style={{ display: 'flex', marginTop: 2 }}>
                            <p style={{ paddingLeft: 5, marginRight: 10 }}>Dont reiceve otp ?</p>
                            <div style={{ color: '#1576ED', cursor: 'pointer' }}
                                onClick={() => { ResendOtp() }}
                            >Resend</div>
                        </div>

                    </div>


                    <div className='col-md-12'>
                        <div className="pt-2">
                            {!Loading && (
                                <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={() => { VerifyMobileOTP() }}>Validate the code</button>
                            )}

                            {Loading && (

                                <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Processing...</span>
                                </button>
                            )}
                        </div>
                    </div>

                </div>


            </Popup>

            <Popup
                title='Update Your Mobile
                Account'
                openPupop={viewPopupUpdate}
                setOpenPopup={SetViewPopupUpdate}
                style={{ zIndex: 1500 }}
            >
                <div className="row">
                    <div className='mb-2 col-md-12'>
                        {
                            message && (
                                <div className='col-md-12 mb-1 mt-1'>
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        {
                                            showAlertDanger &&
                                            <Alert type="error" message={message} showIcon closable />
                                        }
                                    </Space>
                                </div>
                            )
                        }

                        <input className="form-control" value={id} onChange={(e) => setId(e.target.value)} />
                    </div>

                    <div className='mb-2 col-md-12'>
                        <label htmlFor="nom" className="form-label ">Phone Number</label>
                        <PhoneInput
                            inputStyle={{
                                width: '100%',

                            } as React.CSSProperties}
                            inputClass={'form-control'}
                            country={'tz'}
                            value={phone}
                            onChange={changePhoneNumber}
                            enableSearch={true}
                            countryCodeEditable={true}
                        />
                    </div>


                    <div className='col-md-12'>
                        <div className="pt-5">
                            {!Loading && (
                                <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={() => { UpdateMobileOperator(id) }}>Update your mobile</button>
                            )}

                            {Loading && (

                                <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Processing...</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

            </Popup>
        </>
    )
}

export default LinkPaymentMethod