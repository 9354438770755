// src/services/socket.ts
import { io, Socket } from 'socket.io-client';
import { logoSm } from '../components/icons';
import api from '../config/Config';
export const URL_SOCKET = process.env.REACT_APP_SOCKET_URL;

const socket: Socket = io(`${URL_SOCKET}`);

socket.on('connect', () => {
  console.log('Connected to server');
  const payload = {
    message: "Online to partner",
    token: api.initUserIdSocket(),
  };
  
  socket.emit('message', payload);
});

// Demander la permission pour afficher les notifications
if (Notification.permission === 'default') {
  Notification.requestPermission();
}

socket.on('private message', (data) => {
  const { key, message } = data;
  
  if (Notification.permission === 'granted') {
    new Notification('Tiva notification', { // Définir le titre ici
      body: message,
      icon: `${logoSm}`, // URL de votre icône personnalisée
      badge: `${logoSm}`, // Optionnel : icône affichée en petit dans la barre de notification
      data: { // Données personnalisées que vous pouvez utiliser plus tard
        key,
        message,
      },
    });
  } else {
    console.log('Notification permission not granted.');
  }
});

export default socket;
