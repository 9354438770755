import { LegacyRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Select } from 'antd';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../config/Config'
import requestRow from '../../controller/RequestRow';
import toast, { Toaster } from 'react-hot-toast';
import Popup from '../../components/popup';
import PhoneInput from 'react-phone-input-2'
import {notification } from 'antd'
import Select from 'react-select';
import Swal from 'sweetalert2'
import Footer from '../../components/footer';
import Header from '../../components/header';
import LinkPaymentMethod from './LinkPaymentMethod/LinkPaymentMethod';

declare module 'react' {
    interface HTMLAttributes<T extends HTMLDivElement> {
        show?: () => void;
        hide?: () => void;
    }
}


const Payment = () => {
    const options = [
        { label: "United States", value: "usa1" },
        { label: "Tanzania", value: "tnz" },
        { label: "Dr Congo", value: "dcn" },
        { label: "Zambia", value: "zamb" },
        { label: "Angola", value: "angl" },
        { label: "Zimbabwe", value: "zambwa" },
        { label: "Ethiopia", value: "ethop" },
        { label: "Nigeria", value: "ngra" },
        { label: "South Africa", value: "safrc" },
    ];

    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleOptions = (option: any) => {
        setSelectedOption(option);
    };

    const handleClear = () => {
        setSelectedOption(options[0]);
    };

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);

    const data = '';
    const [loadOperator, setLoadOperator] = useState<requestRow[]>([]);
    const [loadPayement, setLoadPayement] = useState<requestRow[]>([]);
    const [phone, setPhone] = useState<string>('');
    const [cardMobile, setCardMobile] = useState('');
    const [codeVal, setCodeVal] = useState('');
    const [id, setId] = useState('');
    const [idOtp, setIdOtp] = useState('');

    // variable to receive the phone number after user make the Mobile number
    const [receivePhoneNumber, setReceivePhoneNumber] = useState('');
    const [viewPopup, SetViewPopup] = useState(false)
    const [viewPopup1, SetViewPopup1] = useState(false)
    const [viewPopupUpdate, SetViewPopupUpdate] = useState(false)

    const [showOptions, setShowOptions] = useState<Record<number, boolean>>({});
    const toggleShowOptions = (i: number): void => { setShowOptions({ ...showOptions, [i]: !showOptions[i] }); };

    function ViewPop() {
        SetViewPopup(true)
    }
    function ViewPop1() {
        SetViewPopup1(true)
    }
    function ViewPopUpdate1(element: any) {
        SetViewPopupUpdate(true);
        setPhone(element.phone);
        setId(element.id);
    }


    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }

        loadMobileOperator();
        payementMobile();
    }, []);

    const loadMobileOperator = () => {
        api.getHttpRequest("/parameters/mobile-operator/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setLoadOperator(json.data);
            }
        }).catch((error) => {
            api.getMessageError(error)
        })
    }

    const payementMobile = () => {
        api.getHttpRequest("/method-payment/phone/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setLoadPayement(json.data);
            }
        }).catch((error: any) => {
            api.getMessageError(error)
        })
    }

    //Add link mobile operator
    const AddMobileOperator = async () => {
        let data = JSON.stringify({
            "phone": phone,
            "id_telecom": cardMobile
        });

        setLoading(true);

        if (phone === '' || cardMobile === '') {
            // toast.error('No field should be empty');
            api.Toast1.fire({
                icon: 'error',
                title: 'No field should be empty'
            })
        }
        else {
            api.getHttpRequest("/method-payment/phone/create", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setReceivePhoneNumber(phone)
                    api.Toast1.fire({
                        icon: 'success',
                        title: `${json.message}`
                    })
                    // toast.success(json.message);
                    SetViewPopup1(false);
                    ViewPop()
                    setPhone('')
                    setCardMobile('')
                    payementMobile();
                    // setShowVerifyOTP(true);
                } else {
                    setLoading(false)
                    api.Toast1.fire({
                        icon: 'error',
                        title: `${json.message}`
                    })
                    // toast.error(json.message);
                }
            }).catch((error) => {
                setLoading(false)
                api.getMessageError(error)
            })
        }

        setTimeout(() => {
            setLoading(false);
        }, 2500);
    }

    const UpdateMobileOperator = async (id: any) => {

        setLoading(true);

        api.getHttpRequest(`/method-payment/phone/set-default/${id}`, {}, 'PUT').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setReceivePhoneNumber(phone)
                api.Toast1.fire({
                    icon: 'success',
                    title: `${json.message}`
                })
                // toast.success(json.message);
                SetViewPopupUpdate(false);
                setId('');
                setPhone('')
                setCardMobile('')
                payementMobile();
                // setShowVerifyOTP(true);
            } else {
                setLoading(false)
                api.Toast1.fire({
                    icon: 'success',
                    title: `${json.message}`
                })
                // toast.error(json.message);
            }
        }).catch((error) => {
            setLoading(false)
            // api.getMessageError(error)
        })
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    // const deleteMobileOperator = async (id: any) => {
    //     api.getHttpRequest(`/user/payement-method/delete/${id}`, {}, 'Delete').then((response) => {
    //         const json = response.data;
    //         if (json.status == 200) {
    //             toast.success(json.message);
    //             payementMobile();
    //             // setShowVerifyOTP(true);
    //         } else {
    //             toast.error(json.message);
    //         }
    //     }).catch((error) => {
    //         setLoading(false)
    //         if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
    //             toast.error("Please connect your device to the internet");
    //         } else if (error.response && error.response.data && error.response.data.message) {
    //             toast.error(error.response.data.message);
    //         } else {
    //             toast.error(error.message);
    //         }
    //     })
    // }

    const handlePopVisibleChange = (i: number, id: any, visible: boolean): void => {
        setPopVisible({ ...popVisible, [i]: visible }); if (!visible) {

            api.getHttpRequest(`/user/payement-method/delete/${id}`, {}, 'Delete').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    toast.success(json.message);
                    payementMobile();
                    // setShowVerifyOTP(true);
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                setLoading(false)
                api.getMessageError(error)
            })
        }
    };

    //Verify code otp to validate mobile operator
    const VerifyMobileOTP = async () => {
        let data = JSON.stringify({
            "verificationCode": codeVal
        });

        setLoading(true);

        if (codeVal === '') {
            toast.error('No field should be empty');
        }
        else {
            api.getHttpRequest("/method-payment/phone/verify", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setIdOtp(json.data.id)
                    toast.success(json.message);
                    SetViewPopup(false);
                    setReceivePhoneNumber('')
                    setCodeVal('');
                    payementMobile();
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                // setLoading(false)       
                // api.getMessageError(error)
            })
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }

    const ResendOtp = async () => {
        api.getHttpRequest(`/method-payment/phone/resend-verification/${idOtp}`, {}, 'PUT').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                toast.success(json.message);
            } else {
                toast.error(json.message);
            }
        }).catch((error) => {
            setLoading(false)
            api.getMessageError(error)
        })
    }

    //Change phone number
    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    function handleChange(value: string) {
        // console.log(`selected ${value}`);
        setSelectedValue(value);
    }

    const [selectedValue1, setSelectedValue1] = useState("");

    const handleChange1 = (event: any) => {
        setSelectedValue1(event.target.value as string);
    };

    const [popVisible, setPopVisible] = useState<Record<number, boolean>>({});

    const [selectedValue, setSelectedValue] = useState('tnz');
    const [value, setValue] = useState(null);

    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);

    const [api1, contextHolder] = notification.useNotification();


    // const showSweetAlert = (element: any) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: `Want to remove this link mobile operator ${element.phone} / ${element.MobileOperator.name}`,
      
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!',
    //         customClass: 'swal-height-10',

    //     }).then((result) => {
    //         if (result.isConfirmed) {

    //             api.getHttpRequest(`/method-payment/phone/delete/${element.id}`, {}, 'Delete').then((response) => {
    //                 const json = response.data;
    //                 if (json.status == 200) {
    //                     payementMobile();
    //                     api.Toast1.fire({
    //                         icon: 'success',
    //                         title: `${json.message}`
    //                     })
    //                 } else {
    //                     api.Toast1.fire({
    //                         icon: 'success',
    //                         title: `${json.message}`
    //                     })
    //                 }
    //             }).catch((error) => {
    //                 setLoading(false)
    //                 api.getMessageError(error)
    //             })
    //         }
    //     })

    // }



    return (

        <>


            <div id="wrapper">

                <Header />
               
               <LinkPaymentMethod/>
            </div>



        </>
    )
}

export default Payment
