import React, { useEffect, useState } from "react";
import { loginImage } from "../../../components/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../config/Config";
import toast, { Toaster } from "react-hot-toast";
import CryptoJS from "crypto-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Alert, Button, Space } from "antd";
import { InterfaceApp } from "../../../controller/InterfaceApp";
import { useQueryClient } from "react-query";

const Login = () => {
  const { TokenUser } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState<string>("");
  const [apps, setApps] = useState<InterfaceApp[]>([]);
  const [password, setPassword] = useState("");
  const [getApp, setGetApp] = useState("");
  const [showAlertDanger, setShowAlertDanger] = useState(false);
  const [message, setMessage] = useState("");

  const changePhoneNumber = (value: string, country: any) => {
    const countryCode = country.dialCode;
    const phoneNumber = value.replace(countryCode, `+${countryCode}`);
    setUsername(phoneNumber);
  };
  const isMobileScreen = window.innerWidth <= 768;

  useEffect(() => {
    if (TokenUser) {
      loginByToken();
    }

    LoadApp();
  }, []);

  const loginByToken = () => {
    api
      .getHttpRequest(`/user/login-by-token/${TokenUser}`, {}, "GET")
      .then((response) => {
        const json = response.data;
        if (json.status === 200) {
          setUsername("");
          setPassword("");

          const key = "ma_clé_de_chiffrement_token_userpartner";
          const key_user = "ma_clé_de_chiffrement_user_connecter";
          const key_avatar = "cle_avatar";
          const key_role = "its_role_key";
          const key_partner_id = "it_partner_id";
          const key_account_access = "its_account_access";
          const key_url = "its_url";
          const key_id_user = "id_user";
          const key_to_url = "url_app_user";
          const key_to_phone = "url_app_phone";
          const key_accessList = "url_accessList";

          // Définir la variable à crypter
          const token = json.data.token;
          const user_connect = json.data.username;
          const user_phone = json.data.phone;
          const user_id = json.data.id;
          const user_avatar = json.data.avatar;
          const user_role = json.data.role;
          const id_partner = json.data.partner_id;
          const user_account_access = json.data.account_access;
          const rolesCanCreateString = JSON.stringify(user_account_access);
          const user_access_list = json.data.accessList;
          const user_is_show_member =
            json.data.account_access.infos.show_members;
          const user_is_url_login =
            json.data.account_access.infos.MetadataList.Application.url;
          const name_app_access =
            json.data.account_access.infos.MetadataList.Application.name;
          //root url pour redigiger l'utilisateur

          const tokenCrypter = CryptoJS.AES.encrypt(token, key).toString();
          const userconnectCrypter = CryptoJS.AES.encrypt(
            user_connect,
            key_user
          ).toString();
          const phoneconnectCrypter = CryptoJS.AES.encrypt(
            user_phone,
            key_to_phone
          ).toString();
          const avatarCrypter = CryptoJS.AES.encrypt(
            user_avatar,
            key_avatar
          ).toString();
          const userRoleCrypter = CryptoJS.AES.encrypt(
            user_role,
            key_role
          ).toString();
          const userIdCrypter = CryptoJS.AES.encrypt(
            id_partner,
            key_partner_id
          ).toString();
          const userAccountAccess = CryptoJS.AES.encrypt(
            rolesCanCreateString,
            key_account_access
          ).toString();
          const userAccassList_list = CryptoJS.AES.encrypt(
            user_access_list,
            key_accessList
          ).toString();
          const userUrlAccess = CryptoJS.AES.encrypt(
            user_is_url_login,
            key_url
          ).toString();
          const userIAccess = CryptoJS.AES.encrypt(
            user_id,
            key_id_user
          ).toString();
          const urlRoute = CryptoJS.AES.encrypt(
            name_app_access,
            key_to_url
          ).toString();

          sessionStorage.setItem("onktxonktx", tokenCrypter);
          sessionStorage.setItem("sutielzatiwo", userconnectCrypter);
          sessionStorage.setItem("obvokotoratebo", avatarCrypter);
          sessionStorage.setItem("bobaonsraoulexajs", userRoleCrypter);
          sessionStorage.setItem("pppaidlozartooner", userIdCrypter);
          sessionStorage.setItem("donkamdiquoeserner", userAccountAccess);
          sessionStorage.setItem("isssmmenmbre", user_is_show_member);
          sessionStorage.setItem("iosrelbulrleogo", userUrlAccess);
          sessionStorage.setItem("iiiiisuusdider", userIAccess);
          sessionStorage.setItem("rpkjoutteappb", urlRoute);
          sessionStorage.setItem("rpkjoutteapphbonebor", phoneconnectCrypter);
          sessionStorage.setItem(
            "listrpkjoutteapphboneboraccess",
            userAccassList_list
          );

          // sessionStorage.setItem('Isshowmember', json.data.account_access.infos.show_members);
          const getRoute =
            json.data.account_access.infos.MetadataList.Application.route;

          navigate(`${getRoute}`);
        } else {
          // window.location.href = `http://localhost:5173/#/tiva-account/${json.data.account_access.infos.MetadataList.Application.name}`;//dev
          // window.location.href = `https://www.tivaaccount.tiva.co.tz/#/tiva-account/${json.data.account_access.infos.MetadataList.Application.name}`; // prod
          window.location.href = api.RedirectionAccess();
        }
      })
      .catch((error) => {
        toast.error(api.getMessageError(error));
      });
  };

  const GetClickApp = (name: any) => {
    setGetApp(name);
  };
  const LoadApp = async () => {
    api
      .getHttpRequestNotToken("/parameters/applications-public", {}, "GET")
      .then((response) => {
        const json = response.data.data;
        const filteredApps = json.filter(
          (app: any) => app.name === "Partner App" || app.name === "Vendor App"
        );
        setApps(filteredApps);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  const queryClient = useQueryClient();
  const loginSubmit = async () => {
    let data = JSON.stringify({
      username: username,
      password: password,
    });
    setLoading(true);
    if (username === "" || password === "") {
      setShowAlertDanger(true);
      setMessage("The Email and Password fields must not be empty");
    } else {
      api
        .getHttpRequest(`/user/login/${getApp}`, data, "POST")
        .then((response) => {
          const json = response.data;
          if (json.status === 200) {
            setUsername("");
            setPassword("");
            localStorage.setItem(
              "Access",
              JSON.stringify(json.data.accessList)
            );
            const key = "ma_clé_de_chiffrement_token_userpartner";
            const key_user = "ma_clé_de_chiffrement_user_connecter";
            const key_avatar = "cle_avatar";
            const key_role = "its_role_key";
            const key_partner_id = "it_partner_id";
            const key_account_access = "its_account_access";
            const key_url = "its_url";
            const key_id_user = "id_user";
            const key_to_url = "url_app_user";
            const key_to_phone = "url_app_phone";
            const key_iduser_socket = "key_iduser_socket_jv";

            // Définir la variable à crypter
            const token = json.data.token;
            const user_connect = json.data.username;
            const user_phone = json.data.phone;
            const user_id = json.data.id;
            const user_avatar = json.data.avatar;
            const user_role = json.data.role;
            const id_partner = json.data.partner_id;
            const user_account_access = json.data.account_access;
            const rolesCanCreateString = JSON.stringify(user_account_access);
            const user_is_show_member =
              json.data.account_access.infos.show_members;
            const user_is_url_login =
              json.data.account_access.infos.MetadataList.Application.url;
            const name_app_access =
              json.data.account_access.infos.MetadataList.Application.name;
            //root url pour redigiger l'utilisateur

            const tokenCrypter = CryptoJS.AES.encrypt(token, key).toString();
            const userconnectCrypter = CryptoJS.AES.encrypt(
              user_connect,
              key_user
            ).toString();
            const phoneconnectCrypter = CryptoJS.AES.encrypt(
              user_phone,
              key_to_phone
            ).toString();
            const avatarCrypter = CryptoJS.AES.encrypt(
              user_avatar,
              key_avatar
            ).toString();
            const userRoleCrypter = CryptoJS.AES.encrypt(
              user_role,
              key_role
            ).toString();
            const userIdCrypter = CryptoJS.AES.encrypt(
              id_partner,
              key_partner_id
            ).toString();
            const userAccountAccess = CryptoJS.AES.encrypt(
              rolesCanCreateString,
              key_account_access
            ).toString();
            const userUrlAccess = CryptoJS.AES.encrypt(
              user_is_url_login,
              key_url
            ).toString();
            const userIAccess = CryptoJS.AES.encrypt(
              user_id,
              key_id_user
            ).toString();
            const urlRoute = CryptoJS.AES.encrypt(
              name_app_access,
              key_to_url
            ).toString();
            const UserIdSocket = CryptoJS.AES.encrypt(
              user_id,
              key_iduser_socket
            ).toString();

            sessionStorage.setItem("onktxonktx", tokenCrypter);
            sessionStorage.setItem("sutielzatiwo", userconnectCrypter);
            sessionStorage.setItem("obvokotoratebo", avatarCrypter);
            sessionStorage.setItem("bobaonsraoulexajs", userRoleCrypter);
            sessionStorage.setItem("pppaidlozartooner", userIdCrypter);
            sessionStorage.setItem("donkamdiquoeserner", userAccountAccess);
            sessionStorage.setItem("isssmmenmbre", user_is_show_member);
            sessionStorage.setItem("iosrelbulrleogo", userUrlAccess);
            sessionStorage.setItem("iiiiisuusdider", userIAccess);
            sessionStorage.setItem("rpkjoutteappb", urlRoute);
            sessionStorage.setItem("rpkjoutteapphbonebor", phoneconnectCrypter);
            sessionStorage.setItem("socketusersconnexotejv", UserIdSocket);
            const user_is_app_login =
            json.data.account_access.infos.MetadataList.Application.name;
            const getRoute = json.data.accessList.home_page;

            if (user_is_app_login === 'Partner App') {
              navigate(getRoute === null ? "/home" : getRoute);
            } else if (user_is_app_login === 'Vendor App') {
              navigate("/vendor");
            } else {
              navigate(`/login`);
            }
            
          } else {
            setShowAlertDanger(true);
            setMessage(json.message);
            navigate("/login");
          }
        })
        .catch((error) => {
          if (
            !window.navigator.onLine &&
            !error.response &&
            error.code === "ERR_NETWORK"
          ) {
            setShowAlertDanger(true);
            setMessage("Please connect your device to the internet");
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setShowAlertDanger(true);
            setMessage(error.response.data.message);
          } else {
            setShowAlertDanger(true);
            setMessage(error.message);
          }
        });
    }

    setTimeout(() => {
      setLoading(false);
      setShowAlertDanger(false);
      setLoading(false);
    }, 3000);
  };

  return (
    <>
      {/* <div style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				minHeight: '100vh',
				background: '#F3F6F9',
			}}>
				<svg xmlns="http://www.w3.org/2000/svg"
				 height="30" width="30" viewBox="0 0 512 512"
				 style={{ animation: 'spin 30s linear infinite'}} 
				 >
					<path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" 
					  fill="#1576ED"
					/>
				</svg>

			</div> */}

      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-form validate-form">
              <h2 className="header-title mb-3 font-30 border-0 text-center" style={{fontFamily: `"Noto Sans", sans-serif`}}>
                Login to {getApp}
              </h2>
              <p className="text-center  mb-3" style={{fontFamily: `"Noto Sans", sans-serif`}}>
                Please select the application to connect to the system
              </p>
              <div
                className="text-center"
                style={{
                  display: "flex",
                  marginBottom: 10,
                  marginLeft: isMobileScreen ? 10 : 70,
                }}
              >
                {apps.map((app) => (
                  <div className="text-center font-weight-normal">
                    <Button
                      style={{
                        fontSize: 15,
                        marginRight: 10,
                        cursor: "pointer",
                        alignItems: "center",
                        justifyContent: "center",
                        borderColor: "#8C8C8C",
                        width: isMobileScreen ? 170 : 200,
                        height: 60,
                        fontFamily:`"Noto Sans", sans-serif`
                      }}
                      onMouseEnter={(e) =>
                        ((e.target as any).style.borderColor = "#8C8C8C")
                      }
                      onMouseLeave={(e) =>
                        ((e.target as any).style.borderColor = "#8C8C8C")
                      }
                      onClick={() => GetClickApp(app.name)}
                      
                    >
                      <img
                        src={app.icon}
                        style={{ width: 20, height: 20, marginRight: 5 }}
                      />
                      {app.name}
                    </Button>
                  </div>
                ))}
              </div>

              {message && (
                <div className=" mb-1">
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {showAlertDanger && (
                      <Alert type="error" message={message} showIcon closable />
                    )}
                  </Space>
                </div>
              )}

              {getApp && (
                <>
                  <div>
                    <label style={{fontFamily:`"Noto Sans", sans-serif`}}>Email or phone</label>
                    <div
                      className="wrap-input100 "
                      data-validate="Valid email is required: ex@abc.xyz"
                    >
                      <input
                        className="input100"
                        type="text"
                        name="email"
                        placeholder="Email or phone"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div>
                    <label style={{fontFamily:`"Noto Sans", sans-serif`}}>Password</label>
                    <div
                      className="wrap-input100 validate-input"
                      data-validate="Password is required"
                    >
                      <input
                        className="input100"
                        type="password"
                        name="pass"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="flex-sb-m w-full py-4">
                    <div className="contact100-form-checkbox">
                      <input
                        className="input-checkbox100"
                        id="ckb1"
                        type="checkbox"
                        name="remember-me"
                      />
                      <label className="label-checkbox100" style={{fontFamily:`"Noto Sans", sans-serif`}}>Remember me</label>
                    </div>
                    <div>
                      <Link to="/forgot_pswd" className="txt1" style={{fontFamily:`"Noto Sans", sans-serif`}}>
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="container-login100-form-btn">
                    {!loading && (
                      <button
                        className="login100-form-btn"
                        onClick={() => loginSubmit()}
                        style={{fontFamily:`"Noto Sans", sans-serif`}}
                      >
                        Login
                      </button>
                    )}
                    {loading && (
                      <button className="login100-form-btn">
                        <i
                          className="fa fa-spinner fa-spin fa-3x  fa-lg"
                          style={{ fontSize: 20 , fontFamily:`"Noto Sans", sans-serif`}}
                        ></i>
                        Connecting...
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
            <div
              className="login100-more"
              style={{ backgroundImage: `url(${loginImage})` }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
