import React, { useState, useEffect } from 'react';
import { Upload, Button, Modal, message, Typography, Row, Col, Table, Tag, Dropdown, Menu, Select, Steps, DatePicker, Progress } from 'antd';
import { UploadOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { SiMicrosoftexcel } from 'react-icons/si';
import axios from 'axios';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { Console } from 'console';
import api from '../../../config/Config'
import requestRow from '../../../controller/RequestRow';

const { Step } = Steps;

const { Option } = Select;

const requiredColumns = ['name', 'middlename', 'lastname', 'phone', 'email', 'address', 'gender', 'civil_status', 'date_of_birth', 'place_of_birth'];

interface DialogUploadExcelProps {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  load: () => void;
  title: string;
}


const DialogUploadExcel: React.FC<DialogUploadExcelProps> = ({ isModalOpen, handleOk, handleCancel, load, title }) => {
  const [Loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [columnMapping, setColumnMapping] = useState<Record<string, string>>({});
  const [missingColumns, setMissingColumns] = useState<string[]>([]);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [mappingColumn, setMappingColumn] = useState<string | null>(null);
  const [underRoles, setUnderRoles] = useState<any[]>([]);

  const [memberUnder, setMemberUnder] = useState<requestRow[]>([])
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [selectedMemberEmails, setSelectedMemberEmails] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [loardSms, setLoardSms] = useState<requestRow[]>([]);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
  const [getIdSms, setGetIdSms] = useState<string[]>([]);
  const [smsClendarData, setSmsClendarData] = useState<{ datetime: string; smsIdList: string[] }>({
    datetime: '',
    smsIdList: [],
  });
  const [categoriRole, setCategoriRole] = useState<requestRow[]>([]);
  const handleChangeMemberSelection = (value: any) => {
    setSelectedMemberEmails(value);
  };



  const steps = [
    { title: 'Upload' },
    { title: 'User Select' },
    { title: 'Calendar' },
  ];

  useEffect(() => {
    loadUnderRoles();
    loadSmsPlaning();
    loadCategorie_or_Role();
  }, []);

  useEffect(() => {
    if (selectedRole) {
      loadUnderMembers(selectedRole);
    }
  }, [selectedRole]);


  const loadUnderRoles = async () => {
    api.getHttpRequestV3("/user/partner/member/load_roles_under", {}, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setUnderRoles(json.data);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

        console.log("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        console.log(error.response.data.message);
      } else {
        console.log(error.message);
      }
    })
  }

  const loadCategorie_or_Role = async () => {
    api.getHttpRequest("/parameters/roles/load-admin", '', 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setCategoriRole(json.data);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

        message.error("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        message.error(error.response.data.message);
      } else {
        message.error(error.message);
      }
    })
  }

const handleFinish = async () => {
  setLoading(true);
  const selectedMemberIds = memberUnder
    .filter(member => selectedMemberEmails.includes(member?.User.id))
    .map(member => member.User.id);

  const totalRows = fileData.length;
  let progress = 0;

  const progressModal = Modal.info({
    title: 'Processing',
    content: (
      <div>
        <p>We are processing your data. Please wait...</p>
        <Progress percent={progress} status="active" />
      </div>
    ),
    closable: false,
    okButtonProps: { style: { display: 'none' } },
  });

  for (let i = 0; i < totalRows; i++) {
    const row = fileData[i];
    const userData: Record<string, any> = {};
    const otherObject: Record<string, any> = {};

    requiredColumns.forEach((requiredColumn) => {
      const mappedColumn = Object.keys(columnMapping).find(key => columnMapping[key] === requiredColumn) || requiredColumn;
      const columnIndex = headers.indexOf(mappedColumn);

      if (columnIndex !== -1) {
        userData[requiredColumn] = typeof row[columnIndex] === 'string' ? row[columnIndex] : row[columnIndex].toString();
        if (requiredColumn === 'phone' && userData[requiredColumn] && !userData[requiredColumn].startsWith('+')) {
          userData[requiredColumn] = '+' + userData[requiredColumn];
        }
      } else {
        userData[requiredColumn] = '';
      }
    });

    headers.forEach((header, index) => {
      if (!Object.values(columnMapping).includes(header)) {
        otherObject[header] = row[index] !== undefined ? row[index].toString() : '';
      }
    });

    userData.users_assign_data = selectedMemberIds;
    userData.other_object = otherObject;
    userData.sms_clendar = {
      datetime: smsClendarData.datetime,
      smsIdList: getIdSms,
    };

    await api.getHttpRequestV3('/user/partner/member/create', userData, 'POST')
      .then((response) => {
        const json = response.data;

        if (json.status === 200) {
          progress = Math.round(((i + 1) / totalRows) * 100);
          progressModal.update({
            content: (
              <div>
                <p>We are processing your data. Please wait...</p>
                <Progress percent={progress} status="active" />
              </div>
            ),
          });
        }

        if (i === totalRows - 1) {
          Modal.destroyAll();
          Modal.success({
            title: 'Success',
            content: (
              <div>
                <p>All data processed successfully.</p>
              </div>
            ),
            onOk: () => {
              handleRemoveFile();
              setCurrentStep(0);
              setSelectedMemberEmails([]);
              setGetIdSms([]);
              setSelectedTime(null);
            },
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        Modal.destroyAll();
        Modal.error({
          title: 'Error',
          icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
          content: (
            <div>
              <p>{error.response.data.message}</p>
            </div>
          ),
          okText: 'OK',
          okButtonProps: { style: { backgroundColor: '#f5222d', borderColor: '#f5222d' } },
          onOk: () => {
            setCurrentStep(0);
            setSelectedMemberEmails([]);
            setGetIdSms([]);
            setSelectedTime(null);
          },
        });
        setLoading(false);
      });
  }
};
  const loadUnderMembers = async (roleId: string) => {
    api.getHttpRequestV3(`/user/partner/member/load_members_under/${roleId}`, {}, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setMemberUnder(json.data);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

        console.log("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        console.log(error.response.data.message);
      } else {
        console.log(error.message);
      }
    })
  }
  const handleFileUpload = (file: any) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (!jsonData.length) {
          throw new Error('The file is empty or not in the correct format.');
        }

        const headers: string[] = jsonData[0] as string[];
        const missing = requiredColumns.filter(col => !headers.includes(col));

        setMissingColumns(missing);
        setFileData(jsonData.slice(1));
        setHeaders(headers);
        setUploadedFileName(file.name);

        if (missing.length === 0) {
          message.success('All required columns are present. File uploaded successfully.');
        } else {
          message.warning('Some required columns are missing. Please map them.');
        }
      } catch (error) {
        console.log('Error reading file.');
      }
    };
    reader.onerror = () => {
      console.log('Failed to read file. Please try again.');
    };
    reader.readAsArrayBuffer(file);
    return false;
  };
  const handleColumnMapping = (selectedColumn: string) => {
    if (mappingColumn && !Object.values(columnMapping).includes(selectedColumn)) {
      const updatedMapping = { ...columnMapping, [mappingColumn]: selectedColumn };
      setColumnMapping(updatedMapping);

      const updatedMissingColumns = missingColumns.filter(col => col !== mappingColumn);
      setMissingColumns(updatedMissingColumns);

      setMappingColumn(null);
      message.info(`Mapped ${mappingColumn} to ${selectedColumn}.`);
    }
  };

  const handleRemoveFile = () => {
    setFileData([]);
    setUploadedFileName('');
    setHeaders([]);
    setColumnMapping({});
    setMissingColumns([]);
    setMappingColumn(null);
    handleCancel();
    message.info('File removed.');
  };

  const handleNext = () => {
    const missing = [];
    if (!headers.includes('name') && !Object.values(columnMapping).includes('name')) {
      missing.push('"name"');
    }
    if (!headers.includes('phone') && !Object.values(columnMapping).includes('phone')) {
      missing.push('"phone"');
    }

    if (missing.length > 0) {
      Modal.error({
        title: 'Required Mapping',
        icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
        content: (
          <div style={{ fontFamily: '"Noto Sans", sans-serif', color: '#555' }}>
            <p>You must map {missing.join(' and ')} before continuing.</p>
          </div>
        ),
        okText: 'OK',
        okButtonProps: { style: { backgroundColor: '#f5222d', borderColor: '#f5222d' } },
      });
    } else {
      if (currentStep === 1) {
        setCurrentStep(2);
      } else {
        Modal.confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
          content: (
            <div style={{ fontFamily: '"Noto Sans", sans-serif', color: '#555' }}>
              <p>Are you sure you want to continue ?</p>
            </div>
          ),
          okText: 'Continue',
          cancelText: 'Cancel',
          okButtonProps: { style: { backgroundColor: '#1890ff', borderColor: '#1890ff' } },
          onOk: () => setCurrentStep(1),
        });
      }
    }
  };

  const columns = headers.map((header, index) => ({
    title: (
      <Dropdown
        overlay={
          <Menu>
            {requiredColumns
              .filter(
                col => missingColumns.includes(col) && !Object.values(columnMapping).includes(col)
              )
              .map((col, i) => (
                <Menu.Item key={i} onClick={() => handleColumnMapping(col)}>
                  <span tabIndex={0}>{col}</span>
                </Menu.Item>
              ))}
          </Menu>
        }
        trigger={['click']}
        disabled={requiredColumns.includes(header) && !missingColumns.includes(header)}
      >
        <span
          style={{
            color: columnMapping[header] || (requiredColumns.includes(header) && !missingColumns.includes(header))
              ? 'green'
              : missingColumns.includes(header)
                ? 'red'
                : 'blue',
            cursor: requiredColumns.includes(header) && !missingColumns.includes(header) ? 'default' : 'pointer',
            fontFamily: '"Noto Sans", sans-serif',
          }}
          onClick={() => {
            if (!requiredColumns.includes(header) || missingColumns.includes(header)) {
              setMappingColumn(header);
            }
          }}
          tabIndex={requiredColumns.includes(header) && !missingColumns.includes(header) ? -1 : 0}
          onKeyPress={(e) => {
            if (
              e.key === 'Enter' &&
              (!requiredColumns.includes(header) || missingColumns.includes(header))
            ) {
              setMappingColumn(header);
            }
          }}
        >
          {columnMapping[header] || header}
        </span>
      </Dropdown>
    ),
    dataIndex: index.toString(),
    key: index,
  }));

  const dataSource = fileData.map((row, rowIndex) => {
    const rowData: Record<string, any> = {};
    headers.forEach((header, colIndex) => {
      rowData[colIndex.toString()] = row[colIndex];
    });
    rowData.key = rowIndex;
    return rowData;
  });

  const handleChangeIdRole = (name: string[]) => {
    const lastEnteredRegion = name[name.length - 1];
    if (lastEnteredRegion) {
      setSelectedRole(lastEnteredRegion);
    } else {
      setSelectedRole('');
    }
  };


  const handleDateChange = (date: Dayjs | null, dateString: string | string[]) => {
    if (date) {
      console.log('Selected date:', date.format());
    } else {
      console.log('Date is null');
    }
    console.log('Date string:', dateString);
  };

  const loadSmsPlaning = async () => {
    api.getHttpRequestV3(`/user/partner/member/load_sms_planing`, {}, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setLoardSms(json.data);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

        console.log("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        console.log(error.response.data.message);
      } else {
        console.log(error.message);
      }
    })
  }

  const handleOkDate = () => {
    console.log('OK button clicked');
  };

  const handleChangeIdSms = (value: any) => {
    setGetIdSms(value)
  }

  const handleCalendarChange = (date: dayjs.Dayjs | null, dateString: string) => {
    if (date) {
      const datetime = Array.isArray(dateString) ? dateString[0] : dateString;
      setSmsClendarData({ datetime, smsIdList: [] });
      setSelectedTime(date)
    }

  };
  return (
    <Modal
      title={title}
      visible={isModalOpen}
      onOk={() => handleOk()}
      onCancel={handleRemoveFile}
      width={'90%'}
      bodyStyle={{
        overflowY: 'auto',
        height: '500px',
      }}
      centered
      footer={[
        currentStep > 0 && (
          <Button
            key="back"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Back
          </Button>
        ),
        currentStep < steps.length - 1 ? (
          <>
            <Button
              key="next"
              type="primary"
              onClick={handleNext}
            >
              Next
            </Button>
            <Button key="cancel" onClick={handleRemoveFile}>
              Cancel
            </Button>
          </>
        ) : (
          <>
           {!Loading && (
            <Button
            key="finish"
            type="primary"
            onClick={handleFinish}
          >
            Finish
          </Button>
           )}
            
            {Loading && (

              <Button type='primary'

              >
                <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span></span>
              </Button>
            )}
          </>

        )
      ]}
    >
      <Steps current={currentStep} style={{ marginBottom: '24px' }}>
        {steps.map((step, index) => (
          <Step key={index} title={step.title} />
        ))}
      </Steps>

      {currentStep === 0 ? (
        <div>
          <div style={{ textAlign: 'center', marginBottom: '1rem', fontFamily: '"Noto Sans", sans-serif' }}>
            {uploadedFileName ? (
              <Row align="middle" justify="center">
                <Col>
                  <SiMicrosoftexcel style={{ fontSize: '48px', color: '#1F7246' }} />
                </Col>
                <Col>
                  <Typography.Text strong style={{ marginLeft: '1rem' }}>
                    {uploadedFileName}
                  </Typography.Text>
                </Col>
                <Col>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={handleRemoveFile}
                    style={{ marginLeft: '1rem', fontFamily: '"Noto Sans", sans-serif' }}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ) : (
              <Upload beforeUpload={handleFileUpload} accept=".xlsx, .xls">
                <Button icon={<UploadOutlined />}>Upload Excel File</Button>
              </Upload>
            )}
          </div>
          {headers.length > 0 && (
            <>
              <Typography.Title level={4} style={{ fontFamily: '"Noto Sans", sans-serif' }}>
                Required Columns
              </Typography.Title>
              <div style={{ fontFamily: '"Noto Sans", sans-serif', whiteSpace: 'nowrap', overflowX: 'auto', marginBottom: '20px' }}>
                {requiredColumns.map((col, index) => (
                  <span key={index} style={{ display: 'inline-flex', alignItems: 'center', marginRight: '10px' }}>
                    <Tag
                      color={
                        headers.includes(col) || Object.values(columnMapping).includes(col)
                          ? 'green'
                          : 'red'
                      }
                      style={{ fontFamily: '"Noto Sans", sans-serif', marginRight: '8px' }}
                    >
                      {col}
                    </Tag>
                    {headers.includes(col) || Object.values(columnMapping).includes(col) ? (
                      <CheckCircleOutlined style={{ color: 'green' }} />
                    ) : (
                      <CloseCircleOutlined style={{ color: 'red' }} />
                    )}
                  </span>
                ))}
              </div>
            </>
          )}
          <StyledTableContainer>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              scroll={{ y: '200px' }}
              className="w-full overflow-x-auto "
            />
          </StyledTableContainer>
        </div>
      ) : currentStep === 1 ? (
        <div>
          <div className="alert alert-info mt-3" role="alert">
            Make sure you select the users to whom we will send the data.
          </div>
          <div className='col-md-12 row'>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <p className="form-label ">Choose Role</p>
              <Select
                mode="multiple"
                placeholder="Filter by Roles"
                style={{ width: '100%' }}
                onChange={handleChangeIdRole}
              >
                {underRoles.map((role, i) => (
                  <Option key={i} value={role.UserRole?.id}>
                    {role.UserRole?.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <p className="form-label ">Choose Member</p>
              <Select
                mode="multiple"
                placeholder="Filter by Members"
                style={{ width: '100%' }}
                onChange={handleChangeMemberSelection}
                value={selectedMemberEmails}
              >
                {
                  memberUnder &&
                    memberUnder !== undefined ?
                    memberUnder.map((item, i) => (
                      <Option key={i} value={item?.User?.id}>{item?.User?.name + " " + item?.User?.lastname + " " + item?.User?.middlename}</Option>
                    ))
                    : "No Member"
                }
              </Select>
            </div>
          </div>
        </div>

      ) : (
        <div>
          <div className="alert alert-info mt-3" role="alert">
            Make sure you select the users to whom we will send the data.
          </div>
          <div className='col-md-12 row'>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <p className="form-label ">Setup date?</p>
              <DatePicker
                showTime
                onChange={handleCalendarChange}
                onOk={handleOkDate}
                value={selectedTime}
                style={{
                  width: '100%',
                  paddingLeft: 5
                }}

              />
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <p className="form-label ">Sms planing</p>
              <Select
                mode="tags"
                placeholder="Please select"
                value={getIdSms}
                onChange={handleChangeIdSms}
                style={{
                  width: '100%',
                  paddingLeft: 5
                }}

              >
                <Option value="">Choose sms</Option>
                {
                  loardSms && loardSms.length > 0 ?
                    loardSms.map((item, i) => (
                      <Option key={i} value={item?.id}>{item?.name}</Option>
                    ))
                    : "No sms"
                }
              </Select>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

const StyledTableContainer = styled.div`
  .ant-table-wrapper {
    .ant-table-body {
      max-height: 300px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: '#23AAF2';
        border-radius: 10px;
        border: 3px solid white;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }

      scrollbar-width: thin;
      scrollbar-color: #23AAF2 white;
    }
  }
`;

export default DialogUploadExcel;