import { Select } from 'antd'
const { Option } = Select;

interface textInputPropos {
    value: string | any,
    setValue: (value: string | any) => void,
    label?: string,
    placeholder?: string,
    options?: { value: string; label: string }[];
    width?: number | string | any,
    labelOption?: string
}

const isMobileScreen = window.innerWidth <= 768;


const SelectPayement = (propos: textInputPropos) => {
    const { value, setValue, label, options, width, labelOption } = propos
    return (
        <div style={{  }}>
            {label && <div><label><label className="form-label">{label}</label></label></div>}
            {/* <Select
                style={{ width: isMobileScreen? "100%" : "100%", marginRight:'20px' }}  size='large'dropdownStyle={{ height: 200 }}
                value={value} onChange={(e) => { setValue(e) }}>
                <Option >{labelOption}</Option>
                {options && options.map((option) => (
                    <Option key={option.value} value={option.value} > 
                        {option.label}
                    </Option>
                ))}
            </Select> */}

            <select style={{borderColor:'#DCE6EC', fontFamily: `"Noto Sans", sans-serif`}} className="form-control" id="gender"
                name='gender'
                value={value} onChange={(e) => { setValue(e.target.value) }}
            >
                <option style={{fontFamily: `"Noto Sans", sans-serif`}}>{labelOption}</option>
                {options && options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default SelectPayement