import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { loginImage } from "../../../components/icons";
import api from '../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import CryptoJS from 'crypto-js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Logup = () => {
  const navigate = useNavigate()
  const [Loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState<string>('');

  const [account, setAcount] = useState({
    username: '',
    phone: ''

  });
  const onChangeValue = (e: any) => {
    setAcount({
      ...account,
      [e.target.name]: e.target.value
    })
  }

  const changePhoneNumber = (value: string, country: any) => {
		const countryCode = country.dialCode;
		const phoneNumber = value.replace(countryCode, `+${countryCode}`);
		setPhone(phoneNumber);
	  };

  const AccountSubmit = async () => {

    let data = JSON.stringify({
      "name": account.username,
      "phone": account.phone
    });

    setLoading(true);

    if (account.username === '' || account.phone === '') {
      toast.error('The Name and Phone fields must not be empty');

    } else {
      api.getHttpRequestNotToken("/user/create", data, 'POST').then((response) => {
        const json = response.data;
        if (json.status == 201) {
          toast.success(json.message)
          const key = 'phone_account';
          // Définir la variable à crypter
          const phone_account = account.phone;
          const phoneCrypter = CryptoJS.AES.encrypt(phone_account, key).toString();
          sessionStorage.setItem('zxremonailnephoy', phoneCrypter)
          navigate('/verify_otp')
        } else {
          // setLoading(false);
          toast.error(json.message);
        }
      }).catch((error) => {
        // setLoading(false);
        if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
          toast.error("Please connect your device to the internet");
        } else if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      })
    }

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }
  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <div>
                <Toaster
                  position="top-right"
                  reverseOrder={false}
                />
              </div>
              <h2 className="header-title mb-3 font-30 font-weight-normal border-0 text-center">
                Create Account
              </h2>
              <label>Enter Name</label>
              <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                <input className="input100" type="text"

                  name='username'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder='Enter Name'
                />

                
                {/* <span className="focus-input100" style={{ paddingLeft: 25, paddingTop: 5 }}>Enter Name</span> */}
                {/* <span className="label-input100">Enter Name</span> */}
              </div>
              <label>Phone Number</label>
              <div className="wrap-input100 validate-input" data-validate="Password is required">
                {/* <input className="input100" type="phone"
                  name='phone'
                  value={account.phone}
                  onChange={onChangeValue}
                  placeholder='Phone Number'
                /> */}
                <PhoneInput
                  inputStyle={{
                    display: 'block',
                    width: '100%',
                    background: '0 0',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '18px',
                    color: '#555',
                    lineHeight: '1.2',
                    height: '100%',
                    WebkitTransition: 'all .4s',
                    oTransition: 'all .4s',
                    mozTransition: 'all .4s',
                    transition: 'all .4s',


                  } as React.CSSProperties}
                  inputClass={'wrap-input100, validate-input'}
                  country={'tz'}
                  value={phone}
                  onChange={changePhoneNumber}
                  enableSearch={true}
                  countryCodeEditable={true}
                />
                {/* <span className="focus-input100" style={{ paddingLeft: 25, paddingTop: 5 }}>Phone Number</span> */}
                {/* <span className="label-input100">Phone Number</span> */}
              </div>
              <div className="flex-sb-m w-full py-4">
                <div className="contact100-form-checkbox">
                  <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
                  <label className="label-checkbox100" htmlFor="ckb1">
                    Remember me
                  </label>
                </div>
                <div>
                  <a href="#" className="txt1">
                    Resend Code?
                  </a>
                </div>
              </div>
              <div className="container-login100-form-btn">
                {!Loading && (
                  <button type="submit" className="login100-form-btn" onClick={AccountSubmit}>
                    Sign Up
                  </button>
                )}

                {Loading && (

                  <button type="submit" className="login100-form-btn" disabled>
                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 20 }}></i> <span>Ongoing processing...</span>
                  </button>
                )}
              </div>
              <div className=" py-4">
                <span className="txt2">
                  Already have an account?{" "}
                  <Link to="/login" className="txt1">
                    Login
                  </Link>
                </span>
              </div>
              <div className=" py-4">
                <span className="txt2">
                  By Sign Up,I agree to the <a href="#">Tiva Policy Statement</a> and <a href="#">Terms and Condition</a>
                </span>
              </div>
              <div className="text-center py-4">
                <span className="txt2">
                  or sign up using
                </span>
              </div>
              <div className="login100-form-social flex-c-m">
                <a href="#" className="login100-form-social-item flex-c-m bg1 mr-2">
                  <i className="mdi mdi-facebook" aria-hidden="true"></i>
                </a>
                <a href="#" className="login100-form-social-item flex-c-m bg2 mr-2">
                  <i className="mdi mdi-twitter" aria-hidden="true"></i>
                </a>
              </div>
            </form>
            <div className="login100-more" style={{ backgroundImage: `url(${loginImage})` }}>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Logup;