import React, { Children, useEffect, useState } from "react";
import {
  avatar1,
  french,
  italy,
  logoDark,
  logoLight,
  logoSm,
  russia,
  spain,
  us,
} from "../icons";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import api from "../../config/Config";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Drawer, Menu } from "antd";
import {
  MenuOutlined,
  AppstoreOutlined,
  ShopOutlined,
  UserOutlined,
  SnippetsOutlined,
  NodeCollapseOutlined,
} from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";
import requestRow from "../../controller/RequestRow";
import { Tooltip } from "react-tooltip";
import { useTheme } from "@mui/material/styles";
import { appBarClasses, useMediaQuery } from "@mui/material";
import { FaAmazonPay } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import { FaLeanpub } from "react-icons/fa";

interface CustomTooltipProps {
  message: string;
}

// import './header.css'
const Header = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const data = "";
  let tempo: any = [];
  const account_isShow_member = sessionStorage.getItem("isssmmenmbre");
  const menuItems1: any = account_isShow_member
    ? [
      {
        label: "Members",
        key: "/member",
        icon: <UserOutlined style={{ fontSize: 25 }} />,
      },
    ]
    : [];

  const [load, setLoad] = useState<requestRow[]>([]);
  const [getAllRole, setGetAllRole] = useState<requestRow[]>([]);
  const [countNotification, setCountNotification] = useState("");

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  function logout() {
    api
      .getHttpRequest("/user/log-out", {}, "POST")
      .then((response) => {
        const json = response.data;
        if (json.status == 200) {
          sessionStorage.clear();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  /** Usse Menu ant */

  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState("/");
  const [userAvatar, setUserAvatar] = useState('');

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
    LoadNotification();
    LoadAllAssignRoles();

    const storedAvatar = api.initAvatar();
    if (storedAvatar) {
      setUserAvatar(storedAvatar);
    }
  }, [location.pathname]);

  const LoadNotification = () => {
    api
      .getHttpRequest(`/user/notifications`, data, "GET")
      .then((response) => {
        const json = response.data;
        if (json.status == 200) {
          setLoad(json.data.rows);
          setCountNotification(json.data.count);
        }
      })
      .catch((error) => {
        if (
          !window.navigator.onLine &&
          !error.response &&
          error.code === "ERR_NETWORK"
        ) {
          toast.error("Please connect your device to the internet");
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  // const DesibleNotification = (id: any) => {
  //     if (!tempo.includes(id)) {
  //         api.getHttpRequest(`/user/notifications/view/${id}`, {}, 'PUT').then((response) => {
  //             const json = response.data;
  //             if (json.status == 200) {
  //                 tempo.push(id);
  //             }
  //         }).catch((error) => {
  //             toast.error(error.message);
  //         })
  //     }

  // }

  const DeleteNotification = () => {
    api
      .getHttpRequest(`/user/notifications/clear`, {}, "DELETE")
      .then((response) => {
        const json = response.data;
        if (json.status == 200) {
          LoadNotification();
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getNotificationIcon = (x: any) => {
    if (x == 1)
      return ["notify-icon bg-success", "mdi mdi-comment-account-outline"];
    if (x == 0)
      return ["notify-icon bg-danger", "mdi mdi-comment-account-outline"];
    return "";
  };

  const LoadAllAssignRoles = () => {
    // setLoading(true);
    api
      .getHttpRequest(
        `/partners/admin/load_percentage_assign_jv_account/${api.initPartnerId()}`,
        data,
        "GET"
      )
      .then((response) => {
        const json = response.data.data;
        setGetAllRole(json.data);

        // setTotalPage(json.count)
      })
      .catch((error) => {
        console.log(error.message);
      });

    setTimeout(() => {
      // setLoading(false);
    }, 3000);
  };

  function CustomTooltip({ message }: CustomTooltipProps) {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
      <div
        className="custom-tooltip"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {isMatch ? (
          <>
            <p>{message.substring(0, 15)}...</p>
          </>
        ) : (
          <>
            <p>{message.substring(0, 40)}...</p>
          </>
        )}

        {showTooltip && (
          <div className="text-muted" style={{ whiteSpace: "pre-wrap" }}>
            {message}
          </div>
        )}
      </div>
    );
  }

  const additionalItems = api.initUserAccessRole().map((item: any) => ({
    label: item.UserRole.name,
    key: `/team-by-roles/${item.UserRole.name}/${item.UserRole.id}`,
    icon: <UserOutlined style={{ fontSize: 25, color: '#1576ED' }} />,
  }));

  const menuItems = [
    {
      label: "Dashboard",
      key: "/",
      icon: <AppstoreOutlined style={{ fontSize: 25, color: '#1576ED' }} />,
      children: [{ label: "Home", key: "/home" }],
    },
    // account_isShow_member === "true" && {
    //   label: "Members",
    //   key: `/members`,
    //   action: () => api.LoginToTivAppv3(api.initToken()),
    //   icon: <UserOutlined style={{ fontSize: 25, color: '#1576ED' }} />,
    // },
    account_isShow_member === "true" && {
      label: "Members",
      key: `/member`,
      icon: <UserOutlined style={{ fontSize: 25, color: '#1576ED' }} />,
    },
    {
      label: "Payment",
      key: "/Payout Methods",
      icon: <FaAmazonPay style={{ fontSize: 25, color: '#1576ED' }} />,
      children: [
        // { label: "Pay", key: "/trans-mode-pay", icon: <ShopOutlined style={{ fontSize: 15 }} /> },
        {
          label: "Payout Methods",
          key: "/add-payment-method",
          icon: <ShopOutlined style={{ fontSize: 15, color: '#1576ED' }} />,
        },
        {
          label: "Payment History",
          key: "/payement-history",
          icon: <ShopOutlined style={{ fontSize: 15, color: '#1576ED' }} />,
        },
      ],
    },

    {
      label: "Inventory",
      key: "/voucher",
      icon: <SnippetsOutlined style={{ fontSize: 25, color: '#1576ED' }} />,
      children: [
        { label: "Vouchers Assigned", key: "/invetory" },
        { label: "In Your Stock", key: "/stock" },
      ],
    },
    {
      label: "Transactions",
      key: "/transaction",
      icon: <NodeCollapseOutlined style={{ fontSize: 25, color: '#1576ED' }} />,
    },
    {
      label: "Training",
      key: "/training",
      icon: <FaLeanpub style={{ fontSize: 25, color: '#1576ED' }} />,
    },
  ].filter(Boolean);;

  const access = localStorage.getItem("Access");
  const myAccess = JSON.parse(access!);

  // const menus = (labelToFind: any): boolean => {
  //   return !myAccess?.interfaces.find(
  //     (menu: any) => menu?.MetadataList?.name === labelToFind
  //   );
  // };
  // const [menuItem, setMenuItem] = useState(menuItems);
  // const [menuItem1, setMenuItem1] = useState(additionalItems);
  // useEffect(() => {
  //   const interfaceNames = myAccess?.interfaces.map(
  //     (item: any) => item.MetadataList.name
  //   );
  //   const matchedMenuItems = menuItems.filter(
  //     (item: any) => interfaceNames?.includes(item?.label) 
  //   );
  //   const matchedMenuItems1 = additionalItems.filter(
  //     (item: any) => interfaceNames?.includes(item?.label)
  //   );
  //   setMenuItem(matchedMenuItems);
  //   setMenuItem1(matchedMenuItems1);
  // }, []);
  // console.log(menuItem, ...menuItem1);

  const [menuItem, setMenuItem] = useState(menuItems);
  const [menuItem1, setMenuItem1] = useState(additionalItems);

  useEffect(() => {
    const interfaceNames = myAccess?.interfaces.map(
      (item: any) => item.MetadataList.name
    );

    const matchedMenuItems = menuItems.filter(
      (item: any) => interfaceNames?.includes(item?.label)
    );

    const matchedMenuItems1 = additionalItems.filter(
      (item: any) => interfaceNames?.includes(item?.label)
    );

    setMenuItem(matchedMenuItems);
    setMenuItem1(matchedMenuItems1);
  }, [myAccess]);

  const handleMenuClick = (item: any) => {
    const selectedItem = [...menuItem, ...menuItem1].find(menu => menu.key === item.key);

    if (selectedItem?.action) {
      selectedItem.action(); // Exécuter l'action si elle est définie
    } else {
      navigate(item.key); // Sinon, naviguer à l'URL associée
    }
    onClose();
  };
  return (
    <>
      <header id="topnav">
        <div className="navbar-custom">
          <div className="container-fluid1">
            <ul className="list-unstyled topnav-menu float-right mb-0">
              <li className="dropdown notification-list"></li>
              <li className="dropdown notification-list">
                <a
                  className="nav-link dropdown-toggle  mr-0"
                  data-toggle="dropdown"
                  href={
                    myAccess?.home_page === null ? "#" : myAccess?.home_page
                  }
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <i className="mdi mdi-bell noti-icon"></i>
                  <span
                    className="badge badge-danger rounded-circle noti-icon-badge"
                    style={{ color: "white" }}
                  >
                    {countNotification}
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-lg">
                  <div className="dropdown-item noti-title">
                    <h5 className="font-16 m-0">
                      <span className="float-right">
                        <div
                          onClick={() => DeleteNotification()}
                          style={{ cursor: "pointer" }}
                          className="text-dark"
                        >
                          <small>Clear All</small>
                        </div>
                      </span>
                      Notification
                    </h5>
                  </div>

                  <div className="slimscroll noti-scroll">
                    {load.map((item, i) => (
                      <div
                        //  onMouseEnter={() => DesibleNotification(item.id)}
                        key={i}
                        className="dropdown-item notify-item"
                      >
                        <div className={getNotificationIcon(item.is_alert)[0]}>
                          <i
                            className={getNotificationIcon(item.is_alert)[1]}
                          ></i>
                        </div>
                        <CustomTooltip message={item.message} />
                        <small className="text-muted">
                          {api.geDateFormat(item.createdAt)}
                        </small>
                      </div>
                    ))}
                  </div>
                  {/* 
                                    <a href=""
                                        className="dropdown-item text-primary text-center notify-item notify-all">
                                        View all
                                        <i className="fi-arrow-right"></i>
                                    </a> */}
                </div>
              </li>

              <li className="dropdown notification-list" style={{ marginTop: 8 }}>
                <a
                  className="nav-link dropdown-toggle nav-user mr-0"
                  data-toggle="dropdown"
                  href={
                    myAccess?.home_page === null ? "#" : myAccess?.home_page
                  }
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                  style={{ display: 'flex', alignItems: 'center', }}
                >
                  <img
                    src={`${userAvatar === "default.png"
                      ? `${avatar1}`
                      : `${api.var_.url_avatar + userAvatar}`
                      }`}
                    alt=""
                    className="rounded-circle"
                  />

                  <span className="pro-user-name d-none d-xl-inline-block ml-2">
                    <p style={{ marginTop: 10, color: '#A7CBF8', fontFamily: `"Noto Sans", sans-serif`, fontWeight: 700 }}>{api.initUser()} <i className="mdi mdi-chevron-down"></i></p>
                    <p style={{ marginTop: '-15%', color: '#A7CBF8', marginRight: '10px', fontFamily: `"Noto Sans", sans-serif` }}><small>{api.initRole()}</small></p>
                  </span>
                </a>


                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  <div className="dropdown-header noti-title">
                    <h6 className="text-overflow m-0">Welcome {api.initUser()}!</h6>
                  </div>

                  {/* <div
                    onClick={() => navigate("/user-profile")}
                    style={{
                      borderRadius: 100,
                      border: "1px solid #1152D9",
                      marginTop: 15,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      width: 250,
                      margin: 2,
                    }}
                  >
                    <div>
                      <div style={{ color: "#1576ED", padding: 10 }}>
                        Manage your Tiva account
                      </div>
                    </div>
                  </div> */}
                  <Link
                    to="/myaccount-tiva/home"
                    className="dropdown-item notify-item"
                  >
                    <i className="mdi mdi-logout-variant"></i>
                    <span>Profil</span>
                  </Link>

                  <div className="dropdown-divider"></div>

                  <Link
                    to="/login"
                    onClick={logout}
                    className="dropdown-item notify-item"
                  >
                    <i className="mdi mdi-logout-variant"></i>
                    <span>Logout</span>
                  </Link>
                </div>
              </li>
            </ul>

            {/** LOGO */}
            <div className="logo-box">
              <div className="logo text-center"></div>
              <div className="logo text-center">
                <span className="logo-lg">
                  <MenuOutlined
                    onClick={showDrawer}
                    style={{
                      fontSize: 30,
                      color: "#A1C8F8",
                      marginRight: 28,
                      marginLeft: 20,
                      marginTop: 3,
                      cursor: "pointer",
                    }}
                  />
                  <img
                    src={logoSm}
                    height="40"
                    onClick={() =>
                      navigate(
                        myAccess?.home_page === null ? "#" : myAccess?.home_page
                      )
                    }
                    style={{ cursor: "pointer" }}
                  />
                  {/** <span className="logo-lg-text-light">Simple</span> */}
                </span>
                <span className="logo-sm">
                  <MenuOutlined
                    onClick={showDrawer}
                    style={{
                      fontSize: 25,
                      color: "#A1C8F8",
                      marginRight: 10,
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                  />
                  {/**  <span className="logo-sm-text-dark">S</span> */}
                  <img
                    src={logoSm}
                    alt=""
                    height="36"
                    onClick={() =>
                      navigate(
                        myAccess?.home_page === null ? "#" : myAccess?.home_page
                      )
                    }
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </div>
            </div>

            <div id="navigation">
              <div
                className="input-group search-area d-lg-inline-flex"
                style={{ marginTop: 18 }}
              >
                <div className="input-group-append">
                  <button className="input-group-text">
                    <i className="mdi mdi-magnify"></i>
                  </button>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                />
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </header>

      <Drawer
        title={
          <img
            src={logoDark}
            alt=""
            height="40"
            onClick={() =>
              navigate(myAccess?.home_page === null ? "#" : myAccess?.home_page)
            }
          />
        }
        placement={"left"}
        width={270}
        closeIcon={<MenuOutlined style={{ fontSize: 25, color: "#1576ED" }} />}
        open={open}
        onClose={onClose}
        zIndex={1100}
      >
        <div className="deznav" style={{ marginLeft: 0 }}>
          <div className="deznav-scroll">
            {/* <Sider collapsedWidth={0}>
              <div>Member</div>
              <Menu
                style={{ width: 250 }}
                onSelect={() => onClose()}
                onClick={(item) => {
                  navigate(item.key);
                }}
                selectedKeys={[selectedKeys]}
                mode="inline"
                items={[...menuItem, ...menuItem1]}
              >
                Member
              </Menu>
            </Sider> */}

            <Sider collapsedWidth={0}>
              <Menu
                style={{ width: 250 }}
                onSelect={() => onClose()}
                onClick={handleMenuClick}
                selectedKeys={[selectedKeys]}
                mode="inline"
                items={[...menuItem, ...menuItem1]}
              >
              </Menu>
            </Sider>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
