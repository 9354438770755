import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { InterfaceHome } from "./config/Interfaces/InterfaceHome";
import CloudRequest from "./controller/CloudRequest";
import { InterfaceProfile } from "./config/Interfaces/InterfaceProfile";

interface TextContextProps {
    homeDashboard: InterfaceHome[];
    setHomeDashboard: React.Dispatch<React.SetStateAction<InterfaceHome[]>>;
    profile : InterfaceProfile;
    setProfile : React.Dispatch<React.SetStateAction<InterfaceProfile>>;
   
}

const TextContext = createContext<TextContextProps | undefined>(undefined);


interface TextProviderProps {
    children: ReactNode;
}

export const TextProvider: React.FC<TextProviderProps> = ({children}) => {
    const [homeDashboard, setHomeDashboard] = useState<InterfaceHome[]>([]);
    const [profile, setProfile] = useState<InterfaceProfile>({});

    useEffect(() => {
        CloudRequest.LoadDashBoard(1).then(data => setHomeDashboard(data))
        CloudRequest.LoadProfile().then(data => setProfile(data))
    }, []);

    return (
        <TextContext.Provider
            value={{homeDashboard, setHomeDashboard, profile, setProfile
            }} >
            {children}
        </TextContext.Provider>
    );
}

export const useTextContext = (): TextContextProps => {
    const context = useContext(TextContext);
    if (!context) {
        throw new Error('useTextContext must be used within a TextProvider');
    }
    return context;
};