import { Alert, Modal, Space } from "antd";
import { useRef, useState } from "react";
import { avatar1 } from "../icons";
import api from '../../config/Config'
import axios from "axios";
import CryptoJS from "crypto-js";

const CustomModal = ({
  title,
  isModalOpen,
  handleOk,
  handleCancel,
  name,
  LoadPrile

}: any) => {

  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [showAlertDanger, setShowAlertDanger] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [fileblog, setFileblog] = useState(null)

  const [_avatarUser, setAvatarUser] = useState<File | null>(null);



  const [selectedImage, setSelectedImage] = useState<string | null>(avatar1);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      if (file.size > 1024 * 1024) {
        setShowAlertDanger(true)
        setMessage('Please select a file that is less than 1 MB.');
      } else {
        const reader = new FileReader();
        reader.onload = async (e) => {
          setSelectedImage(e.target?.result as string);
          setAvatarUser(file);

          try {
            const formData = new FormData();
            formData.append("file", file);

            const response = await axios.post(api.var_.url_api_local + '/storage/files/upload', formData, {
              headers: {
                'Authorization': 'Bearer ' + api.initToken(),
              },
            });

            if (response.data.status === 200) {
              setFileblog(response.data.data.filename);
              setShowAlertSuccess(true);
              setMessage(response.data.message);
            } else {
              setShowAlertDanger(true);
              setMessage(response.data.message);
            }
          } catch (error: any) {
            setShowAlertDanger(true);
            setMessage(error.message);
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };



  const UpdateUserAvatar = () => {
    let data = JSON.stringify({
      "avatar": fileblog,
    });

    setLoading(true);

    if (fileblog === '' || fileblog === null) {
      setShowAlertDanger(true)
      setMessage('Please select a file');
    }
    else {
      const key_avatar = "cle_avatar";
      const avatarCrypter = CryptoJS.AES.encrypt(fileblog, key_avatar).toString();
      api.getHttpRequest(`/user/update_avatar/${api.initIdUserConnect()}`, data, 'PUT').then((response) => {
        const json = response.data;
        if (json.status == 200) {
          sessionStorage.setItem("obvokotoratebo", avatarCrypter);
          LoadPrile()
          setShowAlertSuccess(true)
          setMessage(json.message);
          LoadPrile();
          handleCancel()
        } else {
          setShowAlertDanger(true)
          setMessage(json.message);
        }
      }).catch((error) => {
        setMessage(api.getMessageError(error))
      })

    }

    setTimeout(() => {
      setLoading(false);
      setShowAlertSuccess(false)
      setShowAlertDanger(false)
    }, 3000);
  }



  return (
    <Modal
      title={title}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={400}
    >
      {/* <div >
        <div className="p-2 form-label rounded-sm" role="alert" style={{ backgroundColor: '#F0F3F8' }}>
          You're about to change <strong>{name}'s</strong> avatar
        </div>

        {message && (
          <>
            <div className='col-md-12 mb-1 mt-1'>
              <Space direction="vertical" style={{ width: '100%' }}>
                {
                  showAlertDanger &&
                  <Alert type="error" message={message} showIcon closable />

                }
                {
                  showAlertSuccess &&
                  <Alert type="success" message={message} showIcon closable />

                }
              </Space>
            </div>
          </>
        )}

        <div className="basic-form" >
          <div className="row mt-2 " style={{ justifyItems: 'center', alignItems: 'center', marginLeft: 40 }}>
            <div className='col-md-12 '>
              <div className="pic-holder">
                <img
                  style={{ width: 250, height: 250, borderRadius: '100%' }}
                  id="profilePic"
                  className="pic"
                  src={selectedImage || avatar1}
                  onClick={handleImageClick}
                />
                <input
                  className="uploadProfileInput"
                  type="file"
                  name="profile_pic"
                  id="newProfilePhoto"
                  accept="image/*"
                  style={{ opacity: 0 }}
                  ref={fileInputRef}
                  onChange={handleImageChange}
                />
              </div>
            </div>

            <div id="infos"></div>
            <div className="form-group col-md-12" style={{ marginLeft: 60 }}>
              <label htmlFor="email" className="form-label" style={{ color: '#fff' }}>.</label>

              {!loading && (
                <button onClick={() => UpdateUserAvatar()} type="button" id="btn_update" className="btn btn-primary agree-link">
                  Update Avatar
                </button>
              )}

              {loading && (
                <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                  <i className="fa fa-spinner fa-spin fa-3x fa-lg" style={{ fontSize: 25 }}></i> Processing...<span></span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div> */}

      <div>
        <div className=" p-2 form-label rounded-sm" role="alert" style={{ backgroundColor: '#F0F3F8' }}>
          You're about to change <strong>{name}'s</strong> avatar
        </div>

        {
          message && (
            <>
              <div className='col-md-12 mb-1 mt-1'>
                <Space direction="vertical" style={{ width: '100%' }}>
                  {
                    showAlertDanger &&
                    <Alert type="error" message={message} showIcon closable />
                  }
                </Space>
              </div>
            </>
          )
        }

        <div className="basic-form" style={{ justifyItems: 'center' }}>

          <div className="row mt-2 ml-20">

            <div className='col-md-12 ' style={{marginLeft:80}}>
              <div className="pic-holder ">
                <img id="profilePic" className="pic" src={selectedImage || avatar1}
                onClick={handleImageClick}
                />

                <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{ opacity: 0 }}
                  ref={fileInputRef}
                  onChange={handleImageChange}
                />
                <label htmlFor="newProfilePhoto" className="upload-file-block">
                  <div className="text-center">
                    <div className="mb-1">
                      <i className="fa fa-camera fa-2x"></i>
                    </div>
                    <div className="text-uppercase">
                      Update <br /> Profile Photo
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div id="infos"></div>
            <div className="form-group col-md-12">
              <label htmlFor="email" className="form-label" style={{ color: '#fff' }}>.</label>

              {!loading && (
                <button onClick={() => UpdateUserAvatar()} type="button" id="btn_update" className="btn btn-primary agree-link" >Update Avatar</button>
              )}

              {loading && (

                <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                  <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span></span>
                </button>
              )}
            </div>

          </div>

        </div>

      </div>
    </Modal>
  );
};

export default CustomModal;