import axios from "axios";
import { detailCustomer } from "../../config/constant/apiLink";
import api from "../../config/Config";

export interface ResponseCustomer {
  message?: string;
  status?: number;
  data?: Data;
}


interface DepositAmount {
  total?: number;
  list?: List[];
}

interface List {
  nombre?: number;
  services?: string;
  amount?: number;
}

interface Data {
  id?: string;
  name?: string;
  middlename?: any;
  lastname?: any;
  personnel_number?: number;
  phone?: string;
  gender?: any;
  email?: any;
  address?: any;
  avatar?: string;
  is_kyc?: boolean;
  verified?: boolean;
  civil_status?: any;
  date_of_birth?: any;
  place_of_birth?: any;
  createdAt?: string;
  solde?: number;
  solde_updatedAt?: any;
  shipping_street?: any;
  city_village?: any;
  home_physical_address?: any;
  work_location?: any;
  work_place_location_leader?: any;
  signature?: any;
  national_id_photo?: any;
  national_id?: any;
  id_type?: any;
  phone2?: any;
  id_front?: any;
  id_back?: any;
  isReceiveData?: boolean;
  locationType?: any;
  isShareRegion?: any;
  isShareDistrict?: any;
  isShareWard?: any;
  isCompleted?: boolean;
  chef_document_scan?: any;
  solde_general?: number;
  solde_generalAt?: string;
  bio?: any;
  user_parent?: any;
  UserRole?: UserRole;
  LocationWard?: LocationWard;
  Partner?: Partner;
}

interface UserRole {
  id?: string;
  name?: string;
}

interface LocationWard {
  id?: string;
  name?: string;
  LocationDistric?: LocationDistric;
}

interface LocationDistric {
  id?: string;
  name?: string;
  LocationRegion?: LocationRegion;
}

interface LocationRegion {
  id?: string;
  name?: string;
}

interface Partner {
  id?: string;
  name?: string;
  logo?: string;
  PartnerCategory?: PartnerCategory;
}

interface PartnerCategory {
  id?: string;
  name?: string;
}

export const _getDetailCustomer = async (
  _IdUser?: string
): Promise<ResponseCustomer | undefined> => {
  try {
    const { data } = await axios.get<ResponseCustomer>(
      `${detailCustomer?.getCustomerId(_IdUser!)}`,
      {
        headers: {
          Authorization: `Bearer ${api.initToken()}`,
        },
      }
    );
    console.log(data, "user");
    return data;
  } catch (error) {
    console.log(error, "user");
  }
};
