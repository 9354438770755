import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import { formatDistanceStrict } from "date-fns";
import Swal from "sweetalert2";

// const dev_mode : boolean = false;
// const dev_mode_local : boolean = false;

export const URL_API = process.env.REACT_APP_BASE_URL;
export const URL_API_V3 = process.env.REACT_APP_BASE_URL_V3;
// export const URL_LIVE = import.meta.env.VITE_API_DEV_URL;

export const LOCAL_LOGIN = process.env.REACT_APP_LOGIN_LOCAL_URL;
// export const DEV_LOGIN = process.env.VITE_LOGIN_DEV_URL;
// export const LIVE_LOGIN = import.meta.env.VITE_LOGIN_LIVE_URL;

export const FILE_URL = process.env.REACT_APP_FILE_URL;
export const APP_JV_LOCAL = process.env.REACT_APP_JV_LOCAL;
export const APP_JV_LOCAL_V3 = process.env.REACT_APP_JV_LOCAL_V3;

const url_redirection_login_local = `${LOCAL_LOGIN}/#/login`;
// const url_redirection_login_dev = `https://www.tivaaccount.tiva.co.tz/#/login`;
// const url_redirection_login_online = `https://www.account.tiva.co.tz/#/login`

// const url_redirectionaccess_login_local = `http://localhost:5173`;
// const url_redirectionaccess_login_dev  = `https://www.tivaaccount.tiva.co.tz`
// const url_redirectionaccess_login_production  = `https://www.account.tiva.co.tz`

// const url_redirectionprincipale_login_local = `http://localhost:5173/#/tiva-account-infos-user/`
// const url_redirectionprincipale_login_dev = `https://www.tivaaccount.tiva.co.tz/#/tiva-account-infos-user/`
// const url_redirectionprincipale_login_production = `https://www.account.tiva.co.tz/#/tiva-account-infos-user/`

// const url_api_dev  = 'https://dev-api.tiva.tz/api'
// const url_api_production  = 'https://api.tiva.tz/api'
const url_api_dev = URL_API;
const url_api_dev_v3 = URL_API_V3;

const var_ = {
  url_api_local: url_api_dev, // production
  url_api_local_v3: url_api_dev_v3, // production
  url_avatar: FILE_URL,

  token: null,
};

const key2 = "ma_clé_de_chiffrement_user_connecter";
const key3 = "cle_avatar";
const key4 = "its_role_key";
const key5 = "it_partner_id";
const key6 = "its_account_access";
const key7 = "its_is_member";
const keyurl8 = "its_url";
const keyid = "id_user";
const keyRout = "url_app_user";
const keyphone = "url_app_phone";
const key_listAccess = "url_accessList";
const keysocket = "key_iduser_socket_jv";

// Récupérer la variable chiffrée depuis le local storage
let user_Cripter: string | any = sessionStorage.getItem("sutielzatiwo");
let avatar_Cripter: string | any = sessionStorage.getItem("obvokotoratebo");
let role_Cripter: string | any = sessionStorage.getItem("bobaonsraoulexajs");
let idpartner_Cripter: string | any =
  sessionStorage.getItem("pppaidlozartooner");
let account_access_Cripter: string | any =
  sessionStorage.getItem("donkamdiquoeserner");

let access_list_Cripter: string | any = sessionStorage.getItem("listrpkjoutteapphboneboraccess");

let account_isShow_member: string | any =
  sessionStorage.getItem("isssmmenmbre");
let account_url_access: string | any =
  sessionStorage.getItem("iosrelbulrleogo");
let account_id_access: string | any = sessionStorage.getItem("iiiiisuusdider");
let route_name_access_app: string | any =
  sessionStorage.getItem("rpkjoutteappb");
let user_phone_access_app: string | any = sessionStorage.getItem("rpkjoutteapphbonebor");

let iduser_Cripter: string | any = sessionStorage.getItem("socketusersconnexotejv");
// Décrypter la variable
let user_Decripter: any;
const initUser = () => {
  user_Cripter = sessionStorage.getItem("sutielzatiwo");
  if (user_Cripter !== null) {
    const bytes = CryptoJS.AES.decrypt(user_Cripter, key2);
    user_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return user_Decripter.slice(0, 7);
  }

  // return '';
};
// accessList;

let phone_Decripter: any;
const initUserPhone = () => {
  user_phone_access_app = sessionStorage.getItem("rpkjoutteapphbonebor");
  if (user_phone_access_app !== null) {
    const bytes = CryptoJS.AES.decrypt(user_phone_access_app, keyphone);
    phone_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return phone_Decripter;
  }

  // return '';
};

let route_Decripter: any;
const initRoute = () => {
  route_name_access_app = sessionStorage.getItem("rpkjoutteappb");
  if (route_name_access_app !== null) {
    const bytes = CryptoJS.AES.decrypt(route_name_access_app, keyRout);
    route_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return route_Decripter;
  }

  // return '';
};

let user_url: any;
const initUserUrlAccess = () => {
  account_url_access = sessionStorage.getItem("iosrelbulrleogo");
  if (account_url_access !== null) {
    const bytes = CryptoJS.AES.decrypt(account_url_access, keyurl8);
    user_url = bytes.toString(CryptoJS.enc.Utf8);

    return user_url.slice(0, 7);
  }

  // return '';
};

let user_member: any;
const initShowMember = () => {
  account_isShow_member = sessionStorage.getItem("isssmmenmbre");
  if (account_isShow_member !== null) {
    const bytes = CryptoJS.AES.decrypt(account_isShow_member, key7);
    user_member = bytes.toString(CryptoJS.enc.Utf8);

    return user_member;
  }

  // return '';
};

let avatar_Decripter: any;
const initAvatar = () => {
  avatar_Cripter = sessionStorage.getItem("obvokotoratebo");
  if (avatar_Cripter !== null) {
    const bytes = CryptoJS.AES.decrypt(avatar_Cripter, key3);
    avatar_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return avatar_Decripter;
  }

  // return '';
};

let role_Decripter: any;
const initRole = () => {
  role_Cripter = sessionStorage.getItem("bobaonsraoulexajs");
  if (role_Cripter !== null) {
    const bytes = CryptoJS.AES.decrypt(role_Cripter, key4);
    role_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return role_Decripter;
  }
};

let idPartner_Decripter: any;
const initPartnerId = () => {
  idpartner_Cripter = sessionStorage.getItem("pppaidlozartooner");
  if (idpartner_Cripter !== null) {
    const bytes = CryptoJS.AES.decrypt(idpartner_Cripter, key5);
    idPartner_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return idPartner_Decripter;
  }
};

let idUserConnect_Decripter: any;
const initIdUserConnect = () => {
  account_id_access = sessionStorage.getItem("iiiiisuusdider");
  if (account_id_access !== null) {
    const bytes = CryptoJS.AES.decrypt(account_id_access, keyid);
    idUserConnect_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return idUserConnect_Decripter;
  }
};

let userAccessRoles_Decripter: any;
const initUserAccessRole = () => {
  account_access_Cripter = sessionStorage.getItem("donkamdiquoeserner");
  if (account_access_Cripter !== null) {
    const bytes = CryptoJS.AES.decrypt(account_access_Cripter, key6);
    const accountAccessDecrypted = bytes.toString(CryptoJS.enc.Utf8);
    const accountAccess = JSON.parse(accountAccessDecrypted);
    userAccessRoles_Decripter = accountAccess?.roles_can_create;
    return userAccessRoles_Decripter || [];
  }
  return [];
};

let iduser_Decripter: any;
const initUserIdSocket = () => {
  iduser_Cripter = localStorage.getItem("socketusersconnexotejv");
  if (iduser_Cripter !== null) {
    const bytes = CryptoJS.AES.decrypt(iduser_Cripter, keysocket);
    iduser_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return iduser_Decripter;
  }
};

// const initUserAccessLIst = () => {
//   const access_list_Cripter = sessionStorage.getItem("listrpkjoutteapphboneboraccess");
//   if (access_list_Cripter !== null) {
//     try {
//       const bytes = CryptoJS.AES.decrypt(access_list_Cripter, key_listAccess);
//       const decryptedUserAccessListString = bytes.toString(CryptoJS.enc.Utf8);
//       const decryptedUserAccessList = JSON.parse(decryptedUserAccessListString);
//       const userAccessList_Decripter = decryptedUserAccessList?.access;
//       const userInterfaces_Decripter = decryptedUserAccessList?.interfaces;

//       return {
//         access: userAccessList_Decripter || [],
//         interfaces: userInterfaces_Decripter || []
//       };
//     } catch (error) {
//       console.error("Error decrypting access list:", error);
//     }
//   }

//   return {
//     access: [],
//     interfaces: []
//   };
// };

const initUserAccessLIst = () => {
  const access_list_Cripter = sessionStorage.getItem(
    "listrpkjoutteapphboneboraccess"
  );
  if (access_list_Cripter !== null) {
    try {
      const bytes = CryptoJS.AES.decrypt(access_list_Cripter, key_listAccess);
      const decryptedUserAccessListString = bytes.toString(CryptoJS.enc.Utf8);
      const decryptedUserAccessList = JSON.parse(decryptedUserAccessListString);
      const userAccessList_Decripter = decryptedUserAccessList?.access || [];
      const userInterfaces_Decripter =
        decryptedUserAccessList?.interfaces || [];

      return {
        access: userAccessList_Decripter,
        interfaces: userInterfaces_Decripter,
      };
    } catch (error) {
      console.error("Error decrypting access list:", error);
    }
  }

  return {
    access: [],
    interfaces: [],
  };
};

// Définir la clé de chiffrement
const key = "ma_clé_de_chiffrement_token_userpartner";

// Récupérer la variable chiffrée depuis le local storage
let token_Cripter: string | any = sessionStorage.getItem("onktxonktx");

// Décrypter la variable\
let token_Decripter: any;
const initToken = () => {
  token_Decripter = sessionStorage.getItem("onktxonktx");
  if (token_Decripter !== null) {
    const bytes = CryptoJS.AES.decrypt(token_Decripter, key);
    token_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return token_Decripter;
  }
};

const USER: string[] = [];

const getHttpRequest = async (
  route: any,
  data: any,
  method = "POST",
  content_type = "application/json"
) => {
  initToken();
  const url = var_.url_api_local + route;

  let config_api = {
    method: method,
    url: url,
    headers: {
      "Content-Type": content_type,
      Authorization:
        sessionStorage.getItem("onktxonktx") == null ||
        sessionStorage.getItem("onktxonktx") == undefined
          ? ""
          : `Bearer ${initToken()}`,
      accept: "application/json",
    },
    data: data,
  };

  return await axios.request(config_api);
};

const getHttpRequestV3 = async (
  route: any,
  data: any,
  method = "POST",
  content_type = "application/json"
) => {
  initToken();
  const url = var_.url_api_local_v3 + route;

  let config_api = {
    method: method,
    url: url,
    headers: {
      "Content-Type": content_type,
      Authorization:
        sessionStorage.getItem("onktxonktx") == null ||
        sessionStorage.getItem("onktxonktx") == undefined
          ? ""
          : `Bearer ${initToken()}`,
      accept: "application/json",
    },
    data: data,
  };

  return await axios.request(config_api);
};

const getHttpRequestNotToken = async (
  route: any,
  data: any,
  method = "POST",
  content_type = "application/json"
) => {
  const url = var_.url_api_local + route;
  // console.log("Request : " + url)
  let config_api = {
    method: method,
    url: url,
    headers: {
      "Content-Type": content_type,
      Authorization: null,
      accept: "application/json",
    },
    data: data,
  };

  return await axios.request(config_api);
};

const checkStatus = (status: any, navigation = null) => {
  switch (status) {
    case 403:
      var_.token = null;
      <Navigate to="Login" replace />;
      break;
    case "Request failed with status code 403":
      var_.token = null;
      <Navigate to="Login" replace />;
      break;
    case 401:
      var_.token = null;
      <Navigate to="Login" replace />;
      break;
    default:
      break;
  }
};

// function geDateFormat(dateString: string): string {
//     const date = new Date(Date.parse(dateString));
//     const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
//     const formattedDate = date.toLocaleDateString('en-US', options);
//     return formattedDate;
// }

function geDateFormat(dateString: string): string {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const relativeDate = formatDistanceStrict(date, now, { addSuffix: true });
  return relativeDate;
}

function ReturnVoid0(event: any) {
  event.preventDefault();
}

const Toast1 = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
const Toast2 = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const options: any = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };
  const formattedDate = `From ${date.toLocaleDateString("en-US", options)}`;
  return formattedDate;
}

export const getMessageError = (error: any): string | any => {
  let code = 200;
  let message = "An unexpected error occurred.";
  if (error.response) {
    code = error.response.status;
    message = error.response.data.message;
  } else if (error.code === "ECONNABORTED") {
    return "The internet connection has timed out.";
  } else if (error.code === "ENOTFOUND" || error.code === "EAI_AGAIN") {
    return "Unable to connect to the Internet.";
  } else if (error.code === "ETIMEDOUT") {
    return "The internet connection has timed out due to an exceeded wait time.";
  } else if (error.code === "ECONNREFUSED") {
    return "The connection was denied by the remote server.";
  } else if (error.code === "ENETUNREACH") {
    return "The network is not accessible.";
  } else if (error.code === "EHOSTUNREACH") {
    return "The remote host is not reachable.";
  } else if (error.code === "ECONNRESET") {
    return "The connection has been reset by the remote peer.";
  }
  switch (code) {
    case 200:
      return message;
    case 401:
      return (window.location.href = "/#/login");
    case 403:
      return (window.location.href = "/#/login");
    case 404:
      return message;
    case 409:
      return message;
    case 422:
      return message;
    case 500:
      return message;
    case 400:
      return (window.location.href = `/#/login`);
    default:
      return message;
  }
};

const RedirectionLogin = () => {
  return (window.location.href = `/#/login`);
};

const RedirectionAccess = () => {
  return (window.location.href = `/#/login`);
};

const NavigatePrincipal = () => {
  return (window.location.href = `/#/login`);
};

const LoginToTivApp = (element: any): void => {
  window
    .open(`/login`)
    ?.focus();
};

const LoginToTivAppv3 = (element:any): void => {
  window
    .open(`${APP_JV_LOCAL_V3}/auth/LoginWithToken/${element}`, "_blank")
    ?.focus();
};

export default {
  var_,
  getHttpRequest,
  getHttpRequestNotToken,
  token_Decripter,
  user_Decripter,
  initToken,
  initUser,
  initAvatar,
  initRole,
  geDateFormat,
  ReturnVoid0,
  Toast1,
  Toast2,
  formatDate,
  initPartnerId,
  initUserAccessRole,
  initShowMember,
  initUserUrlAccess,
  getMessageError,
  RedirectionLogin,
  RedirectionAccess,
  NavigatePrincipal,
  initIdUserConnect,
  LoginToTivApp,
  initRoute,
  initUserPhone,
  initUserAccessLIst,
  getHttpRequestV3,
  initUserIdSocket,
  LoginToTivAppv3
};
