import { Input } from 'antd'
import React from 'react'

interface textInputPropos {
    value: string | any,
    setValue: (value: string | any) => void,
    width?: number | string | any,
    label?: string,
    placeholder?:string
}

const isMobileScreen = window.innerWidth <= 768;

const InputSearch = (propos: textInputPropos) => {
    const {value, setValue , placeholder, width, label} = propos
  return (
    <div style={{width: isMobileScreen? '100%' : '100%'}}>
            {label && <div><label>{label}</label></div>}
        <Input.Search placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} size='large'/>
    </div>
  )
}

export default InputSearch