import { Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import {
    CloseOutlined
} from "@ant-design/icons"
// import { theme } from '../../App';



const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    }
}))

const Popup = (props) => {

    const { title, children, openPupop, setOpenPopup } = props;
    const classes = useStyles();

    const hidePopup = () => {
        setOpenPopup(false);
    }

    return (
        <Dialog open={openPupop} maxWidth={1100} classes={{ paper: classes.dialogWrapper }} style={{zIndex:500}}>
            <DialogTitle>
                <div style={{ display: 'flex' }}>
                    <Typography variant='h6' component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    {/* <BtnAll appel='X' onClick={() => setOpenPopup(false)} width='30px' height='30px' color='white' border="none" backgroundColor='#197FC2' /> */}
                    <CloseOutlined onClick={() => setOpenPopup(false)} style={{width:30, height:30}} />
                </div>
            </DialogTitle>

            <DialogContent >
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default Popup