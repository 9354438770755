import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import api from "../../config/Config";
import requestRow from "../../controller/RequestRow";
import { Pagination, Input, Dropdown, Card, Select, Tag, Button, Popover, Tooltip, CollapseProps, Collapse } from "antd";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { default1, docs1, okDocs } from "../../components/icons";
import { TbChecklist } from "react-icons/tb";

import { SearchOutlined } from "@ant-design/icons";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { IoMdArrowDropdown } from "react-icons/io";
import DocsCard from "./component/DocsCard";
import { IoSettingsOutline } from "react-icons/io5";
import StatusCreateModal from "./component/StatusCreateModal";
import AttributeStatus from "./component/AttributeStatus";
import { SiMicrosoftexcel } from "react-icons/si";
import DialogAddMember from "../sellers/component/DialogAddMember";
import DialogUploadExcel from "../sellers/component/DialogUploadExcel";
import CreateFormMember from "./component/CreateFormMember";
import ShowForm from "./component/ShowForm";
const { Option } = Select;

interface SettingItem {
  id: string;
  type: string;
  value: string;
  name: string;
  label: string;
  options: string[];
}

interface FormData {
  id: string;
  name: string;
  observation: string;
  createdAt: string;
  updatedAt: string;
  settings: SettingItem[];
}

const customDropdownRender = (menu: any) => {
  return <div className="custom-select-dropdown">{menu}</div>;
};

const Member = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const data = "";
  const regexTelephone = new RegExp("^\\+\\d{1,3}\\d{9}$");
  const regexEmail = new RegExp(
    "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
  );
  const [Loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [email, setEmail] = useState("");
  const [adress, setAddress] = useState("");
  const [minicipal, setMinicipal] = useState("");
  const [gender, setGender] = useState("");
  const [userRoleId, setUserRoleId] = useState("");
  const [civilStatus, setCivilStatus] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");

  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [count, setCount] = useState("");
  const [countSubTotal, setCountSubTotal] = useState("");
  const [countTotalsToday, setCountTotalsToday] = useState("");
  const [countTotalsThisWeek, setCountTotalsThisWeek] = useState("");
  const [countTotalsThisMonth, setCountTotalsThisMonth] = useState("");
  const [countTotalsSubToday, setCountTotalsSubToday] = useState("");
  const [countTotalsSubThisWeek, setCountTotalsSubThisWeek] = useState("");
  const [countTotalsSubThisMonth, setCountTotalsSubThisMonth] = useState("");
  const [countTotalsSubThisYear, setCountTotalsSubThisYear] = useState("");
  const [countTotalsThisYear, setCountTotalsThisYear] = useState("");

  // Variables Load elements
  const [province, setPronvince] = useState<requestRow[]>([]);
  const [city, setCity] = useState<requestRow[]>([]);
  const [minicipality, setMinicipality] = useState<requestRow[]>([]);
  const [categoriRole, setCategoriRole] = useState<requestRow[]>([]);
  const [sellerLoad, setSellerLoad] = useState<requestRow[]>([]);
  const [load, setLoad] = useState<requestRow[]>([]);
  const [loadFilterZone, setLoadFilterZone] = useState<requestRow[]>([]);
  const [loadFilterDistrict, setLoadFilterDistrict] = useState<requestRow[]>(
    []
  );
  const [loadFilterWard, setLoadFilterWard] = useState<requestRow[]>([]);
  const [loadStat, setLoadStat] = useState<requestStatusConfig[]>([])
  const [checkCount, setCheckCount] = useState("0");

  const [selectedZone, setSelectedZone] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [selectedRole, _setSelectedRole] = useState("Member");
  const [search, setSearch] = useState("");
  const [searchByStatus, setSearchByStatus] = useState('')
  const [date1, setDate1] = useState("2023-01-01");

  // Filter la date du jour
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
    }`;
  const [date2, setDate2] = useState(formattedDate);

  const count1 = (array: []) => array.length;

  //Pagination
  const [totalPage, setTotalPage] = useState(1);

  const items = [
    {
      key: "1",
      label: <div onClick={() => setCheckCount("0")}>Principal Member</div>,
    },
    {
      key: "2",
      label: (
        <div rel="noopener noreferrer" onClick={() => setCheckCount("1")}>
          Sub Member
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (sessionStorage.getItem("onktxonktx") == null) {
      navigate("/login");
    }
    loadProvince();
    loadCity();
    loadMinicipality();
    loadCategorie_or_Role();

    LoadFilterMember(selectedZone, selectedDistrict);
    loadMember(1);
    loadStatus()
    loadUserForm()
  }, [
    checkCount,
    selectedZone,
    selectedDistrict,
    selectedWard,
    selectedRole,
    date1,
    date2,
    search,
    searchByStatus
  ]);

  //Load seller
  function loadMember(page: any) {
    ///partners/app/members/member?is_sub_account=${checkCount}?page=${page}

    api
      .getHttpRequest(
        `/partners/app/members/member?is_sub_account=${checkCount}&region=&ward=&district=&date1=${date1}&date2=${date2}&status=${searchByStatus}&search=&page=${page}`,
        data,
        "GET"
      )
      .then((response) => {
        const json = response.data;
        if (json.status == 200) {
          setLoad(json?.data?.response?.data);
          // setCountHaveAssurence(json.data.count_have_assurence)
          // setCountNotHaveAssurence(json.data.count_not_have_assurence)
          setCount(json?.data?.count_totals);
          setCountSubTotal(json?.data?.count_sub_total);
          setCountTotalsToday(json?.data?.count_totals_today);
          setCountTotalsThisWeek(json?.data?.count_totals_thisWeek);
          setCountTotalsThisMonth(json?.data?.count_totals_thisMonth);

          setCountTotalsSubToday(json?.data?.count_totals_sub_today);
          setCountTotalsSubThisWeek(json?.data?.count_totals_sub_thisWeek);
          setCountTotalsSubThisMonth(json?.data?.count_totals_sub_thisMonth);
          setCountTotalsSubThisYear(json?.data?.count_totals_sub_thisYear);
          setCountTotalsThisYear(json?.data?.count_totals_thisYear);
          setTotalPage(json?.data?.response?.count);
        }
      })
      .catch((error) => {
        api.getMessageError(error);
      });
  }

  function loadMemberByBtn() {
    ///partners/app/members/member?is_sub_account=${checkCount}?page=${page}

    api
      .getHttpRequest(
        `/partners/app/members/member?is_sub_account=${checkCount}&region=${selectedZone}&ward=${selectedWard}&district=${selectedDistrict}&date1=${date1}&date2=${date2}&search=${search}&status=${searchByStatus}`,
        data,
        "GET"
      )
      .then((response) => {
        const json = response.data;
        if (json.status == 200) {
          setLoad(json?.data?.response?.data);
          // setCountHaveAssurence(json.data.count_have_assurence)
          // setCountNotHaveAssurence(json.data.count_not_have_assurence)
          setCount(json?.data?.count_totals);
          setCountSubTotal(json?.data?.count_sub_total);
          setCountTotalsToday(json?.data?.count_totals_today);
          setCountTotalsThisWeek(json?.data?.count_totals_thisWeek);
          setCountTotalsThisMonth(json?.data?.count_totals_thisMonth);

          setCountTotalsSubToday(json?.data?.count_totals_sub_today);
          setCountTotalsSubThisWeek(json?.data?.count_totals_sub_thisWeek);
          setCountTotalsSubThisMonth(json?.data?.count_totals_sub_thisMonth);
          setCountTotalsSubThisYear(json?.data?.count_totals_sub_thisYear);
          setCountTotalsThisYear(json?.data?.count_totals_thisYear);
          setTotalPage(json?.data?.response?.count);
        }
      })
      .catch((error) => {
        api.getMessageError(error);
      });
  }

  const clickBtnSearch = () => {
    loadMemberByBtn();
  };

  const LoadFilterMember = (selectedZone: any, selectedDistrict: any) => {
    const url = `/partners/app/members-filter?region=${selectedZone}&district=${selectedDistrict}&is_sub_account=0`;

    api
      .getHttpRequest(url, data, "GET")
      .then((response) => {
        const json = response.data?.data;

        setLoadFilterZone(json?.list_zone);

        if (selectedZone) {
          setLoadFilterDistrict(json?.list_district);
        }

        if (selectedDistrict) {
          setLoadFilterWard(json?.list_wards);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  //Add Seller_or_Member
  const Add_Seller_or_Member = async () => {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("lastname", lastname);
    formData.append("middlename", middlename);
    formData.append("email", email);
    formData.append("address", adress);
    formData.append("phone", phone);
    formData.append("MunicipalityId", minicipal);
    formData.append("avatar", avatar);
    formData.append("gender", gender);
    formData.append("UserRoleId", userRoleId);
    formData.append("civil_status", civilStatus);
    formData.append("date_of_birth", dateOfBirth);
    formData.append("place_of_birth", placeOfBirth);

    var config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: api.var_.url_api_local + "/member/create",
      headers: {
        Authorization: "Bearer " + api.initToken(),
        "Content-Type": "application/json",
      },
      data: formData,
    };
    setLoading(true);

    if (
      name === "" ||
      lastname === "" ||
      phone === "" ||
      email === "" ||
      minicipal === ""
    ) {
      toast.error("No fields should be empty");
    } else if (!regexTelephone.test(phone)) {
      toast.error("The entry number is incoreact");
    } else if (!regexEmail.test(email)) {
      toast.error("The email address entered is incoreact");
    } else {
      axios(config)
        .then(function (response) {
          const json = response.data;
          if (json.status == 200) {
            toast.success(json.message);
            loadMember(1);
          } else {
            toast.error(json.message);
          }
        })
        .catch((error) => {
          // setLoading(false);
          if (
            !window.navigator.onLine &&
            !error.response &&
            error.code === "ERR_NETWORK"
          ) {
            toast.error("Please connect your device to the internet");
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
        });
    }

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  //Load zones
  const loadProvince = () => {
    api
      .getHttpRequest("/zone/province/load", data, "GET")
      .then((response) => {
        const json = response.data;
        if (json.status == 200) {
          setPronvince(json.data);
        }
      })
      .catch((error) => {
        if (
          !window.navigator.onLine &&
          !error.response &&
          error.code === "ERR_NETWORK"
        ) {
          toast.error("Please connect your device to the internet");
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const loadCity = () => {
    api
      .getHttpRequest("/zone/city/load", data, "GET")
      .then((response) => {
        const json = response.data;
        if (json.status == 200) {
          setCity(json.data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        if (
          !window.navigator.onLine &&
          !error.response &&
          error.code === "ERR_NETWORK"
        ) {
          toast.error("Please connect your device to the internet");
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const loadMinicipality = () => {
    api
      .getHttpRequest("/zone/municipality/load", data, "GET")
      .then((response) => {
        const json = response.data;
        if (json.status == 200) {
          setMinicipality(json.data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        if (
          !window.navigator.onLine &&
          !error.response &&
          error.code === "ERR_NETWORK"
        ) {
          toast.error("Please connect your device to the internet");
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const loadCategorie_or_Role = () => {
    api
      .getHttpRequest("/parameters/roles/load?groupe=0", data, "GET")
      .then((response) => {
        const json = response.data;
        if (json.status == 200) {
          setCategoriRole(json.data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        if (
          !window.navigator.onLine &&
          !error.response &&
          error.code === "ERR_NETWORK"
        ) {
          toast.error("Please connect your device to the internet");
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleImageChange = (e: any) => {
    setAvatar(e.target.files[0]);
  };

  // Status Color

  const loadStatus = () => {
    api
      .getHttpRequest(`/partners/mention_member/${api.initPartnerId()}`, data, "GET")
      .then((response) => {
        const json = response.data;
        setLoadStat(json.data)
      })
      .catch((error) => {
        console.log(error)
      });
  };


  const [settings, setSettings] = useState([]);  
  const [formData, setFormData] = useState<FormData[]>([
    {
      id: '',
      name: '',
      observation: '',
      createdAt: '',
      updatedAt: '',
      settings: [],
    },
  ]);

  const loadUserForm = async () => {
    api.getHttpRequestV3("/user/gui/load", {}, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setFormData(json.data);
      }
    }).catch((error) => {
      // setLoading(false);
      if (
        !window.navigator.onLine &&
        !error.response &&
        error.code === "ERR_NETWORK"
      ) {
        toast.error("Please connect your device to the internet");
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    });
  }


  // Fonction qui va permettre des charger le city appartir de la province
  const [stateCity, setStateCity] = useState<requestRow[]>([]);
  const [stateMinicipality, setStateMinicipality] = useState<requestRow[]>([]);
  const GetProvince = (id: any) => {
    const IdProv = city.filter((x) => x.PronvinceId === id);
    setStateCity(IdProv);
  };

  const GetCity = (id: any) => {
    const IdCity = minicipality.filter((x) => x.CityId === id);
    setStateMinicipality(IdCity);
  };

  const searchSellers = (event: any) => {
    const getSearch = event.target.value;
    if (getSearch.length > 0) {
      const searchdata = sellerLoad.filter(
        (item) =>
          item.name.toLowerCase().includes(getSearch) ||
          item.lastname.toLowerCase().includes(getSearch) ||
          item.middlename.toLowerCase().includes(getSearch)
      );
      setSellerLoad(searchdata);
    } else {
      setSellerLoad(searchResult);
    }
    setQuery(getSearch);
  };

  const [isModalOpenDocs, setIsModalOpenDocs] = useState(false);
  const [isModalOpenStatus, setIsModalOpenStatus] = useState(false);
  const [isModalOpenAttribStatus, setIsModalOpenAttribStatus] = useState(false);
  const [isModalOpenDialogAddMember, setIsModalOpenDialogAddMember] = useState(false);
  const [isModalOpenDialogAddMember1, setIsModalOpenDialogAddMember1] = useState(false);
  const [isModalOpenUploadExcel, setIsModalOpenUploadExcel] = useState(false);
  const [isModalShowForm, setIsModalShowForm] = useState(false);

  const [itemMember, setItemMember] = useState<requestRow[]>([])
  const [chefDocumentScan, setChefDocumentScan] = useState<requestRow[]>([])
  const [StatusId, setStatusId] = useState('')
  const [idLigne, setIdLigne] = useState('')
  const [color, setColor] = useState('')
  const [nameColor, setNameColor] = useState('')
  const [detail, setDetail] = useState('')
  const [userId, setUserId] = useState("")
  const [phoneUser, setPhoneUser] = useState("")
  const [nameUser, setNameUser] = useState("")
  const [formtype, setFormtype] = useState("")

  const showModal = (element: requestRow) => {
    setIsModalOpenDocs(true);
    setUserId(element?.user?.User?.id)
    setNameUser(element?.user?.fullname)
    setPhoneUser(element?.user?.User?.phone)
    if (element?.user?.User?.id_front !== null && element?.user?.User?.id_front !== undefined) {
      const parsDocs = JSON.parse(element.user.User.id_front);
      if (Array.isArray(parsDocs)) {
        setItemMember(parsDocs)
      } else {
        console.error('IdFot is not an array after parsing:', parsDocs);
      }
    }

    // if (element.user.User.chef_document_scan !== null && element.user.User.chef_document_scan !== undefined) {
    //   setChefDocumentScan(JSON.parse(element.user.User.chef_document_scan))
    // }
    if (element?.user?.User?.chef_document_scan !== null && element?.user?.User?.chef_document_scan !== undefined) {
      const parsedScan = JSON.parse(element?.user?.User?.chef_document_scan);
      if (Array.isArray(parsedScan)) {
        setChefDocumentScan(parsedScan);
      } else {
        console.error('chefDocumentScan is not an array after parsing:', parsedScan);
      }
    }
  };

  const showStatusModal = () => {
    setIsModalOpenStatus(true)
  }

  const showAddMemberModal = () => {
    setIsModalOpenDialogAddMember(true)
  }

  const showAddMember1Modal1 = () => {
    // setIsModalOpenDialogAddMember1(true)
    setIsModalShowForm(true)
    setFormtype("existing_form")
  }

  const showAUploadExcel = () => api.LoginToTivAppv3(api.initToken())

  const showFormModal = (item: any) => {
    setIsModalShowForm(true);
    setSettings(item);
    setFormtype("form_creater")
  };


  const showAttributeStatusModal = (StatusId: string, id: string, color: string, name: string, detail: string) => {
    setIsModalOpenAttribStatus(true)
    setStatusId(StatusId)
    setIdLigne(id)
    setColor(color)
    setNameColor(name)
    setDetail(detail)
  }

  const handleOk = () => {
    setIsModalOpenDocs(false);
    setItemMember([])
    setChefDocumentScan([])
    setIsModalOpenStatus(false)
    setIsModalOpenAttribStatus(false)
    setIsModalOpenDialogAddMember(false)
    setIsModalOpenUploadExcel(false)
    setIsModalOpenDialogAddMember1(false)
    setIsModalShowForm(false)
    setStatusId('')
    setIdLigne('')
    setColor('')
    setNameColor('')
  };

  const handleCancel = () => {
    setIsModalOpenDocs(false);
    setItemMember([])
    setChefDocumentScan([])
    setIsModalOpenStatus(false)
    setIsModalOpenAttribStatus(false)
    setIsModalOpenDialogAddMember(false)
    setIsModalOpenDialogAddMember1(false)
    setIsModalOpenUploadExcel(false)
    setIsModalShowForm(false)
    setStatusId('')
    setIdLigne('')
    setColor('')
    setNameColor('')
  };


  const [openTooltip, setOpenTooltip] = useState(null);

  // Fonction pour gérer le clic sur le tag
  const handleTooltipClick = (index: any) => {
    setOpenTooltip(openTooltip === index ? null : index);
  };

  const items1 = [
    {
      key: "1",
      label: (
        <div className="" style={{
          border: '1px solid #F0F0F0', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center',
          justifyContent: 'center', width: 200, borderRadius: 10, backgroundColor: '#FFFFFF', cursor: 'pointer'
        }}
          onClick={showAddMember1Modal1}
        >
          <p style={{
            fontFamily: "sans-serif", fontWeight: 'bold', color: '#87d068',
          }}><strong>Existing form</strong></p>
          <p style={{
            color: "gray", fontFamily: "sans-serif", fontWeight: 'bold', fontSize: 16
          }}><strong></strong></p>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div style={{
          border: '1px solid #F0F0F0', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center',
          justifyContent: 'center', width: 200, borderRadius: 10, backgroundColor: '#FFFFFF', cursor: 'pointer'
        }}>
          <p style={{
            fontFamily: "sans-serif", fontWeight: 'bold', color: '#2db7f5',
          }}
            onClick={showAddMemberModal}
          ><strong>New form</strong></p>
          <p style={{
            color: "gray", fontFamily: "sans-serif", fontWeight: 'bold', fontSize: 16
          }}><strong></strong></p>
        </div>
      )
    },

    {
      key: "3",
      label: (

        <div style={{
          border: '1px solid #F0F0F0', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center',
          justifyContent: 'center', width: 200, borderRadius: 10, backgroundColor: '#FFFFFF', cursor: 'pointer'
        }}>
          
          {
            formData?.map((item, i) => (
              <div key={i}>
                <p style={{
                  fontFamily: "sans-serif", fontWeight: 'bold', color: '#2db7f5',
                }}
                  onClick={() => showFormModal(item)}
                ><strong>{item?.name}</strong></p>
                <p style={{
                  color: "gray", fontFamily: "sans-serif", fontWeight: 'bold', fontSize: 16
                }}><strong></strong></p>
              </div>
            ))
          }


        </div>
      )
    },

  ];

  return (
    <>
      <div id="wrapper">
        <Header />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid1">
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="left-side ml-2 mb-2">
                      <h2 className="m-0 font-24 font-weight-500">
                        All members
                      </h2>
                      <p>
                        List of members who have joined by activating voucher
                        insurances
                      </p>
                    </div>
                    <div>
                      <Toaster position="bottom-left" reverseOrder={false} />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div>
                          <div className="card stacked-2 py-4">
                            <div className="row">
                              <div className="col-xl-4 col-sm-6 widget-inline-box">
                                <div
                                  className="bg-primary-subtle"
                                  style={{
                                    backgroundColor: "#c8e1fe",
                                    padding: 15,
                                    borderRadius: "12px",
                                    border: "1px solid #e5e7eb",
                                    margin: "0px 10px",
                                  }}
                                >
                                  <div>
                                    <div className="d-flex justify-content-between align-items-end">
                                      <h6 className="fs-5">
                                        <h4 className="mt-0">
                                          <i className="text-info mdi mdi-account-multiple mr-2"></i>
                                        </h4>
                                        Total principal members
                                      </h6>
                                      <div>
                                        <h1 style={{ color: "#1576ed" }}>
                                          {count}
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="d-flex justify-content-between align-items-end">
                                      <h6 className="fs-5">
                                        <h5 className="mt-0">
                                          <i className="text-info mdi mdi-account-multiple mr-2"></i>
                                        </h5>
                                        Total sub members
                                      </h6>
                                      <div>
                                        <h2 style={{ color: "black" }}>
                                          {countSubTotal}
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-2 col-sm-3 ">
                                <div className="text-center p-3">
                                  <h6 className="mt-2">
                                    <TbChecklist
                                      className="text-info mdi mdi-account-multiple"
                                      fontSize={20}
                                    />
                                    <b>Today</b>
                                  </h6>
                                  <h3>
                                    <b>{countTotalsToday}</b>
                                  </h3>
                                  <h3>
                                    <b>{countTotalsSubToday}</b>
                                  </h3>
                                </div>
                              </div>

                              <div className="col-xl-2 col-sm-3 ">
                                <div className="text-center p-3">
                                  <h6 className="mt-2">
                                    <TbChecklist
                                      className="text-info mdi mdi-account-multiple"
                                      fontSize={20}
                                    />
                                    <b>This Week</b>
                                  </h6>
                                  <h3>
                                    <b>{countTotalsThisWeek}</b>
                                  </h3>
                                  <h3>
                                    <b>{countTotalsSubThisWeek}</b>
                                  </h3>
                                </div>
                              </div>

                              <div className="col-xl-2 col-sm-3 ">
                                <div className="text-center p-3">
                                  <h6 className="mt-2">
                                    <TbChecklist
                                      className="text-info mdi mdi-account-multiple"
                                      fontSize={20}
                                    />
                                    <b>This Month</b>
                                  </h6>
                                  <h3>
                                    <b>{countTotalsThisMonth}</b>
                                  </h3>
                                  <h3>
                                    <b>{countTotalsSubThisMonth}</b>
                                  </h3>
                                </div>
                              </div>

                              <div className="col-xl-2 col-sm-3 ">
                                <div className="text-center p-3">
                                  <h6 className="mt-2">
                                    <TbChecklist
                                      className="text-info mdi mdi-account-multiple"
                                      fontSize={20}
                                    />
                                    <b>This Year</b>
                                  </h6>
                                  <h3>
                                    <b>{countTotalsThisYear}</b>
                                  </h3>
                                  <h3>
                                    <b>{countTotalsSubThisYear}</b>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-1 mb-2">
                      <Card style={{ width: "100%" }}>
                        <table
                          style={{
                            border: "1px solid black",
                            width: "100%",
                            borderColor: "#F2F2F2",
                          }}
                        >
                          {isMatch ? (
                            <>
                              <tbody style={{ display: "row" }}>
                                <tr style={{ border: "1px solid #F0F0F0" }}>
                                  <td style={{ border: "1px solid #F0F0F0" }}>
                                    <div>
                                      <Dropdown
                                        menu={{
                                          items,
                                        }}
                                        placement="bottom"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#F6FAFE",
                                            border: "1px solid #F0F0F0",
                                            padding: 8,
                                            borderRadius: 5,
                                            justifyContent: "center",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div className="d-flex">
                                            <div style={{ marginLeft: 1 }}>
                                              <span>All Members Category</span>
                                            </div>
                                            <div>
                                              <IoMdArrowDropdown
                                                style={{
                                                  color: "#1576ED",
                                                  fontSize: 25,
                                                  padding: 2,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </Dropdown>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    border: "1px solid #F0F0F0",
                                    display: "row",
                                  }}
                                >
                                  <td
                                    colSpan={2}
                                    style={{ border: "1px solid #F0F0F0" }}
                                  >
                                    <div className="inputBox m-1">
                                      <Select
                                        showSearch
                                        style={{ width: "100%", height: 40 }}
                                        size="large"
                                        dropdownRender={customDropdownRender}
                                        placeholder="Select Region"
                                        optionFilterProp="children"
                                        value={selectedZone}
                                        onChange={(value: any) => {
                                          setSelectedZone(value);
                                        }}
                                        filterOption={(
                                          input: string,
                                          option: any
                                        ) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        <Option selected value="">
                                          All Regions
                                        </Option>
                                        {loadFilterZone &&
                                          loadFilterZone !== undefined
                                          ? loadFilterZone.map((item, i) => (
                                            <Option
                                              key={i}
                                              value={item?.name}
                                              style={{ border: "none" }}
                                            >
                                              {item?.name}{" "}
                                              {`( ${item?.count} )`}
                                            </Option>
                                          ))
                                          : "No Province"}
                                      </Select>
                                    </div>
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    border: "1px solid #F0F0F0",
                                    display: "row",
                                  }}
                                >
                                  <td
                                    colSpan={2}
                                    style={{ border: "1px solid #F0F0F0" }}
                                  >
                                    <div className="inputBox m-1">
                                      <Select
                                        showSearch
                                        style={{ width: "100%", height: 40 }}
                                        size="large"
                                        dropdownRender={customDropdownRender}
                                        placeholder="Select Region"
                                        optionFilterProp="children"
                                        value={selectedDistrict}
                                        onChange={(value: any) => {
                                          setSelectedDistrict(value);
                                        }}
                                        filterOption={(
                                          input: string,
                                          option: any
                                        ) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        <Option selected value="">
                                          All Districts
                                        </Option>
                                        {loadFilterDistrict &&
                                          loadFilterDistrict !== undefined
                                          ? loadFilterDistrict.map(
                                            (item, i) => (
                                              <Option
                                                key={i}
                                                value={item?.name}
                                                style={{ border: "none" }}
                                              >
                                                {item?.name}{" "}
                                                {`( ${item?.count} )`}
                                              </Option>
                                            )
                                          )
                                          : "No Districts"}
                                      </Select>
                                    </div>
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    border: "1px solid #F0F0F0",
                                    display: "row",
                                  }}
                                >
                                  <td
                                    colSpan={2}
                                    style={{ border: "1px solid #F0F0F0" }}
                                  >
                                    <div className="inputBox m-1">
                                      <Select
                                        showSearch
                                        style={{ width: "100%", height: 40 }}
                                        size="large"
                                        dropdownRender={customDropdownRender}
                                        placeholder="Select Region"
                                        optionFilterProp="children"
                                        value={selectedWard}
                                        onChange={(value: any) => {
                                          setSelectedWard(value);
                                        }}
                                        filterOption={(
                                          input: string,
                                          option: any
                                        ) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        <Option selected value="">
                                          All Wards
                                        </Option>
                                        {loadFilterWard &&
                                          loadFilterWard !== undefined
                                          ? loadFilterWard.map((item, i) => (
                                            <Option
                                              key={i}
                                              value={item?.name}
                                              style={{ border: "none" }}
                                            >
                                              {item?.name}{" "}
                                              {`( ${item?.count} )`}
                                            </Option>
                                          ))
                                          : "No Province"}
                                      </Select>
                                    </div>
                                  </td>
                                </tr>

                                <tr style={{ border: "1px solid #F0F0F0" }}>
                                  <td
                                    colSpan={2}
                                    style={{ border: "1px solid #F0F0F0" }}
                                  >
                                    <div className="inputBox m-1">
                                      <Input
                                        size="large"
                                        value={search}
                                        onChange={(e) =>
                                          setSearch(e.target.value)
                                        }
                                        placeholder="Searh here..."
                                        prefix={<SearchOutlined />}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ) : (
                            <>
                              <tbody>
                                <tr style={{ border: "1px solid #F0F0F0" }}>
                                  <td style={{ border: "1px solid #F0F0F0" }}>
                                    <div>
                                      <Dropdown
                                        menu={{
                                          items,
                                        }}
                                        placement="bottom"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#F6FAFE",
                                            border: "1px solid #F0F0F0",
                                            padding: 8,
                                            borderRadius: 5,
                                            justifyContent: "center",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div className="d-flex">
                                            <div style={{ marginLeft: 1 }}>
                                              <span>
                                                {checkCount === "0" &&
                                                  "Principal member"}
                                                {checkCount === "1" &&
                                                  "Sub member"}
                                                {checkCount !== "0" &&
                                                  checkCount !== "1" &&
                                                  "All Members Category"}
                                              </span>
                                            </div>
                                            <div>
                                              <IoMdArrowDropdown
                                                style={{
                                                  color: "#1576ED",
                                                  fontSize: 25,
                                                  padding: 2,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </Dropdown>
                                    </div>
                                  </td>
                                  <td style={{ border: "1px solid #F0F0F0" }}>
                                    <div className="inputBox m-1">
                                      <Select
                                        showSearch
                                        style={{ width: "100%", height: 40 }}
                                        size="large"
                                        dropdownRender={customDropdownRender}
                                        placeholder="Select Region"
                                        optionFilterProp="children"
                                        value={selectedZone}
                                        onChange={(value: any) => {
                                          setSelectedZone(value);
                                        }}
                                        filterOption={(
                                          input: string,
                                          option: any
                                        ) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        <Option selected value="">
                                          All Regions
                                        </Option>
                                        {loadFilterZone &&
                                          loadFilterZone !== undefined
                                          ? loadFilterZone.map((item, i) => (
                                            <Option
                                              key={i}
                                              value={item.name}
                                              style={{ border: "none" }}
                                            >
                                              {item.name}{" "}
                                              {`( ${item.count} )`}
                                            </Option>
                                          ))
                                          : "No Province"}
                                      </Select>
                                    </div>
                                  </td>
                                  <td style={{ border: "1px solid #F0F0F0" }}>
                                    <div className="inputBox m-1">
                                      <Select
                                        showSearch
                                        style={{ width: "100%", height: 40 }}
                                        size="large"
                                        dropdownRender={customDropdownRender}
                                        placeholder="Select Region"
                                        optionFilterProp="children"
                                        value={selectedDistrict}
                                        onChange={(value: any) => {
                                          setSelectedDistrict(value);
                                        }}
                                        filterOption={(
                                          input: string,
                                          option: any
                                        ) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        <Option selected value="">
                                          All Districts
                                        </Option>
                                        {loadFilterDistrict &&
                                          loadFilterDistrict !== undefined
                                          ? loadFilterDistrict.map(
                                            (item, i) => (
                                              <Option
                                                key={i}
                                                value={item.name}
                                                style={{ border: "none" }}
                                              >
                                                {item.name}{" "}
                                                {`( ${item.count} )`}
                                              </Option>
                                            )
                                          )
                                          : "No Districts"}
                                      </Select>
                                    </div>
                                  </td>
                                  <td style={{ border: "1px solid #F0F0F0" }}>
                                    <div className="inputBox m-1">
                                      <Select
                                        showSearch
                                        style={{ width: "100%", height: 40 }}
                                        size="large"
                                        dropdownRender={customDropdownRender}
                                        placeholder="Select Region"
                                        optionFilterProp="children"
                                        value={selectedWard}
                                        onChange={(value: any) => {
                                          setSelectedWard(value);
                                        }}
                                        filterOption={(
                                          input: string,
                                          option: any
                                        ) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        <Option selected value="">
                                          All Wards
                                        </Option>
                                        {loadFilterWard &&
                                          loadFilterWard !== undefined
                                          ? loadFilterWard.map((item, i) => (
                                            <Option
                                              key={i}
                                              value={item.name}
                                              style={{ border: "none" }}
                                            >
                                              {item.name}{" "}
                                              {`( ${item.count} )`}
                                            </Option>
                                          ))
                                          : "No Province"}
                                      </Select>
                                    </div>
                                  </td>
                                </tr>
                                <tr style={{ border: "1px solid #F0F0F0" }}>
                                  <td style={{ border: "1px solid #F0F0F0" }}>
                                    <div className="inputBox m-1">
                                      <Input.Search
                                        size="large"
                                        placeholder="Searh here..."
                                        value={search}
                                        onChange={(e) =>
                                          setSearch(e.target.value)
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          )}
                        </table>

                        <div className="mt-3">
                          {isMatch ? (
                            <>
                              <div style={{ display: "flex" }}>
                                <label
                                  style={{ marginTop: 10, marginRight: 5 }}
                                >
                                  From
                                </label>
                                <div className="inputBox m-1">
                                  <input
                                    style={{ width: 237 }}
                                    type="date"
                                    className="form-control"
                                    placeholder="Operator name"
                                    name="date1"
                                    value={date1}
                                    onChange={(e) => setDate1(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div style={{ display: "flex" }}>
                                <label
                                  style={{ marginTop: 10, marginRight: 5 }}
                                >
                                  To
                                </label>
                                <div className="inputBox m-1">
                                  <input
                                    style={{ marginLeft: 18, width: 237 }}
                                    type="date"
                                    className="form-control"
                                    placeholder="Operator name"
                                    name="date2"
                                    value={date2}
                                    onChange={(e) => setDate2(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div style={{ display: "flex" }}>
                                <div style={{ display: "flex" }}>
                                  <div className="inputBox m-1">
                                    <select className="form-control"
                                      value={searchByStatus}
                                      onChange={(e) => setSearchByStatus(e.target.value)}
                                    >
                                      <option>Status</option>
                                      {
                                        loadStat?.map((item, i) => (
                                          <option key={i} value={item.name}>{item.name}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                </div>

                                <div className="mt-1 cursor-pointer"
                                  onClick={showStatusModal}
                                >
                                  <IoSettingsOutline style={{ width: '40px', height: '40px', color: '#1576ED' }} />

                                </div>

                                <label
                                  style={{
                                    marginRight: 2,
                                    marginLeft: 10,
                                    color: "#2C71BA",
                                    cursor: "pointer",
                                  }}
                                >
                                  Reset Filters
                                </label>

                                <button
                                  onClick={() => {
                                    clickBtnSearch();
                                  }}
                                  style={{
                                    backgroundColor: "#4691EE",
                                    color: "#ffffff",
                                    borderRadius: 5,
                                    borderColor: "#4691EE",
                                    height: 40,
                                    width: 100,
                                    marginLeft: 10,
                                  }}
                                >
                                  <SearchOutlined style={{ fontSize: 30 }} />
                                </button>
                                <button
                                  onClick={showAUploadExcel}
                                  style={{
                                    backgroundColor: '#227447',
                                    color: '#ffffff',
                                    borderRadius: 5,
                                    borderColor: '#4691EE',
                                    marginTop: 10,
                                    marginRight: 5,
                                    marginLeft: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  <SiMicrosoftexcel /> Import Excel
                                </button>
                                <button
                                  onClick={showAUploadExcel}
                                  style={{
                                  backgroundColor: "#4691EE",
                                  color: "#ffffff",
                                  borderRadius: 5,
                                  borderColor: "#4691EE",
                                  marginTop: 10,
                                  marginRight: 5,
                                  marginLeft: 10,
                                   }}
                                  >
                                    New member +
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ display: "flex", marginRight: 10, alignItems: 'center' }}>
                                <div style={{ display: "flex" }}>
                                  <label
                                    style={{ marginTop: 10, marginRight: 5 }}
                                  >
                                    From
                                  </label>
                                  <div className="inputBox m-1">
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder="Operator name"
                                      name="date1"
                                      value={date1}
                                      onChange={(e) => setDate1(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div
                                  style={{ display: "flex", marginLeft: 10 }}
                                >
                                  <label
                                    style={{ marginTop: 10, marginRight: 5 }}
                                  >
                                    To
                                  </label>
                                  <div className="inputBox m-1">
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder="Operator name"
                                      name="date2"
                                      value={date2}
                                      onChange={(e) => setDate2(e.target.value)}
                                    />
                                  </div>
                                </div>

                                <div
                                  style={{ display: "flex", marginLeft: 10 }}
                                >
                                  <div className="inputBox m-1">
                                    <select className="form-control"
                                      value={searchByStatus}
                                      onChange={(e) => setSearchByStatus(e.target.value)}
                                    >
                                      <option>Status</option>
                                      {
                                        loadStat?.map((item, i) => (
                                          <option key={i} value={item.name}>{item.name}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                </div>

                                <div className="mt-1 cursor-pointer"
                                  onClick={showStatusModal}
                                >
                                  <IoSettingsOutline style={{ width: '40px', height: '40px', color: '#1576ED' }} />
                                </div>

                                <label
                                  style={{
                                    marginTop: 10,
                                    marginRight: 5,
                                    marginLeft: 10,
                                    color: "#2C71BA",
                                    cursor: "pointer",
                                  }}
                                >
                                  Reset Filters
                                </label>

                                <button
                                  onClick={() => {
                                    clickBtnSearch();
                                  }}
                                  style={{
                                    backgroundColor: "#4691EE",
                                    color: "#ffffff",
                                    borderRadius: 5,
                                    borderColor: "#4691EE",
                                    marginTop: 10,
                                    marginRight: 5,
                                    marginLeft: 10,
                                  }}
                                >
                                  Search
                                </button>

                                <button
                                  onClick={showAUploadExcel}
                                  style={{
                                    backgroundColor: '#227447',
                                    color: '#ffffff',
                                    borderRadius: 5,
                                    borderColor: '#4691EE',
                                    marginTop: 10,
                                    marginRight: 5,
                                    marginLeft: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  <SiMicrosoftexcel style={{ paddingRight: 2 }} /> Import Excel
                                </button>


                                {/* <Collapse items={collapseItems} bordered={false} ghost={true} /> */}
                                  <button
                                    onClick={showAUploadExcel}
                                    style={{
                                      backgroundColor: "#4691EE",
                                      color: "#ffffff",
                                      borderRadius: 5,
                                      borderColor: "#4691EE",
                                      marginTop: 10,
                                      marginRight: 5,
                                      marginLeft: 10,
                                    }}
                                  >
                                    New member +
                                  </button>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="table-responsive mt-3">
                          <table
                            // className="table table-hover mails m-0 table table-actions-bar table-centered"
                            className="table header-border table-responsive-sm tbl-common"
                          >
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>FullName</th>
                                <th>Phone</th>
                                {/* <th>Form</th> */}
                                <th>Created at</th>
                                <th>Location</th>
                                <th>Docs</th>
                                <th>Nation ID</th>
                                <th>Type ID</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {load.map((item, i) => (
                                <tr
                                  key={i}
                                  className="cursor-pointer"
                                  data-toggle="collapse"
                                  data-target="#demo5"
                                >
                                  <td>

                                    <Tooltip
                                      title={
                                        <div>
                                          <span>{item?.user?.observation}</span>
                                          <br />
                                          <span>{api.geDateFormat(item?.user?.mentionChangeAt)}</span>
                                        </div>
                                      }
                                      color={item?.user?.PartnerMentionStatus?.color}
                                      trigger="click"
                                      placement="left"
                                      open={openTooltip === i}
                                      onOpenChange={() => handleTooltipClick(i)}
                                    >
                                      <Tag
                                        color={item?.user?.PartnerMentionStatus?.color || '#E5F1FB'}
                                        style={{
                                          width: 20,
                                          height: 20,
                                          borderRadius: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => handleTooltipClick(i)}
                                      >
                                        {i + 1}
                                      </Tag>
                                    </Tooltip>

                                  </td>

                                  <td>
                                    <Link
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                      to={`/detail_customer/${item?.user?.User?.id}`}
                                    >
                                      <img
                                        src={`${item?.user?.User?.avatar ===
                                          "default.png"
                                          ? default1
                                          : item?.user?.User?.avatar
                                          }`}
                                        title="contact-img"
                                        className="rounded-circle avatar-sm mr-2"
                                        width={35}
                                        height={35}
                                      />
                                      <span>
                                        {item?.user?.fullname
                                          ? item?.user?.fullname
                                          : ""}
                                      </span>
                                    </Link>
                                  </td>

                                  <td>
                                    <Tag color="blue">
                                      {item?.user?.User?.phone}
                                    </Tag>
                                  </td>

                                  {/* <td>
                                                        <span
                                                            className="badge badge-pill badge-danger">Used</span>

                                                    </td> */}
                                  <td>
                                    {api.formatDate(item?.user?.createdAt)} <br />
                                    <Tag color="cyan">
                                      {api.geDateFormat(item?.user?.createdAt)}
                                    </Tag>
                                  </td>

                                  <td>
                                    <div>
                                      <span>
                                        {
                                          item.user?.User?.LocationWard
                                            ?.LocationDistric?.LocationRegion
                                            ?.name
                                        }
                                      </span>
                                      ,
                                      <span>
                                        {" "}
                                        {
                                          item.user?.User?.LocationWard
                                            ?.LocationDistric?.name
                                        }
                                      </span>
                                      <br />,
                                      <span>
                                        {" "}
                                        {item.user?.User?.LocationWard?.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    {/* {
                                      item?.user?.User?.id_front !== null || item?.user?.User?.chef_document_scan !== null ? (
                                        <div onClick={() => showModal(item)}>
                                          <img src={okDocs} style={{ width: '30px', height: '30px' }} />
                                        </div>
                                      ) : (
                                        <img src={NoDocs} style={{ width: '30px', height: '30px' }} />
                                      )
                                    } */}

                                    <div onClick={() => showModal(item)}>
                                      <img src={docs1} style={{ width: '30px', height: '30px' }} />
                                    </div>

                                  </td>
                                  <td>
                                    <div>{item?.user?.User?.national_id}</div>
                                  </td>
                                  <td>
                                    <div>{item?.user?.User?.id_type}</div>
                                  </td>
                                  <td>
                                    <div className="dropdown">
                                      <button className="dropdown-toggle" type="button" data-toggle="dropdown">
                                        <i className="ti-more-alt font-20"></i>
                                      </button>
                                      <div className="dropdown-menu dropdown-menu-right" style={{ textAlign: 'center' }}>
                                        <span style={{ marginBottom: '10px', display: 'block', fontWeight: 'bold', color: '#1576ED' }}>Assign Status</span>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                          {loadStat?.map((el, i) => (

                                            <div onClick={() => showAttributeStatusModal(el?.id, item?.user?.id, el?.color, el?.name, el?.description)} className="row mb-2 ml-1 mr-1" key={i}>
                                              <Tag color={`${el?.color}`} style={{

                                                width: 150,
                                                height: 20,
                                                borderRadius: 5,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'

                                              }}>
                                                {truncateText(el?.name, 25)}
                                              </Tag>
                                            </div>
                                          ))}
                                          <div>
                                            <Button onClick={showStatusModal} style={{
                                              marginTop: 5, height: 20, backgroundColor: '#E5F1FB',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              fontSize: 10
                                            }}>Add new</Button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <Pagination
                          style={{
                            paddingTop: 10,
                          }}
                          pageSize={10}
                          total={totalPage}
                          onChange={(page: any) => {
                            loadMember(page);
                          }}
                        />
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/** add new seller Modal */}
            <div
              className="modal fade"
              id="add_new_seller"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Add or Update reseller
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="alert alert-info" role="alert">
                      Before creating a reseller account you must make sure you
                      know the person well, especially their real identity, have
                      their photo and physical address and must be able to
                      assume the role of seller of Tiva products.
                    </div>
                    <div className="col-md-12">
                      <div>
                        <Toaster position="bottom-left" reverseOrder={false} />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="pro-pic ">
                                <div className="profile-pic-wrapper">
                                  <div className="pic-holder mb-0">
                                    {/** uploaded pic shown here */}
                                    <img
                                      id="profilePic"
                                      className="pic"
                                      src={default1}
                                    />

                                    <input
                                      className="uploadProfileInput"
                                      type="file"
                                      name="profile_pic"
                                      id="newProfilePhoto"
                                      accept="image/*"
                                      style={{ opacity: 0 }}
                                      onChange={handleImageChange}
                                    />
                                    <label
                                      htmlFor="newProfilePhoto"
                                      className="upload-file-block"
                                    >
                                      <div className="text-center">
                                        <div className="mb-2">
                                          <i className="fa fa-camera fa-2x"></i>
                                        </div>
                                        <div className="text-uppercase">
                                          Update <br /> Profile Photo
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 row">
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="fullname"
                                  name="name"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">LastName</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="fullname"
                                  name="lastname"
                                  value={lastname}
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">MiddleName</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="fullname"
                                  name="middlename"
                                  value={middlename}
                                  onChange={(e) => {
                                    setMiddlename(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label">Category</label>
                                <select
                                  className="form-control"
                                  id="categorie"
                                  name="userRoleId"
                                  value={userRoleId}
                                  onChange={(e) => {
                                    setUserRoleId(e.target.value);
                                  }}
                                >
                                  <option value="">Select category</option>
                                  {categoriRole.map((item, i) => (
                                    <option key={i} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <input type="hidden" id="id" value="00" /> */}

                        <div className="col-md-12 row">
                          <div className="mb-3 col-md-4">
                            <label className="form-label">Gender</label>
                            <select
                              className="form-control"
                              id="gender"
                              name="gender"
                              value={gender}
                              onChange={(e) => {
                                setGender(e.target.value);
                              }}
                            >
                              <option>Choose...</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>

                          <div className="mb-3 col-md-4">
                            <label className="form-label">Date of birth</label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder=""
                              id="datenaiss"
                              name="dateOfBirth"
                              value={dateOfBirth}
                              onChange={(e) => {
                                setDateOfBirth(e.target.value);
                              }}
                            />
                          </div>
                          <div className="mb-3  col-md-4">
                            <label className="form-label">Place of Birth</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              id="phone"
                              name="placeOfBirth"
                              value={placeOfBirth}
                              onChange={(e) => {
                                setPlaceOfBirth(e.target.value);
                              }}
                            />
                          </div>
                          <div className="mb-3  col-md-4">
                            <label className="form-label">Phone</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              id="phone"
                              name="phone"
                              value={phone}
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                            />
                          </div>

                          <div className="mb-3  col-md-4">
                            <label className="form-label">Adress Mail</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder=""
                              id="email"
                              name="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                            <label className="form-label">State/Province</label>
                            <select
                              className="form-control"
                              id="Province"
                              onChange={(e) => GetProvince(e.target.value)}
                            >
                              <option value="">Select State/Province</option>
                              {province && province !== undefined
                                ? province.map((item, i) => (
                                  <option key={i} value={item.id}>
                                    {item.name}
                                  </option>
                                ))
                                : "No Province"}
                            </select>
                          </div>
                          <div className="mb-3  col-md-6">
                            <label className="form-label">City</label>
                            <select
                              className="form-control"
                              id="City"
                              onChange={(e) => GetCity(e.target.value)}
                            >
                              <option value="">Select City</option>
                              {(stateCity && stateCity !== undefined) ||
                                stateCity !== null
                                ? stateCity.map((item, i) => {
                                  return (
                                    <option key={i} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                                : "No City"}
                            </select>
                          </div>

                          <div className="mb-3  col-md-6">
                            <label className="form-label">Street</label>
                            <select
                              className="form-control"
                              id="Minicipality"
                              name="minicipality"
                              value={minicipal}
                              onChange={(e) => {
                                setMinicipal(e.target.value);
                              }}
                            >
                              <option value="">Select Street</option>
                              {(stateMinicipality &&
                                stateMinicipality !== undefined) ||
                                stateMinicipality !== null
                                ? stateMinicipality.map((item, i) => {
                                  return (
                                    <option key={i} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })
                                : "No minicipality"}
                            </select>
                          </div>
                          <div className="mb-3  col-md-12">
                            <label className="form-label">Adresse</label>
                            <textarea
                              className="form-control"
                              placeholder=""
                              id="adresse"
                              name="adress"
                              value={adress}
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    {!Loading && (
                      <button
                        type="button"
                        className="btn btn-primary agree-link font-18"
                        id="btn-Withdraw"
                        onClick={() => Add_Seller_or_Member()}
                      >
                        Save changes
                      </button>
                    )}

                    {Loading && (
                      <button
                        type="button"
                        className="btn btn-primary agree-link font-18"
                        disabled={true}
                        id="btn-Withdraw"
                      >
                        <i
                          className="fa fa-spinner fa-spin fa-3x  fa-lg"
                          style={{ fontSize: 25 }}
                        ></i>{" "}
                        <span>Ongoing processing...</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>

      <DocsCard isModalOpen={isModalOpenDocs} handleOk={handleOk} handleCancel={handleCancel} idFront={itemMember} chefDocumentScan={chefDocumentScan} UserId={userId} phoneUser={phoneUser} nameUser={nameUser} />

      <StatusCreateModal isModalOpen={isModalOpenStatus} handleOk={handleOk} handleCancel={handleCancel} dataLoad={() => loadStatus}
        dataLoadMember={() => loadMember(1)} />

      <AttributeStatus isModalOpen={isModalOpenAttribStatus} handleOk={handleOk} handleCancel={handleCancel} dataLoad={() => loadMember(1)}
        StatusId={StatusId} id={idLigne} color={color} name={nameColor} detail={detail}
      />
      <DialogAddMember
        isModalOpen={isModalOpenDialogAddMember1}
        handleOk={handleOk}
        handleCancel={handleCancel}
        load={() => loadMember(1)}
        title="New Member"
      />

      <CreateFormMember
        isModalOpen={isModalOpenDialogAddMember}
        handleOk={handleOk}
        handleCancel={handleCancel}
        load={() => loadUserForm()}
        title="Form"

      />
      <DialogUploadExcel
        isModalOpen={isModalOpenUploadExcel}
        handleOk={handleOk}
        handleCancel={handleCancel}
        load={() => loadMember(1)}
        title="Import excel file"
      />
      <ShowForm
        isModalOpen={isModalShowForm}
        handleOk={handleOk}
        handleCancel={handleCancel}
        loadload={() => {}}
        data={settings}
        formtype={formtype}
      />
    </>
  );
};

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};
export default Member;
