import { useEffect, useState } from "react";
import api from '../../../../config/Config';
import { Alert, Card, Modal, Pagination, Space } from "antd";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Select } from 'antd';
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import Swal from 'sweetalert2'

const { Option } = Select;

const District = () => {
    const data = '';
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    const [Loading, setLoading] = useState(false);
    const [loaddistrict, setLoadDistrict] = useState<requestLocation[]>([])
    const [getRegion, setGetRegion] = useState<requestManagerAccount[]>([]);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState("");
    const [region, setRegion] = useState("");
    const [id, setId] = useState("")

    // Verify etat button
    const [vBottom, setVBottom] = useState('');

    //Messge
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);
    const [message, setMessage] = useState("");

    const [totalPage, setTotalPage] = useState(1);

    useEffect(() => {
        LoadDistict(1)
        LoadRegion();
        LoadDistictById(1)
        setLoading(false);
    }, [region])

    const LoadDistict = (page: any) => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/district/load?page=${page}`, data, 'GET').then((response) => {
            const json = response.data.data;
            setLoadDistrict(json.rows);
            setTotalPage(json.count)
        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }

    const LoadDistictById = (page: any) => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/district/load-by-region/${region}?page=${page}`, data, 'GET').then((response) => {
            const json = response.data.data;
            setLoadDistrict(json.rows);
            setTotalPage(json.count)
        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }

    const LoadRegion = () => {
        setLoading(true);
        api.getHttpRequest(`/parameters/location/region/load?page=1&limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetRegion(json.rows)

        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    const AddDistrict = async () => {
        let data = JSON.stringify({
            "name": name,
            "description": description,
            "id_region": region
        });
        setLoading(true);

        if (name === '' || region === '') {
            setShowAlertDanger(true);
            setMessage('Make sure you have filled in all the red star fields');
        } else {
            api.getHttpRequest("/parameters/location/district/create", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setName('');
                    setDescription('');
                    setShowAlertSucess(true);
                    setMessage(json.message);
                    LoadDistict(1)

                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    setShowAlertDanger(true);
                    setMessage("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    setShowAlertDanger(true);
                    setMessage(error.response.data.message);
                } else {
                    setShowAlertDanger(true);
                    setMessage(error.message);
                }
            })

        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }

    const UpdateDistrict = async () => {
        let data = JSON.stringify({
            "name": name,
            "description": description,
            "id_region": region
        });
        setLoading(true);

        if (name === '' || region === '') {
            setShowAlertDanger(true);
            setMessage('Make sure you have filled in all the red star fields');
        } else {
            api.getHttpRequest(`/parameters/location/district/update/${id}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setName('');
                    setDescription('');
                    setShowAlertSucess(true);
                    setMessage(json.message);
                    LoadDistict(1)

                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    setShowAlertDanger(true);
                    setMessage("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    setShowAlertDanger(true);
                    setMessage(error.response.data.message);
                } else {
                    setShowAlertDanger(true);
                    setMessage(error.message);
                }
            })

        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }

    const deleteDistrict = (element: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Want to remove this  ${element.name}`,
            // icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            customClass: 'swal-height-10',

        }).then((result) => {
            if (result.isConfirmed) {

                api.getHttpRequest(`/parameters/location/district/delete/${element.id}`, {}, 'Delete').then((response) => {
                    const json = response.data;
                    if (json.status == 200) {
                        LoadDistict(1)
                        // setShowVerifyOTP(true);
                        setShowAlertSucess(true);
                        setMessage(json.message);
                    } else {
                        setShowAlertDanger(true);
                        setMessage(json.message);
                    }
                }).catch((error) => {
                    setLoading(false)
                    if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

                        setShowAlertDanger(true);
                        setMessage("Please connect your device to the internet");
                    } else if (error.response && error.response.data && error.response.data.message) {

                        setShowAlertDanger(true);
                        setMessage(error.response.data.message);
                    } else {
                        setShowAlertDanger(true);
                        setMessage(error.message);
                    }
                })
            }
        })
        setTimeout(() => {
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }

    // Function

    const showElement = (element: any) => {
        setName(element.name)
        setId(element.id)
        setDescription(element.description)
        setRegion(element.LocationRegion.name)
        setVBottom('Update')
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setVBottom('')
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setVBottom('')
    };

    return (
        <>

            <div className="mt-2">
                <Card style={{ width: '100%' }}>
                    <div className="d-block d-sm-flex border-0" style={{ justifyContent: 'space-between' }}>
                        <div>
                            <h4 className="fs-20 text-black mb-0">District</h4>
                        </div>

                        <div className="card-action card-tabs mt-3 mt-sm-0 d-flex">

                            <div className="ml-2" role="search">
                                <button type="button" className="btn-primary agree-link font-10"
                                    id="btn-Withdraw" style={{ width: 150, height: 40 }}
                                    onClick={() => showModal()}
                                >New Dictrict</button>
                            </div>
                        </div>

                    </div>

                    <div className='col-md-12 mb-1'>
                        {/* <label style={{color:'white'}}>vouchers</label> */}
                        <Space direction="vertical" style={{ width: '100%' }}>
                            {
                                showAlertSucess &&
                                <Alert type="success" message={message} showIcon closable />
                            }
                        </Space>


                    </div>

                    <table style={{
                        border: '1px solid black',
                        width: '100%',
                        borderColor: '#F2F2F2'
                    }}>
                        {
                            isMatch ? (
                                <>
                                    <tbody style={{ display: 'row' }}>
                                        <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                            <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                <div className="inputBox m-1">
                                                    <label className="form-label">Search</label>
                                                    <input type="text" className="form-control" placeholder="Search..." id="email"
                                                        name='date2'

                                                    />
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </>
                            ) : (
                                <>
                                    <tbody >
                                        <tr style={{ border: '1px solid #F0F0F0' }}>

                                            <td style={{ border: '1px solid #F0F0F0' }}>
                                                <div className="inputBox m-1">
                                                    <label className="form-label">Search</label>
                                                    <input type='text' placeholder="Search..." className="form-control"
                                                        name='date2'

                                                    />

                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </>
                            )
                        }

                    </table>

                    <div className='mt-4'>

                        <div className="table-responsive">
                            <table className="table header-border table-responsive-sm tbl-common">
                                <thead>
                                    <tr>
                                        <th >Id</th>
                                        <th >District Name</th>
                                        <th >Region</th>
                                        <th >Description</th>
                                        <th>Longitude</th>
                                        <th>Latitude</th>
                                        <th>CreateAt</th>
                                        <th>UpdateAt</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody id="tab_">

                                    {
                                        loaddistrict.map((item, i) => {

                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.LocationRegion ? item.LocationRegion.name : '-'}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.longitude === null ? '-' : item.longitude}</td>
                                                    <td>{item.latitude === null ? '-' : item.latitude}</td>
                                                    <td>{api.formatDate(item.createdAt)}<br />
                                                        {api.geDateFormat(item.createdAt)}
                                                    </td>
                                                    <td>{api.formatDate(item.updatedAt)}<br />
                                                        {api.geDateFormat(item.updatedAt)}
                                                    </td>
                                                    <td>

                                                        <FiEdit onClick={() => showElement(item)} style={{ fontSize: 25, paddingRight: 2, cursor: 'pointer' }} />

                                                        {/* <button onClick={() => navigate(`/load-profile/${item.id}`)} type="button" style={{ backgroundColor: '#1EAAE7', marginLeft: 2, padding: 2, color: 'white', borderRadius: 3 }}><AiOutlineEye style={{ fontSize: 20 }} />Profile</button> */}

                                                        <RiDeleteBin5Line onClick={() => deleteDistrict(item)} style={{ fontSize: 25, color: 'red', cursor: 'pointer' }} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                        <nav>
                            <Pagination
                                style={{
                                    paddingTop: 10
                                }}
                                pageSize={10}
                                total={totalPage}
                                onChange={(page: any) => {
                                    LoadDistict(page)
                                }}
                            />
                        </nav>
                    </div>

                </Card>
            </div>


            <Modal
                title="New Account"
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={null}
                width={1500}
            >
                <div>
                    <div className=" p-2 form-label rounded-sm" role="alert" style={{ backgroundColor: '#F0F3F8' }}>
                        Fill out the form before registering
                    </div>

                    {
                        message && (
                            <div className='col-md-12 mb-1 mt-1'>
                                {/* <label style={{color:'white'}}>vouchers</label> */}
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    {
                                        showAlertDanger &&
                                        <Alert type="error" message={message} showIcon closable />
                                    }
                                </Space>
                            </div>
                        )
                    }

                    <table className="mt-1" style={{
                        border: '1px solid black',
                        width: '100%',
                        borderColor: '#F2F2F2'
                    }}>
                        {
                            isMatch ? (
                                <>
                                    <tbody style={{ display: 'row' }}>

                                        <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                            <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                <div className="inputBox m-1">
                                                    <label className="form-label">Region <span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                                    <Select showSearch className="form-control"

                                                        placeholder="Select Region" optionFilterProp="children" value={region} onChange={(e) => setRegion(e)}
                                                        filterOption={(input: string, option: any) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }>
                                                        <Option selected value="">Select Region
                                                        </Option>
                                                        {
                                                            getRegion &&
                                                                getRegion !== undefined ?
                                                                getRegion.map((item, i) => (
                                                                    <Option key={i} value={item.id} style={{ border: 'none' }}>{item.name}</Option>
                                                                ))
                                                                : "No Province"
                                                        }
                                                    </Select>
                                                </div>
                                            </td >
                                        </tr>
                                        <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                            <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                <div className="inputBox m-1">
                                                    <label className="form-label">District name <span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="" id="email"
                                                        name='name'
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}

                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                            <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                <div className="inputBox m-1">
                                                    <label className="form-label">Description</label>
                                                    <input type="text" className="form-control" placeholder="" id="email"
                                                        name='description'
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}

                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ border: '1px solid #F0F0F0', display: 'row', }} >
                                            <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                <label className="form-label text-white">btn</label>
                                                {!Loading && (
                                                    vBottom ? <button onClick={UpdateDistrict} className="btn-primary text-white form-control">
                                                        Update
                                                    </button> :
                                                        <button onClick={AddDistrict} className="btn-primary text-white form-control">
                                                            Save
                                                        </button>
                                                )}

                                                {Loading && (
                                                    <button className="btn-primary text-white form-control" disabled>
                                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 20 }}></i>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>


                                    </tbody>
                                </>
                            ) : (
                                <>
                                    <tbody >
                                        <tr style={{ border: '1px solid #F0F0F0' }}>

                                            <td style={{ border: '1px solid #F0F0F0' }}>
                                                <div className="inputBox m-1">
                                                    <label className="form-label">Region<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                                    <Select showSearch className="form-control"

                                                        placeholder="Select Region" optionFilterProp="children" value={region}
                                                        onChange={(value: any) => {
                                                            setRegion(value)
                                                        }}
                                                        filterOption={(input: string, option: any) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }>
                                                        <Option selected value="">Select Region
                                                        </Option>
                                                        {
                                                            getRegion &&
                                                                getRegion !== undefined ?
                                                                getRegion.map((item, i) => (
                                                                    <Option key={i} value={item.id} style={{ border: 'none' }}>{item.name}</Option>
                                                                ))
                                                                : "No Province"
                                                        }
                                                    </Select>
                                                </div>
                                            </td>
                                            <td style={{ border: '1px solid #F0F0F0' }}>
                                                <div className="inputBox m-1">
                                                    <label className="form-label">District name <span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                                    <input type='text' className="form-control"
                                                        name='name'
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />

                                                </div>
                                            </td>
                                            <td style={{ border: '1px solid #F0F0F0' }}>
                                                <div className="inputBox m-1">
                                                    <label className="form-label">Description</label>
                                                    <input type='text' className="form-control"
                                                        name='description'
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />

                                                </div>
                                            </td>

                                            <td style={{ border: '1px solid #F0F0F0' }}>
                                                <div className="inputBox m-1">
                                                    <label className="form-label text-white">btn</label>

                                                    {!Loading && (
                                                        vBottom ? <button onClick={UpdateDistrict} className="btn-primary text-white form-control">
                                                            Update
                                                        </button> :
                                                            <button onClick={AddDistrict} className="btn-primary text-white form-control">
                                                                Save
                                                            </button>
                                                    )}

                                                    {Loading && (
                                                        <button className="btn-primary text-white form-control" disabled>
                                                            <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 20 }}></i>
                                                        </button>
                                                    )}
                                                </div>
                                            </td>


                                        </tr>

                                    </tbody>
                                </>
                            )
                        }

                    </table>

                </div>
            </Modal>
        </>
    )
}

export default District