import { Modal } from "antd";
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import api from '../../../config/Config'
import requestRow from '../../../controller/RequestRow';

import { Pagination, Input, Select, Space, Alert } from 'antd';

import { Card,  } from 'antd';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const { Option } = Select;
const { Meta } = Card;
interface ComponentRegionProps {
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    load: () => void;
    title: string;
}

const DialogAddMember: React.FC<ComponentRegionProps> = ({
    isModalOpen,
    handleOk,
    handleCancel,
    load,
    title,

}) => {

    const data = '';
    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');

    const [Loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [phone, setPhone] = useState<string>('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [minicipal, setMinicipal] = useState('');
    const [gender, setGender] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [pswd, setPswd] = useState('');
    const [confpass, setConfPass] = useState('');

    const [viewPopup, SetViewPopup] = useState(false)

    // Variables Load elements
    const [province, setPronvince] = useState<requestRow[]>([])
    const [city, setCity] = useState<requestRow[]>([])
    const [minicipality, setMinicipality] = useState<requestRow[]>([])
    const [categoriRole, setCategoriRole] = useState<requestRow[]>([])

    //Paginate
    const [totalPage, setTotalPage] = useState(1);
    // Show Modal
    const [modal1Open, setModal1Open] = useState(false);
    //Messge
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);
    const [message, setMessage] = useState("");

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    function ViewPop() {
        SetViewPopup(true)
    }

    useEffect(() => {
        loadProvince()
        loadCity()
        loadMinicipality()
        loadCategorie_or_Role()
    },[])

    const getIdRole:any = categoriRole?.find((c) => c.name === "Member")?.id;
  
    //Add Seller_or_Member
    const Add_Seller_or_Member = async () => {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("lastname", lastname);
        formData.append("middlename", middlename)
        formData.append("email", email);
        formData.append("address", adress);
        formData.append("phone", phone);
        formData.append("id_ward", minicipal);
        formData.append("avatar", avatar);
        formData.append("gender", gender);
        formData.append("UserRoleId", getIdRole);
        formData.append("civil_status", civilStatus);
        formData.append("date_of_birth", dateOfBirth);
        formData.append("place_of_birth", placeOfBirth);
        formData.append("is_seller_account", 'true');
        formData.append("default_password", pswd);
        formData.append("id_partner", api.initPartnerId());

        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: api.var_.url_api_local + '/partners/app/members',
            headers: {
                'Authorization': 'Bearer ' + api.initToken(),
                'Content-Type': 'application/json'
            },
            data: formData
        };
        setLoading(true);

        if (name === '' || phone === '' || minicipal === '' || pswd === '') {
            setShowAlertDanger(true);
            setMessage('No fields should be empty');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger(true);
            setMessage('The entry number is incoreact');
        } else if (pswd != confpass) {
            setShowAlertDanger(true);
            setMessage('The password must be the same');
        }
        else {
            axios(config)
                .then(function (response) {
                    const json = response.data;
                    if (json.status == 200) {
                        setShowAlertSucess(true);
                        setMessage(json.message);
                        load()
                        SetViewPopup(false)
                        setPhone("")
                        setName("")
                        setLastName("")
                        setMiddlename("")
                        setEmail("")
                        setAddress("")
                        setMinicipal("")
                        setGender("")
                        setCivilStatus("")
                        setDateOfBirth("")
                        setPlaceOfBirth("")
                        setPswd("")
                        setConfPass("")
                    } else {
                        setShowAlertDanger(true);
                        setMessage(json.message);
                    }
                })
                .catch((error) => {
                    if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                        setShowAlertDanger(true);
                        setMessage("Please connect your device to the internet");
                    } else if (error.response && error.response.data && error.response.data.message) {
                        setShowAlertDanger(true);
                        setMessage(error.response.data.message);
                    } else {
                        setShowAlertDanger(true);
                        setMessage(error.message);
                    }
                })
        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }
    //Load zones
    const loadProvince = async () => {
        api.getHttpRequest("/parameters/location/region/load?page=1&limit=10000", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setPronvince(json.data.rows);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                setMessage("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage(error.message);
            }
        })
    }

    const loadCity = async () => {
        api.getHttpRequest("/parameters/location/district/load?page=1&limit=10000", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCity(json.data.rows);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                setMessage("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {

                setMessage(error.response.data.message);
            } else {
                setMessage(error.message);
            }
        })
    }

    const loadMinicipality = async () => {
        api.getHttpRequest("/parameters/location/ward/load?page=1&limit=10000", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setMinicipality(json.data.rows);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

                setMessage("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {

                setMessage(error.response.data.message);
            } else {
                setMessage(error.message);
            }
        })
    }

    const loadCategorie_or_Role = async () => {
        api.getHttpRequest("/parameters/roles/load-admin", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCategoriRole(json.data);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

                setMessage("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {

                setMessage(error.response.data.message);
            } else {
                setMessage(error.message);
            }
        })
    }
   

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateCity, setStateCity] = useState<requestRow[]>([])
    const [stateMinicipality, setStateMinicipality] = useState<requestRow[]>([])
    const GetProvince = (id: any) => {
        const IdProv = city.filter(x => x.LocationRegionId === id);
        setStateCity(IdProv)
    }

    const GetCity = (id: any) => {
        const IdCity = minicipality.filter(x => x.LocationDistricId === id);
        setStateMinicipality(IdCity)
    }

 

    return (
        <>
           <Modal
                title={`${title}  Account`}
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={null}
                width={1600}
                maskClosable={false}
            >
                <div className="alert alert-info" role="alert">
                    Before creating a member account you must make sure you know the person well, especially their
                    real identity, have their photo and physical address and must be able to assume the role of
                    member of Tiva products.
                </div>
                <div className="col-md-12">
                    <div className='col-md-12 mb-1'>
                        {/* <label style={{color:'white'}}>vouchers</label> */}
                        <Space direction="vertical" style={{ width: '100%' }}>
                            {
                                showAlertSucess &&
                                <Alert type="success" message={message} showIcon closable />
                            } {
                                showAlertDanger &&
                                <Alert type="error" message={message} showIcon closable />
                            }
                        </Space>


                    </div>
                    <div className="row">

                        {/* <input type="hidden" id="id" value="00" /> */}


                        <div className='col-md-12 row'>

                            <div className="mb-1 col-md-3">
                                <label className="form-label">Name<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                <input type="text" className="form-control" placeholder="" id="fullname"
                                    name='name'
                                    value={name}
                                    onChange={(e) => { setName(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1 col-md-3">
                                <label className="form-label">LastName</label>
                                <input type="text" className="form-control" placeholder="" id="fullname"
                                    name='lastname'
                                    value={lastname}
                                    onChange={(e) => { setLastName(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1 col-md-3">
                                <label className="form-label">MiddleName</label>
                                <input type="text" className="form-control" placeholder="" id="fullname"
                                    name='middlename'
                                    value={middlename}
                                    onChange={(e) => { setMiddlename(e.target.value); }}
                                />
                            </div>
                            {/* <div className="mb-1 col-md-4">
                                <label className="form-label">Category<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                <select className="form-control" id="categorie"
                                    name='userRoleId'
                                    value={userRoleId}
                                    onChange={(e) => { setUserRoleId(e.target.value) }}
                                >
                                    <option value="">Select category</option>
                                    {
                                        categoriRole.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))
                                    }


                                </select>
                            </div> */}

                            <div className="mb-1 col-md-3">
                                <label className="form-label">Gender<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                <select className="form-control" id="gender"
                                    name='gender'
                                    value={gender}
                                    onChange={(e) => { setGender(e.target.value) }}
                                >
                                    <option>Choose...</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>

                            <div className="mb-1 col-md-3">
                                <label className="form-label">Date of birth</label>
                                <input type="date" className="form-control" placeholder="" id="datenaiss"
                                    name='dateOfBirth'
                                    value={dateOfBirth}
                                    onChange={(e) => { setDateOfBirth(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1  col-md-3">
                                <label className="form-label">Place of Birth</label>
                                <input type="text" className="form-control" placeholder="" id="phone"
                                    name='placeOfBirth'
                                    value={placeOfBirth}
                                    onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1  col-md-3">
                                <label className="form-label">Phone<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                {/* <input type="text" className="form-control" placeholder="" id="phone"
                                    name='phone'
                                    value={phone}
                                    onChange={(e) => { setPhone(e.target.value) }}
                                /> */}
                                <PhoneInput
                                    inputStyle={{
                                        width: '100%',

                                    } as React.CSSProperties}
                                    inputClass={'form-control'}
                                    country={'tz'}
                                    value={phone}
                                    onChange={changePhoneNumber}
                                    enableSearch={true}
                                    countryCodeEditable={true}
                                />
                            </div>

                            <div className="mb-1  col-md-3">
                                <label className="form-label">Adress Mail</label>
                                <input type="email" className="form-control" placeholder="" id="email"
                                    name='email'
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value); }}
                                />
                            </div>
                            <div className="mb-1 col-md-4">
                                <label className="form-label">Region<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>

                                <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                    className="form-control"
                                    placeholder="Select country" optionFilterProp="children" onChange={GetProvince}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option selected value="">Select State/Region
                                    </Option>
                                    {
                                        province &&
                                            province !== undefined ?
                                            province.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No Province"
                                    }
                                </Select>
                            </div>
                            <div className="mb-1  col-md-4">
                                <label className="form-label">District<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>

                                <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 200 }}
                                    className="form-control"
                                    placeholder="Select country" optionFilterProp="children" onChange={(e) => GetCity(e)}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option selected value="">Select District
                                    </Option>
                                    {
                                        stateCity &&
                                            stateCity !== undefined || stateCity !== null ?
                                            stateCity.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No City"
                                    }
                                </Select>
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Ward/Kata<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>

                                <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                    className="form-control"
                                    placeholder="Select minicipality" optionFilterProp="children" value={minicipal} onChange={(e) => { setMinicipal(e) }}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option selected value="">Select/Ward
                                    </Option>
                                    {
                                        stateMinicipality &&
                                            stateMinicipality !== undefined || stateMinicipality !== null ?
                                            stateMinicipality.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No minicipality"
                                    }
                                </Select>
                            </div>
                            <div className="mb-1  col-md-12">
                                <label className="form-label">Address</label>
                                <textarea className="form-control" placeholder="" id="adresse"
                                    name='adress'
                                    value={adress}
                                    onChange={(e) => { setAddress(e.target.value) }}
                                ></textarea>
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Default password</label>
                                <input type="password" className="form-control" placeholder=""

                                    name='pswd'
                                    value={pswd}
                                    onChange={(e) => { setPswd(e.target.value) }}
                                />
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Confirm password</label>
                                <input type="password" className="form-control" placeholder=""
                                    name='confpass'
                                    value={confpass}
                                    onChange={(e) => { setConfPass(e.target.value); }}
                                />
                            </div>
                            <div className="mb-1  col-md-4">
                                <label className="form-label text-white">submit</label>
                                {!Loading && (
                                    <button type="button" className="btn btn-primary agree-link font-18"
                                        id="btn-Withdraw"
                                        onClick={() => (Add_Seller_or_Member())}
                                    >Save </button>
                                )}

                                {Loading && (

                                    <button type="button" className="btn btn-primary agree-link font-18" disabled={true}
                                        id="btn-Withdraw"

                                    >
                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span></span>
                                    </button>
                                )}

                            </div>


                        </div>

                    </div>

                </div>
            </Modal>
        </>
    )
}

export default DialogAddMember;