import { HashRouter, Routes, Route } from "react-router-dom";
import Login from "./views/authentification/login";
import Logup from "./views/authentification/Logup";
import VerifyOtp from "./views/authentification/Logup/VerifyOtp";
import Forgot from "./views/authentification/forgotpass";
import VerifyForgot from "./views/authentification/forgotpass/VerifyForgot";
import Container from "./components/Container";
import ErrorPage from "./views/authentification/error";
import api from "./config/Config";
import ErrorOfLine from "./views/authentification/erroroffline";
import Home from "./views/home";
import Payment from "./views/payement";
import Member from "./views/member";
import VoucherDistribution from "./views/vouchers";
import Transaction from "./views/transaction";
import Sellers from "./views/sellers";
import ListSellerByRoles from "./views/sellers/list_by_roles";
import SellerAccount from "./views/sellers/sellerAcount";
import ProfilSeller from "./views/profil";
import SecurityParams from "./views/profil/settings";
import VoucherAssigned from "./views/assignedvoucher";
import Stock from "./views/vouchers/Stock";
import VendorHome from "./views/vendor/venderHome";
import StockVendor from "./views/vendor/vendorStock";
// import PaymentHistory from "./views/vendor/pay/historyPay";
import TeamMember from "./views/vendor/teammember";
import AddPaymentVendor from "./views/vendor/addpayement";
import LayoutLocation from "./views/managerlocation/location/LayoutLocation";
import Region from "./views/managerlocation/location/region";
import District from "./views/managerlocation/location/district";
import Ward from "./views/managerlocation/location/ward";
import JvWithdraw from "./views/withdrawer/jvwithdraw";
import VendorWithdraw from "./views/vendor/withdrawervendor/vendorwithdraw";
import PaymentHistoryToTiva from "./views/vendor/withdrawervendor/payementhistory";
import ModePayement from "./views/methodepayement/ModePayement";
import VendorModePaye from "./views/vendor/pay/VendorModePaye";
import Pay from "./views/vendor/pay";
import TransactionPay from "./views/methodepayement/paymode/TransactionPay";
import { QueryClient, QueryClientProvider } from "react-query";
import DetailCustomer from "./views/member/detailCustomer/DetailCustomer";
import LayoutParametter from "./views/profil/profilcomponent/useLayout";
import MyAccountTiva from "./views/profil/profilcomponent/userhome";
import InfoPerso from "./views/profil/profilcomponent/infoPerso";
import MySecurity from "./views/profil/profilcomponent/mySecurity";
import LayoutParametterVendor from "./views/profil/profilcomponent/useLayoutVendor";
import LoginByToken from "./views/authentification/login/LoginBytoken";
import HomeTraining from "./views/training/hometrainig";
import TrainingByCategory from "./views/training/bycategory";
import LearningCourseVideo from "./views/training/learningcoursevideo";
import VendorHomeTraining from "./views/training/vendor/vendorhometraining";
import VendorTrainingByCategory from "./views/training/vendor/vendorbycategory";
import VendorLearningCourseVideo from "./views/training/vendor/vendorlearningcoursevideo/page";
// import { useEffect } from "react";
import socket from "./config/socket";
import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';
import { useEffect } from "react";
import { logoSm } from "./components/icons";

function App() {
  const queryClient = new QueryClient();
  useEffect(() => {
    socket.on('private message', ({ message }) => {
      addNotification({
        title: `Notification ${message}`,
        icon: `${logoSm}`,
        message: message,
        duration: 5000, 
        native: true, 
      });
    });

    return () => {
      socket.off('private message');
    };
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/tiva-account-for-access/:TokenUser"
              element={<LoginByToken />}
            />
            <Route path="/account" element={<Logup />} />
            <Route path="/verify_otp" element={<VerifyOtp />} />
            <Route path="/forgot_pswd" element={<Forgot />} />
            <Route path="/verify_forgot" element={<VerifyForgot />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/offline" element={<ErrorOfLine />} />
            {/* <Route  path='*' element={<Container/>} /> */}

            {/** All root */}

            <Route path="/home" element={<Home />} />
            <Route path="/partner" element={<Home />} />
            <Route path="/add-payment-method" element={<Payment />} />
            <Route path="/payement-history" element={<ModePayement />} />
            <Route path="/trans-mode-pay" element={<TransactionPay />} />
            <Route path="/member" element={<Member />} />
            <Route path="/invetory" element={<VoucherDistribution />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/list_of_seller" element={<Sellers />} />
            <Route
              path="/training"
              element={<HomeTraining />}
            />
            <Route
              path="/training-by-category/:id"
              element={<TrainingByCategory />}
            />

            <Route
              path="/tiva-course/:idSubCateg"
              element={<LearningCourseVideo />}
            />

            <Route
              path="/team-by-roles/:nameRoles/:idRoles"
              element={<ListSellerByRoles />}
            />
            <Route
              path="/seller_account/:id_sellers"
              element={<SellerAccount />}
            />
            <Route
              path="/detail_customer/:id_customer"
              element={<DetailCustomer />}
            />
            <Route path="/user-profile" element={<ProfilSeller />} />
            <Route path="/security-parameters" element={<SecurityParams />} />
            <Route path="/voucher-assign" element={<VoucherAssigned />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/vendor" element={<VendorHome />} />
            <Route path="/payment-history" element={<VendorModePaye />} />
            <Route path="/pay" element={<Pay />} />
            <Route
              path="/vendor-payment-method"
              element={<AddPaymentVendor />}
            />
            <Route path="/stock-vendor" element={<StockVendor />} />
            {/* <Route path='/payment-history' element={<PaymentHistory />} /> */}
            <Route path="/team-member-network" element={<TeamMember />} />
            <Route path="/amount-withdraw" element={<JvWithdraw />} />
            <Route
              path="/amount-withdraw-for-vendor"
              element={<VendorWithdraw />}
            />
            <Route
              path="/history-payment-to-tiva"
              element={<PaymentHistoryToTiva />}
            />



            <Route path="*" element={<LayoutLocation />}>
              <Route path="location/region" element={<Region />} />
              <Route path="location/district" element={<District />} />
              <Route path="location/ward" element={<Ward />} />
            </Route>

            <Route path="*" element={<LayoutParametter />}>
              <Route path="myaccount-tiva/home" element={<MyAccountTiva />} />
              <Route path="myaccount-tiva/infos-perso" element={<InfoPerso />} />
              <Route path="myaccount-tiva/security" element={<MySecurity />} />
            </Route>

            <Route path="*" element={<LayoutParametterVendor />}>
              <Route path="myaccount-tiva-vendor/home" element={<MyAccountTiva />} />
              <Route path="myaccount-tiva-vendor/infos-perso" element={<InfoPerso />} />
              <Route path="myaccount-tiva-vendor/security" element={<MySecurity />} />
            </Route>
            <Route
              path="/training-vendor"
              element={<VendorHomeTraining />}
            />
            <Route
              path="/training-by-category-vendor/:id"
              element={<VendorTrainingByCategory/>}
            />

            <Route
              path="/tiva-course-vendor/:idSubCateg"
              element={<VendorLearningCourseVideo />}
            />
          </Routes>

        </HashRouter>
      </QueryClientProvider>
      <Notifications/>
    </>
  );
}

export default App;
