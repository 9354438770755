import { useEffect, useState } from 'react'
import { hi, tivaAccademi } from '../../../../components/icons'
import api from "../../../../config/Config"
import { Link } from 'react-router-dom'
import { Pagination, Spin } from 'antd'
import { GrLanguage } from "react-icons/gr";
import HeaderVendor from '../../../../components/header/IndexVendor'

const VendorHomeTraining = () => {
    const data = "";
    const [loadCategory, setLoadCategory] = useState<ResponseLearning[]>([])
    const [loadLangage, setLoadLangage] = useState<ResponseLearning[]>([])
    const [totalPage, setTotalPage] = useState(1);
    const [selectedLanguage, setSelectedLanguage] = useState('en');


    useEffect(() => {
        loadCategoryLeaning(1)
    }, [selectedLanguage])
    const loadCategoryLeaning = (page: number) => {
        api
            .getHttpRequestV3(`/user/training?langue=${selectedLanguage}&category=&page=${page}`, data, "GET")
            .then((response) => {
                const json = response.data.data;
                setLoadCategory(json?.list)
                setLoadLangage(json?.langues)
                setTotalPage(json.count)
            })
            .catch((error) => {
                console.log(error)
            });
    };


    const handleLanguageChange = (event: any) => {
        setSelectedLanguage(event.target.value);
    };



    return (
        <div id="wrapper" className="d-flex flex-column min-vh-100">
            <HeaderVendor />
            <main className="flex-grow-1">
                <section>
                    <div className="container-fluid p-0" style={{ marginTop: '70px' }}>
                        <div
                            style={{
                                backgroundImage: `url(${tivaAccademi})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: '100%',
                                height: 0,
                                paddingBottom: '27%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            className="item-image img-padding support-banner"
                        >
                            <div style={{ width: '66.66%', paddingTop: '20%' }}>
                                <h2
                                    className="how-can-help font-weight-500"
                                    style={{ margin: '0', color: 'white', fontFamily: `"Noto Sans", sans-serif` }}
                                >
                                    <span><img src={hi} alt="" /></span> Hey, how can we help?
                                </h2>
                                <div className="support-search s003">
                                    <form>
                                        <div className="inner-form" style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                                            <div className="input-field first-wrap">
                                                <div className="input-select">
                                                    <div className="dropdown-toggle" data-toggle="dropdown" style={{ color: '#017DF3' }}>
                                                        <a href="members.html"><GrLanguage style={{ fontSize: 35, color: '#017DF3' }} /> <span className="caret"></span></a>
                                                    </div>
                                                    <ul className="dropdown-menu countriesflag text-left" style={{ zIndex: 900, width: '10%', marginTop: '-50px' }}>
                                                        <div className="row m-0">
                                                            <ul className="col-sm-3 mb-5">
                                                                <li><h4>Language</h4></li>
                                                                {loadLangage.map((item, i) => (
                                                                    <li key={i} style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <input
                                                                            key={i}
                                                                            type="radio"
                                                                            id={item.langue}
                                                                            name="language"
                                                                            value={item.langue}
                                                                            onChange={handleLanguageChange}
                                                                            checked={selectedLanguage === item.langue}
                                                                        />
                                                                        <label htmlFor={item.langue} style={{ marginLeft: '8px' }}>
                                                                            {item.langue}
                                                                        </label>
                                                                    </li>
                                                                ))}

                                                            </ul>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="input-field second-wrap">
                                                <input id="search" type="text" placeholder="Search for answers" />
                                            </div>
                                            <div className="input-field third-wrap">
                                                <button className="btn-search" type="button">
                                                    <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="flex-grow-1">
                    <div className="container py-5">
                        <div className="row trining-category">
                            {loadCategory && loadCategory.length > 0 ? (
                                loadCategory.map((item, i) => (
                                    <div key={i} className="col-12 col-md-4 col-lg-4 mb-4 d-flex align-self-stretch">
                                        <div
                                            className="card mb-4"
                                            style={{
                                                height: '100%',
                                                borderRadius: '5px',
                                                marginBottom: 10,
                                                width: '100%',
                                                minWidth: '300px',
                                                maxWidth: '400px',
                                            }}
                                        >
                                            <div className="card-body d-flex flex-column">
                                                <h5 className="card-title" style={{ fontFamily: `"Noto Sans", sans-serif` }}>{item.name}</h5>
                                                <p className="text-muted font-16" style={{ fontFamily: `"Noto Sans", sans-serif` }}>
                                                    {item.description}
                                                </p>
                                                <div className="mt-auto">
                                                    <div className="card-footer">
                                                        <Link
                                                            className="font-16"
                                                            to={`/training-by-category-vendor/${item?.training_category_id}`}
                                                            style={{ color: '#1576ED', fontFamily: `"Noto Sans", sans-serif`, cursor: 'pointer' }}
                                                        >
                                                            See all Training <i className="fas fa-long-arrow-alt-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col-12 d-flex justify-content-center">
                                    <Spin />No data found
                                </div>
                            )}
                            <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: 10 }}>
                                <Pagination
                                    style={{ paddingTop: 0, paddingBottom: 10 }}
                                    pageSize={10}
                                    total={totalPage}
                                    onChange={(page) => loadCategoryLeaning(page)}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* <div className="content-page" >
                <div className="content">
                    <Footer />
                </div>
            </div> */}

        </div>
    )
}

export default VendorHomeTraining