import React, { useState } from 'react';
import { TbLetterX } from "react-icons/tb";
import { IoIosAddCircleOutline } from "react-icons/io";

export enum FieldType {
    Text = "text",
    Number = "number",
    Email = "email",
    Phone = "phone",
    // Checkbox = "checkbox",
    // Radio = "radio",
    Select = "select",
    Date = "date",
    // File = "file",
    // Hidden = "hidden",
    Password = "password",
    // Url = "url",
    // Time = "time",
    TextArea = "textarea",
    // DateTime = "dateTime",
    Color = "color",
}
export const getName = (label: string): string => {
    return label.replace(/[^\w]/g, '_').toLowerCase();
}
export interface Field {
    id: number;
    type: FieldType;
    value: string;
    label: string;
    name: string;
    options: string[];
}

export const FieldEditor: React.FC<{
    field: Field;
    onUpdate: (id: number, field: Partial<Field>) => void;
    onRemove: (id: number) => void;
}> = ({ field, onUpdate, onRemove }) => {
    const [newOption, setNewOption] = useState('');

    // const handleValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    //     let newValue: string | undefined;
    //     if (e.target.type === 'checkbox') {
    //         newValue = e.target.checked ? e.target.value : undefined;
    //     } else {
    //         newValue = e.target.value;
    //     }
    //     onUpdate(field.id, { value: newValue });
    // };

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let newValue: string | undefined;
        if (e.target instanceof HTMLInputElement) {
            if (e.target.type === 'checkbox') {
                newValue = e.target.checked ? e.target.value : undefined;
            } else {
                newValue = e.target.value;
            }
        } else if (e.target instanceof HTMLSelectElement) {
            newValue = e.target.value;
        }
        onUpdate(field.id, { value: newValue });
    };

    // function handleValueChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    //     let newValue: string | undefined;
    //     if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
    //       newValue = e.target.checked ? e.target.value : undefined;
    //     } else if (e.target instanceof HTMLSelectElement && e.target.type === 'select-one') {
    //       newValue = e.target.value;
    //     } else {
    //       newValue = (e.target as HTMLInputElement).value;
    //     }

    //   }

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onUpdate(field.id, { value: e.target.value });
    };
    const handleAddOption = () => {
        if (newOption.trim() !== '') {
            onUpdate(field.id, { options: [...field.options, newOption.trim()] });
            setNewOption('');
        }
    };

    const handleRemoveOption = (index: number) => {
        const newOptions = [...field.options];
        newOptions.splice(index, 1);
        onUpdate(field.id, { options: newOptions });
    };

    const handleRemove = () => {
        onRemove(field.id);
    };

    return (
        <div style={{ display:'flex', justifyContent:'space-between', justifyItems:'center', alignItems:'center', alignContent:'center' }}>
            <div style={{width:'100%'}}>
                <label className="form-label">{field.label}
                    {/* <span style={{ color: 'red', paddingLeft: 1 }}>*</span> */}
                    </label>
                {[
                    FieldType.Text,
                    FieldType.Email,
                    FieldType.Number,
                    FieldType.Phone,
                    FieldType.Password,
                    // FieldType.Url,
                    // FieldType.Time,
                    FieldType.Color,
                ].includes(field.type) && (
                        <div className="mb-1 col-md-12">
                            <input
                                type={field.type}
                                value={field.value}
                                onChange={handleValueChange}
                                className="form-control"
                            />
                        </div>

                    )}
                {/* {field.type === FieldType.Checkbox && (
                <input
                    type={field.type}
                    checked={field.value === 'true'}
                    onChange={handleValueChange}
                />
            )} */}

                {/* {field.type === FieldType.Radio && (
                    <>
                        <div>
                            {field.options.map((option, index) => (
                                <label key={index} style={{  alignItems: 'center', margin: '5px 0' }}>
                                <input
                                  className='form-control'
                                  type={field.type}
                                  name={field.name}
                                  value={option}
                                  checked={field.value === option}
                                  onChange={(e) => handleValueChange(e)}
                                  // Espace entre le bouton et le texte
                                />
                                {option}
                              </label>
                            ))}
                        </div>
                        <div style={{display:'flex'}}>
                            <input
                                className='form-control'
                                type="text"
                                value={newOption}
                                onChange={(e) => setNewOption(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddOption();
                                    }
                                }}
                            />
                            <button onClick={handleAddOption}><IoIosAddCircleOutline size={30}/></button>
                        </div>
                        <div>
                            {field.options.map((option, index) => (
                                <div key={index} style={{display:'flex', justifyContent:'space-between'}}>
                                    {option}{' '}
                                    <button onClick={() => handleRemoveOption(index)}><TbLetterX size={15} color='#1576ED'/></button>
                                </div>
                            ))}
                        </div>
                    </>
                )} */}

                {/* {field.type === FieldType.Checkbox && (
                    <>
                        <div>
                            {field.options.map((option, index) => (
                                <label key={index}>
                                    <input
                                        className='form-control'
                                        type={field.type}
                                        name={field.name}
                                        value={option}
                                        checked={field.value.includes(option)}
                                        onChange={(e) => handleValueChange(e)}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                        <div style={{display:'flex'}}>
                            <input
                                className='form-control'
                                type="text"
                                value={newOption}
                                onChange={(e) => setNewOption(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddOption();
                                    }
                                }}
                            />
                            <button onClick={handleAddOption}><IoIosAddCircleOutline size={30}/></button>
                        </div>
                        <div>
                            {field.options.map((option, index) => (
                                <div key={index} style={{display:'flex', justifyContent:'space-between'}}>
                                    {option}{' '}
                                    <button onClick={() => handleRemoveOption(index)}><TbLetterX size={15} color='#1576ED'/></button>
                                </div>
                            ))}
                        </div>
                    </>
                )} */}

                {field.type === FieldType.Select && (
                    <>
                        <select
                            className='form-control'
                            value={field.value} onChange={handleValueChange}>
                            <option value="">Sélectionnez une option</option>
                            {field.options.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        <div >
                            {field.options.map((option, index) => (
                                <div key={index} style={{display:'flex', justifyContent:'space-between'}}>
                                    {option}{' '}
                                    <button onClick={() => handleRemoveOption(index)}><TbLetterX size={15} color='#1576ED'/></button>
                                </div>
                            ))}
                        </div>
                        <div className='d-flex mt-2' style={{justifyItems:'center'}}>
                            <input
                                className='form-control'
                                type="text"
                                placeholder='Option 1'
                                value={newOption}
                                onChange={(e) => setNewOption(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddOption();
                                    }
                                }}
                            />
                            <button onClick={handleAddOption}><IoIosAddCircleOutline size={30}/></button>
                        </div>
                        
                    </>
                )}
                {field.type === FieldType.Date && (
                    <input
                        className='form-control'
                        type="date"
                        value={field.value}
                        onChange={handleValueChange}
                    />
                )}
                {/* {field.type === FieldType.DateTime && (
                    <input
                        className='form-control'
                        type="datetime-local"
                        value={field.value}
                        onChange={handleValueChange}
                    />
                )}
                {field.type === FieldType.File && (
                    <input
                        className='form-control'
                        type="file"
                        value={field.value}
                        onChange={handleValueChange}
                    />
                )} */}
                {field.type === FieldType.TextArea && (
                    <textarea className='form-control' value={field.value} onChange={handleTextAreaChange} />
                )}
            </div>

            <div><button onClick={handleRemove}><TbLetterX size={25} color='#1576ED'/></button></div>
            <hr />
        </div>
    );
};
