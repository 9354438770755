import { Card } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const LocationParams = () => {

    const [activeElement, setActiveElement] = useState('/location/region');

    const handleElementClick = (element:any) => {
        setActiveElement(element);
    };

    return (
        <div className="">
            <Card style={{ width: '100%' }}>
                <div className="d-flex">
                    <div className='mr-2'>
                    <Link to="/location/region" 
                    className={`p-1 fs-20 ${
                        activeElement === 'region'
                        ? 'bg-primary text-white'
                        : 'bg-[#E9E9E9] text-[#000]'
                      }`}
                      style={{
                        fontSize: 16,
                        borderRadius: 5, 
                      }}
                    onClick={() => handleElementClick('region')}
                    >Region</Link>
                    </div>
                    <div className='mr-2'>
                    <Link to="/location/district" 
                    className={`p-1 fs-20 ${
                        activeElement === 'district'
                        ? 'bg-primary text-white'
                        : 'bg-[#E9E9E9] text-[#000]'
                      }`}
                      style={{
                        fontSize: 16,
                        borderRadius: 5, 
                      }}
                    onClick={() => handleElementClick('district')}
                    >District</Link>
                    </div>
                    <div className='mr-2'>
                    <Link to="/location/ward" 
                    className={`p-1 fs-20 ${
                        activeElement === 'ward'
                        ? 'bg-primary text-white'
                        : 'bg-[#E9E9E9] text-[#000]'
                      }`}
                      style={{
                        fontSize: 16,
                        borderRadius: 5, 
                      }}
                    onClick={() => handleElementClick('ward')}
                    >Ward</Link>
                    </div>     
                </div>
            </Card>


        </div>
    )
}

export default LocationParams