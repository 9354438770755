
import { Outlet, useNavigate } from 'react-router-dom'
import AccountIndex from '.'
import { MdOutlineAccountCircle } from 'react-icons/md';
import { BsPersonVcard } from 'react-icons/bs';
import { TbUserEdit } from "react-icons/tb";
import HeaderVendor from '../../../components/header/IndexVendor';
import AccountIndexProfil from './indexVendorProfil';
const LayoutParametterVendor = () => {
    const isMobileScreen = window.innerWidth <= 768;
    const navigate = useNavigate()

    return (
        <>
            <div id="wrapper">
                <HeaderVendor />

                <div className="content-page bg-white">
                    {
                        isMobileScreen && <div>
                            <div className="profile-info">

                                <div className="profile-details">

                                    <div className="dropdown ms-auto">
                                        <a href="#" className="" data-bs-toggle="dropdown" aria-expanded="true">

                                        </a>

                                        <div className="dropdown user-setting">
                                            <button style={{ display: 'flex', alignItems: 'center' }} className="dropdown-toggle btn btn-primary sharp border-info"
                                                type="button" data-toggle="dropdown" aria-expanded="false">
                                                <TbUserEdit className="font-20" />Profil
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right font-16 ">
                                                <div className="dropdown-item"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => navigate('/myaccount-tiva-vendor/home')}
                                                ><MdOutlineAccountCircle className="mdi mdi-account-multiple-plus-outline font-20 mr-2" style={{ color: '1576ED' }} />
                                                    Home</div>
                                                <div className="dropdown-item"
                                                    style={{ cursor: 'pointer' }}
                                                    data-toggle="modal" data-target="#exampleModal"
                                                    onClick={() => navigate('/myaccount-tiva-vendor/infos-perso')}
                                                >
                                                    <BsPersonVcard className="mdi mdi-account-multiple-plus-outline font-20 mr-2" style={{ color: '1576ED' }} />
                                                    Personal Information</div>
                                                <div className="dropdown-item"
                                                    onClick={() => navigate('/myaccount-tiva-vendor/security')}
                                                ><BsPersonVcard className="mdi mdi-account-multiple-plus-outline font-20 mr-2" style={{ color: '1576ED' }} />Security</div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="content">

                        <div className="container-fluid1">
                            <div className="row">
                                {
                                    isMobileScreen ? ('') : (
                                        <div className="col-md-4">
                                            <AccountIndexProfil />
                                        </div>
                                    )
                                }


                                <div className={isMobileScreen ? `col-md-12` : `col-md-8`} style={{ marginLeft: isMobileScreen ? '2px' : '25%', marginTop: isMobileScreen ? '-2%' : '-12%' }}>
                                    <div className="">
                                        <div className="">{<Outlet />}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default LayoutParametterVendor
