import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from '../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import requestRow from '../../controller/RequestRow';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Pagination, Input, Select, Space, Alert } from 'antd';
import PhoneInput from 'react-phone-input-2'
import Footer from '../../components/footer';
import Header from '../../components/header';
import { avatar1 } from '../../components/icons';
import { MdPhotoCamera } from 'react-icons/md';
import CustomModal from '../../components/modal/componentModalAvatar';
const { Option } = Select;

const ProfilSeller = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const data = '';
    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');
    const regexEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');

    const [Loading, setLoading] = useState(false);
    const [categoriRole, setCategoriRole] = useState<requestRow[]>([])
    const [getRegion, setGetRegion] = useState<requestRow[]>([]);
    const [getDistrict, setGetDistrict] = useState<requestRow[]>([]);
    const [getWard, setGetWard] = useState<requestRow[]>([]);
    const [avatar, setAvatar] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [minicipal, setMinicipal] = useState('');
    const [loadprov, setLoadProv] = useState('');
    const [loadity, setLoadCity] = useState('');
    const [userRoleId, setUserRoleId] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [minicipalName, setMinicipalName] = useState('');

    const [region, setRegion] = useState('');
    const [district, setDistric] = useState('');
    const [ward, setWard] = useState('');
    const [wardName, setWardName] = useState('');

    const [avatarUser, setAvatarUser] = useState('');

    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }

        LoadRegion()
        LoadDistrict()
        LoadWard()
        LoadPrile()
        loadCategorie_or_Role()

    }, []);

    const LoadPrile = async () => {
        api.getHttpRequest("/user/profil", data, 'GET').then((response) => {

            const json = response.data.data;

            const startDate = new Date(json.date_of_birth);
            const formattedStartDate = startDate.toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            setDateOfBirth(formattedStartDate)
            setName(json.name);
            setMiddlename(json.middlename);
            setLastName(json.lastname);
            setEmail(json.email);
            setPhone(json.phone);
            setGender(json.gender)
            setAddress(json.address);
            setAvatar(json.avatar);
            setUserRoleId(json.UserRoleId);
            setRegion(json.LocationWard.LocationDistric.LocationRegion.name)
            setDistric(json.LocationWard.LocationDistric.name)
            setWardName(json.LocationWard.name)
            setWard(json.LocationWard.id)
            setMinicipal(json.MunicipalityId);
            setPlaceOfBirth(json.place_of_birth)
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    //Update Profile
    // const UpdateProfile = async () => {
    //     const formData = new FormData();
    //     formData.append("phone", phone);
    //     formData.append("name", name);
    //     formData.append("lastname", lastname);
    //     formData.append("middlename", middlename)
    //     formData.append("email", email);
    //     formData.append("address", adress);
    //     formData.append("MunicipalityId", minicipal);
    //     formData.append("gender", gender);
    //     formData.append("date_of_birth", dateOfBirth);
    //     formData.append("place_of_birth", placeOfBirth);


    //     var config = {
    //         method: 'POST',
    //         maxBodyLength: Infinity,
    //         url: api.var_.url_api_local + '/user/profile/update',
    //         headers: {
    //             'Authorization': 'Bearer ' + api.initToken(),
    //             'Content-Type': 'application/json'
    //         },
    //         data: formData
    //     };
    //     setLoading(true);
    //     if (name === '' || lastname === '' || phone === ''
    //         || email === '' || minicipal === '') {
    //         toast.error('No fields should be empty');

    //     } else if (!regexTelephone.test(phone)) {
    //         toast.error('The entry number is incoreact');

    //     } else if (!regexEmail.test(email)) {
    //         toast.error('The email address entered is incoreact');
    //     }
    //     else {
    //         axios(config)
    //             .then(function (response) {
    //                 const json = response.data;
    //                 if (json.status == 200) {
    //                     toast.success(json.message)
    //                 } else {
    //                     toast.error(json.message)
    //                 }
    //             })
    //             .catch((error) => {
    //                 if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
    //                     toast.error("Please connect your device to the internet");
    //                 } else if (error.response && error.response.data && error.response.data.message) {
    //                     toast.error(error.response.data.message);
    //                 } else {
    //                     toast.error(error.message);
    //                 }
    //             })
    //     }

    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 5000);
    // }

    const UpdateProfile = () => {
        let data = JSON.stringify({
            "name": name,
            "lastname": lastname,
            "middlename": middlename,
            "email": email,
            "address": adress,
            "phone": phone,
            "id_ward": ward,
            "avatar": "",
            "gender": gender,
            "UserRoleId": api.initIdUserConnect(),
            "civil_status": civilStatus,
            "date_of_birth": dateOfBirth,
            "place_of_birth": placeOfBirth,
            "id_partner": api.initPartnerId(),
            "default_password": '1111111',
        });

        setLoading(true);

        if (name === '' || phone === '') {
            setShowAlertDanger(true)
            setMessage('Make sure you have filled in all the red star fields');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger(true)
            setMessage('The entry number is incoreact');
        }
        else {
            api.getHttpRequest(`/partners/app/members/${api.initIdUserConnect()}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    LoadPrile()
                    setShowAlertSuccess(true)
                    setMessage(json.message);
                } else {
                    setShowAlertDanger(true)
                    setMessage(json.message);
                }
            }).catch((error) => {
                setMessage(api.getMessageError(error))
            })

        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertSuccess(false)
            setShowAlertDanger(false)
        }, 3000);
    }

    //Load zones

    const LoadRegion = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/region/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetRegion(json.rows)

        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }
    const LoadDistrict = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/district/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetDistrict(json.rows)

        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }
    const LoadWard = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/ward/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetWard(json.rows)

        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }


    const loadCategorie_or_Role = async () => {
        api.getHttpRequest("/parameters/roles/load?groupe=1", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCategoriRole(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const handleImageChange = (e: any) => {
        setAvatar(e.target.files[0]);
    };

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateDistrict, setStateDistrict] = useState<requestRow[]>([])
    const [stateWard, setStateWard] = useState<requestRow[]>([])
    const GetRegion = (id: any) => {
        const IdRegion = getDistrict.filter(x => x.LocationRegionId === id);
        setStateDistrict(IdRegion)
    }

    const GetWard = (id: any) => {
        const IdDistrict = getWard.filter(x => x.LocationDistricId === id);
        setStateWard(IdDistrict)
    }

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };
    const [isModalOpen3, setIsModalOpen3] = useState(false);

    const showAvatarModal = () => {
        setIsModalOpen3(true);
    }

    const handleOk3 = () => {
        setIsModalOpen3(false);
    };

    const handleCancel3 = () => {
        setIsModalOpen3(false);
    };

    // console.log(city);
    return (
        <>
            <div id="wrapper">
                <Header />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid1">

                            {/* <div className="row">
                                <div className="col-12">
                                    <div>
                                        <h2 className="header-title mb-1 font-30 border-none">My Profile
                                        </h2>
                                    </div>
                                </div>

                            </div> */}



                            <div className="row">
                                <div className="col-xl-12">

                                    <div className="card stacked-2">

                                        <div className="card-header flex-wrap border-0 pb-0 align-items-end profile ">
                                            <div className="profile-head">
                                                <h4 className="fs-20 text-black mb-2">Profile
                                                </h4>
                                                <div className="photo-content">

                                                    <div className="row">

                                                        <div className='col-md-12 row'>
                                                            <div className='col-md-2 '>
                                                                <MdPhotoCamera
                                                                    style={{
                                                                        position: 'absolute',
                                                                        fontSize: 30,
                                                                        bottom: 0,
                                                                        right: 0,
                                                                        marginRight: 40,
                                                                        marginBottom: '72%'
                                                                    }}
                                                                    onClick={() =>showAvatarModal()}
                                                                />
                                                                <div className="pic-holder ">

                                                                    <img id="profilePic" className="pic" src={`${avatarUser === 'default.png' ? `${avatar1}` : `${api.var_.url_avatar + avatarUser}`}`} />

                                                                    <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{ opacity: 0 }} />
                                                                    <label htmlFor="newProfilePhoto" className="upload-file-block">
                                                                        <div className="text-center">
                                                                            <div className="mb-1">
                                                                                <i className="fa fa-camera fa-2x"></i>
                                                                            </div>
                                                                            <div className="text-uppercase">
                                                                                Update <br /> Profile Photo
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className='col-md-10 row'>
                                                                <div className="mb-1 col-md-3 ">
                                                                    <label className="form-label">Name</label>
                                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                                        name='name'
                                                                        value={name}
                                                                        onChange={(e) => { setName(e.target.value) }}
                                                                    />
                                                                </div>
                                                                <div className="mb-1 col-md-3">
                                                                    <label className="form-label">LastName</label>
                                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                                        name='lastname'
                                                                        value={lastname}
                                                                        onChange={(e) => { setLastName(e.target.value) }}
                                                                    />
                                                                </div>
                                                                <div className="mb-1 col-md-3">
                                                                    <label className="form-label">MiddleName</label>
                                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                                        name='middlename'
                                                                        value={middlename}
                                                                        onChange={(e) => { setMiddlename(e.target.value); }}
                                                                    />
                                                                </div>
                                                                <div className="mb-1 col-md-3">
                                                                    <label className="form-label">Gender</label>
                                                                    <select className="form-control" id="gender"
                                                                        name='gender'
                                                                        value={gender}
                                                                        onChange={(e) => { setGender(e.target.value) }}
                                                                    >
                                                                        <option>Choose...</option>
                                                                        <option value="Male">Male</option>
                                                                        <option value="Female">Female</option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                </div>

                                                                <div className="mb-1 col-md-3">
                                                                    <p className="form-label">Civil status</p>
                                                                    <select className="form-control" id="gender"
                                                                        name='civilStatus'
                                                                        value={civilStatus}
                                                                        onChange={(e) => { setCivilStatus(e.target.value) }}
                                                                    >
                                                                        <option>Choose...</option>
                                                                        <option value="Married">Married</option>
                                                                        <option value="Single">Single</option>
                                                                        <option value="Widow">Widow</option>
                                                                        <option value="Widower">Widower</option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                </div>

                                                                <div className="mb-1 col-md-3">
                                                                    <label className="form-label">Date of birth</label>
                                                                    <input type="date" className="form-control" placeholder="" id="datenaiss"
                                                                        name='dateOfBirth'
                                                                        value={dateOfBirth}
                                                                        onChange={(e) => { setDateOfBirth(e.target.value) }}
                                                                    />
                                                                </div>
                                                                <div className="mb-1  col-md-3">
                                                                    <label className="form-label">Place of Birth</label>
                                                                    <input type="text" className="form-control" placeholder="" id="phone"
                                                                        name='placeOfBirth'
                                                                        value={placeOfBirth}
                                                                        onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                                                    />
                                                                </div>
                                                                <div className="mb-1  col-md-3">
                                                                    <label className="form-label">Phone</label>
                                                                    <PhoneInput
                                                                        inputStyle={{
                                                                            width: '100%',

                                                                        } as React.CSSProperties}
                                                                        inputClass={'form-control'}
                                                                        country={'tz'}
                                                                        value={phone}
                                                                        onChange={changePhoneNumber}
                                                                        enableSearch={true}
                                                                        countryCodeEditable={true}
                                                                    />
                                                                </div>

                                                                <div className="mb-1  col-md-3">
                                                                    <label className="form-label">Adress Mail</label>
                                                                    <input type="email" className="form-control" placeholder="" id="email"
                                                                        name='email'
                                                                        value={email}
                                                                        onChange={(e) => { setEmail(e.target.value); }}
                                                                    />
                                                                </div>

                                                                <div className="mb-1 col-md-3">
                                                                    <label className="form-label">Region</label>

                                                                    <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                                                        className="form-control"
                                                                        placeholder="Select country" optionFilterProp="children" value={region}
                                                                        onChange={(value: any) => {
                                                                            GetRegion(value)
                                                                            setRegion(value)
                                                                        }}
                                                                        filterOption={(input: string, option: any) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }>
                                                                        <Option selected value="">Select Region
                                                                        </Option>
                                                                        {
                                                                            getRegion &&
                                                                                getRegion !== undefined ?
                                                                                getRegion.map((item, i) => (
                                                                                    <Option key={i} value={item.id}>{item.name}</Option>
                                                                                ))
                                                                                : "No Province"
                                                                        }
                                                                    </Select>
                                                                </div>
                                                                <div className="mb-1  col-md-3">
                                                                    <label className="form-label">District</label>

                                                                    <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 200 }}
                                                                        className="form-control"
                                                                        placeholder="Select country" optionFilterProp="children" value={district}
                                                                        onChange={(value: any) => {
                                                                            GetWard(value)
                                                                            setDistric(value)
                                                                        }}
                                                                        filterOption={(input: string, option: any) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        <Option selected value="">Select district
                                                                        </Option>
                                                                        {
                                                                            stateDistrict &&
                                                                                stateDistrict !== undefined || stateDistrict !== null ?
                                                                                stateDistrict.map((item, i) => (
                                                                                    <Option key={i} value={item.id}>{item.name}</Option>
                                                                                ))
                                                                                : "No City"
                                                                        }
                                                                    </Select>
                                                                </div>

                                                                <div className="mb-1  col-md-3">
                                                                    <label className="form-label">Ward/Kata</label>

                                                                    <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                                                        className="form-control"
                                                                        placeholder="Select minicipality" optionFilterProp="children" value={wardName}
                                                                        onChange={(value: any) => {
                                                                            setWard(value)
                                                                            setWardName(value)

                                                                        }}
                                                                        filterOption={(input: string, option: any) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }>
                                                                        <Option selected value="">Select ward/kata
                                                                        </Option>
                                                                        {
                                                                            stateWard &&
                                                                                stateWard !== undefined || stateWard !== null ?
                                                                                stateWard.map((item, i) => (
                                                                                    <Option key={i} value={item.id}>{item.name}</Option>
                                                                                ))
                                                                                : "No minicipality"
                                                                        }
                                                                    </Select>
                                                                </div>

                                                                <div className="mb-2  col-md-12 ">
                                                                    <label className="form-label">Adresse</label>
                                                                    <textarea className="form-control mr-5" placeholder="" id="adresse"
                                                                        name='adress'
                                                                        value={adress}
                                                                        onChange={(e) => { setAddress(e.target.value) }}
                                                                    ></textarea>
                                                                </div>

                                                                {
                                                                    message && (
                                                                        <>
                                                                            <div className='col-md-12 mb-1 '>
                                                                                {/* <label style={{color:'white'}}>vouchers</label> */}
                                                                                <Space direction="vertical" style={{ width: '100%' }}>
                                                                                    {
                                                                                        showAlertDanger &&
                                                                                        <Alert type="error" message={message} showIcon closable />
                                                                                    },
                                                                                    {
                                                                                        showAlertSuccess &&
                                                                                        <Alert type="success" message={message} showIcon closable />
                                                                                    }
                                                                                </Space>


                                                                            </div>
                                                                        </>
                                                                    )
                                                                }

                                                                <div className="form-group col-md-4">
                                                                    {!Loading && (
                                                                        <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={() => UpdateProfile()}>Update profile</button>
                                                                    )}

                                                                    {Loading && (

                                                                        <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                                                            <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {/** Update file */}


                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="profile-info">

                                                    <div className="profile-details">

                                                        <div className="dropdown ms-auto">
                                                            <a href="#" className="" data-bs-toggle="dropdown" aria-expanded="true">

                                                            </a>

                                                            <div className="dropdown user-setting">
                                                                <button className=" dropdown-toggle btn btn-primary sharp border-info"
                                                                    type="button" data-toggle="dropdown" aria-expanded="false">
                                                                    <i className="ti-more-alt font-20"></i>
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right font-16 ">
                                                                    <div className="dropdown-item"
                                                                        style={{ cursor: 'pointer' }}
                                                                    // onClick={() => showModal2()}
                                                                    ><i
                                                                        className="mdi mdi-account-outline font-20"></i> Security Params</div>
                                                                    <div className="dropdown-item"
                                                                        style={{ cursor: 'pointer' }}
                                                                        data-toggle="modal" data-target="#exampleModal"
                                                                    >
                                                                        {/* <RxAvatar className="mdi mdi-account-multiple-plus-outline font-20" /> */}
                                                                        Update Profile Photo</div>
                                                                    <div className="dropdown-item" ><i
                                                                        className="mdi mdi-plus scale5 font-20"></i> Add to
                                                                        group</div>
                                                                    <a className="dropdown-item" ><i
                                                                        className="mdi mdi mdi-block-helper font-18"></i>
                                                                        Block</a>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

            <CustomModal
                title={`Update Avatar`}
                isModalOpen={isModalOpen3}
                handleOk={handleOk3}
                handleCancel={handleCancel3}
                name={name}
                LoadPrile={LoadPrile}
            // Autres props nécessaires pour le contenu de la modal
            />
        </>
    )
}

export default ProfilSeller
