
import { Alert, Modal, Space, Tag } from 'antd'
import { useEffect, useState } from 'react'
import api from "../../../config/Config";

const AttributeStatus = ({ isModalOpen, handleOk, handleCancel, dataLoad, StatusId, id, color, name, detail }: any) => {

    const [description, setDescription] = useState("")
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [color, name])

    const AddStatus = () => {
        let data = JSON.stringify({
            "id": id,
            "StatusId": StatusId,
            "observation": description
        });

        setLoading(true);
        if (name === '' || color === '') {
            setShowAlertDanger(true);
            setMessage('Make sure you have filled in all the red star fields');
        }
        else {
            api.getHttpRequest(`/partners/mention_assign_member`, data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    dataLoad();
                    setShowAlertSucess(true);
                    setMessage(json.message);

                    setShowAlertSucess(true);
                    setMessage(json.message);
                    setDescription('')


                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    const errorMessage = error.response.data.message;
                    setShowAlertDanger(true);
                    setMessage(errorMessage)
                }
            })
        }

        setTimeout(() => {
            setLoading(false);
            setMessage('')
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }

    return (
        <Modal
            title="Attribute status to this user"
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={500}
            // style={{ top: 100 }}
            // bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
            maskClosable={false}
        >
            <div>
                <div className=" pb-3 form-label rounded-sm" role="alert">
                    Make sure you want to finish everything before sending.
                </div>
                {
                    message && (
                        <div className='col-md-12 mb-1 mt-1'>
                            {/* <label style={{color:'white'}}>vouchers</label> */}
                            <Space direction="vertical" style={{ width: '100%' }}>
                                {
                                    showAlertDanger &&
                                    <Alert type="error" message={message} showIcon closable />
                                }

                                {
                                    showAlertSucess &&
                                    <Alert type="success" message={message} showIcon closable />
                                }
                            </Space>
                        </div>
                    )
                }
                <div className="row">
                    <div className="mb-3  col-md-12" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tag color={`${color}`} style={{ width: 20, height: 20, borderRadius: '100%' }}>
                        </Tag>
                        <span>
                            <span style={{ fontWeight: 'bold', fontFamily: `"Noto Sans", sans-serif` }}>{name}</span> <br />
                            <span style={{ fontFamily: `"Noto Sans", sans-serif` }}>{detail}</span>
                        </span>
                    </div>

                    <div className="mb-3  col-md-12">
                        <label className="form-label">Description</label>
                        <textarea
                            className="form-control"
                            placeholder=""
                            id="adresse"
                            name="adress"
                            style={{ height: '50%' }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                </div>
                <div >
                    {/* <button
                        type="button"
                        className=" btn-primary agree-link rounded"
                        id="btn-Withdraw"

                    >
                        Save
                    </button> */}
                    {!loading &&
                        <button
                            type="submit"
                            className="form-control btn btn-primary"
                            style={{ color: "white" }}
                            onClick={AddStatus}
                        >
                            Save
                        </button>}

                    {loading && (
                        <button
                            type="submit"
                            className="form-control btn btn-primary"
                            disabled
                        >
                            <i
                                className="fa fa-spinner fa-spin fa-3x  fa-lg"
                                style={{ fontSize: 20 }}
                            ></i>{" "}
                            <span></span>
                        </button>
                    )}

                </div>

            </div>

        </Modal>
    )
}

export default AttributeStatus