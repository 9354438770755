import React, { useEffect, useState } from 'react'
import { avatar1, avatar3, b1, default1 } from '../../../../components/icons'
import { useNavigate, useParams } from 'react-router-dom';
import requestRow from '../../../../controller/RequestRow';
import axios from 'axios';
import api from '../../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import { Alert, Pagination, Space, Select } from 'antd';
import {
    Card, Row, Col, Badge, Drawer, List, Input, Button,
    Checkbox
} from "antd";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import {
    DiffOutlined, SwapOutlined, HomeOutlined, UnorderedListOutlined
} from "@ant-design/icons"
import PhoneInput from 'react-phone-input-2'
import Footer from '../../../../components/footer';
import Header from '../../../../components/header';
import { MdOutlineSwapVert } from "react-icons/md";
import HeaderVendor from '../../../../components/header/IndexVendor';
const { Option } = Select;

const PaymentHistoryToTiva = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();



    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }
    }, []);


    return (
        <>
            <div id="wrapper">

                <HeaderVendor />
                <div className="content-page">
                    <div className="content">
                        {/** Start container-fluid */}

                        <div className="container-fluid1">


                            <div className="row">


                                <div className="col-xl-12">
                                    <div className="card stacked-2 seller-account-tab">
                                        <div className="row">
                                            <div className="col-12">

                                                <div className="tab-content">
                                                    <div className="tab-pane active" id="add_Voucher_tab">
                                                        <div className="col-md-12">
                                                            <Card style={{ width: '100%', marginBottom: 15 }}>
                                                                <div className="m-0">
                                                                    <h4 className="fs-18 text-black mb-0">Payment history to TIVA</h4>
                                                                </div>

                                                                <div className=''>

                                                                    {/* <div className="mb-1 d-flex d-block" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>

                                                                        <div className="" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Total Voucher</strong> <br />
                                                                                    <span>{10000}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Voucher Used</strong> <br />
                                                                                    <span>{2003}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Vendor Transfer</strong> <br />
                                                                                    <span>{20390}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Voucher Available</strong> <br />
                                                                                    <span>{10101}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Vouchers Actived</strong> <br />
                                                                                    <span>{10234}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Vouchers Deleted</strong> <br />
                                                                                    <span>{10209}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Available Amount</strong> <br />
                                                                                    <span>{90834}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                    </div> */}

                                                                    <div className="table-responsive">
                                                                        <table className="table header-border table-responsive-sm tbl-common text-center" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">No</th>
                                                                                    <th scope="col">Date</th>
                                                                                    <th scope="col">Transaction ID</th>
                                                                                    <th scope="col">Paid Amount</th>
                                                                                    <th scope="col">Balance</th>
                                                                                    <th scope="col">Telecom <br /> Used to Pay</th>
                                                                                    <th scope="col">Telecom <br /> Transaction Reference</th>
                                                                                    <th scope="col">Receipt #</th>
                                                                                    <th scope="col">Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                <tr style={{ cursor: 'pointer' }}>
                                                                                    <td>1</td>
                                                                                    <td>Jan 16, 2024 10:13 AM <br />
                                                                                        4 Minutes Ago
                                                                                    </td>
                                                                                    <td>TP25663225</td>
                                                                                    <td>Tsh.5000</td>
                                                                                    <td>Tsh.100</td>
                                                                                    <td>Airtel Money <br />
                                                                                        +2556365226332 <br />
                                                                                        Josephat Beka
                                                                                    </td>
                                                                                    <td>22566636668552</td>
                                                                                    <td>TW5266333652</td>
                                                                                    <td><span style={{color:'#C0FAFF', fontSize:16}}>Success</span></td>

                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>

                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <Footer />
                    </div>
                </div>
            </div>

        </>
    )
}

export default PaymentHistoryToTiva
