import React from 'react'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        2023 - 2024 &copy; copyright <a href="#">Tiva</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
