import { useState } from "react";
import { MdOutlineAccountCircle } from "react-icons/md";
import {  useNavigate } from "react-router-dom";
import { PiLockKeyBold } from "react-icons/pi";
import { BsPersonVcard } from "react-icons/bs";
import { Menu } from "antd";

const AccountIndex = () => {
    const navigate = useNavigate();

    const [_open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>


            <div style={{ marginBottom: '1.25rem', marginTop: 'auto', borderRadius: '0.75rem', position: 'relative' }}>
                <Menu
                    onSelect={() => onClose()}
                    onClick={(item) => {
                        navigate(item.key);
                    }}
                    style={{ width: 280 }}
                    mode="inline"
                    items={[
                        {
                            label: 'Home',
                            key: '/myaccount-tiva/home',
                            icon: <MdOutlineAccountCircle style={{ fontSize: 30 ,color:'1576ED'}} />,
                        },
                        {
                            label: 'Personal Information',
                            key: '/myaccount-tiva/infos-perso',
                            icon: <BsPersonVcard style={{ fontSize: 30, color:'1576ED' }} />,
                        },
                        {
                            label: 'Security',
                            key: '/myaccount-tiva/security',
                            icon: <PiLockKeyBold style={{ fontSize: 30, color:'1576ED' }} />,
                        },
                    ]}
                ></Menu>

            </div>

        </>
    )
}

export default AccountIndex