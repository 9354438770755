import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import api from '../../../config/Config'
import requestRow from '../../../controller/RequestRow';
import {
    SearchOutlined, FileExcelOutlined
} from "@ant-design/icons"
import { Pagination, Input, Select, Space, Alert } from 'antd';
import { avatar1, default1 } from '../../../components/icons';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { Card, Avatar, Dropdown, Menu, Modal } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import Popup from '../../../components/popup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Footer from '../../../components/footer';
import Header from '../../../components/header';
const { Option } = Select;
const { Meta } = Card;

const menu = (
    <Menu>
        <Menu.Item key="1">Modifier</Menu.Item>
        <Menu.Item key="2">Supprimer</Menu.Item>
    </Menu>
);

const ListSellerByRoles = () => {
    const { nameRoles, idRoles } = useParams();
    const rolesId = idRoles as string;
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const data = '';
    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');

    const [Loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [phone, setPhone] = useState<string>('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [minicipal, setMinicipal] = useState('');
    const [gender, setGender] = useState('');
    const [userRoleId, setUserRoleId] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [pswd, setPswd] = useState('');
    const [confpass, setConfPass] = useState('');

    const [minicipalName, setMinicipalName] = useState('');
    const [query, setQuery] = useState('')
    const [searchResult, setSearchResult] = useState([]);
    const [viewPopup, SetViewPopup] = useState(false)

    // Variables Load elements
    const [province, setPronvince] = useState<requestRow[]>([])
    const [city, setCity] = useState<requestRow[]>([])
    const [minicipality, setMinicipality] = useState<requestRow[]>([])
    const [categoriRole, setCategoriRole] = useState<requestRow[]>([])
    const [sellerLoad, setSellerLoad] = useState<requestRow[]>([])
    const [loadVendor, setLoadVendor] = useState<requestRow[]>([]);

    //Paginate
    const [totalPage, setTotalPage] = useState(1);
    // Show Modal
    const [modal1Open, setModal1Open] = useState(false);
    //Messge
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);
    const [message, setMessage] = useState("");

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    function ViewPop() {
        SetViewPopup(true)
    }

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') === null) {
            navigate('/login')
        }
        loadProvince()
        loadCity()
        loadMinicipality()
        loadCategorie_or_Role()
        // loadSeller(1)
        loadSeller1();
        // Promise.all([loadProvince(), loadCity(), loadMinicipality(), loadCategorie_or_Role(), loadSeller(1)])
        // .catch((error) => {
        //     if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
        //         toast.error("Pas de connexion internet");
        //     } else {
        //         toast.error(error.response.data.message);
        //     }
        // });
    }, [nameRoles, rolesId]);

    //Load seller
    // function loadSeller(page: any) {
    //     api.getHttpRequest(`/partners/app/members/${nameRoles}?page=${page}`, data, 'GET').then((response) => {
    //         const json = response.data;
    //         if (json.status == 200) {
    //             setSellerLoad(json.data.rows);
    //             setSearchResult(json.data.rows);
    //             setTotalPage(json.data.count);
    //         }
    //     }).catch((error) => {
    //         if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
    //             setShowAlertDanger(true);
    //             setMessage("Please connect your device to the internet");
    //         } else if (error.response && error.response.data && error.response.data.message) {
    //             setShowAlertDanger(true);
    //             setMessage(error.response.data.message);
    //         } else {
    //             setShowAlertDanger(true);
    //             setMessage(error.message);
    //         }
    //     })
    // }
    const loadSeller1 = (page: number = 1) => {
        // setLoading(true);
        // /vendor/admin/load/${api.initPartnerId()}/${rolesId}?page=${page}

        api.getHttpRequest(`/partners/app/members/${nameRoles}?page=${page}`, data, 'GET').then((response) => {
            const json = response.data;
            // setLoad(json.results);
            if (json.status == 200) {
                setLoadVendor(json.data.response.data);
                setTotalPage(json.data.response.count);
            }

        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }

    //Add Seller_or_Member
    const Add_Seller_or_Member = async () => {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("lastname", lastname);
        formData.append("middlename", middlename)
        formData.append("email", email);
        formData.append("address", adress);
        formData.append("phone", phone);
        formData.append("id_ward", minicipal);
        formData.append("avatar", avatar);
        formData.append("gender", gender);
        formData.append("UserRoleId", rolesId);
        formData.append("civil_status", civilStatus);
        formData.append("date_of_birth", dateOfBirth);
        formData.append("place_of_birth", placeOfBirth);
        formData.append("is_seller_account", 'true');
        formData.append("default_password", pswd);
        formData.append("id_partner", api.initPartnerId());

        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: api.var_.url_api_local + '/partners/app/members',
            headers: {
                'Authorization': 'Bearer ' + api.initToken(),
                'Content-Type': 'application/json'
            },
            data: formData
        };
        setLoading(true);

        if (name === '' || phone === '' || minicipal === '' || pswd === '') {
            setShowAlertDanger(true);
            setMessage('No fields should be empty');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger(true);
            setMessage('The entry number is incoreact');
        } else if (pswd != confpass) {
            setShowAlertDanger(true);
            setMessage('The password must be the same');
        }
        else {
            axios(config)
                .then(function (response) {
                    const json = response.data;
                    if (json.status == 200) {
                        setShowAlertSucess(true);
                        setMessage(json.message);
                        loadSeller1()
                        SetViewPopup(false)
                    } else {
                        setShowAlertDanger(true);
                        setMessage(json.message);
                    }
                })
                .catch((error) => {
                    if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                        setShowAlertDanger(true);
                        setMessage("Please connect your device to the internet");
                    } else if (error.response && error.response.data && error.response.data.message) {
                        setShowAlertDanger(true);
                        setMessage(error.response.data.message);
                    } else {
                        setShowAlertDanger(true);
                        setMessage(error.message);
                    }
                })
        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }
    //Load zones
    const loadProvince = async () => {
        api.getHttpRequest("/parameters/location/region/load?page=1&limit=10000", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setPronvince(json.data.rows);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                setMessage("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage(error.message);
            }
        })
    }

    const loadCity = async () => {
        api.getHttpRequest("/parameters/location/district/load?page=1&limit=10000", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCity(json.data.rows);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                setMessage("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {

                setMessage(error.response.data.message);
            } else {
                setMessage(error.message);
            }
        })
    }

    const loadMinicipality = async () => {
        api.getHttpRequest("/parameters/location/ward/load?page=1&limit=10000", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setMinicipality(json.data.rows);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

                setMessage("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {

                setMessage(error.response.data.message);
            } else {
                setMessage(error.message);
            }
        })
    }

    const loadCategorie_or_Role = async () => {
        api.getHttpRequest("/parameters/roles/load-admin", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCategoriRole(json.data);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

                setMessage("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {

                setMessage(error.response.data.message);
            } else {
                setMessage(error.message);
            }
        })
    }


    const handleImageChange = (e: any) => {
        setAvatar(e.target.files[0]);
    };

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateCity, setStateCity] = useState<requestRow[]>([])
    const [stateMinicipality, setStateMinicipality] = useState<requestRow[]>([])
    const GetProvince = (id: any) => {
        const IdProv = city.filter(x => x.LocationRegionId === id);
        setStateCity(IdProv)
    }

    const GetCity = (id: any) => {
        const IdCity = minicipality.filter(x => x.LocationDistricId === id);
        setStateMinicipality(IdCity)
    }

    const searchSellers = (event: any) => {
        const getSearch = event.target.value;
        if (getSearch.length > 0) {
            const searchdata = sellerLoad.filter((item) => item.name.toLowerCase().includes(getSearch)
                || item.lastname.toLowerCase().includes(getSearch) || item.middlename.toLowerCase().includes(getSearch));
            setSellerLoad(searchdata)
        } else {
            setSellerLoad(searchResult)
        }
        setQuery(getSearch);
    }

    function IsKyc(x: any) {
        if (x === true) {
            return <span className="bg-success-transparent text-success p-1" style={{ borderRadius: 5 }}>Is active</span>
        } else return (<span className="bg-danger text-white p-1" style={{ borderRadius: 5 }}>Not active</span>)
    }

    return (
        <>


            <div id="wrapper">

                <Header />
                <div className="content-page">
                    <div className="content">

                        <div className="container-fluid1">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className="card stacked-2">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card-box border-0 reseller-distributer">
                                                    <div className="card-header ">


                                                        <div className="left-side">
                                                            <h2 className="m-0 font-24 font-weight-500">{nameRoles}</h2>
                                                            <p>List of {nameRoles} who have joined by jv
                                                            </p>
                                                        </div>
                                                        <div className="header-left">
                                                            <div className="dashboard_bar" style={{ marginRight: 5 }}>
                                                                <div className="input-group1 search-area1 d-lg-inline-flex" >

                                                                    <Input style={{ color: 'black' }} size="large" placeholder="Searh here..." value={query} onChange={(e) => searchSellers(e)} prefix={<SearchOutlined />} />


                                                                </div>
                                                            </div>
                                                            <span style={{ marginTop: 13 }}
                                                                className="btn btn-primary btn-rounded mb-2 "

                                                                onClick={() => ViewPop()}
                                                            >
                                                                {nameRoles}<AiOutlinePlusCircle style={{ fontSize: 25, marginLeft: 1 }} /></span>

                                                        </div>
                                                    </div>
                                                    {
                                                        message && (
                                                            <div className='col-md-12 mb-1'>
                                                                <Space direction="vertical" style={{ width: '100%' }}>
                                                                    {
                                                                        showAlertSucess &&
                                                                        <Alert type="success" message={message} showIcon closable />
                                                                    } {
                                                                        showAlertDanger &&
                                                                        <Alert type="error" message={message} showIcon closable />
                                                                    }
                                                                </Space>
                                                            </div>
                                                        )
                                                    }

                                                    <div className="table-responsive">
                                                        <table className="table header-border table-responsive-sm tbl-common">
                                                            <thead>
                                                                <tr>
                                                                    <th>Number</th>
                                                                    <th>FullName</th>
                                                                    <th>Vouchers</th>
                                                                    <th>Phone</th>
                                                                    {/* <th>Kyc</th> */}
                                                                    <th>Location</th>
                                                                    <th>CreateAt</th>
                                                                    <th>Options</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="tab_">
                                                                {
                                                                    loadVendor.map((item, i) => (
                                                                        <tr key={i} style={{ alignItems: 'center' }}>
                                                                            <td>{item.user.User.personnel_number}</td>

                                                                            <td>
                                                                                <img src={`${item.user.User.avatar === 'default.png' ? default1 : item.user.User.avatar}`}
                                                                                    title="contact-img"
                                                                                    className="rounded-circle avatar-sm mr-2" width={35} height={35} />
                                                                                <span>{item.user.fullname ? item.user.fullname : ''}</span>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <span>Total : {item.vouchers.total_vouchers} </span><br />
                                                                                    <span>Used: <span style={{ color: '#DE5244' }}>{item.vouchers.total_used}</span> </span><br />
                                                                                    <span>In Stock: {item.vouchers.toal_in_stock} </span>
                                                                                </div>
                                                                            </td>
                                                                            <td>{item.user.User.phone}</td>
                                                                            {/* <td >{IsKyc(item.infos.is_kyc)}</td> */}
                                                                            <td>
                                                                                <div>
                                                                                    <span>Region : {item.user.User.LocationWard?.LocationDistric?.LocationRegion.name}</span><br />
                                                                                    <span>Distric : {item.user.User.LocationWard?.LocationDistric?.name}</span><br />
                                                                                    <span>Ward : {item.user.User.LocationWard?.name}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {api.formatDate(item.user.createdAt)} <br />
                                                                                {api.geDateFormat(item.user.createdAt)}
                                                                            </td>

                                                                            <td>
                                                                                <div className="dropdown">
                                                                                    <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="ti-more-alt font-20"></i>
                                                                                    </button>
                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                        <Link className="dropdown-item" to={`/seller_account/${item.user.User.id}`}>Seller
                                                                                            Account</Link>

                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <nav>
                                                        <Pagination
                                                            style={{
                                                                paddingTop: 10
                                                            }}
                                                            pageSize={10}
                                                            total={totalPage}
                                                            onChange={(page: any) => {
                                                                loadSeller1(page)
                                                            }}
                                                        />
                                                    </nav>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div >



                        {/** end container-fluid */}


                        {/** add new seller Modal */}
                        <div className="modal fade" id="add_new_seller" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Add or Update reseller
                                        </h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="alert alert-info" role="alert">
                                            Before creating a reseller account you must make sure you know the person well, especially their
                                            real identity, have their photo and physical address and must be able to assume the role of
                                            seller of Tiva products.
                                        </div>
                                        <div className="col-md-12">
                                            <div>
                                                <Toaster
                                                    position="bottom-left"
                                                    reverseOrder={false}

                                                />
                                            </div>
                                            <div className="row">
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <div className="pro-pic " >
                                                                <div className="profile-pic-wrapper">
                                                                    <div className="pic-holder mb-0">
                                                                        {/** uploaded pic shown here */}
                                                                        <img id="profilePic" className="pic" src={default1} />

                                                                        <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{ opacity: 0 }}
                                                                            onChange={handleImageChange}
                                                                        />
                                                                        <label htmlFor="newProfilePhoto" className="upload-file-block">
                                                                            <div className="text-center">
                                                                                <div className="mb-2">
                                                                                    <i className="fa fa-camera fa-2x"></i>
                                                                                </div>
                                                                                <div className="text-uppercase">
                                                                                    Update <br /> Profile Photo
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-8 row' >
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label">Name<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                                                <input type="text" className="form-control" placeholder="" id="fullname"
                                                                    name='name'
                                                                    value={name}
                                                                    onChange={(e) => { setName(e.target.value) }}
                                                                />
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label">LastName</label>
                                                                <input type="text" className="form-control" placeholder="" id="fullname"
                                                                    name='lastname'
                                                                    value={lastname}
                                                                    onChange={(e) => { setLastName(e.target.value) }}
                                                                />
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label">MiddleName</label>
                                                                <input type="text" className="form-control" placeholder="" id="fullname"
                                                                    name='middlename'
                                                                    value={middlename}
                                                                    onChange={(e) => { setMiddlename(e.target.value); }}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <input type="hidden" id="id" value="00" /> */}


                                                <div className='col-md-12 row'>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label">Gender</label>
                                                        <select className="form-control" id="gender"
                                                            name='gender'
                                                            value={gender}
                                                            onChange={(e) => { setGender(e.target.value) }}
                                                        >
                                                            <option>Choose...</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>

                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label">Date of birth</label>
                                                        <input type="date" className="form-control" placeholder="" id="datenaiss"
                                                            name='dateOfBirth'
                                                            value={dateOfBirth}
                                                            onChange={(e) => { setDateOfBirth(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="mb-3  col-md-4">
                                                        <label className="form-label">Place of Birth</label>
                                                        <input type="text" className="form-control" placeholder="" id="phone"
                                                            name='placeOfBirth'
                                                            value={placeOfBirth}
                                                            onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="mb-3  col-md-4">
                                                        <label className="form-label">Phone<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                                        <input type="text" className="form-control" placeholder="" id="phone"
                                                            name='phone'
                                                            value={phone}
                                                            onChange={(e) => { setPhone(e.target.value) }}
                                                        />
                                                    </div>

                                                    <div className="mb-3  col-md-4">
                                                        <label className="form-label">Adress Mail</label>
                                                        <input type="email" className="form-control" placeholder="" id="email"
                                                            name='email'
                                                            value={email}
                                                            onChange={(e) => { setEmail(e.target.value); }}
                                                        />
                                                    </div>
                                                    <div className="mb-3 col-md-4">
                                                        <label className="form-label">Region</label>

                                                        <Select showSearch style={{ width: 225, height: 51 }} dropdownStyle={{ height: 200 }}

                                                            placeholder="Select country" optionFilterProp="children" onChange={GetProvince}
                                                            filterOption={(input: string, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }>
                                                            <Option selected value="">Select State/Region
                                                            </Option>
                                                            {
                                                                province &&
                                                                    province !== undefined ?
                                                                    province.map((item, i) => (
                                                                        <Option key={i} value={item.id}>{item.name}</Option>
                                                                    ))
                                                                    : "No Province"
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div className="mb-4  col-md-6">
                                                        <label className="form-label">Disctrict</label>
                                                        <select className="form-control" id="City"
                                                            onChange={(e) => GetCity(e.target.value)}
                                                        >
                                                            <option value=''>Select City</option>
                                                            {
                                                                stateCity &&
                                                                    stateCity !== undefined || stateCity !== null ?
                                                                    stateCity.map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={item.id}>{item.name}</option>
                                                                        )
                                                                    })
                                                                    : "No City"
                                                            }

                                                        </select>
                                                    </div>

                                                    <div className="mb-4  col-md-6">
                                                        <label className="form-label">Ward<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                                        <select className="form-control" id="Minicipality"
                                                            name='minicipality'
                                                            value={minicipal}

                                                            onChange={(e) => { setMinicipal(e.target.value) }}
                                                        >
                                                            <option value=''>Select ward</option>
                                                            {
                                                                stateMinicipality &&
                                                                    stateMinicipality !== undefined || stateMinicipality !== null ?
                                                                    stateMinicipality.map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={item.id}>{item.name}</option>
                                                                        )
                                                                    })
                                                                    : "No minicipality"
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="mb-4  col-md-12">
                                                        <label className="form-label">Adresse</label>
                                                        <textarea className="form-control" placeholder="" id="adresse"
                                                            name='adress'
                                                            value={adress}
                                                            onChange={(e) => { setAddress(e.target.value) }}
                                                        ></textarea>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">


                                        {!Loading && (
                                            <button type="button" className="btn btn-primary agree-link font-18"
                                                id="btn-Withdraw"
                                                onClick={() => (Add_Seller_or_Member())}
                                            >Save changes</button>
                                        )}

                                        {Loading && (

                                            <button type="button" className="btn btn-primary agree-link font-18" disabled={true}
                                                id="btn-Withdraw"

                                            >
                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

            <Popup
                title={`Add or Update ${nameRoles}`}
                openPupop={viewPopup}
                setOpenPopup={SetViewPopup}
            >
                <div className="alert alert-info" role="alert">
                    Before creating a reseller account you must make sure you know the person well, especially their
                    real identity, have their photo and physical address and must be able to assume the role of
                    seller of Tiva products.
                </div>
                <div className="col-md-12">
                    <div className='col-md-12 mb-1'>
                        {/* <label style={{color:'white'}}>vouchers</label> */}
                        <Space direction="vertical" style={{ width: '100%' }}>
                            {
                                showAlertSucess &&
                                <Alert type="success" message={message} showIcon closable />
                            } {
                                showAlertDanger &&
                                <Alert type="error" message={message} showIcon closable />
                            }
                        </Space>


                    </div>
                    <div className="row">

                        {/* <input type="hidden" id="id" value="00" /> */}


                        <div className='col-md-12 row'>

                            <div className="mb-1 col-md-3">
                                <label className="form-label">Name<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                <input type="text" className="form-control" placeholder="" id="fullname"
                                    name='name'
                                    value={name}
                                    onChange={(e) => { setName(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1 col-md-3">
                                <label className="form-label">LastName</label>
                                <input type="text" className="form-control" placeholder="" id="fullname"
                                    name='lastname'
                                    value={lastname}
                                    onChange={(e) => { setLastName(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1 col-md-3">
                                <label className="form-label">MiddleName</label>
                                <input type="text" className="form-control" placeholder="" id="fullname"
                                    name='middlename'
                                    value={middlename}
                                    onChange={(e) => { setMiddlename(e.target.value); }}
                                />
                            </div>
                            {/* <div className="mb-1 col-md-4">
                                <label className="form-label">Category<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                <select className="form-control" id="categorie"
                                    name='userRoleId'
                                    value={userRoleId}
                                    onChange={(e) => { setUserRoleId(e.target.value) }}
                                >
                                    <option value="">Select category</option>
                                    {
                                        categoriRole.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))
                                    }


                                </select>
                            </div> */}

                            <div className="mb-1 col-md-3">
                                <label className="form-label">Gender<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                <select className="form-control" id="gender"
                                    name='gender'
                                    value={gender}
                                    onChange={(e) => { setGender(e.target.value) }}
                                >
                                    <option>Choose...</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>

                            <div className="mb-1 col-md-3">
                                <label className="form-label">Date of birth</label>
                                <input type="date" className="form-control" placeholder="" id="datenaiss"
                                    name='dateOfBirth'
                                    value={dateOfBirth}
                                    onChange={(e) => { setDateOfBirth(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1  col-md-3">
                                <label className="form-label">Place of Birth</label>
                                <input type="text" className="form-control" placeholder="" id="phone"
                                    name='placeOfBirth'
                                    value={placeOfBirth}
                                    onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1  col-md-3">
                                <label className="form-label">Phone<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                {/* <input type="text" className="form-control" placeholder="" id="phone"
                                    name='phone'
                                    value={phone}
                                    onChange={(e) => { setPhone(e.target.value) }}
                                /> */}
                                <PhoneInput
                                    inputStyle={{
                                        width: '100%',

                                    } as React.CSSProperties}
                                    inputClass={'form-control'}
                                    country={'tz'}
                                    value={phone}
                                    onChange={changePhoneNumber}
                                    enableSearch={true}
                                    countryCodeEditable={true}
                                />
                            </div>

                            <div className="mb-1  col-md-3">
                                <label className="form-label">Adress Mail</label>
                                <input type="email" className="form-control" placeholder="" id="email"
                                    name='email'
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value); }}
                                />
                            </div>
                            <div className="mb-1 col-md-4">
                                <label className="form-label">Region<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>

                                <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                    className="form-control"
                                    placeholder="Select country" optionFilterProp="children" onChange={GetProvince}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option selected value="">Select State/Region
                                    </Option>
                                    {
                                        province &&
                                            province !== undefined ?
                                            province.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No Province"
                                    }
                                </Select>
                            </div>
                            <div className="mb-1  col-md-4">
                                <label className="form-label">District<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>

                                <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 200 }}
                                    className="form-control"
                                    placeholder="Select country" optionFilterProp="children" onChange={(e) => GetCity(e)}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option selected value="">Select District
                                    </Option>
                                    {
                                        stateCity &&
                                            stateCity !== undefined || stateCity !== null ?
                                            stateCity.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No City"
                                    }
                                </Select>
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Ward/Kata<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>

                                <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                    className="form-control"
                                    placeholder="Select minicipality" optionFilterProp="children" value={minicipal} onChange={(e) => { setMinicipal(e) }}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option selected value="">Select/Ward
                                    </Option>
                                    {
                                        stateMinicipality &&
                                            stateMinicipality !== undefined || stateMinicipality !== null ?
                                            stateMinicipality.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No minicipality"
                                    }
                                </Select>
                            </div>
                            <div className="mb-1  col-md-12">
                                <label className="form-label">Address</label>
                                <textarea className="form-control" placeholder="" id="adresse"
                                    name='adress'
                                    value={adress}
                                    onChange={(e) => { setAddress(e.target.value) }}
                                ></textarea>
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Default password</label>
                                <input type="password" className="form-control" placeholder=""

                                    name='pswd'
                                    value={pswd}
                                    onChange={(e) => { setPswd(e.target.value) }}
                                />
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Confirm password</label>
                                <input type="password" className="form-control" placeholder=""
                                    name='confpass'
                                    value={confpass}
                                    onChange={(e) => { setConfPass(e.target.value); }}
                                />
                            </div>
                            <div className="mb-1  col-md-4">
                                <label className="form-label text-white">submit</label>
                                {!Loading && (
                                    <button type="button" className="btn btn-primary agree-link font-18"
                                        id="btn-Withdraw"
                                        onClick={() => (Add_Seller_or_Member())}
                                    >Save changes</button>
                                )}

                                {Loading && (

                                    <button type="button" className="btn btn-primary agree-link font-18" disabled={true}
                                        id="btn-Withdraw"

                                    >
                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span></span>
                                    </button>
                                )}

                            </div>


                        </div>

                    </div>

                </div>


            </Popup>
        </>
    )
}

export default ListSellerByRoles
