import { LegacyRef, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../../config/Config'
import requestRow from '../../../controller/RequestRow';
import toast, { Toaster } from 'react-hot-toast';
import Popup from '../../../components/popup';
import PhoneInput from 'react-phone-input-2'
import { Popover, Popconfirm, Button, notification, Space } from 'antd'
import type { NotificationPlacement } from 'antd/es/notification/interface';
import Swal from 'sweetalert2'
import HeaderVendor from '../../../components/header/IndexVendor';
import Footer from '../../../components/footer';
import LinkVendorPayment from '../../payement/LinkPaymentMethod/LinkVendorPayment';


const { Option } = Select;

declare module 'react' {
    interface HTMLAttributes<T extends HTMLDivElement> {
        show?: () => void;
        hide?: () => void;
    }
}

const close = () => {
    console.log(
        'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
};

const AddPaymentVendor = () => {
   
    return (
        <>
            <div id="wrapper">
              <HeaderVendor />
                {/* <Header /> */}
                <LinkVendorPayment/>
            </div>


        </>
    )
}

export default AddPaymentVendor
