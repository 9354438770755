import React, { useEffect, useState } from 'react'
import Header from '../../../components/header'
import { course, iconlogo } from '../../../components/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import api from "../../../config/Config"
import { Pagination, Spin } from 'antd'
import Footer from '../../../components/footer'

const LearningCourseVideo = () => {
    const navigate = useNavigate();
    const isMobileScreen = window.innerWidth <= 768;
    const { idSubCateg } = useParams()
    const data = "";
    const [loadCategory, setLoadCategory] = useState<ResponseLearning[]>([])
    const [totalPage, setTotalPage] = useState(1);
    const [idvideo, setVideo] = useState("")
    const [selectedLanguage, _setSelectedLanguage] = useState('en');
    const [description, setDescription] = useState("")

    useEffect(() => {
        loadCategoryLeaning(1)
    }, [idvideo])
    const loadCategoryLeaning = (page: number) => {
        api
            .getHttpRequestV3(`/user/training/${idSubCateg}/${selectedLanguage}?page=${page}&limit=3`, data, "GET")
            .then((response) => {
                const json = response.data.data;
                setLoadCategory(json.rows)
                setTotalPage(json.count)
            })
            .catch((error) => {
                console.log(error)
            });
    };
    const selectedCategory = loadCategory.find((category) => category.id === idSubCateg);

    const redirectionTraining = () => {
        return navigate("/training")
    }
    const handleGetTrainingIdVideo = (idvideo: string, description: string) => {
        setVideo(idvideo)
        setDescription(description)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div id="wrapper">
            <Header />
            <section>
                <div className="container-fluid p-0 course-banner-section">
                    <div
                        style={{
                            backgroundImage: `url(${course})`,
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            minHeight: '500px',
                            position: 'relative',
                            marginTop: '25px',
                            paddingTop: '93px',

                        }}
                        className="item-image img-padding course-banner" >
                        <div className="container">
                            <div className="col-md-8 mx-auto">

                                <div className="tiva-course-header " >
                                    <div className="tiva-course-header-1">
                                        <div className="logo-brand-tiva">
                                            <img src={iconlogo} alt="" className="img-fluid" />
                                        </div></div>
                                    <div className="tiva-course-header-1">
                                        <div className="integrations-top-content" style={{ color: 'white' }}>
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb" >
                                                    <li className="breadcrumb-item" style={{ color: '#017DF3', fontFamily: `"Noto Sans", sans-serif` }}><a href="user-home.html">Home</a></li>
                                                    <li onClick={redirectionTraining} className="breadcrumb-item active" aria-current="page" style={{
                                                        color: 'white', fontFamily: `"Noto Sans", sans-serif`, fontWeight: 'bold',
                                                        cursor: 'pointer'
                                                    }}>Training</li>
                                                </ol>
                                            </nav>
                                            <div className="heading-xlarge margin-bottom-xxsmall w-embed">
                                                <h1>{selectedCategory?.name} </h1>
                                            </div>

                                            <div

                                            >
                                                <p
                                                    className={`pull-left text-white font-14 custom-description`}
                                                    style={{ fontFamily: `"Noto Sans", sans-serif` }}
                                                    // dangerouslySetInnerHTML={{
                                                    //     __html: description || 'Chargement...',
                                                    // }}
                                                    
                                                >{description}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">

                                </div>
                                <div className="col-sm-12 col-md-8">

                                </div>


                                <div className="video-container">
                                    <iframe width="100%" height="415" src={`https://www.youtube.com/embed/${idvideo}`}
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section >

            <section>

                <div className="container py-5">
                    <div className="row trining-category2">
                        <div className="col-lg-12">
                            <h2 className="text-center we-help-heading text-center py-3">Explore other lessons</h2>
                        </div>

                        {loadCategory && loadCategory.length > 0 ? (
                            loadCategory.map((item, i) => (
                                <div key={i} className="col-12 col-md-4 col-lg-4  d-flex align-self-stretch mb-3"
                                onClick={() => handleGetTrainingIdVideo(item?.training?.youtube_id, item.name? item.name : item?.training?.slug)}
                                >
                                    <div className="card  mb-4" style={{ height: '100%', borderRadius: '5px', marginBottom: 10 }}>
                                        <div className="position-relative">

                                            <img src={`https://dealer.tiva.co.tz/tiva/images/training/` + item?.training?.banner} className="card-img-top" alt="..." />
                                            <div className="duration" style={{ fontFamily: `"Noto Sans", sans-serif` }}>
                                                {item?.training?.duration} minutes
                                            </div>
                                        </div>
                                        <div className="card-body d-flex flex-column">
                                            <h5 className="card-title " style={{ fontFamily: `"Noto Sans", sans-serif` }}>
                                                {item.name? item.name : item?.training?.slug}
                                            </h5>
                                            <p className="text-muted" style={{ fontFamily: `"Noto Sans", sans-serif` }}>
                                                {item.short_description}
                                            </p>
                                            <div className="mt-auto ">
                                                <div className="card-footer">
                                                    <div className="font-16"
                                                        style={{ color: '#1576ED', fontFamily: `"Noto Sans", sans-serif`, cursor: 'pointer' }}
                                                        
                                                    > See Tutorial  <i className="fas fa-long-arrow-alt-right"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-12 d-flex justify-content-center">
                                <Spin />No data found
                            </div>
                        )}

                        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: 10 }}>
                            <Pagination
                                style={{ paddingTop: 0, paddingBottom: 10 }}
                                pageSize={3}
                                total={totalPage}
                                onChange={(page) => loadCategoryLeaning(page)}
                            />
                        </div>
                    </div>
                </div>

            </section>

            {/* <div className="content-page" style={{marginTop:'-50%'}}>
                <div className="content">

                    <Footer />
                </div>
            </div> */}

        </div >
    )
}

export default LearningCourseVideo