import api from '../config/Config'
import { InterfaceHome } from "../config/Interfaces/InterfaceHome";
import { InterfaceProfile } from '../config/Interfaces/InterfaceProfile';

const LoadDashBoard = async (page?: number): Promise<InterfaceHome[]> => {
    try {
        const response = await api.getHttpRequest(`/vendor/loadVendorDashboard?page=${page}`, {}, "GET")
        const json = response.data.data;
        return json
    } catch (error) {
        console.error("Error in load_dashboard:", error);
        return [];
    }
}

const LoadProfile = async (): Promise<InterfaceProfile> => {
    try {
        const response = await api.getHttpRequest(`/user/profil`, {}, "GET")
        const json = response.data.data;
        return json
    } catch (error) {
        console.error("Error in load_dashboard:", error);
        return {
            id: "",
            name: "",
            middlename: "",
            lastname: "",
            personnel_number: 0,
            phone: "",
            gender: "",
            email: "",
            address: "",
            avatar: "",
            is_kyc: false,
            verified: false,
            civil_status: "",
            date_of_birth: "",
            place_of_birth: "",
            createdAt: "",
            user_parent: {
                id: "",
                name: "",
                middlename: "",
                lastname: "",
                email: "",
                phone: ""
            },
            LocationWard: {
                id: "",
                name: "",
                LocationDistric: {
                    id: "",
                    name: "",
                    LocationRegion: {
                        id: "",
                        name: ""
                    }
                }
            },
            Partner: {
                id: "",
                name: "",
                logo: "",
                PartnerCategory: {
                    id: "",
                    name: ""
                }
            }
        };
    }
}

export default { LoadDashBoard, LoadProfile }