import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import api from '../../../../config/Config';
import { avatar1, info1, info2 } from "../../../../components/icons";
import { Avatar } from "antd";

const MyAccountTiva = () => {
    useEffect(() => {
        // if(sessionStorage.getItem("onktxonktxtourser") === null) {
        //     navigate("/home")
        // }
    }, []);



    return (
        <>
            <div style={{ marginBottom: '20px',marginTop:20}}className='md:mt-8 sm:mt-20 smsl:mt-20 ml-1  gap-4 h-full bg-white'>
                <div className=" flex p-2 justify-between items-center">

                    <div className="col-md-12" role="menu" style={{ textAlign: 'center' }}>
                        <div style={{ margin: 10 }}>
                            <div>
                                <Avatar className=" rounded-full border border-gray-400 p-[22px] bg-white" style={{width: 120, height: 120}}>
                                    <img src={`${api.initAvatar() === 'default.png' ? `${avatar1}` : `${api.var_.url_avatar + api.initAvatar()}`}`}
                                        style={{width: 120, height: 120, borderRadius: 100 }} />
                                </Avatar>

                            </div>
                            <div>
                                <span style={{ fontSize: 32, paddingTop: 20, paddingBottom: '100px', fontFamily:`"Noto Sans", sans-serif` }}>Welcome sir/mrs {api.initUser()}</span>
                            </div>
                            <div>
                                <p style={{fontFamily:`"Noto Sans", sans-serif`}}>
                                    Manage your information, as well as the privacy and security of your data to get the most out of TIva services
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div
                                style={{
                                    backgroundColor: "white",
                                    border: "1px solid #F0F0F0",
                                    padding: 5,
                                    borderRadius: 5,
                                    justifyContent: "center",
                                    maxWidth: "400px",
                                    margin: "0 auto", fontFamily:`"Noto Sans", sans-serif`
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "1" }}>
                                        <h2 style={{
                                            color: "#1a0dab",
                                            fontSize: "24px",
                                            marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif`
                                        }}>Your Account Tiva</h2>
                                        <div style={{ marginBottom: "10px" , fontFamily:`"Noto Sans", sans-serif`}}>
                                        Safety Tips for the Safety Check-up
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: "20px" }}>
                                        <img src={info1} style={{ width: 60, height: 60 }} />
                                    </div>
                                </div>
                                <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                    <p style={{fontFamily:`"Noto Sans", sans-serif`}}>Learn more</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div
                                style={{
                                    backgroundColor: "white",
                                    border: "1px solid #F0F0F0",
                                    padding: 5,
                                    borderRadius: 5,
                                    justifyContent: "center",
                                    maxWidth: "400px",
                                    margin: "0 auto"
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "1" }}>
                                        <h2 style={{
                                            color: "#1a0dab",
                                            fontSize: "24px",
                                            marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif`
                                        }}>Security Params</h2>
                                        <div style={{ marginBottom: "10px" , fontFamily:`"Noto Sans", sans-serif`}}>
                                        Safety Tips for the Safety Check-up
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: "20px" }}>
                                        <img src={info2} style={{ width: 60, height: 60 }} />
                                    </div>
                                </div>
                                <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                    <p style={{fontFamily:`"Noto Sans", sans-serif`}}>Learn more</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default MyAccountTiva