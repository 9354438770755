import React, { useEffect, useState } from 'react';
import { Modal, Form, Select, Button, DatePicker, Steps, Space, Alert, CascaderProps, Tree, TreeDataNode, TreeProps } from 'antd';
import { Cascader } from 'antd';
import { Field } from './Field';
import api from '../../../config/Config';
import requestRow from '../../../controller/RequestRow';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import { Dayjs } from 'dayjs';
import { Key } from 'antd/es/table/interface';

const { Option } = Select

interface SettingItem {
  id: string;
  type: string;
  value: string;
  name: string;
  label: string;
  options: string[];
}

interface FormData {
  id: string;
  name: string;
  observation: string;
  createdAt: string;
  updatedAt: string;
  settings: SettingItem[];
}

interface ComponentRegionProps {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  data: any;
  formtype: string;
  loadload: () => void;
}
interface SMSCalendarData {
  datetime: string;
  smsIdList: string[];
}

interface UserRole {
  id: string;
  name: string;
}

interface MetadataList {
  level: number;
  name: string;
}

interface DataItem {
  createdAt: string;
  location: string[];
  UserRole: UserRole;
  MetadataList: MetadataList;
}

interface Option {
  value: number | string; // Permet d'utiliser des valeurs de type string ou number
  label: string;
  children?: Option[];
}


const ShowForm: React.FC<ComponentRegionProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  data,
  formtype,
  loadload
}) => {
  // const [formData, setFormData] = useState<FormData>(data);
  const [form] = Form.useForm<FormData>();
  const formData = {
    id: data?.id,
    name: data?.name,
    observation: data?.observation,
    createdAt: data?.createdAt,
    updatedAt: data?.updatedAt,
    settings: data?.settings,
  };

  useEffect(() => {
    formtype = 'form_creater'
  }, [formData, data, formtype]);

  const [_formFields, _setFormFields] = useState<Field[]>([]);
  const [showAlertDanger, setShowAlertDanger] = useState(false);
  const [showAlertDanger1, setShowAlertDanger1] = useState(false);
  const [showAlertSucess, setShowAlertSucess] = useState(false);
  const [message, setMessage] = useState("");
  const [message1, setMessage1] = useState("");

  const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');

  const [Loading, setLoading] = useState(false);
  const [avatar, _setAvatar] = useState('');
  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState('');
  const [lastname, setLastName] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [email, setEmail] = useState('');
  const [adress, setAddress] = useState('')
  const [minicipal, setMinicipal] = useState('');
  const [gender, setGender] = useState('');
  const [civilStatus, setCivilStatus] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [placeOfBirth, setPlaceOfBirth] = useState('');
  const [pswd, setPswd] = useState('');
  const [confpass, setConfPass] = useState('');
  const [dataTree, setDataTree] = useState<TreeDataNode[]>([]);
  const [_viewPopup, SetViewPopup] = useState(false)

  // Variables Load elements
  const [province, setPronvince] = useState<requestRow[]>([])
  const [city, setCity] = useState<requestRow[]>([])
  const [minicipality, setMinicipality] = useState<requestRow[]>([])
  const [categoriRole, setCategoriRole] = useState<requestRow[]>([])
  const [roleUnder, setRoleUnder] = useState<requestRow[]>([])
  const [memberUnder, setMemberUnder] = useState<requestRow[]>([])
  const [loardSms, setLoardSms] = useState<requestRow[]>([])

  const [getIdRoleUnder, setGetIdRoleUnder] = useState("")
  const [getIdMembers, setGetIdMembers] = useState<string[]>([]);
  const [getIdSms, setGetIdSms] = useState<string[]>([])
  const [_selectDateTime, setSelectDateTime] = useState("");
  const [selectedValues, setSelectedValues] = useState("")


  const changePhoneNumber = (value: string, country: any) => {
    const countryCode = country.dialCode;
    const phoneNumber = value.replace(countryCode, `+${countryCode}`);
    setPhone(phoneNumber);
  };

  interface TreeDataNode {
    title: string;
    key: string;
    value?: string;
    children?: TreeDataNode[];
  }
  // Fonction pour créer la structure TreeDataNode[]
  const createTreeData = (): TreeDataNode[] => {
    return roleUnder
      .filter(role => role.MetadataList.level <= 5) // 1er niveau (le plus bas)
      .map((role, index) => {
        const secondLevelChildren = roleUnder
          .filter(r => r.MetadataList.level > role.MetadataList.level && r.MetadataList.level <= 9) // 2ème niveau
          .map((child, childIndex) => {
            const thirdLevelChildren = roleUnder
              .filter(c => c.MetadataList.level > child.MetadataList.level && c.MetadataList.level <= 11) // 3ème niveau
              .map((grandchild, grandchildIndex) => ({
                title: grandchild.UserRole.name,
                key: `${index}-${childIndex}-${grandchildIndex}`,
                value: grandchild.UserRole.id
              }));

            return {
              title: child.UserRole.name,
              key: `${index}-${childIndex}`,
              value: child.UserRole.id,
              children: thirdLevelChildren.length > 0 ? thirdLevelChildren : undefined,
            };
          });

        return {
          title: role.UserRole.name,
          key: `${index}`,
          value: role.UserRole.id,
          children: secondLevelChildren.length > 0 ? secondLevelChildren : undefined,
        };
      });
  };

  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);

  // const [formDataForm, setFormDataForm] = useState<{ [key: string]: any }>({});
  const [formDataForm, setFormDataForm] = useState<{ [key: string]: any }>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormDataForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Dayjs | null, dateString: string) => {
    // console.log('Selected Time: ', date);
    setSelectDateTime(dateString);
    setSelectedTime(date);
  };

  const handleOkDate = () => {
    console.log('OK button clicked');
  };

  useEffect(() => {
    loadProvince()
    loadCity()
    loadMinicipality()
    loadCategorie_or_Role()
    loadRoleUnder()
    if (getIdRoleUnder) {
      loadMemberUnder(getIdRoleUnder)
    }
    loadSmsPlaning()
  }, [getIdRoleUnder])

  const getIdRole: any = categoriRole?.find((c) => c.name === "Member")?.id;

  const [options, setOptions] = useState<CascaderProps['options']>([]);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]); // État pour les options filtrées

  useEffect(() => {
    // Récupérer ou initialiser les données roleUnder
    setRoleUnder(data); // Remplacez par votre logique de récupération de données réelle
  }, []);

  useEffect(() => {
    // Initialiser les options par défaut
    const defaultRoles = roleUnder.map(item => ({
      value: item.UserRole.name, // Utiliser le nom du rôle comme valeur
      label: item.UserRole.name
    }));
    setOptions(defaultRoles);
    setFilteredOptions(defaultRoles); // Initialiser les options filtrées
  }, [roleUnder]); // Dépendance sur roleUnder pour mettre à jour les options


  //Add Seller_or_Member
  const saveForm = async () => {
    const formData = new FormData();
    // Ajouter les users_assign_data
    getIdMembers.forEach((userId) => {
      formData.append('users_assign_data[]', userId);
    });

    // Affecter les valeurs de formDataForm à des variables spécifiques
    // const name = formDataForm.name || '';
    const middlename = formDataForm.middlename || '';
    const lastname = formDataForm.lastname || '';
    // const phone = formDataForm.phone || '';
    const email = formDataForm.email || '';
    const address = formDataForm.address || '';
    const gender = formDataForm.gender || '';
    const civilStatus = formDataForm.civil_status || '';
    const dateOfBirth = formDataForm.date_of_birth || '';
    const placeOfBirth = formDataForm.place_of_birth || '';

    // Ajouter les valeurs aux variables à FormData
    formData.append("name", name);
    formData.append('middlename', middlename);
    formData.append('lastname', lastname);
    formData.append("phone", phone);
    formData.append('email', email);
    formData.append('address', address);
    formData.append('gender', gender);
    formData.append('civil_status', civilStatus);
    formData.append('date_of_birth', dateOfBirth);
    formData.append('place_of_birth', placeOfBirth);
    formData.append("UserRoleId", getIdRole);
    // ... (ajouter les données de sms_calendar) ...

    // Créer l'objet other_object
    const otherObject: { [key: string]: any } = {};
    const expectedFields = ['users_assign_data', 'gender', 'phone', 'sms_calendar', 'name', 'middlename', 'lastname', 'email', 'address', 'civil_status', 'date_of_birth', 'place_of_birth']; // Liste des champs attendus par l'API
    for (const key in formDataForm) {
      if (!expectedFields.includes(key)) {
        otherObject[key] = formDataForm[key];
      }
    }


    if (Object.keys(otherObject).length > 0) {
      const otherObjectData = JSON.stringify(otherObject);
      formData.append('other_object', JSON.parse(otherObjectData));
    } else {
      formData.append("other_object", JSON.stringify(null));
    }

    // ..

    if (selectedTime) {
      const smsCalendarData: SMSCalendarData = {
        datetime: selectedTime.format('YYYY-MM-DD HH:mm'),
        smsIdList: getIdSms,
      };

      formData.append('sms_calendar[datetime]', smsCalendarData.datetime);
      for (const smsId of smsCalendarData.smsIdList) {
        formData.append('sms_calendar[smsIdList][]', smsId);
      }
    }

    var config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: api.var_.url_api_local_v3 + '/user/partner/member/create',
      headers: {
        'Authorization': 'Bearer ' + api.initToken(),
        'Content-Type': 'application/json'
      },
      data: formData
    };
    setLoading(true);

    if (formDataForm.name === '' || formDataForm.phone === '') {
      setShowAlertDanger(true);
      setMessage('No fields should be empty');
      setLoading(false);
    } else if (!regexTelephone.test(formDataForm.phone)) {
      setShowAlertDanger(true);
      setMessage('The entry number is incoreact');
      setLoading(false);
    }
    else {
      axios(config)
        .then(function (response) {
          const json = response.data;
          if (json.status == 200) {
            setShowAlertSucess(true);
            setMessage(json.message);
            loadload()
            SetViewPopup(false)
            setPhone("")
            setName("")
            setLoading(false);
            // handleCancel()
            setGetIdMembers([])
            setGetIdSms([])
            setSelectedTime(null)
            setFormDataForm({})
          } else {
            setShowAlertDanger(true);
            setMessage(json.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
            setShowAlertDanger(true);
            setMessage("Please connect your device to the internet");
            setLoading(false);
          } else if (error.response && error.response.data && error.response.data.message) {
            setShowAlertDanger(true);
            setMessage(error.response.data.message);
            setLoading(false);
          } else {
            setShowAlertDanger(true);
            setMessage(error.message);
            setLoading(false);
          }
        })
    }
    setTimeout(() => {
      setLoading(false);
      setShowAlertDanger(false);
      setShowAlertDanger1(false);
      setShowAlertSucess(false);
    }, 60000);
  }

  const saveFormExist = () => {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("lastname", lastname);
    formData.append("middlename", middlename)
    formData.append("email", email);
    formData.append("address", adress);
    formData.append("phone", phone);
    formData.append("id_ward", minicipal);
    formData.append("avatar", avatar);
    formData.append("gender", gender);
    formData.append("UserRoleId", getIdRole);
    formData.append("civil_status", civilStatus);
    formData.append("date_of_birth", dateOfBirth);
    formData.append("place_of_birth", placeOfBirth);
    formData.append("is_seller_account", 'true');

    if (selectedTime) {
      const smsCalendarData: SMSCalendarData = {
        datetime: selectedTime.format('YYYY-MM-DD HH:mm'),
        smsIdList: getIdSms,
      };

      formData.append('sms_calendar[datetime]', smsCalendarData.datetime);
      for (const smsId of smsCalendarData.smsIdList) {
        formData.append('sms_calendar[smsIdList][]', smsId);
      }
    }

    formData.append("other_object", JSON.stringify(null));
    getIdMembers.forEach((userId) => {
      formData.append('users_assign_data[]', userId);
    });

    var config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: api.var_.url_api_local_v3 + '/user/partner/member/create',
      headers: {
        'Authorization': 'Bearer ' + api.initToken(),
        'Content-Type': 'application/json'
      },
      data: formData
    };
    setLoading(true);

    if (name === '' || phone === '') {
      setShowAlertDanger(true);
      setMessage('No fields should be empty');
      setLoading(false);
    } else if (!regexTelephone.test(phone)) {
      setShowAlertDanger(true);
      setMessage('The entry number is incoreact');
      setLoading(false);
    }
    else {
      axios(config)
        .then(function (response) {
          const json = response.data;
          if (json.status == 200) {
            setShowAlertSucess(true);
            setMessage(json.message);
            loadload()
            SetViewPopup(false)
            setPhone("")
            setName("")
            setLastName("")
            setMiddlename("")
            setEmail("")
            setAddress("")
            setMinicipal("")
            setGender("")
            setCivilStatus("")
            setDateOfBirth("")
            setPlaceOfBirth("")
            setPswd("")
            setConfPass("")
            setLoading(false);
            // handleCancel()
            setGetIdMembers([])
            setGetIdSms([])
            setSelectedTime(null)
          } else {
            setShowAlertDanger(true);
            setMessage(json.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
            setShowAlertDanger(true);
            setMessage("Please connect your device to the internet");
            setLoading(false);
          } else if (error.response && error.response.data && error.response.data.message) {
            setShowAlertDanger(true);
            setMessage(error.response.data.message);
            setLoading(false);
          } else {
            setShowAlertDanger(true);
            setMessage(error.message);
            setLoading(false);
          }
        })
    }
    setTimeout(() => {
      setLoading(false);
      setShowAlertDanger(false);
      setShowAlertDanger1(false);
      setShowAlertSucess(false);
    }, 60000);
  }

  const loadProvince = async () => {
    api.getHttpRequest("/parameters/location/region/load?page=1&limit=10000", data, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setPronvince(json.data.rows);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
        setMessage("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage(error.message);
      }
    })
  }

  const loadCity = async () => {
    api.getHttpRequest("/parameters/location/district/load?page=1&limit=10000", data, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setCity(json.data.rows);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
        setMessage("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        setMessage(error.response.data.message);
      } else {
        setMessage(error.message);
      }
    })
  }

  const loadMinicipality = async () => {
    api.getHttpRequest("/parameters/location/ward/load?page=1&limit=10000", data, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setMinicipality(json.data.rows);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

        setMessage("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        setMessage(error.response.data.message);
      } else {
        setMessage(error.message);
      }
    })
  }

  const loadCategorie_or_Role = async () => {
    api.getHttpRequest("/parameters/roles/load-admin", data, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setCategoriRole(json.data);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

        setMessage("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        setMessage(error.response.data.message);
      } else {
        setMessage(error.message);
      }
    })
  }



  const loadRoleUnder = async () => {
    api.getHttpRequestV3("/user/partner/member/load_roles_under", data, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setRoleUnder(json.data);
        const treeData = createTreeData();
        console.log(treeData);
        setDataTree(treeData);

      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

        setMessage("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        setMessage(error.response.data.message);
      } else {
        setMessage(error.message);
      }
    })
  }

  const onSelectRole: TreeProps['onSelect'] = (selectedKeys, infos) => {
    console.log('Select ::: ', selectedKeys);
    const data: any = infos;
    if (data) {
      console.log('Select', data);
    }
  }

  const onCheckRole: TreeProps['onCheck'] = (selectedKeys, infos) => {
    console.log('Select ::: ', selectedKeys, infos);
  }

  const loadMemberUnder = async (id: any) => {
    api.getHttpRequestV3(`/user/partner/member/load_members_under/${id}`, data, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setMemberUnder(json.data);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

        setMessage("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        setMessage(error.response.data.message);
      } else {
        setMessage(error.message);
      }
    })
  }
  const loadSmsPlaning = async () => {
    api.getHttpRequestV3(`/user/partner/member/load_sms_planing`, data, 'GET').then((response) => {
      const json = response.data;
      if (json.status == 200) {
        setLoardSms(json.data);
      }
    }).catch((error) => {
      if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {

        setMessage("Please connect your device to the internet");
      } else if (error.response && error.response.data && error.response.data.message) {

        setMessage(error.response.data.message);
      } else {
        setMessage(error.message);
      }
    })
  }

  // Fonction qui va permettre des charger le city appartir de la province
  const [stateCity, setStateCity] = useState<requestRow[]>([])
  const [stateMinicipality, setStateMinicipality] = useState<requestRow[]>([])
  const GetProvince = (id: any) => {
    const IdProv = city.filter(x => x.LocationRegionId === id);
    setStateCity(IdProv)
  }

  const GetCity = (id: any) => {
    const IdCity = minicipality.filter(x => x.LocationDistricId === id);
    setStateMinicipality(IdCity)
  }

  const handleChangeIdRole = (name: string[]) => {
    const lastEnteredRegion = name[name.length - 1];
    // const filteredRegion = getDistrict.filter(x => x.LocationRegionId === lastEnteredRegion);

    if (lastEnteredRegion) {
      setGetIdRoleUnder(lastEnteredRegion);
    } else {
      setGetIdRoleUnder('');
    }
  };
  const handleChangeIdMembers = (value: any) => {
    setGetIdMembers(value);
  };
  const handleChangeIdSms = (value: any) => {
    setGetIdSms(value)
  }



  const steps = [
    {
      title: 'Your Forms',
      content: <>
        {
          formtype === "form_creater" ? (
            <>
              <div className=" p-2 form-label rounded-sm mb-2 mt-3" role="alert" style={{ backgroundColor: '#F0F3F8' }}>
                Customize your form<br />
                {data?.name}<br />
                {data?.observation}
              </div>
              <div className="row">

                {/* <div className='col-md-12 row mb-2'>
                  <div className="mb-1 col-md-6">
                    <label className="form-label">Description of the form</label>
                    <input className="form-control" type='text' placeholder='Description of the form'
                      name={data?.name}
                    />
                  </div>

                  <div className="mb-1 col-md-6">
                    <label className="form-label">Observation</label>
                    <input className="form-control" type='text' placeholder='Observation'
                      value={data?.observation}
                    />
                  </div>
                </div> */}
                <div className='col-md-12 row mb-2'>
                  <div className="mb-1 col-md-6">
                    <label className="form-label">Name<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                    <input type="text" className="form-control" placeholder="" id="fullname"
                      name='name'
                      value={name}
                      onChange={(e) => { setName(e.target.value) }}
                    />
                  </div>

                  <div className="mb-1 col-md-6">
                    <label className="form-label">Phone<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                    <PhoneInput
                      inputStyle={{
                        width: '100%',

                      } as React.CSSProperties}
                      inputClass={'form-control'}
                      country={'tz'}
                      value={phone}
                      onChange={changePhoneNumber}
                      enableSearch={true}
                      countryCodeEditable={true}
                    />
                  </div>
                </div>

                <div className="col-md-12 row">
                  {data?.settings?.map((setting: any, i: any) => (
                    <div className="col-md-6" key={i}> {/* Utilisez col-md-4 directement ici */}
                      {setting.type === 'select' && (
                        <div className="mb-1">
                          <label className="form-label">{setting.label}</label>
                          <select
                            className="form-control"
                            id={setting.name}
                            name={setting.name}
                            value={formDataForm[setting.name] || ''}
                            onChange={handleInputChange}
                          >
                            {setting.options.map((option: any, index: any) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {setting.type === 'date' && (
                        <div className="mb-1">
                          <label className="form-label">{setting.label}</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder=""
                            id={setting.name}
                            name={setting.name}
                            value={formDataForm[setting.name] || ''}
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                      {setting.type === 'file' && (
                        ""
                      )}
                      {setting.type !== 'select' && setting.type !== 'date' && setting.type !== 'file' && (
                        <div className="mb-1">
                          <label className="form-label">{setting.label}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            id={setting.name}
                            name={setting.name}
                            value={formDataForm[setting.name] || ''}
                            onChange={handleInputChange}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* {formFields.map((field: any) => (
          <div className='col-md-12 flex'>
            <FieldEditor
              key={field.id}
              field={field}
              onUpdate={updateField}
              onRemove={removeField}
            />
          </div>

        ))} */}
                <div className='col-md-12 mb-2 mt-2'>
                  {/* <label style={{color:'white'}}>vouchers</label> */}
                  <Space direction="vertical" style={{ width: '100%' }}>
                    {
                      showAlertDanger1 &&
                      <Alert type="error" message={message1} showIcon closable />
                    }
                  </Space>
                </div>

              </div>

            </>
          ) : (
            <>
              <div className="alert alert-info mt-3" role="alert">
                Before creating a member account you must make sure you know the person well, especially their
                real identity, have their photo and physical address and must be able to assume the role of
                member of Tiva products.
              </div>
              <div className="col-md-12">

                <div className="row">

                  {/* <input type="hidden" id="id" value="00" /> */}


                  <div className='col-md-12 row'>

                    <div className="mb-1 col-md-3">
                      <label className="form-label">Name<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                      <input type="text" className="form-control" placeholder="" id="fullname"
                        name='name'
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                      />
                    </div>
                    <div className="mb-1 col-md-3">
                      <label className="form-label">LastName</label>
                      <input type="text" className="form-control" placeholder="" id="fullname"
                        name='lastname'
                        value={lastname}
                        onChange={(e) => { setLastName(e.target.value) }}
                      />
                    </div>
                    <div className="mb-1 col-md-3">
                      <label className="form-label">MiddleName</label>
                      <input type="text" className="form-control" placeholder="" id="fullname"
                        name='middlename'
                        value={middlename}
                        onChange={(e) => { setMiddlename(e.target.value); }}
                      />
                    </div>
                    {/* <div className="mb-1 col-md-4">
                                <label className="form-label">Category<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                                <select className="form-control" id="categorie"
                                    name='userRoleId'
                                    value={userRoleId}
                                    onChange={(e) => { setUserRoleId(e.target.value) }}
                                >
                                    <option value="">Select category</option>
                                    {
                                        categoriRole.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))
                                    }


                                </select>
                            </div> */}

                    <div className="mb-1 col-md-3">
                      <label className="form-label">Gender<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                      <select className="form-control" id="gender"
                        name='gender'
                        value={gender}
                        onChange={(e) => { setGender(e.target.value) }}
                      >
                        <option>Choose...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="mb-1 col-md-3">
                      <label className="form-label">Date of birth</label>
                      <input type="date" className="form-control" placeholder="" id="datenaiss"
                        name='dateOfBirth'
                        value={dateOfBirth}
                        onChange={(e) => { setDateOfBirth(e.target.value) }}
                      />
                    </div>
                    <div className="mb-1  col-md-3">
                      <label className="form-label">Place of Birth</label>
                      <input type="text" className="form-control" placeholder="" id="phone"
                        name='placeOfBirth'
                        value={placeOfBirth}
                        onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                      />
                    </div>
                    <div className="mb-1  col-md-3">
                      <label className="form-label">Phone<span style={{ color: 'red', paddingLeft: 1 }}>*</span></label>
                      {/* <input type="text" className="form-control" placeholder="" id="phone"
                                    name='phone'
                                    value={phone}
                                    onChange={(e) => { setPhone(e.target.value) }}
                                /> */}
                      <PhoneInput
                        inputStyle={{
                          width: '100%',

                        } as React.CSSProperties}
                        inputClass={'form-control'}
                        country={'tz'}
                        value={phone}
                        onChange={changePhoneNumber}
                        enableSearch={true}
                        countryCodeEditable={true}
                      />
                    </div>

                    <div className="mb-1  col-md-3">
                      <label className="form-label">Adress Mail</label>
                      <input type="email" className="form-control" placeholder="" id="email"
                        name='email'
                        value={email}
                        onChange={(e) => { setEmail(e.target.value); }}
                      />
                    </div>

                    <div className="mb-1  col-md-12">
                      <label className="form-label">Address</label>
                      <textarea className="form-control" placeholder="" id="adresse"
                        name='adress'
                        value={adress}
                        onChange={(e) => { setAddress(e.target.value) }}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className='col-md-12 mb-1'>
                  {/* <label style={{color:'white'}}>vouchers</label> */}
                  <Space direction="vertical" style={{ width: '100%' }}>
                    {
                      showAlertDanger1 &&
                      <Alert type="error" message={message1} showIcon closable />
                    }
                  </Space>
                </div>

              </div>
            </>
          )
        }


      </>,
    },
    {
      title: 'select the users to whom we will send the data',
      content:
        <>
          <div className="alert alert-info mt-3" role="alert">
            Make sure you select the users to whom we will send the data.
          </div>
          <div className='row'>
            <div className='col-md-12 row'>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                {/* Ici le tree data */}
                <Tree
                  checkable
                  treeData={dataTree}
                  onCheck={onCheckRole}
                  onSelect={onSelectRole}
                />
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <p className="form-label ">Choose Member</p>
                <Select
                  mode="tags"
                  placeholder="Please select"
                  value={getIdMembers}
                  onChange={handleChangeIdMembers}
                  style={{
                    width: '100%',
                    paddingLeft: 5
                  }}

                >
                  <Option selected value="">Choose Member</Option>
                  {
                    memberUnder &&
                      memberUnder !== undefined ?
                      memberUnder.map((item, i) => (
                        <Option key={i} value={item?.User?.id}>{item?.User?.name + " " + item?.User?.lastname + " " + item?.User?.middlename}</Option>
                      ))
                      : "No Member"
                  }
                </Select>
              </div>
            </div>

          </div>
        </>,
    },

    {
      title: 'Calendar',
      content: <>
        <div className="alert alert-info mt-3" role="alert">
          Make sure you select the users to whom we will send the data.
        </div>
        <div className='row'>
          <div className='col-md-12 row'>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <p className="form-label ">Setup date ?</p>
              <DatePicker
                showTime
                onChange={handleDateChange}
                onOk={handleOkDate}
                value={selectedTime}
                style={{
                  width: '100%',
                  paddingLeft: 5
                }}
              />

            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
              <p className="form-label ">Sms planing</p>
              <Select
                mode="tags"
                placeholder="Please select"
                value={getIdSms}
                onChange={handleChangeIdSms}
                style={{
                  width: '100%',
                  paddingLeft: 5
                }}

              >
                <Option selected value="">Choose sms</Option>
                {
                  loardSms &&
                    loardSms !== undefined ?
                    loardSms.map((item, i) => (
                      <Option key={i} value={item?.id}>{item?.name}</Option>
                    ))
                    : "No sma"
                }
              </Select>
            </div>
          </div>



        </div>
        <div className='col-md-12 mb-2 mt-2'>
          {/* <label style={{color:'white'}}>vouchers</label> */}
          <Space direction="vertical" style={{ width: '100%' }}>
            {
              showAlertSucess &&
              <Alert type="success" message={message} showIcon closable />
            } {
              showAlertDanger &&
              <Alert type="error" message={message} showIcon closable />
            }
          </Space>
        </div>
        <div className="mb-3 mt-4 d-flex justify-content-end">
          <label className="form-label text-white">submit</label>
          {!Loading && (
            formtype === "form_creater" ? (
              <Button
                type='primary'
                id="btn-Withdraw"
                onClick={() => (saveForm())}
              >Save all informations </Button>
            ) : (
              <Button
                type='primary'
                id="btn-Withdraw"
                onClick={() => (saveFormExist())}
              >Save all informations </Button>
            )


          )}

          {Loading && (

            <Button type='primary'

            >
              <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span></span>
            </Button>
          )}

        </div>
      </>,
    },

  ];

  const [current, setCurrent] = useState(0);
  const next = () => {
    if (name === '' || name === undefined || name === null || phone === undefined || phone === null || phone === '') {
      setCurrent(current);
      setShowAlertDanger1(true);
      setMessage1('The name and phone fields are required to make sure to complete it with to continue');
    } else {
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));



  return (
    <Modal
      title={`${data?.name}`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={true}
      maskClosable={false}
      width={1400}
      footer={false}
    >
      {/* <Form
        form={form}
        name="identification_form"
        initialValues={formData}
      >
        {data?.settings?.map((setting: any) => (
          <Form.Item
            key={setting.id}
            name={['settings', setting.id.toString(), 'value']}
            label={setting.label}
            rules={[{ required: true, message: `Veuillez saisir ${setting.label}` }]}
          >
            {setting.type === 'select' ? (
              <Select>
                {setting.options.map((option: any) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            ) : setting.type === 'date' ? (
              <DatePicker />
            ) : setting.type === 'file' ? (
              <Upload>
                <Button icon={<InboxOutlined />}>Cliquez pour importer un fichier</Button>
              </Upload>
            ) : (
              <Input
                value={setting.value}
              // onChange={(e) => handleInputChange(setting.id, e.target.value)}
              />
            )}
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregistrer
          </Button>
        </Form.Item>
      </Form> */}



      <div>
        <Steps current={current} items={items} />
        <div >{steps[current].content}</div>
        <div style={{ marginTop: 24 }}>
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}

          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary"
            // onClick={() => message.success('Processing complete!')}
            >
              Done
            </Button>
          )}

        </div>
      </div>
    </Modal>
  );
};

export default ShowForm;