import { Alert, Button, Card, Drawer, Modal, Space } from 'antd'
import React, { useState } from 'react'
import { MdClose } from 'react-icons/md';
import DialogAddMember from '../../sellers/component/DialogAddMember';
import { FieldType, Field, FieldEditor, getName } from './Field';
import api from '../../../config/Config'

interface ComponentRegionProps {
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    load: () => void;
    title: string;
}

const CreateFormMember: React.FC<ComponentRegionProps> = ({
    isModalOpen,
    handleOk,
    handleCancel,
    load,
    title,

}) => {

    const [isShowFrom, setIsShowFrom] = useState('');

    const showForm = () => {
        setIsShowFrom('show')
    }

    const [loading, setLoading] = useState(false);
    const [formFields, setFormFields] = useState<Field[]>([]);
    const [labelField, setLabelField] = useState('');
    const [currentType, setCurrentType] = useState<FieldType>(FieldType.Text);
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [observation, setObservation] = useState("");

    const addField = (fieldType: FieldType, label: string) => {
        setFormFields([...formFields, { id: Date.now(), type: fieldType, value: '', name: getName(label), label: label, options: [] }]);
        console.log(formFields)
    };

    const updateField = (id: number, field: Partial<Field>) => {
        setFormFields(formFields.map(f => (f.id === id ? { ...f, ...field } : f)));
    };

    const removeField = (id: number) => {
        setFormFields(formFields.filter(f => f.id !== id));
    };

    const saveForm = () => {
        let data = JSON.stringify({
            "name": name,
            "observation": observation,
            "settings": formFields
        });

        setLoading(true);
        if (name === '' || formFields === null) {
            setShowAlertDanger(true);
            setMessage('Make sure you have filled in all the red star fields');
        }
        else {
            api.getHttpRequestV3(`/user/gui/create`, data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    load();
                    setShowAlertSucess(true);
                    setMessage(json.message);

                    setShowAlertSucess(true);
                    setMessage(json.message);
                    setName('')
                    setObservation('')


                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    const errorMessage = error.response.data.message;
                    setShowAlertDanger(true);
                    setMessage(errorMessage)
                }
            })
        }

        setTimeout(() => {
            setLoading(false);
            setMessage('')
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }

    return (
        <>
            <Modal
                title={`New form`}
                open={isModalOpen}
                onOk={handleOk} onCancel={handleCancel}
                closable={true}
                maskClosable={false}
                width={900}
                footer={false}
            >
                <div className=" p-2 form-label rounded-sm mb-2" role="alert" style={{ backgroundColor: '#F0F3F8' }}>
                    Customize your form

                </div>
                <div style={{
                    // height: '100%',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                }}>


                    < div >
                        {/* <span>Creating the new form</span> */}
                        <div className="row">

                            {/* <input type="hidden" id="id" value="00" /> */}
                            <div className='col-md-12 row mb-2'>
                                <div className="mb-1 col-md-6">
                                    <label className="form-label">Description of the form</label>
                                    <input className="form-control" type='text' placeholder='Description of the form'
                                        name={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                <div className="mb-1 col-md-6">
                                    <label className="form-label">Observation</label>
                                    <input className="form-control" type='text' placeholder='Observation'
                                        value={observation}
                                        onChange={(e) => setObservation(e.target.value)}
                                    />
                                </div>
                            </div>

                            <hr />

                            <div className='col-md-12 row'>

                                <div className="mb-1 col-md-5">
                                    <input className="form-control" type='text' value={labelField} onChange={(e) => setLabelField(e.target.value)} placeholder='Veuillez saisir le label' />
                                </div>
                                <div className="mb-1 col-md-5">
                                    <select
                                        className="form-control"
                                        onChange={(e: any) => setCurrentType(e.target.value)}
                                        value={currentType}
                                    >
                                        {Object.values(FieldType).map((type) => (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-1 col-md-2">
                                    <Button type='primary' onClick={() => addField(currentType, labelField)}>+</Button>
                                </div>
                            </div>




                        </div>
                        <div>

                            {formFields.map(field => (
                                <FieldEditor
                                    key={field.id}
                                    field={field}
                                    onUpdate={updateField}
                                    onRemove={removeField}
                                />
                            ))}
                        </div>

                        <div className='col-md-12 mb-1'>
                            {/* <label style={{color:'white'}}>vouchers</label> */}
                            <Space direction="vertical" style={{ width: '100%' }}>
                                {
                                    showAlertSucess &&
                                    <Alert type="success" message={message} showIcon closable />
                                } {
                                    showAlertDanger &&
                                    <Alert type="error" message={message} showIcon closable />
                                }
                            </Space>


                        </div>

                        <div className='mt-2'>
                            <Button type='primary' onClick={saveForm}>Enregistrer le formulaire</Button>
                            {/* <button onClick={loadForm}>Charger le formulaire</button> */}
                        </div>
                    </div>
                </div>

            </Modal >


        </>
    )
}

export default CreateFormMember