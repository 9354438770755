import { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import api from '../../../../config/Config';
import { useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Alert, Card, Select, Space } from "antd";
import { MdPhotoCamera } from "react-icons/md";
import { avatar1, info1 } from "../../../../components/icons";
import CustomModal from "../../../../components/modal/componentModalAvatar";

const { Option } = Select;

const InfoPerso = () => {

    const navigate = useNavigate();

    const data = '';

    const [getRegion, setGetRegion] = useState<requestManagerAccount[]>([]);
    const [getDistrict, setGetDistrict] = useState<requestManagerAccount[]>([]);
    const [getWard, setGetWard] = useState<requestManagerAccount[]>([]);

    const [Loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [gender, setGender] = useState('');
    const [_userRoleId, setUserRoleId] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [_id, setId] = useState('');
    const [region, setRegion] = useState('');
    const [district, setDistric] = useState('');
    const [ward, setWard] = useState('');
    const [wardName, setWardName] = useState('');
    const [avatarUser, setAvatarUser] = useState('');

    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');

    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [showAlertDanger1, setShowAlertDanger1] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        // if (sessionStorage.getItem("onktxonktxtourser") === null) {
        //     navigate("/home")
        // }

        LoadPrile()
        LoadRegion()
        LoadDistrict()
        LoadWard()
    }, []);

    const LoadPrile = async () => {
        api.getHttpRequest(`/user/admin/load/${api.initIdUserConnect()}`, data, 'GET').then((response: any) => {

            const json = response.data.data;

            const startDate = new Date(json.date_of_birth);
            const formattedStartDate = startDate.toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            setDateOfBirth(formattedStartDate)
            setId(json.id)
            setName(json.name);
            setMiddlename(json.middlename);
            setLastName(json.lastname);
            setEmail(json.email);
            setPhone(json.phone);
            setGender(json.gender)
            setAddress(json.address);
            setAvatarUser(json.avatar);
            setUserRoleId(json.UserRoleId);
            setRegion(json.LocationWard.LocationDistric.LocationRegion.name)
            setDistric(json.LocationWard.LocationDistric.name)
            setWardName(json.LocationWard.name)
            setWard(json.LocationWard.id)
            setPlaceOfBirth(json.place_of_birth)
        }).catch((error) => {
            api.getMessageError(error)
        })
    }

    const NewUpdateProfile = () => {
        let data = JSON.stringify({
            "name": name,
            "lastname": lastname,
            "middlename": middlename,
            "email": email,
            "address": adress,
            "phone": phone,
            "id_ward": ward,
            "avatar": "",
            "gender": gender,
            "UserRoleId": api.initIdUserConnect(),
            "civil_status": civilStatus,
            "date_of_birth": dateOfBirth,
            "place_of_birth": placeOfBirth,
            "id_partner": api.initPartnerId(),
            "default_password": '1111111',
        });

        setLoading(true);

        if (name === '' || phone === '') {
            setShowAlertDanger1(true)
            setMessage('Make sure you have filled in all the red star fields');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger1(true)
            setMessage('The entry number is incoreact');
        }
        else {
            api.getHttpRequest(`/partners/app/members/${api.initIdUserConnect()}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    LoadPrile()
                    setShowAlertSuccess(true)
                    setMessage(json.message);
                } else {
                    setShowAlertDanger1(true)
                    setMessage(json.message);
                }
            }).catch((error) => {
                setMessage(api.getMessageError(error))
            })

        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertSuccess(false)
            setShowAlertDanger1(false)
        }, 3000);
    }


    const LoadRegion = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/region/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetRegion(json.rows)

        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }
    const LoadDistrict = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/district/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetDistrict(json.rows)

        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }
    const LoadWard = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/ward/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetWard(json.rows)

        }).catch((error) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }

    const [showAdditionalInfo, setShowAdditionalInfo] = useState(true);

    const handleOptionChange = (event: any) => {
        setShowAdditionalInfo(event === false);
    };
    const handleOptionChange1 = () => {
        setShowAdditionalInfo(true)
    };

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', /* Affiche 3 cartes par ligne */
        gridGap: '2px', /* Espacement entre les cartes */
    };

    const isMobileScreen = window.innerWidth <= 768;

    // Modifier le nombre de colonnes pour les écrans de petite taille
    if (isMobileScreen) {
        gridStyle.gridTemplateColumns = 'repeat(1, 1fr)'; // Affiche 1 carte par ligne sur mobile
    }

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    const [stateDistrict, setStateDistrict] = useState<requestManagerAccount[]>([])
    const [stateWard, setStateWard] = useState<requestManagerAccount[]>([])
    const GetRegion = (id: any) => {
        const IdRegion = getDistrict.filter(x => x.LocationRegionId === id);
        setStateDistrict(IdRegion)
    }

    const GetWard = (id: any) => {
        const IdDistrict = getWard.filter(x => x.LocationDistricId === id);
        setStateWard(IdDistrict)
    }

    const [isModalOpen3, setIsModalOpen3] = useState(false);

    const showAvatarModal = () => {
        setIsModalOpen3(true);
    }

    const handleOk3 = () => {
        setIsModalOpen3(false);
    };

    const handleCancel3 = () => {
        setIsModalOpen3(false);
    };

    return (
        <>
            <div className="mt-2" style={{marginTop:20}}>
                <Card style={{ width: '100%' }}>

                    <div className=" flex p-2 justify-between items-center">

                        <div className="row align-items-center">
                            <div className="col-md-6">

                                <span className="font-25" style={{fontWeight:'bold', fontSize:16 ,fontFamily:`"Noto Sans", sans-serif`}}>Information about your profile in the Tiva system </span>

                                <p className="p-tag-1 " style={{fontFamily:`"Noto Sans", sans-serif`}}>Tiva is an innovative technology-driven company committed to providing
                                    affordable health insurance, microloans
                                </p>

                            </div>


                            <div className="col-md-6 wow slideInLeft" data-wow-delay="0.25s"
                                style={{ visibility: 'visible', animationDelay: '0.25s', animationName: 'slideInLeft' }}
                            >
                                <div className="margin-right-15">
                                    <img src={info1} className="width-100 bottom-margins-images" alt="pic" />
                                </div>
                            </div>

                        </div>

                        {
                            showAdditionalInfo && (
                                <>
                                    <div className="col-md-12 mt-3">
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                border: "1px solid #F0F0F0",
                                                padding: 5,
                                                borderRadius: 5,
                                                justifyContent: "center",

                                                margin: "0 auto"
                                            }}
                                        >
                                            <div onClick={() => showAvatarModal()} style={{ display: "flex", alignItems: "center", cursor: 'pointer' }}>
                                                <div style={{ flex: "1" }}>
                                                    <h2 style={{

                                                        fontSize: "24px",
                                                        marginBottom: "10px",
                                                        fontFamily:`"Noto Sans", sans-serif`
                                                    }}>General Information</h2>
                                                    <div style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                        Your profile picture
                                                    </div>
                                                </div>
                                                <div className="avatar-container" style={{ position: 'relative', display: 'inline-block' }}>
                                                    <MdPhotoCamera
                                                        style={{
                                                            position: 'absolute',
                                                            fontSize: 30,
                                                            bottom: 0,
                                                            right: 0,
                                                            marginRight: 8,
                                                            marginBottom: -8,
                                                            zIndex: 900,
                                                            color:'#1576ED'
                                                        }}

                                                    />
                                                    <img
                                                        src={`${avatarUser === "default.png" ? `${avatar1}` : `${api.var_.url_avatar + avatarUser}`}`}
                                                        style={{ width: 60, height: 60, borderRadius: 50, marginRight: 10 }}
                                                        className="avatar-image"
                                                    />
                                                </div>

                                            </div>


                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px",fontFamily:`"Noto Sans", sans-serif`}}>
                                                            FullName
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px" }}>
                                                            {name + ' ' + lastname + ' ' + middlename}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>

                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px",fontFamily:`"Noto Sans", sans-serif` }}>
                                                            Civil status
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px" }}>
                                                            {civilStatus}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20, fontFamily:`"Noto Sans", sans-serif` }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>

                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            Date of birth
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            {dateOfBirth}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>

                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px",fontFamily:`"Noto Sans", sans-serif`}}>
                                                            Place of Birth
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px",fontFamily:`"Noto Sans", sans-serif` }}>
                                                            {placeOfBirth}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 ,fontFamily:`"Noto Sans", sans-serif`}}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>


                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1px" }}>

                                                        <div style={{ marginBottom: "10px" , fontFamily:`"Noto Sans", sans-serif`}}>
                                                            Gender
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            {gender}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                border: "1px solid #F0F0F0",
                                                padding: 5,
                                                borderRadius: 5,
                                                justifyContent: "center",

                                                margin: "0 auto"
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ flex: "1" }}>
                                                    <h2 style={{

                                                        fontSize: "24px",
                                                        marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif`
                                                    }}>Coordinates</h2>

                                                </div>

                                            </div>


                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            E-mail
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif`}}>
                                                            {email}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            Phone
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            {phone}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>



                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                border: "1px solid #F0F0F0",
                                                padding: 5,
                                                borderRadius: 5,
                                                justifyContent: "center",

                                                margin: "0 auto"
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ flex: "1" }}>
                                                    <h2 style={{

                                                        fontSize: "24px",
                                                        marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif`
                                                    }}>Address</h2>

                                                </div>

                                            </div>


                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            Region
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            {region}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            District
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            {district}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>

                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            Ward/Kata
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            {wardName}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>

                                            <div style={{ borderTop: "1px solid #F0F0F0", paddingTop: "5px" }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ flex: "1" }}>

                                                        <div style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            Full Adress
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "1" }}>

                                                        <p style={{ marginBottom: "10px", fontFamily:`"Noto Sans", sans-serif` }}>
                                                            {adress}
                                                        </p>
                                                    </div>
                                                    <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                        <FaChevronRight />
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <div
                                            style={{
                                                backgroundColor: "#E5F1FB",
                                                border: "1px solid #F0F0F0",
                                                padding: 5,
                                                borderRadius: 5,
                                                justifyContent: "center",
                                                cursor: 'pointer',
                                                margin: "0 auto"
                                            }}
                                            onClick={handleOptionChange}
                                        >
                                            <div style={{ display: "flex", alignItems: "center",fontFamily:`"Noto Sans", sans-serif` }}>
                                                <div style={{ flex: "1" }}>
                                                    <h2 style={{

                                                        fontSize: "24px",
                                                        marginBottom: "5px",
                                                    }}>Update Information</h2>

                                                </div>
                                                <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                    <MdEdit style={{ fontSize: 22 }} />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </>
                            )
                        }



                        {
                            showAdditionalInfo === false && (
                                <>


                                    <div className="col-md-12">
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                border: "1px solid #F0F0F0",
                                                padding: 5,
                                                borderRadius: 5,
                                                justifyContent: "center",

                                            }}
                                        >
                                            {
                                                message && (
                                                    <>
                                                        <div className='col-md-12 mb-1 mt-1'>
                                                            {/* <label style={{color:'white'}}>vouchers</label> */}
                                                            <Space direction="vertical" style={{ width: '100%' }}>
                                                                {
                                                                    showAlertSuccess &&
                                                                    <Alert type="success" message={message} showIcon closable />
                                                                }
                                                                {
                                                                    showAlertDanger1 &&
                                                                    <Alert type="error" message={message} showIcon closable />
                                                                }
                                                            </Space>
                                                        </div>
                                                    </>
                                                )
                                            }

                                            <form method="post" role="form" >
                                                <input type="hidden" name="_token" value="pJzoCSLX89w5AgZulIazt4TlMMj1gpriu2ZTkoG4" />
                                                <div style={gridStyle}>

                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">Name</label>
                                                        <input type="text" className="form-control" id="newConfirm" placeholder=""
                                                            name='name'
                                                            value={name}
                                                            onChange={(e) => { setName(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">LastName</label>
                                                        <input type="text" className="form-control" id="newConfirm" placeholder=""
                                                            name='lastname'
                                                            value={lastname}
                                                            onChange={(e) => { setLastName(e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">MiddleName</label>
                                                        <input type="text" className="form-control" id="newConfirm" placeholder=""
                                                            name='middlename'
                                                            value={middlename}
                                                            onChange={(e) => { setMiddlename(e.target.value); }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">Gender</label>

                                                        <select className="form-control" id="gender"
                                                            name='gender'
                                                            value={gender}
                                                            onChange={(e) => { setGender(e.target.value) }}
                                                        >
                                                            <option>Choose...</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">Civil Status</label>
                                                        <select className="form-control" id="gender"
                                                            name='civilStatus'
                                                            value={civilStatus}
                                                            onChange={(e) => { setCivilStatus(e.target.value) }}
                                                        >
                                                            <option>Choose...</option>
                                                            <option value="Married">Married</option>
                                                            <option value="Single">Single</option>
                                                            <option value="Widow">Widow</option>
                                                            <option value="Widower">Widower</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">Date of birth</label>
                                                        <input type="date" className="form-control" placeholder="" id="datenaiss"
                                                            name='dateOfBirth'
                                                            value={dateOfBirth}
                                                            onChange={(e) => { setDateOfBirth(e.target.value) }}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">Place of Birth</label>
                                                        <input type="text" className="form-control" placeholder="" id="phone"
                                                            name='placeOfBirth'
                                                            value={placeOfBirth}
                                                            onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">Phone</label>
                                                        <PhoneInput
                                                            inputStyle={{
                                                                width: '100%',

                                                            } as React.CSSProperties}
                                                            inputClass={'form-control'}
                                                            country={'tz'}
                                                            value={phone}
                                                            onChange={changePhoneNumber}
                                                            enableSearch={true}
                                                            countryCodeEditable={true}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">Adress Mail</label>
                                                        <input type="email" className="form-control" placeholder="" id="email"
                                                            name='email'
                                                            value={email}
                                                            onChange={(e) => { setEmail(e.target.value); }}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">Region</label>
                                                        <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                                            className="form-control"
                                                            placeholder="Select country" optionFilterProp="children" value={region}
                                                            onChange={(value: any) => {
                                                                GetRegion(value)
                                                                setRegion(value)
                                                            }}
                                                            filterOption={(input: string, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }>
                                                            <Option selected value="">Select Region
                                                            </Option>
                                                            {
                                                                getRegion &&
                                                                    getRegion !== undefined ?
                                                                    getRegion.map((item, i) => (
                                                                        <Option key={i} value={item.id}>{item.name}</Option>
                                                                    ))
                                                                    : "No Province"
                                                            }
                                                        </Select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">District</label>
                                                        <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 200 }}
                                                            className="form-control"
                                                            placeholder="Select country" optionFilterProp="children" value={district}
                                                            onChange={(value: any) => {
                                                                GetWard(value)
                                                                setDistric(value)
                                                            }}
                                                            filterOption={(input: string, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            <Option selected value="">Select district
                                                            </Option>
                                                            {
                                                                stateDistrict &&
                                                                    stateDistrict !== undefined || stateDistrict !== null ?
                                                                    stateDistrict.map((item, i) => (
                                                                        <Option key={i} value={item.id}>{item.name}</Option>
                                                                    ))
                                                                    : "No City"
                                                            }
                                                        </Select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="newConfirm">Ward/Kata</label>
                                                        <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                                            className="form-control"
                                                            placeholder="Select minicipality" optionFilterProp="children" value={wardName}
                                                            onChange={(value: any) => {
                                                                setWard(value)
                                                                setWardName(value)

                                                            }}
                                                            filterOption={(input: string, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }>
                                                            <Option selected value="">Select ward/kata
                                                            </Option>
                                                            {
                                                                stateWard &&
                                                                    stateWard !== undefined || stateWard !== null ?
                                                                    stateWard.map((item, i) => (
                                                                        <Option key={i} value={item.id}>{item.name}</Option>
                                                                    ))
                                                                    : "No minicipality"
                                                            }
                                                        </Select>
                                                    </div>


                                                </div>


                                                {!Loading && (
                                                    <button type="button" id="btn_update" className="btn btn-default btn-submit" onClick={() => NewUpdateProfile()}
                                                    style={{backgroundColor:'#1576ED'}}
                                                    >Update profile</button>
                                                )}

                                                {Loading && (

                                                    <button type="button" id="btn_update" className="btn btn-primary agree-link" style={{backgroundColor:'#1576ED'}} disabled>
                                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Processing ...</span>
                                                    </button>
                                                )}
                                            </form>
                                        </div>
                                    </div>


                                    <div className="col-md-12 mt-3">
                                        <div
                                            style={{
                                                backgroundColor: "#E5F1FB",
                                                border: "1px solid #F0F0F0",
                                                padding: 5,
                                                borderRadius: 5,
                                                justifyContent: "center",
                                                cursor: 'pointer',
                                                margin: "0 auto"
                                            }}
                                            onClick={handleOptionChange1}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ flex: "1" }}>
                                                    <h2 style={{

                                                        fontSize: "24px",
                                                        marginBottom: "5px"
                                                    }}>Exit to View profile</h2>

                                                </div>
                                                <div style={{ marginLeft: "20px", marginRight: 20 }}>
                                                    <MdEdit style={{ fontSize: 22 }} />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </>

                            )
                        }



                    </div>

                </Card>
            </div>



            {/* < Modal
                title={`Update Avatar`}
                open={isModalOpen3} onOk={handleOk3} onCancel={handleCancel3}
                footer={null}
                width={400}
            >
                <div>
                    <div className=" p-2 form-label rounded-sm" role="alert" style={{ backgroundColor: '#F0F3F8' }}>
                        You're about to change <strong>{name}'s</strong> avatar
                    </div>

                    {
                        message && (
                            <>
                                <div className='col-md-12 mb-1 mt-1'>
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        {
                                            showAlertDanger1 &&
                                            <Alert type="error" message={message} showIcon closable />
                                        }
                                    </Space>
                                </div>
                            </>
                        )
                    }

                    <div className="basic-form" style={{ justifyItems: 'center' }}>

                        <div className="row mt-2 ml-20">

                            <div className='col-md-12 '>
                                <div className="pic-holder ">
                                    <img style={{ width: 250, height: 250, borderRadius:'100%'}} id="profilePic"
                                        className="pic"
                                        src={selectedImage || avatar1}
                                        onClick={() => fileInputRef.current.click()}
                                    />

                                    <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{ opacity: 0 }}
                                        ref={fileInputRef}
                                        onChange={handleImageChange}
                                    />

                                </div>
                            </div>

                            <div id="infos"></div>
                            <div className="form-group col-md-6">
                                <label htmlFor="email" className="form-label" style={{ color: '#fff' }}>.</label>

                                {!Loading && (
                                    <button type="button" id="btn_update" className="btn btn-primary agree-link" >Update Avatar</button>
                                )}

                                {Loading && (

                                    <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span></span>
                                    </button>
                                )}
                            </div>

                        </div>

                    </div>

                </div>
            </Modal > */}

            <CustomModal
                title={`Update Avatar`}
                isModalOpen={isModalOpen3}
                handleOk={handleOk3}
                handleCancel={handleCancel3}
                name={name}
                LoadPrile={LoadPrile}
            // Autres props nécessaires pour le contenu de la modal
            />

        </>
    )
}

export default InfoPerso