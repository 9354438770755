import { Link, useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import {
    Card, Badge,
    Button,
    Spin,
    Space,
    Alert,

} from "antd";
import { useEffect, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import requestTopay from "../../../controller/requestTopay";
import requestRow from "../../../controller/RequestRow";
import Swal from "sweetalert2";
import React from "react";
import { LoadingOutlined } from '@ant-design/icons';

const MakeVendorPayment = () => {

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();

    const data = '';
    const [Loading, setLoading] = useState(false);
    const [soldeSendTo, setSoldeSendTo] = useState('');
    const [totalSoldeVouchers, setTotalSoldeVouchers] = useState('');
    const [totalVouchersInStock, setTotalVouchersInStock] = useState('');
    const [countUsed, setCountUsed] = useState('');
    const [numberOfSerieAssigned, setNumberOfSerieAssigned] = useState('');
    const [paymentMethod, setPaymentMethod] = useState<requestTopay[]>([]);
    const [loadConstant, setLoadConstant] = useState<requestRow[]>([]);
    const [phone, setPhone] = useState('');
    const [operator, setOperator] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [icon, setIcon] = useState('');
    const [totalVouchers, setTotalVouchers] = useState('');
    const [totalVouchersUsed, setTotalVouchersUsed] = useState('')
    const [totalVouchersAvailable, setTotalVouchersAvailable] = useState('')
    const [totalUsedPinsBalance, setTotalUsedPinsBalance] = useState('')
    const [totalUsedPinsAvailableBalance, setTotalUsedPinsAvailableBalance] = useState('')
    const [totalAmountCollected, setTotalAmountCollected] = useState('')
    const [totalAmountCollectedNow, setTotalAmountCollectedNow] = useState('')
    const [allSeries, setAllSeries] = useState<requestTopay[]>([])
    const isMobileScreen = window.innerWidth <= 768;
    //Payment variables
    const [payId, setPayId] = useState('');
    const [valuePay, setValuePay] = useState('');

    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [message, setMessage] = useState("");

    // const [phone_, setPhone] = useState(paymentMethod[0]?.phone);
    // const [operator_, setOperator] = useState(paymentMethod[0]?.MobileOperator.name); 
    // const [selectedItem, setSelectedItem] = useState(paymentMethod[0]);

    // function getId(item: any) {
    //     setActiveIndex(item.id);
    //     setSelectedItem(item);
    //   }
    useEffect(() => {
        if (sessionStorage.getItem("onktxonktx") === null) {
            navigate("/error")
        }

        loadToPay();
        LoadConstant()
        // loadToPay1()
    }, [])

    // function loadToPay() {
    //     api.getHttpRequest(`/partners/load-to-pay`, data, 'GET').then((response) => {

    //         const json = response.data;
    //         setSoldeSendTo(json.data.infos_vendor.solde_send_to);
    //         setTotalSoldeVouchers(json.data.infos_vendor.total_solde_vouchers);
    //         setTotalVouchersInStock(json.data.infos_vendor.total_vouchers_in_stock);
    //         setCountUsed(json.data.infos_vendor.count_used);
    //         setNumberOfSerieAssigned(json.data.infos_vendor.number_of_serie_assigned)
    //         setPaymentMethod(json.data.payment_method);
    //         if (json.data.payment_method.length !== 0) {
    //             setPhone(json.data.payment_method[0].phone);
    //             setOperator(json.data.payment_method[0].MobileOperator.name);
    //             setIcon(json.data.payment_method[0].MobileOperator.icon);
    //             setPayId(json.data.payment_method[0].id);
    //         }
    //         setValuePay(json.data.infos_vendor.solde_send_to);

    //     }).catch((error) => {
    //         api.getMessageError(error)
    //     })
    // }

    const loadToPay = async () => {
        api.getHttpRequest(`/vouchers/prevision-payment`, data, 'GET').then((response) => {

            const json = response.data.data;
            setTotalVouchers(json.total_vouchers)
            setTotalVouchersUsed(json.total_vouchers_used)
            setTotalVouchersAvailable(json.total_vouchers_available)
            setTotalUsedPinsBalance(json.total_used_pins_balance)
            setTotalUsedPinsAvailableBalance(json.total_used_pins_available_balance)
            setTotalAmountCollected(json.total_amount_collected)
            setTotalAmountCollectedNow(json.total_amount_collected_now)
            setValuePay(json.total_amount_collected_now)
            setPaymentMethod(json.method_payement)
            if (json.method_payement.length !== 0) {
                const defaultPaymentMethod = json.method_payement.find((method: any) => method.isDefault === true);

                if (defaultPaymentMethod) {
                    setPhone(defaultPaymentMethod.phone);
                    setOperator(defaultPaymentMethod.MobileOperator.name);
                    setIcon(defaultPaymentMethod.MobileOperator.icon);
                    setPayId(defaultPaymentMethod.id);
                }
            }
            setAllSeries(json.all_series)
        }).catch((error) => {
            api.getMessageError(error)
        })
    }

    const LoadConstant = async () => {
        api.getHttpRequest("/parameters/constant", data, 'GET').then((response) => {
            const json = response.data;
            setLoadConstant(json.data.rows)

        }).catch((error) => {
            api.getMessageError(error)
        })
    }

    const getConstant = loadConstant.find((c) => c.name === 'currency')?.value;

    function getId(element: any) {
        setActiveIndex(element.id);
        setPhone(element.phone)
        setOperator(element.MobileOperator.name)
        setPayId(element.id)
        setIcon(element.MobileOperator.icon)
    }

    const [chargement, setChargement] = useState(false);

    const handleBoutonClick = () => {
        setChargement(true);
        setTimeout(() => {
            setChargement(false);
        }, 60000);
    };

    {/**Button to pay */ }
    let isPaymentInProgress = false;
    const paymentbtn = async () => {
        let data = JSON.stringify({
            "amount": valuePay,
            "currency": "TZS",
            "phone": phone,
            "type": "PAYMENT",
            "isFor": "PAY FROM VENDOR"
        });
        if (isPaymentInProgress) {
            return;
        }
        isPaymentInProgress = true;
        setLoading(true);

        try {
            const response = await api.getHttpRequest("/mobile-money/create-receive", data, 'POST');
            const json = response.data;

            if (json.status === 200) {
                setChargement(true);
                setTimeout(() => {
                    setChargement(false);
                }, 60000);

                await new Promise(resolve => setTimeout(resolve, 60000));
                navigate('/payment-history')

                loadToPay();
            } else {
                navigate('/payment-history')
                toast.error(json.message);
            }
            setLoading(false);
        } catch (error: any) {

            if (error.response && error.response.data && error.response.data.message) {
                const errorMessage = error.response.data.message;
                setShowAlertDanger(true);
                setMessage(errorMessage);
            }
        }

        setTimeout(() => {
            setLoading(false);
            setMessage('')
            setShowAlertDanger(false);
            isPaymentInProgress = false;
        }, 5000);
    };

    // const Add_Payement = () => {
    //     let data = JSON.stringify({
    //         "amount": amount,
    //         "currency": "TZS",
    //         "phone": phone,
    //         "type": title,
    //         "isFor": "PAY FROM VENDOR"
    //     });

    //     setLoading(true);
    //     if (amount === '' || phone === '' || title === '') {
    //         setShowAlertDanger(true);
    //         setMessage('Make sure you have filled in all the red star fields');
    //     } else if (!regexTelephone.test(phone)) {
    //         setShowAlertDanger(true);
    //         setMessage('The entry number is incoreact');
    //     }
    //     else {
    //         api.getHttpRequest(`/mobile-money/create-receive`, data, 'POST').then((response) => {
    //             const json = response.data;
    //             if (json.status == 200) {
    //                 setShowAlertSucess(true);
    //                 setMessage(json.message);
    //                 Swal.fire({
    //                     position: "center",
    //                     icon: "success",
    //                     title: json.message,
    //                     showConfirmButton: false,
    //                     timer: 3000
    //                   });
    //                   handleCancel1();
    //             } else {
    //                 setShowAlertDanger(true);
    //                 setMessage(json.message);
    //             }
    //         }).catch((error) => {
    //             setShowAlertDanger(true);
    //             setMessage(api.getMessageError(error))
    //         })

    //     }

    //     setTimeout(() => {
    //         setLoading(false);
    //         setShowAlertDanger(false);
    //         setShowAlertSucess(false);
    //     }, 3000);
    // }

    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const toggleShowMore1 = () => {
        setShowMore(showMore);
    };



    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className='card-header d-block d-sm-flex border-0 py-0 px-3 mb-4'>
                        <div>
                            <h2 className="header-title pb-0 font-30 border-none">Make payment
                            </h2>
                            <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 1 }}>
                                <label style={{
                                    backgroundColor: 'red',
                                    height: 20,
                                    width: 6,
                                    borderRadius: 10
                                }}></label>
                                <p style={{ paddingLeft: 2 }}>Select your payment method</p>
                            </div>
                        </div>
                        {/* <div className='card-action card-tabs mt-3 mt-sm-0 d-flex'>
                            <div className='ml-2' role='search'>

                                <div
                                    style={{ marginBottom: '5px', textAlign: 'right', color: 'blue' }}
                                >
                                    <Link
                                        style={{ textDecoration: 'underline', color: '#3d8ef7' }}
                                        to={'/payment-history'}
                                    >
                                        View payment history
                                    </Link>
                                </div>
                            </div>
                        </div> */}
                        <div className="mb-2 ml-2 d-block d-sm-flex border-0">
                            <div className="card-action card-tabs  mt-sm-0">
                                <ul className="nav nav-tabs" role="tablist">

                                    <li className="nav-item">
                                        <Link className="nav-link " id="Monthly_" to="/vendor"
                                            role="tab">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" id="Weekly_" to="/pay"
                                            role="tab">Pay</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " id="Weekly_" to="/payment-history"
                                            role="tab">Payment history</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">

                            <Card style={{ width: '100%' }}>

                                <div className="table-responsive-sm">
                                    <table className="table table-striped">
                                        <thead className="bg-blue-200 text-black" style={{ color: 'black' }}>

                                            <tr>
                                                <th className=" border">Serial Assign</th>
                                                <th className="right border">Pins</th>
                                                <th className="center border">Sales Details</th>
                                                <th className="right border">Total amount to be paid already collected </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="left strong border-right">
                                                    <div style={{ display: 'flex', marginBottom: 15 }}>
                                                        <label style={{
                                                            backgroundColor: '#4920E9',
                                                            height: 30,
                                                            width: 10,
                                                            borderRadius: 10
                                                        }}></label>
                                                        <div style={{ marginLeft: 10 }}>


                                                            {allSeries.slice(0, 2).map((series, index) => (
                                                                <div key={index}>
                                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}
                                                                        className="font-16"> {series.reference}</strong>
                                                                    <p>{api.formatDate(series.createdAt)}<br />{api.geDateFormat(series.createdAt)}</p>
                                                                </div>
                                                            ))}

                                                            {
                                                                allSeries.length > 0 && (
                                                                    <>
                                                                        {!showMore && (
                                                                            <button onClick={toggleShowMore} style={{ backgroundColor: '#4920E9', color: 'white' }}>View All serie</button>
                                                                        )}
                                                                    </>
                                                                )
                                                            }


                                                            {showMore &&
                                                                allSeries.map((series, index) => (
                                                                    <div key={index}>
                                                                        <strong style={{ marginBottom: 1, fontSize: 16 }}
                                                                            className="font-16"> {series.reference}</strong>
                                                                        <p>{api.formatDate(series.createdAt)}<br />{api.geDateFormat(series.createdAt)}</p>
                                                                    </div>
                                                                ))}


                                                        </div>

                                                        <Badge count={'i'} size="small" style={{ backgroundColor: '#0B6AF8', marginTop: 4, marginLeft: 15 }} />


                                                    </div>
                                                </td>
                                                <td className="right border-right">
                                                    <div className="col-12 p-0 ">
                                                        <div style={{ display: 'flex', marginBottom: 15 }}>
                                                            <label style={{
                                                                backgroundColor: '#4920E9',
                                                                height: 25,
                                                                width: 10,
                                                                borderRadius: 10
                                                            }}></label>
                                                            <span className="font-weight-bold ml-1">

                                                                Assigned:</span> <span className="ml-1"> {totalVouchers}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 p-0 ">
                                                        <div style={{ display: 'flex', marginBottom: 15 }}>
                                                            <label style={{
                                                                backgroundColor: 'red',
                                                                height: 25,
                                                                width: 10,
                                                                borderRadius: 5
                                                            }}>

                                                            </label>
                                                            <span className="font-weight-bold font-16  ml-1">

                                                                Used:</span> <span className="ml-1 font-16 ml-2" style={{ color: 'red' }}> {totalVouchersUsed}</span>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 p-0 mb-1">
                                                        <div style={{ display: 'flex', marginBottom: 15 }}>
                                                            <label style={{
                                                                backgroundColor: 'green',
                                                                height: 25,
                                                                width: 10,
                                                                borderRadius: 5,

                                                            }}>

                                                            </label>
                                                            <span className="font-weight-bold font-16 ml-1">

                                                                Available:</span> <span className="font-16 ml-2" style={{ color: 'green' }}> {totalVouchersAvailable}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="center border-right">
                                                    <div className="col-12 p-0 ">
                                                        <div style={{ display: 'flex', marginBottom: 15 }}>

                                                            <span className="font-weight-bold ml-1 font-16">

                                                                Used Pins Balance:</span> <span className="ml-1 font-16">{getConstant}. {totalUsedPinsBalance}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 p-0 ">
                                                        <div style={{ display: 'flex', marginBottom: 15 }}>

                                                            <span className="font-weight-bold ml-1 font-18">

                                                                Unused Pins Balance:</span> <span className="ml-1 font-16">{getConstant}. {totalUsedPinsAvailableBalance}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="center border-right"> <span className="font-24">{getConstant}. {totalAmountCollected ? totalAmountCollected : 0}</span></td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6 ml-auto">
                                        <table className="table table-clear">
                                            <tbody>
                                                <tr>
                                                    <td className="left">
                                                        <h6 className="mb-0">To Pay</h6>
                                                        <strong className="text-dark-1 font-18">Subtotal</strong>
                                                    </td>
                                                    <td className="right">
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ marginLeft: 10 }}>
                                                                <label style={{
                                                                    backgroundColor: '#0B6AF8',
                                                                    color: '#ffffff', padding: 5, fontSize: 24, borderRadius: 5
                                                                }}>{getConstant}.{totalAmountCollectedNow}
                                                                </label>

                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td className="left">
                                                        <h4 className=" font-18 text-secondary">Payment mode</h4>
                                                        <div className="payment-mode-option">
                                                            <ul className="nav nav-pills mb-1 mt-3" id="pills-tab" role="tablist">
                                                                {paymentMethod.map((item, index) => (
                                                                    <li key={index} className="nav-item">
                                                                        <a
                                                                            className={`nav-link ${index === activeIndex ? "active" : ""}`}
                                                                            id={item.id}
                                                                            data-toggle="pill"
                                                                            href={`#${item.id}`}
                                                                            role="tab"
                                                                            aria-controls={item.id}
                                                                            aria-selected={paymentMethod[0] ? "true" : "false"}
                                                                            onClick={() => getId(item)}
                                                                        >
                                                                            {item.MobileOperator.name}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            <div className="tab-pane show" id="pills-profile" role="tabpanel" aria-labelledby="m-pesa-01">
                                                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="tigo-pesa-1">
                                                                    {/* <form action="">
                                                                <label htmlFor="nom" className="form-label ">Your Phone Number</label>
                                                                <input type="text" value={`${phone}-${operator}`} className="default-select form-control  font-20 text-dark-1" placeholder="Phone nun" />
                                                                <button type="button" className="btn btn-primary btn-lg btn-block mt-3">Pay</button>

                                                            </form> */}
                                                                    <div className="row col-md-12">

                                                                        <div className="col-md-7">
                                                                            <label htmlFor="nom" className="form-label ">Your Phone Number</label>
                                                                            {/* <input type="text" value={`${phone}-${operator}`} className="default-select form-control  font-20 text-dark-1" placeholder="Phone nun" /> */}
                                                                            <div className="d-flex align-items-center p-1 rounded border border-primaryx">

                                                                                <img className="me-3" width="49" height="49" style={{ borderRadius: 10 }} src={icon && icon ? icon : `${phone}`} />
                                                                                <div>
                                                                                    <p className="text-primary font-weight-bold fs-14 mb-0 ml-1">{operator}</p>
                                                                                    <span className="text-primary font-weight-bold font-w600 ml-1">{phone && phone ? phone : '+1'}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-5 " >
                                                                            <label htmlFor="nom" className="form-label ">Amount</label>
                                                                            <div className="input-group d-flex" style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                                                                <span className="input-group-text" style={{ fontWeight: 'bold' }}>{getConstant}</span>
                                                                                <input type="number" value={valuePay} onChange={(e) => setValuePay(e.target.value)}
                                                                                    className="default-select form-control font-20 text-dark-1 border border-primary"
                                                                                    disabled style={{ height: 55, width: '100%' }} placeholder="Amount" />
                                                                            </div>


                                                                        </div>

                                                                        {
                                                                            message && (
                                                                                <div className='col-md-12 mt-3'>
                                                                                    {/* <label style={{color:'white'}}>vouchers</label> */}
                                                                                    <Space direction="vertical" style={{ width: '100%' }}>
                                                                                        {
                                                                                            showAlertDanger &&
                                                                                            <Alert type="error" message={message} showIcon closable />
                                                                                        }
                                                                                    </Space>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {!Loading && (
                                                                            <button type="button" className="btn btn-primary btn-lg btn-block mt-3" onClick={paymentbtn}>Pay</button>
                                                                        )}

                                                                        {Loading && (

                                                                            <button type="button" className="btn btn-primary btn-lg btn-block mt-3" disabled>
                                                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                    <>
                                                                        <div>
                                                                            {/* <Button onClick={handleBoutonClick}>Ouvrir en plein écran</Button> */}
                                                                            {chargement && (
                                                                                <div
                                                                                    style={{
                                                                                        position: 'fixed',
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                        right: 0,
                                                                                        bottom: 0,
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center',
                                                                                        background: 'rgba(0, 0, 0, 0.7)',
                                                                                        zIndex: 9999,
                                                                                    }}
                                                                                >
                                                                                    <div style={{
                                                                                        display: 'row',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center',
                                                                                    }}>
                                                                                        <Spin indicator={<LoadingOutlined />} />
                                                                                        <div style={{ color: '#1576ED', marginLeft: isMobileScreen ? '20px' : '-120px', fontWeight: 'bold' }}>
                                                                                            The popup has just opened on your phone,<br /> please enter your password for payment.
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                </div>

                            </Card>
                        </div>


                    </div>

                </div>
                <Footer />
            </div>
        </div>
    )
}

export default MakeVendorPayment