import {
  Avatar,
  Button,
  Checkbox,
  Collapse,
  CollapseProps,
  Descriptions,
  Form,
  Input,
  Select,
  Tag,
} from "antd";
import { ResponseCustomer } from "../../../tantack/detailCustomer/customer";
import { formattedDate } from "../../../utils/filterData";
import dayjs from "dayjs";
// import SignatureCanvas from "react-signature-canvas";

const CustomerDetailComp = ({ data }: { data: ResponseCustomer }) => {
  const items: CollapseProps["items"] = [
    {
      key: "1Account Information",
      label: <label style={{ fontWeight: "bold" }}>Account Information</label>,
      children: (
        <>
          <div className="" style={{ marginLeft: "28px" }}>
            <Form
              layout="vertical"
              // initialValues={initialValues}
            >
              <div className="row">
                <div className="col" style={{ marginRight: "20px" }}>
                  <Descriptions
                    title="ACCOUNT DETAILS"
                    size="small"
                    column={1}
                    items={[]}
                  />
                  <div>
                    {data?.data?.national_id ? (
                      <Form.Item name="nida" label="Connect NIDA Number">
                        <Input
                          readOnly
                          style={{ width: "630px" }}
                          placeholder="Connect NIDA Number"
                          defaultValue={data?.data?.national_id}
                        />
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    {data?.data?.name ? (
                      <Form.Item name="name" label="First Name">
                        <Input
                          readOnly
                          style={{ width: "630px" }}
                          placeholder="First Name"
                          defaultValue={data?.data?.name}
                        />
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    <div style={{ width: "100%", display: "flex" }}>
                      {data?.data?.middlename ? (
                        <Form.Item name="middlename" label="Middle Name">
                          <Input
                            readOnly
                            defaultValue={data?.data?.middlename}
                            style={{ width: "310px", marginRight: 10 }}
                            placeholder="Middle Name"
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                      {data?.data?.lastname ? (
                        <Form.Item name="lastname" label="Last Name">
                          <Input
                            readOnly
                            defaultValue={data?.data?.lastname}
                            style={{ width: "310px" }}
                            placeholder="Last Name"
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                    </div>

                    <div style={{ width: "100%", display: "flex" }}>
                      {data?.data?.gender ? (
                        <Form.Item name="gender" label="Gender">
                          <Input
                            readOnly
                            style={{ width: "250px", marginRight: 10 }}
                            placeholder="Connect NIDA Number"
                            defaultValue={data?.data?.gender}
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                      {data?.data?.civil_status ? (
                        <Form.Item name="civil_status" label="Mariage Status">
                          <Input
                            readOnly
                            style={{ width: "250px", marginRight: 10 }}
                            // placeholder="Connect NIDA Number"
                            defaultValue={data?.data?.civil_status}
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                    </div>

                    <div style={{ width: "100%", display: "flex" }}>
                      {data?.data?.national_id ? (
                        <Form.Item name="national_id" label="National ID">
                          <Input
                            defaultValue={data?.data?.national_id}
                            readOnly
                            style={{ width: "310px", marginRight: 10 }}
                            placeholder="National ID #"
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                      {data?.data?.id_type ? (
                        <Form.Item name="id_type" label="ID Type">
                          <Input
                            readOnly
                            defaultValue={data?.data?.id_type}
                            style={{ width: "310px" }}
                            placeholder="ID Type #"
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                    </div>
                    {data?.data?.id_front ? (
                      <div style={{ width: "100%", display: "flex" }}>
                        <Form.Item
                          name="identity_card"
                          label="National ID Photo"
                        >
                          <div className="form-group col-md-12">
                            <div id="national-Id-1" className="hide">
                              <div className="row">
                                <div className="form-group col-md-12">
                                  <div
                                    className="row pt-4"
                                    style={{ display: "flex" }}
                                  >
                                    <div className="col-md-6">
                                      <span>ID Front</span>
                                      <div>
                                        <img
                                          className="img-fluid"
                                          src="/assets/images/access.png"
                                          alt="Default Image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <div style={{ width: "100%", display: "flex" }}>
                      <Form.Item
                        name="identity_card_back"
                        label="Back of national ID Photo"
                      >
                        <div className="form-group col-md-12">
                          <div id="national-Id-1" className="hide">
                            <div className="row">
                              <div className="form-group col-md-12">
                                <div
                                  className="row pt-4"
                                  style={{ display: "flex" }}
                                >
                                  <div className="col-md-6">
                                    <span>ID Back</span>
                                    <div>
                                      <img
                                        className="img-fluid"
                                        // src={
                                        //   data?.data.user_infos.id_front
                                        //     ? `${
                                        //         import.meta.env.VITE_FILE_URL
                                        //       }${data?.data.user_infos.id_back}`
                                        //     : default_card_back
                                        // }
                                        alt="Default Image"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form.Item>
                    </div> */}

                    <div style={{ width: "100%", display: "flex" }}>
                      {data?.data?.date_of_birth ? (
                        <Form.Item name="date_of_birth" label="Age">
                          <Input
                            name="date_of_birth"
                            // type="date"
                            readOnly
                            defaultValue={dayjs(
                              data?.data?.date_of_birth
                            ).format("MMM D, YYYY")}
                            style={{ width: "310px", marginRight: 10 }}
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                      {data?.data?.place_of_birth ? (
                        <Form.Item name="place_of_birth" label="Place of Birth">
                          <Input
                            readOnly
                            defaultValue={data?.data?.place_of_birth}
                            style={{ width: "310px" }}
                            placeholder="Place of Birth"
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )}
                    </div>
                    {data?.data?.signature ? (
                      <Form.Item name="signature" label="Signature">
                        <div className="row pt-4" style={{ display: "flex" }}>
                          <div
                            className="col-md-6"
                            style={{
                              width: "310px",
                              height: 100,
                              marginBottom: "10px",
                            }}
                          >
                            <div style={{ width: "100%" }}>
                              <img
                                className="img-fluid"
                                src="/assets/images/access.png"
                                alt="Default Image"
                              />
                            </div>
                          </div>
                        </div>{" "}
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    {data?.data?.chef_document_scan ? (
                      <Form.Item
                        name="chef_document_scan"
                        label="Your recommandation doc"
                      >
                        <Input
                          id="demo2"
                          className="demo1"
                          // type="file"
                          placeholder="Drag and drop file here"
                          name="demo2[]"
                          style={{ width: "300px", marginRight: 10 }}
                          // onChange={handleChangeChefDoc}
                        />
                      </Form.Item>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col">
                  <Descriptions
                    title="TIVA ACCOUNT DETAILS"
                    size="small"
                    column={1}
                    items={[]}
                  />
                  <div>
                    {data?.data?.phone ? (
                      <Form.Item name="phone" label="Phone">
                        <Input
                          placeholder="Phone"
                          readOnly
                          defaultValue={data?.data?.phone}
                        />
                      </Form.Item>
                    ) : (
                      ""
                    )}
                    {data?.data?.phone2 ? (
                      <Form.Item name="phone" label="Phone2">
                        <Input
                          placeholder="Phone"
                          readOnly
                          defaultValue={data?.data?.phone2}
                        />
                      </Form.Item>
                    ) : (
                      ""
                    )}
                    {data?.data?.email ? (
                      <Form.Item name="email" label="Email Address">
                        <Input
                          placeholder="Email Address"
                          defaultValue={data?.data?.email}
                          readOnly
                        />
                      </Form.Item>
                    ) : (
                      ""
                    )}
                    {data?.data?.Partner ? (
                      <div
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        JV Partners Benefits :{" "}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "7px",
                          }}
                        >
                          <Avatar src={data?.data?.avatar} />
                          <Tag color="blue">{`${data?.data?.Partner?.name}`}</Tag>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <Form.Item name="" label="Sub Member at these JV">
                      <div
                        style={{
                          marginBottom: "10px",
                          padding: "20px",
                          border: "1px solid #e3e3e3",
                          boxShadow: "1px 1px #e3e3e3",
                        }}
                      >
                        {data?.data?.jv_list?.map((jv: any) => {
                          return (
                            <div style={{ marginBottom: "5px" }}>
                              <Tag color="cyan">{jv.name}</Tag>{" "}
                              <span>
                                Join on{" "}
                                {formattedDate(jv.createdAt).formattedDate}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </Form.Item> */}

                    {/* <Form.Item name='notes' label='Important notes'>
									<Input.TextArea rows={4}></Input.TextArea>
								</Form.Item> */}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </>
      ),
    },
    {
      key: "2Address Information",
      label: <label style={{ fontWeight: "bold" }}>Address Information</label>,
      // children: <AddressAccountInfos />,
    },
    {
      key: "3Your workspace and Membership",
      label: (
        <label style={{ fontWeight: "bold" }}>
          Your workspace and Membership
        </label>
      ),
      // children: <WorkspaceAndMemberShip />,
    },
    {
      key: "4Know Your Customer",
      label: <label style={{ fontWeight: "bold" }}>Know Your Customer</label>,
      children: <p>Know Your Customer</p>,
    },
  ];
  return (
    <div style={{ padding: "50px " }}>
      <Collapse items={items} bordered={false} ghost={true} />
    </div>
  );
};

export default CustomerDetailComp;
