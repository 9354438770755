import React, { useState } from 'react';
import { loginImage } from '../../../components/icons';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import CryptoJS from 'crypto-js';

const Forgot = () => {
  const navigate = useNavigate()
  const [Loading, setLoading] = useState(false);

  const [account, setAcount] = useState({
    email_or_phone: '',

  });
  const onChangeValue = (e: any) => {
    setAcount({
      ...account,
      [e.target.name]: e.target.value
    })
  }

  const FourgotSubmit = async () => {

    let data = JSON.stringify({
      "email_or_phone": account.email_or_phone
    });

    setLoading(true);

    if (account.email_or_phone === '') {
      toast.error('The email_or_phone field must not be empty')
    }
    else {
      api.getHttpRequestNotToken("/user/forgot-password/start", data, 'POST').then((response) => {
        const json = response.data;
        if (json.status == 200) {
          toast.success(json.message)
          const key = 'phone_account';
          // Définir la variable à crypter
          const phone_account = account.email_or_phone;
          const phoneCrypter = CryptoJS.AES.encrypt(phone_account, key).toString();
          sessionStorage.setItem('zxremonailnephoy', phoneCrypter)
          navigate('/verify_forgot')
        } else {
          toast.error(json.message)
        }
      }).catch((error) => {
        if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
          toast.error("Please connect your device to the internet");
        } else if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      })
    }

    setTimeout(() => {
      setLoading(false);
    }, 5000)
  }

  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-form validate-form">
              <div>
                <Toaster
                  position="top-right"
                  reverseOrder={false}
                />
              </div>
              <h2 className="header-title mb-3 font-30 font-weight-normal border-0 text-center">
                Forgot Password?
              </h2>
              <label>Email or Phone number</label>
              <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                <input className="input100" type="text"
                  name='email_or_phone'
                  value={account.email_or_phone}
                  onChange={onChangeValue}
                  placeholder='Email or Phone number'
                />
                {/* <span className="focus-input100" style={{ paddingLeft: 25, paddingTop: 5 }}>Email or Phone number</span> */}
                {/* <span className="label-input100">Email or Phone number</span> */}
              </div>

              <div className="flex-sb-m w-full py-4">
                <div className="contact100-form-checkbox">
                  <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
                  <label className="label-checkbox100" htmlFor="ckb1">
                    Remember me
                  </label>
                </div>
                <div>
                  <a href="#" className="txt1">
                    Forgot Password?
                  </a>
                </div>
              </div>
              <div className="container-login100-form-btn">
                {!Loading && (
                  <button type="submit" className="login100-form-btn" onClick={FourgotSubmit}>
                    Send
                  </button>
                )}

                {Loading && (

                  <button type="submit" className="login100-form-btn" disabled>
                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 20 }}></i> <span>Ongoing processing...</span>
                  </button>
                )}
              </div>
              <div className=" py-4">
                <span className="txt2">
                  Already have an account?{" "}
                  <Link to="/login" className="txt1">
                    Login?
                  </Link>
                </span>
              </div>
              {/* <div className=" py-4">
                <span className="txt2">
                  By signing in,I agree to the <a href="#">Tiva Policy Statement</a> and <a href="#">Terms and Condition</a>
                </span>
              </div> */}
              {/* <div className="text-center py-4">
                <span className="txt2">
                  or sign up using
                </span>
              </div>
              <div className="login100-form-social flex-c-m">
                <a href="#" className="login100-form-social-item flex-c-m bg1 mr-2">
                  <i className="mdi mdi-facebook" aria-hidden="true"></i>
                </a>
                <a href="#" className="login100-form-social-item flex-c-m bg2 mr-2">
                  <i className="mdi mdi-twitter" aria-hidden="true"></i>
                </a>
              </div> */}
            </div>
            <div className="login100-more" style={{ backgroundImage: `url(${loginImage})` }}>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Forgot
