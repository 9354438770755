
import Header from "../../components/header"
import { useEffect, useState } from "react"
import ButtomPayement from "../../components/ButtomPayement"
import InputSearch from "../../components/InputSearch"
import SelectPayement from "../../components/SelectPayement"
import api from '../../config/Config'
import { useTextContext } from "../../TextProvider"
import { avatar1 } from "../../components/icons"
import { Alert, Button, Card, Modal, Pagination, Select, Space, Tag } from 'antd'
import PhoneInput from 'react-phone-input-2'
import Swal from 'sweetalert2';
import { FaSpinner } from "react-icons/fa6"
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa"
import { MdSignalWifiConnectedNoInternet0 } from "react-icons/md"
import { TbDeviceMobileQuestion } from "react-icons/tb"
import { GrTransaction } from "react-icons/gr";
import { Link } from "react-router-dom"
import Footer from "../../components/footer"
const { Option } = Select;

const customDropdownRender = (menu: any) => {
    return <div className="custom-select-dropdown">{menu}</div>;
};

const ModePayement = () => {
    const { profile } = useTextContext();
    //Messge
    const data = '';

    const [loadPay, setLoadPay] = useState<requestPayment[]>([])
    const [total, setTotal] = useState<requestPayment[]>([])
    const [totalPage, setTotalPage] = useState(1);

    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);
    const [message, setMessage] = useState("");


    const [type, setType] = useState('');
    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState('');
    const [searchStatus, setSearchStatus] = useState('');

    const [loading, setLoading] = useState(false);

    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');

    const [phone, setPhone] = useState<string>('');
    const [amount, setAmount] = useState('');

    useEffect(() => {
        LoadHistoryPayement(1)
    }, [profile, searchType, searchStatus])

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    const LoadHistoryPayement = async (page: number = 1) => {
        api.getHttpRequest(`/mobile-money/load-receive?type=${searchType}&status=${searchStatus}&page=${page}`, data, 'GET').then((response) => {
            const json = response.data;
            setLoadPay(json.data.result.rows)
            setTotal(json.data.totals)
            setTotalPage(json.data.result.count)

        }).catch((error) => {
            api.getMessageError(error)
        })
    }

    const Add_Payement = () => {
        let data = JSON.stringify({
            "amount": amount,
            "currency": "Tsh",
            "phone": phone,
            "type": type
        });

        setLoading(true);
        if (amount === '' || phone === '' || type === '') {
            setShowAlertDanger(true);
            setMessage('Make sure you have filled in all the red star fields');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger(true);
            setMessage('The entry number is incoreact');
        }
        else {
            api.getHttpRequest(`/mobile-money/create-receive`, data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setShowAlertSucess(true);
                    setMessage(json.message);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: json.message,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    setAmount('');
                    LoadHistoryPayement(1)
                    handleCancel1();
                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                setShowAlertDanger(true);
                setMessage(api.getMessageError(error))
            })

        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 3000);
    }

    const getStatusIcon = (status: any) => {
        switch (status) {
            case 'IN PROGRESS':
                return <Button style={{
                    fontSize: 15, marginRight: 5, cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#1576ED',
                    color: '#1576ED'
                }}
                > <FaSpinner style={{ color: '#1576ED' }} />{status}</Button>;
            case 'SUCCESSFUL':
                return <Button style={{
                    fontSize: 15, marginRight: 5, cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#1576ED',
                    color: '#1576ED'
                }}
                > <FaCheckCircle style={{ color: '#1576ED' }} />{status}</Button>;
            case 'ERROR':
                return <Button style={{
                    fontSize: 15, marginRight: 5, cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#D87266',
                    color: '#D87266'
                }}
                > <FaExclamationCircle style={{ color: '#D87266' }} />{status}</Button>;
            case 'REJECTED':
                return <Button style={{
                    fontSize: 15, marginRight: 5, cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#B3DBF2',
                    color: '#B3DBF2'
                }}
                > <MdSignalWifiConnectedNoInternet0 style={{ color: '#B3DBF2' }} />{status}</Button>;
            case 'POPUP NOT VALIDATED':
                return <Button style={{
                    fontSize: 15, marginRight: 5, cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#F9F0FF',
                    color: '#531DAB'
                }}
                > <MdSignalWifiConnectedNoInternet0 style={{ color: '#531DAB' }} />{status}</Button>;

            default:
                return null;
        }

    };

    const getModepaye = (status: any) => {
        switch (status) {
            case 'WITHDRAWAL':
                return <Button style={{
                    fontSize: 15, marginRight: 5, cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#CBE8FA',
                    backgroundColor: '#CBE8FA',
                    color: '#209BED'
                }}
                > <TbDeviceMobileQuestion style={{ color: '#209BED' }} />{status}</Button>;
            case 'PAYMENT':
                return <Button style={{
                    fontSize: 15, marginRight: 5, cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#F5F5F5',
                    backgroundColor: '#F5F5F5',
                    color: '#E7142D'
                }}
                > <FaCheckCircle style={{ color: '#E7142D' }} />{status}</Button>;
            case 'TRANSFER':
                return <Button style={{
                    fontSize: 15, marginRight: 5, cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: '#E9F3FB',
                    backgroundColor: '#E9F3FB',
                    color: '#444461'
                }}
                > <GrTransaction style={{ color: '#444461' }} />{status}</Button>;
            default:
                return null;
        }

    };

    const [current, setCurrent] = useState<number>(0);
    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const showModalPayement = (type: any) => {
        setIsModalOpen2(true);
        setType(type);
        setPhone(`${profile.phone}`)
    };
    const handleOk1 = () => {
        setIsModalOpen2(false);
        setType('');
    };

    const handleCancel1 = () => {
        setIsModalOpen2(false);
        setType('');
    };

    const isMobileScreen = window.innerWidth <= 768;

    const options = [
        { value: 'WITHDRAWAL', label: 'WITHDRAWAL' },
        { value: 'PAYMENT', label: 'PAYMENT' },
        { value: 'TRANSFER', label: 'TRANSFER' },
    ];
    const optionsStatus = [
        { value: 'IN PROGRESS', label: 'IN PROGRESS' },
        { value: 'SUCCESSFUL', label: 'SUCCESSFUL' },
        { value: 'ERROR', label: 'ERROR' },
        { value: 'REJECTED', label: 'REJECTED' },
    ];


    return (
        <>
            <div id="wrapper">
                <Header />

                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid1">
                            <div className="row">
                                <div className="col-9">
                                    <div>
                                        <h2 className="header-title pb-0 font-30 border-none pl-1">Payment History
                                        </h2>
                                        <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 1 }}>
                                            <label style={{
                                                backgroundColor: 'red',
                                                height: 20,
                                                width: 6,
                                                borderRadius: 10
                                            }}></label>
                                            <p style={{ paddingLeft: 2 }}>List of payments</p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="mb-2 ml-5 d-block d-sm-flex border-0">
                                        <div className="card-action card-tabs  mt-sm-0">
                                            <ul className="nav nav-tabs" role="tablist">

                                                <li className="nav-item">
                                                    <Link className="nav-link active" id="Monthly_" to="/home"
                                                        role="tab">Home</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-xl-12">

                                    <div className="card stacked-2 ml-2 mr-2">
                                        <div className="card-header flex-wrap border-0 pb-0 align-items-end">
                                            <div className="d-flex align-items-center mb-3 me-3">


                                                <Space direction="horizontal">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            width: "full",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "start",
                                                                flexWrap: "wrap",
                                                            }}
                                                        >
                                                            {total &&
                                                                total.map((total, index) => (
                                                                    <CardStat
                                                                        key={index}
                                                                        title={total?.type?.toLocaleLowerCase()}
                                                                        value={total.amount}
                                                                        currency={total.currency}
                                                                        status={total.status}
                                                                    />
                                                                ))
                                                            }
                                                        </div>

                                                    </div>
                                                </Space>


                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header d-block d-sm-flex border-0">
                                        <div>
                                            <h4 className="fs-20 text-black">List of payments</h4>

                                        </div>
                                        <div className="card-action card-tabs mt-3 mt-sm-0">
                                          
                                        </div>
                                    </div>
                                    <div className="card-body payment-bx tab-content p-0">
                                        <div className="tab-pane active show fade" role="tabpanel">

                                            <div style={{ margin: 10 }}>
                                                <table
                                                    style={{
                                                        border: "1px solid black",
                                                        width: "100%",
                                                        borderColor: "#F2F2F2",
                                                    }}
                                                >
                                                    {isMobileScreen ? (
                                                        <>
                                                            <tbody style={{ display: "row" }}>

                                                                <tr
                                                                    style={{
                                                                        border: "1px solid #F0F0F0",
                                                                        display: "row",
                                                                    }}
                                                                >
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{ border: "1px solid #F0F0F0" }}
                                                                    >
                                                                        <div className="inputBox m-1">
                                                                            <SelectPayement labelOption='Select Type' label="By Type" value={searchType} setValue={setSearchType} options={options} />
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr
                                                                    style={{
                                                                        border: "1px solid #F0F0F0",
                                                                        display: "row",
                                                                    }}
                                                                >
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{ border: "1px solid #F0F0F0" }}
                                                                    >
                                                                        <div className="inputBox m-1">
                                                                            <SelectPayement labelOption="Select Status" label="By Status" value={searchStatus} setValue={setSearchStatus} options={optionsStatus} />
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr
                                                                    style={{
                                                                        border: "1px solid #F0F0F0",
                                                                        display: "row",
                                                                    }}
                                                                >
                                                                    <td
                                                                        colSpan={2}
                                                                        style={{ border: "1px solid #F0F0F0" }}
                                                                    >
                                                                        <div className="inputBox m-1">
                                                                            <InputSearch label='Search' value={search} setValue={setSearch} placeholder="Search here..." />
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <tbody>
                                                                <tr style={{ border: "1px solid #F0F0F0" }}>

                                                                    <td style={{ border: "1px solid #F0F0F0" }}>
                                                                        <div className="inputBox m-1">
                                                                            <SelectPayement labelOption='Select Type' label="By Type" value={searchType} setValue={setSearchType} options={options} />
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ border: "1px solid #F0F0F0" }}>
                                                                        <div className="inputBox m-1">
                                                                            <SelectPayement labelOption="Select Status" label="By Status" value={searchStatus} setValue={setSearchStatus} options={optionsStatus} />
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ border: "1px solid #F0F0F0" }}>
                                                                        <div className="inputBox m-1">
                                                                            <InputSearch label='Search' value={search} setValue={setSearch} placeholder="Search here..." />
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </>
                                                    )}
                                                </table>
                                            </div>


                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table header-border table-responsive-sm tbl-common">
                                                        <thead>
                                                            <tr>
                                                                <th >No</th>
                                                                <th >Dates</th>
                                                                <th>Transaction ID</th>
                                                                <th >Withdrawn Amount</th>
                                                                <th>Transaction fee Mobile</th>
                                                                <th >Transaction fee Tiva</th>
                                                                <th>VAT</th>
                                                                <th >Total</th>
                                                                <th>Balance</th>
                                                                <th >Telecom</th>
                                                                <th >Status</th>
                                                                <th >Observation</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="tab_">
                                                            {
                                                                loadPay.map((item, i) => (

                                                                    <tr key={i}>
                                                                        <td className="cell-300">

                                                                            <span className="avatar">
                                                                                <span className="p-2 rounded-pill bg-light" style={{ width: 20, height: 20 }} >{i + 1}</span>
                                                                            </span>
                                                                        </td>
                                                                        <td className="cell-300">
                                                                            {api.formatDate(item.createdAt)}<br />
                                                                            {api.geDateFormat(item.createdAt)}
                                                                        </td>

                                                                        <td className="cell-300">
                                                                            {item.reference}<br />
                                                                            {getModepaye(item.type)}
                                                                        </td>

                                                                        <td>
                                                                            <p
                                                                                style={{
                                                                                    fontFamily: "sans-serif",
                                                                                    padding: "8px 2px",
                                                                                    border: "1px solid #1576ed",
                                                                                    textAlign: "center",
                                                                                    borderRadius: "8px",
                                                                                }}
                                                                            >
                                                                                <h6 style={{ color: "#1576ed" }}>
                                                                                    {item.currency}.{item.amount}
                                                                                </h6>
                                                                            </p>
                                                                        </td>
                                                                        <td>{item.currency}.{item.fee_mobile}</td>
                                                                        <td>{item.currency}.{item.fee_tiva}</td>
                                                                        <td>{item.currency}.{item.vat}</td>
                                                                        <td>{item.currency}.00</td>
                                                                        <td>{item.currency}.00</td>
                                                                        <td >
                                                                            {item.MobileOperator.name}
                                                                            <br />
                                                                            <Tag color='blue'>{item.phone}</Tag>
                                                                        </td>

                                                                        <td>{getStatusIcon(item.status)}</td>
                                                                        <td>{item.observation}</td>
                                                                    </tr>

                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <nav>
                                                        <Pagination
                                                            style={{
                                                                paddingTop: 10
                                                            }}
                                                            pageSize={10}
                                                            total={totalPage}
                                                            onChange={(page: any) => {
                                                                LoadHistoryPayement(page)

                                                            }}
                                                        />
                                                    </nav>
                                                </div>

                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <Footer />
                    </div>
                </div>

            </div>


        </>
    )
}
function CardStat({ title, value, currency, status }: any) {
    return (
        <div style={{ padding: 7 }}>
            <Card
                hoverable
                style={{
                    boxSizing: "border-box",
                    maxWidth: "max-content",
                    minWidth: "300px",
                }}
                bodyStyle={{}}
            >
                <h4 style={{ textTransform: "capitalize" }}>{title}</h4>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <h6 style={{ fontWeight: "bolder", color: "blueviolet" }}>
                        {currency}. {value}
                    </h6>
                    <p>
                        <Tag
                            color={
                                status == "SUCCESS"
                                    ? "cyan"
                                    : status == "ERROR"
                                        ? "error"
                                        : "warning"
                            }
                        >
                            {status}
                        </Tag>
                    </p>
                </div>
            </Card>
        </div>
    );
}

export default ModePayement