import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Alert, Button, ColorPicker, Modal, Popconfirm, Space, Tag } from 'antd'
import { useEffect, useState } from 'react'
import api from "../../../config/Config";
import Swal from 'sweetalert2';
import { AiTwotoneEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";

const StatusCreateModal = ({ isModalOpen, handleOk, handleCancel, dataLoad, dataLoadMember }: any) => {
    const data = ""
    const [loadStat, setLoadStat] = useState<requestStatusConfig[]>([])

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [vBottom, setVBottom] = useState("")
    const [id, setId] = useState("")

    const [color, setColor] = useState<string>('#1677ff');
    const [open, setOpen] = useState<boolean>(false);

    const handleColorChange = (value: string) => {
        setColor(value);
    };

    useEffect(() => {
        loadStatus()
    }, [color])

    const loadStatus = () => {
        api
            .getHttpRequest(`/partners/mention_member/${api.initPartnerId()}`, data, "GET")
            .then((response) => {
                const json = response.data;
                setLoadStat(json.data)
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const AddStatus = () => {
        let data = JSON.stringify({
            "PartnerId": api.initPartnerId(),
            "name": name,
            "color": color,
            "description": description
        });

        setLoading(true);
        if (name === '' || color === '') {
            setShowAlertDanger(true);
            setMessage('Make sure you have filled in all the red star fields');
        }
        else {
            api.getHttpRequest(`/partners/mention_member`, data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    loadStatus();
                    dataLoad();
                    dataLoadMember();
                    setShowAlertSucess(true);
                    setMessage(json.message);

                    setShowAlertSucess(true);
                    setMessage(json.message);
                    setName('')
                    setDescription('')
                    setColor('')
                    

                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    const errorMessage = error.response.data.message;
                    setShowAlertDanger(true);
                    setMessage(errorMessage)
                }
            })
        }

        setTimeout(() => {
            setLoading(false);
            setMessage('')
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }
    const UpdteStatus = () => {
        let data = JSON.stringify({
            "PartnerId": api.initPartnerId(),
            "name": name,
            "color": color,
            "description": description
        });

        setLoading(true);
        if (name === '' || color === '') {
            setShowAlertDanger(true);
            setMessage('Make sure you have filled in all the red star fields');
        }
        else {
            api.getHttpRequest(`/partners/mention_member/${id}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    loadStatus();
                    dataLoad();
                    dataLoadMember();
                    setShowAlertSucess(true);
                    setMessage(json.message);
                    setShowAlertSucess(true);
                    setMessage(json.message);
                    setName('')
                    setDescription('')
                    setColor('')
                    

                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    const errorMessage = error.response.data.message;
                    setShowAlertDanger(true);
                    setMessage(errorMessage)
                }
            })
        }

        setTimeout(() => {
            setLoading(false);
            setMessage('')
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }

    const handleDelete = (id: string) => {
        api
            .getHttpRequest(`/partners/mention_member/${id}`, {}, "Delete")
            .then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    loadStatus();
                    // setShowVerifyOTP(true);
                    setShowAlertSucess(true);
                    setMessage(json.message);
                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                if (
                    !window.navigator.onLine &&
                    !error.response &&
                    error.code === "ERR_NETWORK"
                ) {
                    setShowAlertDanger(true);
                    setMessage("Please connect your device to the internet");
                } else if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    setShowAlertDanger(true);
                    setMessage(error.response.data.message);
                } else {
                    setShowAlertDanger(true);
                    setMessage(error.message);
                }
            });
    }
    const showElement = (element: requestStatusConfig) => {
        setName(element.name)
        if (element.color) {
            handleColorChange(element.color)
        }
        setDescription(element.description)
        setVBottom('update')
        setId(element.id)
    }


    return (
        <Modal
            title="Status configuration"
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            footer={null}
            width={500}
            style={{ top: 100 }}
            // bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
            maskClosable={false}
        >
            <div>
                <div className=" pb-3 form-label rounded-sm" role="alert">
                    Make sure you want to finish everything before sending.
                </div>
                {
                    message && (
                        <div className='col-md-12 mb-1 mt-1'>
                            {/* <label style={{color:'white'}}>vouchers</label> */}
                            <Space direction="vertical" style={{ width: '100%' }}>
                                {
                                    showAlertDanger &&
                                    <Alert type="error" message={message} showIcon closable />
                                }

                                {
                                    showAlertSucess &&
                                    <Alert type="success" message={message} showIcon closable />
                                }
                            </Space>
                        </div>
                    )
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-md-12 row">
                                <div className="mb-3 col-md-10">
                                    <label className="form-label">Name</label>
                                    <input
                                        type="text"
                                        style={{ height: '50%' }}
                                        className="form-control "
                                        placeholder=""
                                        id="fullname"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3 col-md-2">
                                    <label className="form-label">Color</label>
                                    <ColorPicker
                                        style={{ height: '50%' }}
                                        value={color}
                                        open={open}
                                        onOpenChange={setOpen}
                                        showText={() => (
                                            <DownOutlined
                                                rotate={open ? 180 : 0}
                                                style={{ color: 'rgba(0, 0, 0, 0.25)' }}
                                            />
                                        )}
                                        onChange={(color, hex) => handleColorChange(hex)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <input type="hidden" id="id" value="00" /> */}

                    <div className="mb-3  col-md-12">
                        <label className="form-label">Description</label>
                        <textarea
                            className="form-control"
                            placeholder=""
                            id="adresse"
                            name="adress"
                            style={{ height: '50%' }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                </div>
                <div >
                    {/* <button
                        type="button"
                        className=" btn-primary agree-link rounded"
                        id="btn-Withdraw"

                    >
                        Save
                    </button> */}
                    {!loading &&
                        (vBottom ? (
                            <button
                                type="submit"
                                className="form-control btn btn-primary"
                                style={{ color: "white" }}
                                onClick={UpdteStatus}
                            >
                                Update
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="form-control btn btn-primary"
                                style={{ color: "white" }}
                                onClick={AddStatus}
                            >
                                Save
                            </button>
                        ))}

                    {loading && (
                        <button
                            type="submit"
                            className="form-control btn btn-primary"
                            disabled
                        >
                            <i
                                className="fa fa-spinner fa-spin fa-3x  fa-lg"
                                style={{ fontSize: 20 }}
                            ></i>{" "}
                            <span></span>
                        </button>
                    )}

                </div>

            </div>

            <div className="table-responsive mt-3" style={{ top: 10, maxHeight: '80vh', overflowY: 'auto' }}>
                <table
                    // className="table table-hover mails m-0 table table-actions-bar table-centered"
                    className="table header-border table-responsive-sm tbl-common"
                >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Color</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody

                    >
                        {loadStat?.map((item, i) => (
                            <tr
                                key={i}
                                className="cursor-pointer"
                                data-toggle="collapse"
                                data-target="#demo5"
                            >
                                <td>
                                    <span style={{ fontWeight: 'bold' }}>{item?.name}</span><br />
                                    <small>{api.geDateFormat(item.createdAt)}</small>
                                </td>

                                <td>
                                    <Tag color={`${item?.color}`} style={{ width: 20, height: 20, borderRadius: '100%' }}>
                                    </Tag>
                                </td>
                                <td>
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <div
                                                onClick={() => showElement(item)}
                                                style={{ marginRight: 20 }}
                                            >
                                                <AiTwotoneEdit
                                                    style={{ color: '1576ED', width: 15, height: 15 }}
                                                />
                                            </div>

                                            <Popconfirm
                                                title="Delete the status"
                                                description="Do you want to delete?"
                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                onConfirm={(e) => handleDelete(item.id)} // Action de suppression
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <div><AiOutlineDelete style={{ color: 'red', width: 15, height: 15 }} /></div>
                                            </Popconfirm>



                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Modal>
    )
}

export default StatusCreateModal