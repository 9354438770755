import { useEffect, useState } from 'react';
import { avatar1, french, italy, logoSm, russia, spain, us } from '../../../components/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import api from '../../../config/Config'
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { IoLocationOutline } from 'react-icons/io5';
import { TbTransitionBottom } from 'react-icons/tb';
import RequestRow from '../../../controller/RequestRow';

const LocationHome = () => {
	const theme = useTheme();
	const isMatch = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();
	const data = '';
	const tempo: any = [];

	const [load, setLoad] = useState<RequestRow[]>([]);
	const [countNotification, setCountNotification] = useState('');
	// const [open, setOpen] = useState(false);

	function logout() {
		api
			.getHttpRequest('/user/log-out', {}, 'POST')
			.then((response) => {
				const json = response.data;
				if (json.status == 200) {
					sessionStorage.clear();
					navigate(`/login`)
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	// const [click, setClick] = useState(false);

	// const handleClick = () => setClick(!click);

	/** Usse Menu ant */

	const location = useLocation();
	// const [selectedKeys, setSelectedKeys] = useState('/')

	useEffect(() => {
		// const pathName = location.pathname
		// setSelectedKeys(pathName);
		LoadNotification();
	}, [location.pathname]);

	const LoadNotification = () => {
		api
			.getHttpRequest(`/user/notifications`, data, 'GET')
			.then((response) => {
				const json = response.data;
				if (json.status == 200) {
					setLoad(json.data.rows);
					setCountNotification(json.data.count);
				}
			})
			.catch((error) => {
				if (
					!window.navigator.onLine &&
					!error.response &&
					error.code === 'ERR_NETWORK'
				) {
					console.log('Please connect your device to the internet');
				} else if (
					error.response &&
					error.response.data &&
					error.response.data.message
				) {
					console.log(error.response.data.message);
				} else {
					console.log(error.message);
				}
			});
	};

	const DesibleNotification = (id: any) => {
		if (!tempo.includes(id)) {
			api
				.getHttpRequest(`/user/notifications/view/${id}`, {}, 'PUT')
				.then((response) => {
					const json = response.data;
					if (json.status == 200) {
						tempo.push(id);
					}
				})
				.catch((error) => {
					console.log(error.message);
				});
		}
	};

	const DeleteNotification = () => {
		api
			.getHttpRequest(`/user/notifications/clear`, {}, 'DELETE')
			.then((response) => {
				const json = response.data;
				if (json.status == 200) {
					LoadNotification();
				}
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	const getNotificationIcon = (x: any) => {
		if (x == 1)
			return ['notify-icon bg-success', 'mdi mdi-comment-account-outline'];
		if (x == 0)
			return ['notify-icon bg-danger', 'mdi mdi-comment-account-outline'];
		return '';
	};

	function CustomTooltip({ message }: any) {
		const [showTooltip, setShowTooltip] = useState(false);

		return (
			<div
				className='custom-tooltip'
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				{isMatch ? (
					<>
						<p>{message.substring(0, 15)}...</p>
					</>
				) : (
					<>
						<p>{message.substring(0, 40)}...</p>
					</>
				)}

				{showTooltip && (
					<div className='text-muted' style={{ whiteSpace: 'pre-wrap' }}>
						{message}
					</div>
				)}
			</div>
		);
	}

	return (
		<>
			<header id='topnav'>
				<div className='navbar-custom'>
					<div className='container-fluid1'>
						<ul className='list-unstyled topnav-menu float-right mb-0'>
							<li className='dropdown d-none d-lg-block'>
								<div
									className='nav-link dropdown-toggle mr-0'
									data-toggle='dropdown'
									role='button'
									aria-haspopup='false'
									aria-expanded='false'
								>
									<img src={us} className='w-3' height='16'/>{' '}
									<span className='align-middle d-none d-xl-inline-block ml-2'>
										English <i className='mdi mdi-chevron-down'></i>{' '}
									</span>
								</div>
								<div className='dropdown-menu dropdown-menu-right'>
									<div className='dropdown-item notify-item'>
										<img src={spain} className='mr-2' height='12' />
										<span className='align-middle'>Spanish</span>
									</div>

									<div className='dropdown-item notify-item'>
										<img src={italy} className='mr-2' height='12' />
										<span className='align-middle'>Italian</span>
									</div>

									<div className='dropdown-item notify-item'>
										<img src={french} className='mr-2' height='12' />
										<span className='align-middle'>French</span>
									</div>

									<div className='dropdown-item notify-item'>
										<img src={russia} className='mr-2' height='12' />
										<span className='align-middle'>Russian</span>
									</div>
								</div>
							</li>

							<li className='dropdown notification-list'>
								<div
									className='nav-link dropdown-toggle '
									data-toggle='dropdown'
									role='button'
									aria-haspopup='false'
									aria-expanded='false'
								>
									<i className='mdi mdi-bell noti-icon'></i>
									<span className='badge badge-danger rounded-circle noti-icon-badge'>
										{countNotification}
									</span>
								</div>
								<div className='dropdown-menu dropdown-menu-right dropdown-lg'>
									<div className='dropdown-item noti-title'>
										<h5 className='font-16 m-0'>
											<span className='float-right'>
												<div
													onClick={() => DeleteNotification()}
													style={{ cursor: 'pointer' }}
													className='text-dark'
												>
													<small>Clear All</small>
												</div>
											</span>
											Notification
										</h5>
									</div>

									<div className='slimscroll noti-scroll'>
										{load.map((item, i) => (
											<div
												onMouseEnter={() => DesibleNotification(item.id)}
												key={i}
												className='dropdown-item notify-item'
											>
												<div className={getNotificationIcon(item.is_alert)[0]}>
													<i
														className={getNotificationIcon(item.is_alert)[1]}
													></i>
												</div>
												<CustomTooltip message={item.message} />
												<small className='text-muted'>
													{api.geDateFormat(item.createdAt)}
												</small>
											</div>
										))}
									</div>
									{/* 
                                    <a href=""
                                        className="dropdown-item text-primary text-center notify-item notify-all">
                                        View all
                                        <i className="fi-arrow-right"></i>
                                    </a> */}
								</div>
							</li>

							<li className='dropdown notification-list'>
								<div
									className='nav-link dropdown-toggle nav-user mr-0'
									data-toggle='dropdown'
									role='button'
									aria-haspopup='false'
									aria-expanded='false'
								>
									<img
										src={`${
											api.initAvatar() === 'default.png'
												? `${avatar1}`
												: `${api.var_.url_avatar + api.initAvatar()}`
										}`}
										className='rounded-circle'
									/>
									<span className='pro-user-name d-none d-xl-inline-block ml-2'>
										{api.initUser()} <i className='mdi mdi-chevron-down'></i>
									</span>
								</div>
								<div className='dropdown-menu dropdown-menu-right profile-dropdown '>
									<div className='dropdown-header noti-title'>
										<h6 className='text-overflow m-0'>Welcome !</h6>
									</div>

									<div
										onClick={() => api.RedirectionAccess()}
										style={{
											borderRadius: 100,
											border: '1px solid #1152D9',
											marginTop: 15,
											textAlign: 'center',
											display: 'flex',
											justifyContent: 'center',
											cursor: 'pointer',
											width: 250,
											margin: 2,
										}}
									>
										<div>
											<div style={{ color: '#1576ED', padding: 10 }}>
											   Manage your Tiva account
											</div>
										</div>
									</div>

									<div className='dropdown-divider'></div>

									<Link
										to='/offline'
										onClick={logout}
										className='dropdown-item notify-item'
									>
										<i className='mdi mdi-logout-variant'></i>
										<span>Logout</span>
									</Link>
								</div>
							</li>
						</ul>

						{/** LOGO */}
						<div className='logo-box'>
							<div className='logo text-center'></div>
							<div className='logo text-center'>
								<span className='logo-lg'>
									{/* <MenuOutlined onClick={showDrawer} style={{ fontSize: 30, color: '#A1C8F8', marginRight: 28, marginLeft: 20, cursor: 'pointer' }} /> */}
									<img
										src={logoSm}
										alt=''
										height='40'
										onClick={() => navigate('/location/region')}
										style={{ cursor: 'pointer' }}
									/>
									{/** <span className="logo-lg-text-light">Simple</span> */}
								</span>
								<span className='logo-sm'>
									{/* <MenuOutlined onClick={showDrawer} style={{ fontSize: 25, color: '#A1C8F8', marginRight: 10, marginLeft: 5, cursor: 'pointer' }} /> */}
									{/**  <span className="logo-sm-text-dark">S</span> */}
									<img
										src={logoSm}
										alt=''
										height='36'
										onClick={() => navigate('/location/region')}
										style={{ cursor: 'pointer' }}
									/>
								</span>
							</div>
						</div>

						<div id='navigation'>
							<ul className='navigation-menu'>
								<li className='has-submenu' style={{ color: 'white' }}>
									<Link to='/location/region'>
										{' '}
										<i className='ti-home'></i> Menu{' '}
										<div className='arrow-down'></div>
									</Link>
									<ul className='submenu'>
										{/* <li>
											<Link to='/vouchers_transactions'>Transactions</Link>
										</li> */}
										<li>
											<Link to='/location/region'>Location</Link>
										</li>
									</ul>
								</li>

								{/* <li className='has-submenu' style={{ color: 'white' }}>
									<Link to='/vouchers_transactions'>
										{' '}
										<TbTransitionBottom style={{ fontSize: 20 }} />
										Transactions
									</Link>{' '}
								</li> */}
								<li className='has-submenu' style={{ color: 'white' }}>
									<Link to='/location/region'>
										{' '}
										<IoLocationOutline style={{ fontSize: 20 }} />
										Location
									</Link>{' '}
								</li>
							</ul>
							<div className='clearfix'></div>
						</div>

						<div className='clearfix'></div>
					</div>
				</div>
			</header>
		</>
	);
};

export default LocationHome;
