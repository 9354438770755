import { Button, Image, Modal, Card, Row, Col, CollapseProps, Collapse, Tag } from "antd"
import { PiDownloadSimpleDuotone } from "react-icons/pi";
import { IoEyeOutline } from "react-icons/io5";
import api from "../../../config/Config"
import { FilePdfOutlined, FileImageOutlined, FileUnknownOutlined, FileWordOutlined, FileExcelOutlined } from '@ant-design/icons';
import { docs1 } from "../../../components/icons";
import { useEffect, useState } from "react";
import ModalFrameViewaDocs from "./ModalFrameViewsDocs";
const { Meta } = Card;

const getFileIcon = (fileName: any) => {
    if (!fileName) {
        return <FileUnknownOutlined style={{ fontSize: '35px', color: '#8c8c8c' }} />;
    }

    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
        case 'pdf':
            return <FilePdfOutlined style={{ fontSize: '35px', color: '#FF0000' }} />;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
            return <FileImageOutlined style={{ fontSize: '35px', color: '#1890ff' }} />;
        case 'doc':
        case 'docx':
            return <FileWordOutlined style={{ fontSize: '35px', color: '#1890ff' }} />;
        case 'xls':
        case 'xlsx':
            return <FileExcelOutlined style={{ fontSize: '35px', color: '#52c41a' }} />;
        default:
            return <FileUnknownOutlined style={{ fontSize: '35px', color: '#8c8c8c' }} />;
    }
};

const downloadFile = async (url: string, fileName: string) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href); // Clean up
    } catch (error) {
        console.error("Error downloading file:", error);
    }
};

const DocsCard = ({ isModalOpen, handleOk, handleCancel, phoneUser, nameUser, UserId }: any) => {
    const downloadUrl = (fileName: string) => `${fileName}`;

    const [loadIdentity, setLoadIdentity] = useState<RequestDocument[]>([])
    const [loadDocs, setLoadDocs] = useState<RequestDocument[]>([])
   
    useEffect(() => {
        if (UserId) {
            api
                .getHttpRequestV3(`/user/partner/member/document-identity/${UserId}`, {}, "GET")
                .then((response) => {
                    const json = response.data;
                    if (json.status == 200) {
                        setLoadIdentity(json.data)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });

            api
                .getHttpRequestV3(`/user/partner/member/document/${UserId}`, {}, "GET")
                .then((response) => {
                    const json = response.data;
                    if (json.status == 200) {
                        setLoadDocs(json.data)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }, [UserId])
    
    const [isModalOpenFrame, setIsModalOpenFrame] = useState(false);
    const [showFilename, setFhowFilename] = useState("")
    const showModalFrame = (element: any) => {
        setIsModalOpenFrame(true);
        setFhowFilename(element)
    };

    const handleOk1 = () => {
        setIsModalOpenFrame(false);
        setFhowFilename("")
    };
    const handleCancel1 = () => {
        setIsModalOpenFrame(false); 
        setFhowFilename("")
    }

    const items: CollapseProps["items"] = [

        {
            key: "1Identity Information",
            label: (
                <label style={{ fontWeight: "bold" }}>Identity</label>
            ),
            children:
                <>
                    <div className="table-responsive mt-3">
                        <table
                            className="table header-border table-responsive-sm tbl-common"
                        >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{ alignItems: 'center', justifyContent: 'center' }}>IdFront</th>
                                    <th>IdBack</th>
                                    <th>Created at</th>
                                    <th style={{ alignItems: 'center', justifyContent: 'center', }}>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadIdentity?.length > 0 ? (
                                    loadIdentity?.map((doc: any, index: number) => (
                                        <tr key={index} className="cursor-pointer" data-toggle="collapse" data-target="#demo5">
                                            <td>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div><img src={docs1} style={{ width: 30, height: 30 }} /></div>
                                                    <div>
                                                        <b>
                                                            {doc?.DocumentType?.name}{" "}
                                                        </b>
                                                        <p
                                                            style={{
                                                                color: "gray",
                                                                fontFamily: "sans-serif",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {doc?.DocumentType?.description ?? " "}
                                                        </p>

                                                    </div>
                                                </div>

                                            </td>
                                            <td style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex' }}>
                                                    {/* <PiDownloadSimpleDuotone
                                                        style={{ color: '1576ED', width: 35, height: 35, paddingRight: 10 }}
                                                        onClick={() => downloadFile(downloadUrl(api.var_.url_avatar + doc?.fileFront?.filename), doc?.DocumentType?.name)}
                                                    /> */}
                                                    {/* <Image src={api.var_.url_avatar + doc?.fileFront?.filename} alt={`Front of ID ${index}`} style={{ width: '100px', height: 'auto' }} /> */}
                                                    {getFileIcon(api.var_.url_avatar + doc?.fileFront?.filename)}
                                                    <Button
                                                        style={{ justifyContent: 'center' }}
                                                        // href={api.var_.url_avatar + doc?.fileFront?.filename}
                                                        // download={doc?.DocumentType?.name}
                                                        onClick={()=>showModalFrame(doc?.fileFront?.filename)}
                                                        // target="_blank"
                                                    >
                                                        <IoEyeOutline style={{ color: '1576ED' }} />
                                                    </Button>
                                                </div>
                                            </td>
                                            <td>
                                                {/* <div>
                                                    <Button
                                                        style={{ justifyContent: 'center' }}
                                                        href={api.var_.url_avatar + doc.file_name}
                                                        download={doc.name}
                                                        target="_blank"
                                                    >
                                                        <IoEyeOutline style={{ color: '1576ED' }} />
                                                    </Button>
                                                </div> */}

                                                <div style={{ display: 'flex' }}>
                                                    {/* <PiDownloadSimpleDuotone
                                                        style={{ color: '1576ED', width: 35, height: 35, paddingRight: 10 }}
                                                        onClick={() => downloadFile(downloadUrl(api.var_.url_avatar + doc?.fileFront?.fileBack), doc.name)}
                                                    /> */}
                                                    {/* <Image src={api.var_.url_avatar + doc?.fileBack?.filename} alt={`Front of ID ${index}`} style={{ width: '100px', height: 'auto' }} /> */}
                                                    {getFileIcon(api.var_.url_avatar + doc?.fileBack?.filename)}
                                                    <Button
                                                        style={{ justifyContent: 'center' }}
                                                        // href={api.var_.url_avatar + doc?.fileBack?.filename}
                                                        // download={doc?.DocumentType?.name}

                                                        // target="_blank"
                                                        onClick={()=>showModalFrame(doc?.fileBack?.filename)}
                                                    >
                                                        <IoEyeOutline style={{ color: '1576ED' }} />
                                                    </Button>
                                                </div>
                                            </td>
                                            <td>
                                                {api.formatDate(doc?.createdAt)} <br />
                                                <Tag color="cyan">
                                                    {api.geDateFormat(doc?.createdAt)}
                                                </Tag>
                                            </td>
                                            <td style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <PiDownloadSimpleDuotone
                                                    style={{ color: '1576ED', width: 30, height: 30 }}
                                                    onClick={() => downloadFile(downloadUrl(api.var_.url_avatar + doc?.fileFront?.filename), doc?.DocumentType?.name)}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={3} style={{ textAlign: 'center', padding: '20px' }}>
                                            <img src={docs1} style={{ width: 45, height: 45 }} /><br />
                                            No documents available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            ,

        },
        {
            key: "2Document Information",
            label: (
                <label style={{ fontWeight: "bold" }}>Document</label>
            ),
            children:
                <>
                    <div className="table-responsive mt-3">
                        <table
                            className="table header-border table-responsive-sm tbl-common"
                        >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th style={{ alignItems: 'center', justifyContent: 'center' }}>Number</th>
                                    <th>File</th>
                                    <th>Created at</th>
                                    <th style={{ alignItems: 'center', justifyContent: 'center', }}>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadDocs?.length > 0 ? (
                                    loadDocs?.map((doc: any, index: number) => (
                                        <tr key={index} className="cursor-pointer" data-toggle="collapse" data-target="#demo5">
                                            <td>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div><img src={docs1} style={{ width: 30, height: 30 }} /></div>
                                                    <div>
                                                        <b>
                                                            {doc?.name}{" "}
                                                        </b>
                                                        <p
                                                            style={{
                                                                color: "gray",
                                                                fontFamily: "sans-serif",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {doc?.File?.originalname ?? " "}
                                                        </p>

                                                    </div>
                                                </div>

                                            </td>
                                            <td style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex' }}>
                                                    {doc?.number}
                                                </div>
                                            </td>
                                            <td>
                                                {/* <div>
                                                    <Button
                                                        style={{ justifyContent: 'center' }}
                                                        href={api.var_.url_avatar + doc.file_name}
                                                        download={doc.name}
                                                        target="_blank"
                                                    >
                                                        <IoEyeOutline style={{ color: '1576ED' }} />
                                                    </Button>
                                                </div> */}

                                                <div style={{ display: 'flex' }}>
                                                    {/* <PiDownloadSimpleDuotone
                                                        style={{ color: '1576ED', width: 35, height: 35, paddingRight: 10 }}
                                                        onClick={() => downloadFile(downloadUrl(api.var_.url_avatar + doc?.fileFront?.fileBack), doc.name)}
                                                    /> */}
                                                    {/* <Image src={api.var_.url_avatar + doc?.fileBack?.filename} alt={`Front of ID ${index}`} style={{ width: '100px', height: 'auto' }} /> */}
                                                    {getFileIcon(api.var_.url_avatar + doc?.File?.filename)}
                                                    <Button
                                                        style={{ justifyContent: 'center' }}
                                                        // href={api.var_.url_avatar + doc?.File?.filename}
                                                        // download={doc?.File?.name}
                                                        onClick={()=>showModalFrame(doc?.File?.filename)}
                                                        // target="_blank"
                                                    >
                                                        <IoEyeOutline style={{ color: '1576ED' }} />
                                                    </Button>
                                                </div>
                                            </td>
                                            <td>
                                                {api.formatDate(doc?.createdAt)} <br />
                                                <Tag color="cyan">
                                                    {api.geDateFormat(doc?.createdAt)}
                                                </Tag>
                                            </td>
                                            <td style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                <PiDownloadSimpleDuotone
                                                    style={{ color: '1576ED', width: 30, height: 30 }}
                                                    onClick={() => downloadFile(downloadUrl(api.var_.url_avatar + doc?.File?.filename), doc?.name)}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={3} style={{ textAlign: 'center', padding: '20px' }}>
                                            <img src={docs1} style={{ width: 45, height: 45 }} /><br />
                                            No documents available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            ,

        },
    ];

    

    return (
        <>

            <Modal
                title={`Docs for ${nameUser}`}
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={null}
                width={1500}
                style={{ top: 100 }}
                bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
                maskClosable={false}
            >

                <div>
                    <div className=" p-2 form-label rounded-sm" role="alert" style={{ backgroundColor: '#F0F3F8' }}>
                        List of documents and membership cards for {nameUser} <br />
                        <Tag>{phoneUser}</Tag>
                    </div>

                    <Collapse items={items} bordered={false} ghost={true} defaultActiveKey={["1Identity Information"]}/>
                </div>
            </Modal>



            <ModalFrameViewaDocs
                isModalOpen={isModalOpenFrame}
                handleOk={handleOk1}
                handleCancel={handleCancel1}
                title="Document"
                src={showFilename}
                width={`100%`}
                height={`100%`}
            />
        </>

    )
}

export default DocsCard