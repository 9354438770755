import { useEffect, useState } from 'react'
import {  default1,  logoSm} from '../icons'
import { Link,  useNavigate, useLocation } from 'react-router-dom'
import api from '../../config/Config'
import toast from 'react-hot-toast';
import requestRow from '../../controller/RequestRow';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { FaLeanpub } from 'react-icons/fa';

const HeaderVendor = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const data = '';

    const [load, setLoad] = useState<requestRow[]>([])
    const [countNotification, setCountNotification] = useState('');
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    function logout() {
        api.getHttpRequest('/user/log-out', {}, 'POST').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                ;
                sessionStorage.clear();
            }
        }).catch((error) => {
            console.log(error);
        })

    }

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    /** Usse Menu ant */

    const location = useLocation()
    const [selectedKeys, setSelectedKeys] = useState('/')
    const [userAvatar, setUserAvatar] = useState('');

    useEffect(() => {
        const pathName = location.pathname
        setSelectedKeys(pathName);
        LoadNotification()
        const storedAvatar = api.initAvatar();
        if (storedAvatar) {
            setUserAvatar(storedAvatar);
        }
    }, [location.pathname])

    const LoadNotification = () => {
        api.getHttpRequest(`/user/notifications`, data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setLoad(json.data.rows);
                setCountNotification(json.data.count);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    // const DesibleNotification = (id: any) => {
    //     if (!tempo.includes(id)) {
    //         api.getHttpRequest(`/user/notifications/view/${id}`, {}, 'PUT').then((response) => {
    //             const json = response.data;
    //             if (json.status == 200) {
    //                 tempo.push(id);
    //             }
    //         }).catch((error) => {
    //             toast.error(error.message);
    //         })
    //     }

    // }

    const DeleteNotification = () => {
        api.getHttpRequest(`/user/notifications/clear`, {}, 'DELETE').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                LoadNotification()
            }
        }).catch((error) => {
            toast.error(error.message);
        })
    }

    const getNotificationIcon = (x: any) => {
        if (x == 1) return ['notify-icon bg-success', 'mdi mdi-comment-account-outline'];
        if (x == 0) return ['notify-icon bg-danger', 'mdi mdi-comment-account-outline'];
        return '';
    }


    function CustomTooltip({ message }: any) {
        const [showTooltip, setShowTooltip] = useState(false);

        return (
            <div
                className="custom-tooltip"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {
                    isMatch ? (
                        <>
                            <p>{message.substring(0, 15)}...</p>
                        </>
                    ) : (
                        <>
                            <p>{message.substring(0, 40)}...</p>
                        </>
                    )
                }

                {showTooltip && <div className="text-muted" style={{ whiteSpace: 'pre-wrap' }}>{message}</div>}
            </div>
        );
    }

    function NavigatePrincipal() {
        // window.location.href = `http://localhost:5173/#/tiva-account-infos-user/${api.initToken()}`;//dev
        window.location.href = `https://www.tivaaccount.tiva.co.tz/#/tiva-account-infos-user/${api.initToken()}` //prod
        // window.location.href = `${api.initUserUrlAccess}`
    }

    return (
        <>
            <header id="topnav">


                <div className="navbar-custom">
                    <div className="container-fluid1">


                        <ul className="list-unstyled topnav-menu float-right mb-0">


                            <li className="dropdown notification-list">
                                {/** Mobile menu toggle */}
                                {/* <a className="navbar-toggle nav-link" onClick={showDrawer}>
                    <div className="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a> */}

                                {/** End mobile menu toggle */}
                            </li>


                            {/* <li className="dropdown d-none d-lg-block">
                                <a className="nav-link dropdown-toggle mr-0" data-toggle="dropdown" href="#" role="button"
                                    aria-haspopup="false" aria-expanded="false">
                                    <img src={us} height="12" /> <span
                                        className="align-middle d-none d-xl-inline-block ml-2">English <i
                                            className="mdi mdi-chevron-down"></i> </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">

                                    <a href="" className="dropdown-item notify-item">
                                        <img src={spain} className="mr-2" height="12" />
                                        <span className="align-middle">Spanish</span>
                                    </a>


                                    <a href="" className="dropdown-item notify-item">
                                        <img src={italy} className="mr-2" height="12" />
                                        <span className="align-middle">Italian</span>
                                    </a>


                                    <a href="" className="dropdown-item notify-item">
                                        <img src={french} className="mr-2" height="12" />
                                        <span className="align-middle">French</span>
                                    </a>


                                    <a href="" className="dropdown-item notify-item">
                                        <img src={russia} className="mr-2" height="12" />
                                        <span className="align-middle">Russian</span>
                                    </a>
                                </div>
                            </li> */}

                            <li className="  d-lg-block">
                                <Link className="nav-link mr-0" to="/training-vendor" role="button"
                                    >
                                    <FaLeanpub style={{ fontSize: 25 , color:'white'}} /> <span
                                        className="align-middle d-none d-xl-inline-block ml-2">Learning <i
                                            className="mdi mdi-chevron-down"></i> </span>
                                </Link>
                               
                            </li>

                            <li className="dropdown notification-list">
                                <a className="nav-link dropdown-toggle " data-toggle="dropdown" href="#" role="button"
                                    aria-haspopup="false" aria-expanded="false">
                                    <i className="mdi mdi-bell noti-icon"></i>
                                    <span className="badge badge-danger rounded-circle noti-icon-badge">{countNotification}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-lg">
                                    <div className="dropdown-item noti-title">
                                        <h5 className="font-16 m-0">
                                            <span className="float-right">
                                                <div onClick={() => DeleteNotification()} style={{ cursor: 'pointer' }} className="text-dark">
                                                    <small>Clear All</small>
                                                </div>
                                            </span>Notification
                                        </h5>
                                    </div>

                                    <div className="slimscroll noti-scroll">

                                        {load.map((item, i) => (
                                            <div
                                                // onMouseEnter={() => DesibleNotification(item.id)} 
                                                key={i} className="dropdown-item notify-item">
                                                <div className={getNotificationIcon(item.is_alert)[0]}>
                                                    <i className={getNotificationIcon(item.is_alert)[1]}></i>
                                                </div>
                                                <CustomTooltip message={item.message} />
                                                <small className="text-muted">
                                                    {api.geDateFormat(item.createdAt)}
                                                </small>
                                            </div>
                                        ))}

                                    </div>
                                    {/* 
                                    <a href=""
                                        className="dropdown-item text-primary text-center notify-item notify-all">
                                        View all
                                        <i className="fi-arrow-right"></i>
                                    </a> */}

                                </div>
                            </li>

                            <li className="dropdown notification-list" style={{ marginTop: 8 }}>
                                <a className="nav-link dropdown-toggle nav-user mr-0" data-toggle="dropdown" href="#"
                                    role="button" aria-haspopup="false" aria-expanded="false"
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <img src={`${userAvatar === 'default.png' ? `${default1}` : `${api.var_.url_avatar + userAvatar}`}`} className="rounded-circle" />
                                    <span className="pro-user-name d-none d-xl-inline-block ml-2">
                                        <p style={{ marginTop: 10, color: '#A7CBF8', fontFamily: `"Noto Sans", sans-serif` }}>{api.initUser()} <i className="mdi mdi-chevron-down"></i></p>
                                        <p style={{ marginTop: '-15%', color: '#A7CBF8', marginRight: '10px', fontFamily: `"Noto Sans", sans-serif` }}><small>{api.initRole()}</small></p>
                                    </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">

                                    <div className="dropdown-header noti-title">
                                        <h6 className="text-overflow m-0">Welcome {api.initUser()} !</h6>
                                    </div>

                                    {/* <div onClick={()=>api.NavigatePrincipal()} style={{
                                        borderRadius: 100, border: '1px solid #1152D9', marginTop: 15, textAlign: 'center', display: 'flex',
                                        justifyContent: 'center', cursor: 'pointer',width:250, margin:2
                                    }}
                                    >
                                        <div>
                                            <div style={{ color: '#1576ED', padding: 10 }}>Manage your Tiva account</div>
                                        </div>
                                    </div> */}
                                    <Link
                                        to="/myaccount-tiva-vendor/home"

                                        className="dropdown-item notify-item"
                                    >
                                        <i className="mdi mdi-logout-variant"></i>
                                        <span>Profil</span>
                                    </Link>

                                    <div className="dropdown-divider"></div>


                                    <Link to="/login" onClick={logout} className="dropdown-item notify-item">
                                        <i className="mdi mdi-logout-variant"></i>
                                        <span>Logout</span>
                                    </Link>

                                </div>
                            </li>



                        </ul>

                        {/** LOGO */}
                        <div className="logo-box">

                            <div className="logo text-center">

                            </div>
                            <div className="logo text-center">

                                <span className="logo-lg">
                                    {/* <MenuOutlined onClick={showDrawer} style={{ fontSize: 30, color: '#A1C8F8', marginRight: 28, marginLeft: 20, cursor: 'pointer' }} /> */}
                                    <img src={logoSm} alt="" height="40" onClick={() => navigate('/vendor')} style={{ cursor: 'pointer' }} />
                                    {/** <span className="logo-lg-text-light">Simple</span> */}

                                </span>
                                <span className="logo-sm">
                                    {/* <MenuOutlined onClick={showDrawer} style={{ fontSize: 25, color: '#A1C8F8', marginRight: 10, marginLeft: 5, cursor: 'pointer' }} /> */}
                                    {/**  <span className="logo-sm-text-dark">S</span> */}
                                    <img src={logoSm} alt="" height="36" onClick={() => navigate('/vendor')} style={{ cursor: 'pointer' }} />
                                </span>
                            </div>
                        </div>

                        <div id="navigation" >
                            
                            <div className="input-group search-area d-lg-inline-flex" style={{ marginTop: 18 }}>
                                <div className="input-group-append">
                                    <button className="input-group-text"><i className="mdi mdi-magnify"></i></button>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..." />
                            </div>

                            {/* <ul className="navigation-menu">

                <li className="has-submenu">
                    <Link to="/home"> <i className="ti-home"></i> Dashboard <div className="arrow-down"></div>
                    </Link>
                    <ul className="submenu">
                        <li><Link to="/home">Home</Link></li>
                        <li><Link to="/add-payment-method">Add Payout Methods</Link></li>
                    </ul>
                </li>


                <li className="has-submenu"><Link to="/member"> <i className="icon-user"></i>Members</Link> </li>
                <li className="has-submenu"><Link to="/invetory"> <i className="mdi mdi-ticket"></i> Inventory</Link>
                </li>
                <li className="has-submenu"><Link to="/transaction"> <i className="mdi mdi-ticket"></i> Transactions</Link>
                </li>
                <li className="has-submenu"><Link to="/list_of_seller"> <i className="mdi mdi-format-list-bulleted"></i>
                    Sellers</Link></li>


            </ul>
            <div className="clearfix"></div> */}
                        </div>


                        <div className="clearfix"></div>
                    </div>
                </div>

            </header>
        </>
    )
}

export default HeaderVendor