import { useState } from "react";
import api from '../../../../config/Config';
import { Alert, Space } from "antd";
import { info2 } from "../../../../components/icons";

const MySecurity = () => {

    const [Loading1, setLoading1] = useState(false);

    const [newPassword, setNewPassword] = useState('')
    const [newConfirm, setnewConfirm] = useState('')
    const [oldPassword,setOldPassword] = useState('')

    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [showAlertDanger1, setShowAlertDanger1] = useState(false);
    const [message, setMessage] = useState("");

    const UpdatePassWord = () => {
        let data = JSON.stringify({
            "password": newPassword,
            "confirmPassword": newConfirm,
            "oldPassword": oldPassword
        });

        setLoading1(true);

        if (newPassword === '' || newConfirm === '' || oldPassword === '') {
            setShowAlertDanger1(true);
            setMessage('No fields should be empty');
        } else if (newPassword != newConfirm) {
            setShowAlertDanger1(true);
            setMessage('The password must be the same');
        }
        else {
            api.getHttpRequest(`/user/updatePassword/${api.initIdUserConnect()}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setShowAlertSuccess(true)
                    setMessage(json.message);
                } else {
                    setShowAlertDanger1(true)
                    setMessage(json.message);
                }
            }).catch((error) => {
                api.getMessageError(error)
            })

        }

        setTimeout(() => {
            setLoading1(false);
            setShowAlertSuccess(false)
            setShowAlertDanger1(false)
            setMessage('')
        }, 3000);
    }

    
    return (
        <>
            <div className='md:mt-8 sm:mt-20 smsl:mt-20 ml-1  gap-4 h-full bg-white' style={{marginTop:20}}>
                <div className=" flex p-2 justify-between items-center">

                    <div className="row align-items-center">
                        <div className="col-md-6">

                            <span className="font-25" style={{fontWeight:'bold', fontSize:16 ,fontFamily:`"Noto Sans", sans-serif`}}>Security Params </span>

                            <p className="p-tag-1 " style={{fontFamily:`"Noto Sans", sans-serif`}}>Tiva is an innovative technology-driven company committed to providing
                                affordable health insurance, microloans
                            </p>

                        </div>


                        <div className="col-md-6 wow slideInLeft" data-wow-delay="0.25s"
                            style={{ visibility: 'visible', animationDelay: '0.25s', animationName: 'slideInLeft' }}>

                            <div className="margin-right-15">

                                <img src={info2} className="width-100 bottom-margins-images" alt="pic" />

                            </div>

                        </div>

                    </div>
                    <div className="col-md-12">
                        <div
                            style={{
                                backgroundColor: "white",
                                border: "1px solid #F0F0F0",
                                padding: 5,
                                borderRadius: 5,
                                justifyContent: "center",

                            }}
                        >
                            {
                                message && (
                                    <>
                                        <div className='col-md-12 mb-1 mt-1'>
                                            {/* <label style={{color:'white'}}>vouchers</label> */}
                                            <Space direction="vertical" style={{ width: '100%' }}>
                                                {
                                                    showAlertSuccess &&
                                                    <Alert type="success" message={message} showIcon closable />
                                                }
                                                {
                                                    showAlertDanger1 &&
                                                    <Alert type="error" message={message} showIcon closable />
                                                }
                                            </Space>
                                        </div>
                                    </>
                                )
                            }
                            <form method="post" role="form" >
                                <input type="hidden" name="_token" value="pJzoCSLX89w5AgZulIazt4TlMMj1gpriu2ZTkoG4" />
                                <div className="form-group">
                                    <label htmlFor="newConfirm">Older Password</label>
                                    <input type="password" className="form-control" id="newConfirm" placeholder=""
                                        name='newConfirm'
                                        value={oldPassword}
                                        onChange={(e) => { setOldPassword(e.target.value) }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="newConfirm">New Password</label>
                                    <input type="password" className="form-control" id="newConfirm" placeholder=""
                                        name='newConfirm'
                                        value={newPassword}
                                        onChange={(e) => { setNewPassword(e.target.value) }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="newConfirm">Confirm Password</label>
                                    <input type="password" className="form-control" id="newConfirm" placeholder=""
                                        name='newConfirm'
                                        value={newConfirm}
                                        onChange={(e) => { setnewConfirm(e.target.value) }}
                                    />
                                </div>

                                {!Loading1 && (
                                    <button onClick={() => UpdatePassWord()} type="button" id="btn_update" className="btn btn-default btn-submit" 
                                     style={{backgroundColor:'#1576ED'}}
                                    >Update Password</button>
                                )}

                                {Loading1 && (

                                    <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Processing ...</span>
                                    </button>
                                )}
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MySecurity