import React, { useState } from 'react'
import { loginImage } from '../../../components/icons'
import { Link, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import api from '../../../config/Config'
import CryptoJS from 'crypto-js';

type Code = {
  email_or_phone: string;
  code_val: string
}

const VerifyOtp = () => {

  const navigate = useNavigate()
  const [Loading, setLoading] = useState(false);

  // Définir la clé de chiffrement
  const key = 'phone_account';

  // Récupérer la variable chiffrée depuis le local storage
  const phone_Cripter = sessionStorage.getItem('zxremonailnephoy');

  // Décrypter la variable
  let phone_Decripter: any;
  if (phone_Cripter !== null) {
    const bytes = CryptoJS.AES.decrypt(phone_Cripter, key);
    phone_Decripter = bytes.toString(CryptoJS.enc.Utf8);
  } else {
    phone_Decripter = null;
  }

  const [valcode, setValcode] = useState<Code>({
    email_or_phone: "",
    code_val: ""
  })

  const setNewValue = (id_: string, newValue: string) =>
    setValcode(prevState => ({ ...prevState, [id_]: newValue }))

  {/** Button validation code */ }
  const btnValidatecode = async () => {

    var data = JSON.stringify({
      "code_val": valcode.code_val,
      "phone": phone_Decripter
    });

    setLoading(true);

    if (valcode.code_val === '') {
      toast.error('The Code OTP field must not be empty')
    }
    else {
      api.getHttpRequestNotToken("/user/verify", data, 'POST').then((response) => {
        const json = response.data;
        if (json.status == 200) {
          toast.success(json.message)
          navigate('/login')
        } else {
          toast.error(json.message)
        }
      }).catch((error) => {
        if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
					toast.error("Please connect your device to the internet");
				} else if (error.response && error.response.data && error.response.data.message) {
					toast.error(error.response.data.message);
				} else {
					toast.error(error.message);
				}
      })
    }
    setTimeout(() => {
      setLoading(false);
    }, 5000)
  }

  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form className="login100-form validate-form">
              <div>
                <Toaster
                  position="top-right"
                  reverseOrder={false}
                />
              </div>
              <h2 className="header-title mb-3 font-30 font-weight-normal border-0 text-center">
                Verify OTP
              </h2>
              <label>Enter the code</label>
              <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                <input className="input100" type="text"
                  name='code_val'
                  value={valcode.code_val}
                  onChange={(e) => { setNewValue('code_val', e.target.value) }}
                  placeholder='Enter the code'
                />
                {/* <span className="focus-input100" style={{paddingLeft:25, paddingTop:5}}>Enter the code</span> */}
                {/* <span className="label-input100">Enter the code</span> */}
              </div>

              <div className="flex-sb-m w-full py-4">
                <div className="contact100-form-checkbox">
                  <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
                  <label className="label-checkbox100" htmlFor="ckb1">
                    Remember me
                  </label>
                </div>
                <div>
                  <a href="#" className="txt1">
                    Forgot Password?
                  </a>
                </div>
              </div>
              <div className="container-login100-form-btn">
                {!Loading && (
                  <button type="submit" className="login100-form-btn" onClick={btnValidatecode}>
                    Validate
                  </button>
                )}

                {Loading && (

                  <button type="submit" className="login100-form-btn" disabled>
                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 20 }}></i> <span>Ongoing processing...</span>
                  </button>
                )}
              </div>
              <div className=" py-4">
                <span className="txt2">
                  Already have an account?{" "}
                  <Link to="/login" className="txt1">
                    Login
                  </Link>
                </span>
              </div>
              <div className=" py-4">
                <span className="txt2">
                  By Sign Up in,I agree to the <a href="#">Tiva Policy Statement</a> and <a href="#">Terms and Condition</a>
                </span>
              </div>
              <div className="text-center py-4">
                <span className="txt2">
                  or sign up using
                </span>
              </div>
              <div className="login100-form-social flex-c-m">
                <a href="#" className="login100-form-social-item flex-c-m bg1 mr-2">
                  <i className="mdi mdi-facebook" aria-hidden="true"></i>
                </a>
                <a href="#" className="login100-form-social-item flex-c-m bg2 mr-2">
                  <i className="mdi mdi-twitter" aria-hidden="true"></i>
                </a>
              </div>
            </form>
            <div className="login100-more" style={{ backgroundImage: `url(${loginImage})` }}>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyOtp
