import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../config/Config";
import toast, { Toaster } from "react-hot-toast";
import CryptoJS from "crypto-js";
import "react-phone-input-2/lib/style.css";

const LoginByToken = () => {

  const { TokenUser } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loginByToken();
  }, [TokenUser]);

  const loginByToken = () => {
    api
      .getHttpRequestNotToken(`/user/login-by-token/${TokenUser}`, "", "GET")
      .then((response) => {
        const json = response.data;
        if (json.status === 200) {

          localStorage.setItem(
            "Access",
            JSON.stringify(json.data.accessList)
          );
          const key = "ma_clé_de_chiffrement_token_userpartner";
          const key_user = "ma_clé_de_chiffrement_user_connecter";
          const key_avatar = "cle_avatar";
          const key_role = "its_role_key";
          const key_partner_id = "it_partner_id";
          const key_account_access = "its_account_access";
          const key_url = "its_url";
          const key_id_user = "id_user";
          const key_to_url = "url_app_user";
          const key_to_phone = "url_app_phone";
          const key_iduser_socket = "key_iduser_socket_jv";
          // Définir la variable à crypter
          const token = json.data.token;
          const user_connect = json.data.username;
          const user_phone = json.data.phone;
          const user_id = json.data.id;
          const user_avatar = json.data.avatar;
          const user_role = json.data.role;
          const id_partner = json.data.partner_id;
          const user_account_access = json.data.account_access;
          const rolesCanCreateString = JSON.stringify(user_account_access);
          const user_is_show_member =
            json.data.account_access.infos.show_members;
          const user_is_url_login =
            json.data.account_access.infos.MetadataList.Application.url;
          const name_app_access =
            json.data.account_access.infos.MetadataList.Application.name;
          //root url pour redigiger l'utilisateur

          const tokenCrypter = CryptoJS.AES.encrypt(token, key).toString();
          const userconnectCrypter = CryptoJS.AES.encrypt(
            user_connect,
            key_user
          ).toString();
          const phoneconnectCrypter = CryptoJS.AES.encrypt(
            user_phone,
            key_to_phone
          ).toString();
          const avatarCrypter = CryptoJS.AES.encrypt(
            user_avatar,
            key_avatar
          ).toString();
          const userRoleCrypter = CryptoJS.AES.encrypt(
            user_role,
            key_role
          ).toString();
          const userIdCrypter = CryptoJS.AES.encrypt(
            id_partner,
            key_partner_id
          ).toString();
          const userAccountAccess = CryptoJS.AES.encrypt(
            rolesCanCreateString,
            key_account_access
          ).toString();
          const userUrlAccess = CryptoJS.AES.encrypt(
            user_is_url_login,
            key_url
          ).toString();
          const userIAccess = CryptoJS.AES.encrypt(
            user_id,
            key_id_user
          ).toString();
          const urlRoute = CryptoJS.AES.encrypt(
            name_app_access,
            key_to_url
          ).toString();
          const UserIdSocket = CryptoJS.AES.encrypt(
            user_id,
            key_iduser_socket
          ).toString();

          sessionStorage.setItem("onktxonktx", tokenCrypter);
          sessionStorage.setItem("sutielzatiwo", userconnectCrypter);
          sessionStorage.setItem("obvokotoratebo", avatarCrypter);
          sessionStorage.setItem("bobaonsraoulexajs", userRoleCrypter);
          sessionStorage.setItem("pppaidlozartooner", userIdCrypter);
          sessionStorage.setItem("donkamdiquoeserner", userAccountAccess);
          sessionStorage.setItem("isssmmenmbre", user_is_show_member);
          sessionStorage.setItem("iosrelbulrleogo", userUrlAccess);
          sessionStorage.setItem("iiiiisuusdider", userIAccess);
          sessionStorage.setItem("rpkjoutteappb", urlRoute);
          sessionStorage.setItem("rpkjoutteapphbonebor", phoneconnectCrypter);
          sessionStorage.setItem("socketusersconnexotejv", UserIdSocket);
          // sessionStorage.setItem('Isshowmember', json.data.account_access.infos.show_members);
          // const getRoute =
          //   json.data.account_access.infos.MetadataList.Application.route;
          const user_is_app_login =
            json.data.account_access.infos.MetadataList.Application.name;
          const getRoute = json.data.accessList.home_page.home_page;
          if (user_is_app_login === 'Partner App') {
            navigate(getRoute === null ? "/home" : getRoute);
          } else if (user_is_app_login === 'Vendor App') {
            navigate("/vendor");
          } else {
            navigate("/login");
          }

          // navigate(`${getRoute}`);
        } else {
          // window.location.href = `http://localhost:5173/#/tiva-account/${json.data.account_access.infos.MetadataList.Application.name}`;//dev
          // window.location.href = `https://www.tivaaccount.tiva.co.tz/#/tiva-account/${json.data.account_access.infos.MetadataList.Application.name}`; // prod
          navigate(`/login`);
        }
      })
      .catch((error) => {
        toast.error(api.getMessageError(error));
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          background: "#F3F6F9",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="30"
          width="30"
          viewBox="0 0 512 512"
          style={{ animation: "spin 30s linear infinite" }}
        >
          <path
            d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
            fill="#1576ED"
          />
        </svg>
      </div>
    </>
  );
};

export default LoginByToken;
