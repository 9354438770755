import { Alert, Modal, Space } from 'antd'
import PhoneInput from 'react-phone-input-2'
import { useEffect, useState } from 'react';
import api from '../../config/Config'
import Swal from 'sweetalert2';
import { useTextContext } from '../../TextProvider';
import requestTopay from '../../controller/requestTopay';


const ModalPayement = ({
    isModalOpen2, handleOk1, handleCancel1,
    title, amountTowithdraw, phoneModePayment, currency, nameMethode
}: any) => {

    const { profile } = useTextContext();
    const data = '';
    const [loading, setLoading] = useState(false);

    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');
    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);
    const [message, setMessage] = useState("");
    const [operator, setOperator] = useState('');
    const [icon, setIcon] = useState('');
    const [payId, setPayId] = useState('');
    const [paymentMethod, setPaymentMethod] = useState<requestTopay[]>([]);

    const [phone, setPhone] = useState<string>('');
    const [amount, setAmount] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        setPhone(`${phoneModePayment}`)
        setAmount(amountTowithdraw)
        payementMobile()
    }, [phoneModePayment, amountTowithdraw])

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };


    const Add_Payement = () => {
        let data = JSON.stringify({
            "amount": amount,
            "currency": "Tsh",
            "phone": phone,
            "type": title,
            "isFor": "PAY MONEY TO MEMBER"
        });

        setLoading(true);
        if (amount === '' || phone === '' || title === '') {
            setShowAlertDanger(true);
            setMessage('Make sure you have filled in all the red star fields');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger(true);
            setMessage('The entry number is incoreact');
        }
        else {
            api.getHttpRequest(`/mobile-money/create-receive`, data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setShowAlertSucess(true);
                    setMessage(json.message);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: json.message,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    handleCancel1();
                } else {
                    setShowAlertDanger(true);
                    setMessage(json.message);
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    const errorMessage = error.response.data.message;
                    setShowAlertDanger(true);
                    setMessage(errorMessage)
                }
            })
        }

        setTimeout(() => {
            setLoading(false);
            setMessage('')
            setShowAlertDanger(false);
            setShowAlertSucess(false);
        }, 5000);
    }

    const payementMobile = () => {
        api.getHttpRequest("/method-payment/phone/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setPaymentMethod(json.data)
                if (json.data.length !== 0) {
                    const defaultPaymentMethod = json.data.find((method: any) => method.isDefault === true);
                    if (defaultPaymentMethod) {
                        setPhone(defaultPaymentMethod.phone);
                        setOperator(defaultPaymentMethod.MobileOperator?.name);
                        setIcon(defaultPaymentMethod.MobileOperator?.icon);
                        setPayId(defaultPaymentMethod.id);
                    }
                }
            }
        }).catch((error: any) => {
            api.getMessageError(error)
        })
    }

    function getId(element: any) {
        setActiveIndex(element.id);
        setPhone(element.phone)
        setOperator(element.MobileOperator.name)
        setPayId(element.id)
        setIcon(element.MobileOperator.icon)
    }

    return (
        <>
            <Modal
                title={`${title}`}
                open={isModalOpen2} onOk={handleOk1} onCancel={handleCancel1}
                footer={null}
                width={500}
            >
                <div >
                    <div className=" p-2 form-label rounded-sm" role="alert">
                        <h2 className="font-24 text-center font-weight-normal" style={{ fontFamily: `"Noto Sans", sans-serif` }}>Withdraw to Your Account
                        </h2>
                    </div>
                    {/* fontFamily:'sans-serif' */}
                    {
                        message && (
                            <div className='col-md-12 mb-1 mt-1'>
                                {/* <label style={{color:'white'}}>vouchers</label> */}
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    {
                                        showAlertDanger &&
                                        <Alert type="error" message={message} showIcon closable />
                                    }
                                </Space>
                            </div>
                        )
                    }

                    <div style={{ width: '100%' }}>
                        <div className="">
                            <div className="">

                                <div className="" style={{ width: '100%' }}>

                                    <div className="basic-form">
                                        <div className="mb-1">
                                            <label className="form-label">&nbsp;</label>
                                            <div className="input-group" style={{ width: '100%' }}>
                                                <span className="input-group-text" style={{ fontFamily: `"Noto Sans", sans-serif` }}>{currency}</span>
                                                <input type="text" id="montant" className="default-select form-control border border-primaryx" style={{ borderColor: '#F5F5F5', fontFamily: `"Noto Sans", sans-serif`, height: 50 }}
                                                    placeholder="0.00"

                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div style={{ marginBottom: '10px' }}>

                                            <div className="">
                                                <h4 className=" font-18 text-secondary " style={{ fontFamily: `"Noto Sans", sans-serif` }}>Payment mode</h4>
                                                <div className="payment-mode-option">
                                                    <ul className="nav nav-pills mb-1 mt-1" id="pills-tab" role="tablist">
                                                        {paymentMethod.map((item, index) => {
                                                            if (item.verified == true) {
                                                                return (
                                                                    <>
                                                                        <li key={index} className="nav-item">
                                                                            <a
                                                                                className={`nav-link ${index === activeIndex ? "active" : ""}`}
                                                                                id={item.id}
                                                                                data-toggle="pill"
                                                                                href={`#${item.id}`}
                                                                                role="tab"
                                                                                aria-controls={item.id}
                                                                                aria-selected={paymentMethod[0] ? "true" : "false"}
                                                                                onClick={() => getId(item)}
                                                                                style={{ fontFamily: `"Noto Sans", sans-serif` }}
                                                                            >
                                                                                {item.MobileOperator.name}
                                                                            </a>
                                                                        </li>
                                                                    </>
                                                                )
                                                            }

                                                        })}
                                                    </ul>
                                                    <div  className="tab-pane show" id="pills-profile" role="tabpanel" aria-labelledby="m-pesa-01">
                                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="tigo-pesa-1">
                                                            <div>
                                                                <div className="d-flex align-items-center p-1 rounded border border-primaryx">

                                                                    <img className="me-3" width="49" height="49" style={{ borderRadius: 10 }} src={icon && icon ? icon : `${phone}`} />
                                                                    <div>
                                                                        <p className="text-primary font-weight-bold fs-14 mb-0 ml-1" style={{ fontFamily: `"Noto Sans", sans-serif` }}>{operator}</p>
                                                                        <span className="text-primary font-weight-bold font-w600 ml-1" style={{ fontFamily: `"Noto Sans", sans-serif` }}>{phone && phone ? phone : '+255'}</span>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            {/* <div className="col-md-5 " >
                                                                    <label htmlFor="nom" className="form-label ">Amount</label>
                                                                    <div className="input-group d-flex" style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                                                        <span className="input-group-text" style={{ fontWeight: 'bold' }}>00</span>
                                                                        <input type="number" 
                                                                            className="default-select form-control font-20 text-dark-1 border border-primary"
                                                                            disabled style={{ height: 55, width: '100%' }} placeholder="Amount" />
                                                                    </div>
                                                                </div> */}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <button type="button" className="btn btn-primary agree-link font-18"
                                        id="btn-Withdraw"
                                        onClick={() => Add_Payement()}
                                    >{title}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    )
}

export default ModalPayement