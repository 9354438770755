import { Modal } from 'antd';
import React, { useEffect, useRef } from 'react'
import api from '../../../config/Config';

interface ComponentWies {
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    title: string;
    src: string;
    width: any;
    height: any;
}

const ModalFrameViewaDocs: React.FC<ComponentWies> = ({
    isModalOpen,
    handleOk,
    handleCancel,
    title,
    src,  width, height
}) => {

    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.src = api.var_.url_avatar + src;
        }
    }, [src]);

    return (
        <>
            <Modal
                title={`${title} `}
                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                footer={null}
                width={900}
                maskClosable={false}
            >
                <iframe
                    ref={iframeRef}
                    width={width}
                    height={height}
                    style={{padding:10}}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </Modal>
        </>
    )
}

export default ModalFrameViewaDocs