import { Link, useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import {
    Card, Badge,

} from "antd";
import { useEffect, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import requestTopay from "../../../controller/requestTopay";
import requestRow from "../../../controller/RequestRow";
import MakePayement from "../makepayement/MakePayement";

const TransactionPay = () => {
   
    return (
        <>
            <div id="wrapper">
                <Header />
                {/* <Header /> */}
                <MakePayement/>
            </div>
        </>
    )
}

export default TransactionPay