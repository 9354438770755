
import { Outlet } from 'react-router-dom'
import LocationParams from '.'
import LocationHome from '../locationHome'

const LayoutLocation = () => {
  return (
    <>
      <div id="wrapper">
        <LocationHome />
        
        <div className="content-page">
          <div className="content">

            <div className="container-fluid1">
              <LocationParams />
              <div className="">{<Outlet />}</div>
            </div>

          </div>
        </div>
      </div>
    </>

  )
}

export default LayoutLocation
