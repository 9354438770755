import { Link, useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import {
    Card, Badge, 
    
} from "antd";
import { useEffect, useState } from "react";
import requestTopay from "../../../controller/requestTopay";
import requestRow from "../../../controller/RequestRow";
import HeaderVendor from "../../../components/header/IndexVendor";
import Footer from "../../../components/footer";
import MakeVendorPayment from "../../methodepayement/makepayement/MakeVendorPayment";

const Pay = () => {
 
    return (
        <>
         <div id="wrapper">
            <HeaderVendor />

                {/* <Header /> */}
                <MakeVendorPayment />
                
            </div>
        </>
    )
}

export default Pay