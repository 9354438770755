import React, { useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import { Button, Input, Steps } from "antd";
import { Link, useParams } from "react-router-dom";
import Header from "../../../components/header";
import { useQuery } from "react-query";
import { _getDetailCustomer } from "../../../tantack/detailCustomer/customer";
import CustomerDetailComp from "./CustomerDetailComp";
const { Step } = Steps;
const DetailCustomer = () => {
  const [current, setCurrent] = useState<number>(0);
  const { id_customer } = useParams();
  const steps = [
    {
      title: <label>Step 1: Names</label>,
      content: <></>,
    },
    {
      title: <label>Step 2: Your workspace</label>,
      content: <></>,
    },
    {
      title: <label>Step 3: Your dependents</label>,
      content: <></>,
    },
    {
      title: <label>Step 4: Photo</label>,
      content: <></>,
    },
    {
      title: <label>Application completed</label>,
      content: <></>,
    },
  ];

  const { data: dataCustomerInfo, isLoading: isLoadingdataCustomerInfo } =
    useQuery(
      ["customer_info", id_customer],
      () => _getDetailCustomer(id_customer),
      {
        enabled: !!id_customer,
      }
    );
  console.log(dataCustomerInfo);
  return (
    <div>
      <Header />
      <div style={{ paddingTop: "100px" }}>
        {isLoadingdataCustomerInfo ? (
          <>loading...</>
        ) : (
          <>
            <section style={{ backgroundColor: "#f3f3f3" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  padding: "0 20px",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                <div className="">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "20px" }}
                  >
                    <div>
                      <BsPersonCircle style={{ fontSize: "80px" }} />
                    </div>
                    <div>
                      <label className=" " style={{ margin: "5px 0" }}>
                        Names & dependents
                      </label>
                      <label style={{ fontSize: "20px", fontWeight: 700 }}>
                        {!dataCustomerInfo?.data?.name &&
                        !dataCustomerInfo?.data?.middlename &&
                        !dataCustomerInfo?.data?.lastname ? (
                          "Unkown Customer"
                        ) : (
                          <>
                            {dataCustomerInfo?.data?.name ?? ""}{" "}
                            {dataCustomerInfo?.data?.middlename ?? ""}{" "}
                            {dataCustomerInfo?.data?.lastname ?? ""}
                          </>
                        )}
                      </label>
                    </div>
                  </div>

                  <div
                    className="d-flex"
                    style={{ marginTop: "15px", minWidth: "max-content" }}
                  >
                    <label className="col-sm-3">
                      <div className="example form-group">
                        <label className=" ">Total</label>
                        <h5 style={{ marginTop: "15px", color: "#666666" }}>
                          {/* Tsh. {`${data?.data.deposit_amount.total}`} */}
                        </h5>
                      </div>
                    </label>
                    {/* {depositsList?.map((deposit) => (
                    <label className="col-sm-4">
                      <div className="example form-group">
                        <label style={{ textTransform: "capitalize" }}>
                          Tsh. {deposit.services.toLocaleLowerCase()}
                        </label>
                        <h5 style={{ marginTop: "15px", color: "#666666" }}>
                          Tsh. {`${deposit.amount}`}
                        </h5>
                      </div>
                    </label>
                  ))} */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="d-flex"
                    style={{
                      marginBottom: "15px",
                      gap: "5px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      style={{
                        borderColor: "#3d8ef7",
                        color: "#3d8ef7",
                        backgroundColor: "#f3f3f3",
                      }}
                    >
                      Import
                    </Button>
                    <Button
                      style={{
                        borderColor: "#3d8ef7",
                        color: "#3d8ef7",
                        backgroundColor: "#f3f3f3",
                      }}
                    >
                      Add To Campaign
                    </Button>
                    <Button
                      style={{
                        borderColor: "#3d8ef7",
                        color: "#3d8ef7",
                        backgroundColor: "#f3f3f3",
                      }}
                    >
                      Send List Email
                    </Button>
                    <Button
                      style={{
                        borderColor: "#3d8ef7",
                        color: "#3d8ef7",
                        backgroundColor: "#f3f3f3",
                      }}
                    >
                      Printable View
                    </Button>
                  </div>

                  <div
                    style={{
                      marginTop: "50px",
                      textAlign: "right",
                      color: "blue",
                    }}
                  >
                    <Link
                      style={{ textDecoration: "underline", color: "#3d8ef7" }}
                      to={"/member"}
                    >
                      Return to members
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <section
              style={{ padding: "10px", backgroundColor: "white" }}
              className="custom-steps"
            >
              <Steps
                current={current}
                className="site-navigation-steps"
                items={steps.map((step) => ({
                  key: step.title,
                  title: step.title,
                  icon: <></>,
                }))}
              >
                <Step
                  title={<label>Step 1: Names</label>}
                  icon={<></>}
                  status={
                    current === 0
                      ? "finish"
                      : current === 0
                      ? "process"
                      : "wait"
                  }
                />
                <Step
                  title={<label>Step 2: Your workspace</label>}
                  icon={<></>}
                  status={
                    current === 1
                      ? "finish"
                      : current === 1
                      ? "process"
                      : "wait"
                  }
                />
                <Step
                  title={<label>Step 3: Your dependents</label>}
                  icon={<></>}
                  status={
                    current === 2
                      ? "finish"
                      : current === 2
                      ? "process"
                      : "wait"
                  }
                />
                <Step
                  title={<label>Step 4: Photo</label>}
                  icon={<></>}
                  status={
                    current === 3
                      ? "finish"
                      : current === 3
                      ? "process"
                      : "wait"
                  }
                />
                <Step
                  title={<label>Application completed</label>}
                  icon={<></>}
                  status={
                    current === 4
                      ? "finish"
                      : current === 4
                      ? "process"
                      : "wait"
                  }
                />
              </Steps>
              {/* <div>{steps[current].content}</div> */}
            </section>
          </>
        )}
      </div>
      <CustomerDetailComp data={dataCustomerInfo!} />
    </div>
  );
};

export default DetailCustomer;
