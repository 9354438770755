import React, { ChangeEvent, useEffect, useState } from 'react'
import { avatar1, avatar3, b1, default1 } from '../../../components/icons'
import { useNavigate, useParams } from 'react-router-dom';
import requestRow from '../../../controller/RequestRow';
import axios from 'axios';
import api from '../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import { Alert, Pagination, Space, Select } from 'antd';
import {
    Card, Row, Col, Badge, Drawer, List, Input, Button,
    Checkbox
} from "antd";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import {
    DiffOutlined, SwapOutlined, HomeOutlined, UnorderedListOutlined
} from "@ant-design/icons"
import PhoneInput from 'react-phone-input-2'
import Footer from '../../../components/footer';
import Header from '../../../components/header';
import { MdOutlineSwapVert } from "react-icons/md";
const { Option } = Select;

const SellerAccount = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const { id_sellers } = useParams();
    const { transaction_by_id } = useParams();
    const jvId = api.initIdUserConnect();

    const navigate = useNavigate();
    const data = '';

    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');
    const regexEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');

    const [Loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [gender, setGender] = useState('');
    const [userRoleId, setUserRoleId] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [id, setId] = useState('');

    // Add telecom fields

    // Variables Load elements
    const [minicipality, setMinicipality] = useState<requestRow[]>([])
    const [categoriRole, setCategoriRole] = useState<requestRow[]>([])
    const [sellerLoad, setSellerLoad] = useState<requestRow[]>([])
    const [tva, setTva] = useState<requestRow[]>([])
    const [operator, setOperator] = useState<requestRow[]>([])

    //Load transaction by id 
    const [getRegion, setGetRegion] = useState<requestManagerAccount[]>([]);
    const [getDistrict, setGetDistrict] = useState<requestManagerAccount[]>([]);
    const [getWard, setGetWard] = useState<requestManagerAccount[]>([]);
    const [role, setRole] = useState<requestRow[]>([])
    const [stock, setStock] = useState<requestProfile[]>([]);
    const [loadConstant, setLoadConstant] = useState<requestVoucher[]>([]);
    const [allFromSerie, setAllFromSerie] = useState<requestVoucher[]>([]);
    const [seriesNumber, setSeriesNumber] = useState('')
    const [vouchersValid, setVoucherValid] = useState('')
    const [vouchersUsed, setVouchersUsed] = useState('')
    const [voucherDifference, setVoucherDifference] = useState(0)

    const [avatarUser, setAvatarUser] = useState('');
    const [sommePins, setSommesPins] = useState('')
    const [sommeTotal, setSommeTotal] = useState('')
    const [sommeAvailablePins, setSommeAvailablePins] = useState('')
    const [sommeDiscount, setSommeDiscount] = useState('')
    const [sommeVatAmount, setSommeVatAmount] = useState('')

    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);

    const [message, setMessage] = useState("");
    const [showAlertDanger1, setShowAlertDanger1] = useState(false);
    const [showAlertSucess1, setShowAlertSucess1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [region, setRegion] = useState('');
    const [district, setDistric] = useState('');
    const [ward, setWard] = useState('');
    const [wardName, setWardName] = useState('');
    const [description, setDescription] = useState('');
    //Payement textField
    const [getcurrency, setGetCurrency] = useState('');
    const [avatar, setAvatar] = useState<string | null>(null);


    //Var Stock 
    const [countAssign, setCountAssign] = useState('');
    const [countTotal, setCountTotal] = useState('');
    const [totalPinInStock, setTotalPinInStock] = useState('');
    const [countUsed, setCountUsed] = useState('');

    //filter to serie
    const [serieFrom, setSerieFrom] = useState('')
    const [serieTo, setSerieTo] = useState('')

    const [countTotal1, setCountTotal1] = useState('');
    const [countUsed1, setcountUsed] = useState('');
    const [countActive, setCountActive] = useState('')
    const [countTransfered, setCountTransfered] = useState('')
    const [countAvailable, setCountAvailable] = useState('')
    const [amount, setAmount] = useState('');
    const [totalVouchersDeleted, setTotalVouchersDeleted] = useState('');

    const [total, setTotal] = useState('');
    const [totalUsed, setTotalUsed] = useState('');
    const [totalVendorTransfer, setTotalVendorTransfer] = useState('');
    const [totalAvailable, setTotalAvailable] = useState('');
    const [totalVouchersActived, setTotalVouchersActived] = useState('');
    const [totalVouchersDeleted1, setTotalVouchersDeleted1] = useState('');
    const [totalAvailableAmount, setTotalAvailableAmount] = useState('');

    const [showAlertSuccess2, setShowAlertSuccess2] = useState(false);
    const [showAlertDanger2, setShowAlertDanger2] = useState(false);
    //Paginate
    const [totalPage, setTotalPage] = useState(1);

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }

        LoadRegion()
        LoadDistrict()
        LoadWard()
        loadMinicipality()
        loadCategorie_or_Role()
        // loadSeller()
        loadTva()
        loadOperator()
        LoadPrile();
        LoadStock(1);
        LoadVocher();
        LoadConstant();
        LoadFindVocher()
    }, [jvId]);

    const LoadPrile = async () => {
        api.getHttpRequest(`/user/admin/load/${id_sellers}`, data, 'GET').then((response) => {

            const json = response.data.data;

            const startDate = new Date(json.date_of_birth);
            const formattedStartDate = startDate.toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            setDateOfBirth(formattedStartDate)
            setId(json.id)
            setName(json.name);
            setMiddlename(json.middlename);
            setLastName(json.lastname);
            setEmail(json.email);
            setPhone(json.phone);
            setGender(json.gender)
            setAddress(json.address);
            setAvatarUser(json.avatar);
            setUserRoleId(json.UserRoleId);
            setRegion(json.LocationWard.LocationDistric.LocationRegion.name)
            setDistric(json.LocationWard.LocationDistric.name)
            setWardName(json.LocationWard.name)
            setWard(json.LocationWard.id)
            setPlaceOfBirth(json.place_of_birth)
        }).catch((error) => {
            api.getMessageError(error)
        })
    }

    const LoadStock = async (page: any) => {
        api.getHttpRequest(`/vouchers/in-stock?id_user=${id_sellers}&page=${page}`, data, 'GET').then((response) => {

            const json = response.data.data;
            setStock(json.data);
            // setCount(json.count);
            setCountAssign(json.count_assign);
            setCountTotal(json.count_total);
            setTotalPinInStock(json.total_pin_in_stock)
            setCountUsed(json.count_used)
            setTotalPage(json.count)
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    const LoadFindVocher = () => {

        api.getHttpRequest(`/vouchers/find-vouchers/${serieFrom}/${serieTo}/${jvId}`, data, 'GET').then((response) => {
            const json = response.data;
            // setLoadVoucher(json.results.rows);
            if (json.status === 200) {
                setCountTotal1(json.data.total);
                setcountUsed(json.data.total_used);
                setCountActive(json.data.total_vouchers_actived);
                setCountTransfered(json.data.total_vendor_transfer);
                setCountAvailable(json.data.total_available)
                setAmount(json.data.total_available_amount)
                setTotalVouchersDeleted(json.data.total_vouchers_deleted);
                setShowAlertSucess(true);
                setMessage(json.message);
                // setAssignedVouchers(json.total_vouchers);
                // setLoading1(false);
            } else {
                setShowAlertDanger(true);
                setMessage(json.message);
            }

        }).catch((error) => {
            setMessage(api.getMessageError(error.message))
        })

        setTimeout(() => {
            setShowAlertDanger(false);
            setShowAlertSucess(false);
            setLoading(false);
        }, 5000);
    }

    const LoadVocher = () => {
        api.getHttpRequest(`/vouchers/find-vouchers-account/${jvId}`, data, 'GET').then((response) => {
            const json = response.data;
            // setLoadVoucher(json.results.rows);
            if (json.status == 200) {
                setTotal(json.data.total)
                setTotalUsed(json.data.total_used)
                setAllFromSerie(json.data.assigned_vouchers);
                setTotalVendorTransfer(json.data.total_vendor_transfer)
                setTotalAvailable(json.data.total_available)
                setTotalVouchersActived(json.data.total_vouchers_actived)
                setTotalVouchersDeleted1(json.data.total_vouchers_deleted)
                setTotalAvailableAmount(json.data.total_available_amount)
                // setAssignedVouchers(json.total_vouchers);
                // setLoading1(false);
            } else {
                setShowAlertDanger(true);
                setMessage(json.message);
            }

        }).catch((error) => {
            setMessage(api.getMessageError(error.message))
        })

        setTimeout(() => {
            setShowAlertDanger(false);
            setShowAlertSucess(false);
            setLoading(false);
        }, 5000);
    }

    const LoadConstant = () => {
        api.getHttpRequest("/parameters/constant", data, 'GET').then((response) => {
            const json = response.data;
            setLoadConstant(json.data.rows)

        }).catch((error) => {
            setMessage(api.getMessageError(error.message))
        })
    }

    const getConstant = loadConstant.find((c) => c.name === 'currency')?.value;

    const NewUpdateProfile = () => {
        let data = JSON.stringify({
            "name": name,
            "lastname": lastname,
            "middlename": middlename,
            "email": email,
            "address": adress,
            "phone": phone,
            "id_ward": ward,
            "avatar": "",
            "gender": gender,
            "UserRoleId": id_sellers,
            "civil_status": civilStatus,
            "date_of_birth": dateOfBirth,
            "place_of_birth": placeOfBirth,
            "id_partner": api.initPartnerId(),
            "default_password": '1111111',
        });

        setLoading(true);

        if (name === '' || phone === '') {
            setShowAlertDanger1(true)
            setMessage1('Make sure you have filled in all the red star fields');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger1(true)
            setMessage1('The entry number is incoreact');
        }
        else {
            api.getHttpRequest(`/partners/app/members/${id_sellers}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    LoadPrile()
                    setShowAlertSucess1(true)
                    setMessage1(json.message);
                } else {
                    setShowAlertDanger1(true)
                    setMessage1(json.message);
                }
            }).catch((error) => {
                setMessage1(api.getMessageError(error))
            })

        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertSucess1(false)
            setShowAlertDanger1(false)
        }, 3000);
    }

    const UpdateAvatar = async () => {
        const formData = new FormData();

        if (avatar !== null) {
            formData.append("avatar", avatar); // Ajoute l'avatar au FormData s'il est défini
        }

        setLoading(true);
        if (avatar === null) {
            setShowAlertDanger2(true);
            setMessage("Please include image file");
        } else {
            try {
                const response = await axios.put(
                    api.var_.url_api_local + `/user/update_avatar/${id_sellers}`,
                    formData,
                    {
                        headers: {
                            Authorization: "Bearer " + api.initToken(),
                            "Content-Type": "multipart/form-data", // Utilisez "multipart/form-data" pour envoyer le FormData
                        },
                    }
                );

                const json = response.data;
                if (json.status == 200) {
                    LoadPrile();
                    setShowAlertSuccess2(true);
                    setMessage(json.message);

                } else {
                    setShowAlertDanger2(true);
                    setMessage(json.message);
                }
            } catch (error) {
                api.getMessageError(error);
            }
        }


        setTimeout(() => {
            setLoading(false);
            setShowAlertDanger2(false);
            setShowAlertSuccess2(false);
        }, 3000);

    };

    //Load zones
    const LoadRegion = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/region/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetRegion(json.rows)

        }).catch((error: any) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }
    const LoadDistrict = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/district/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetDistrict(json.rows)

        }).catch((error: any) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }
    const LoadWard = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/ward/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetWard(json.rows)

        }).catch((error: any) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }


    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    // const loadRole = async () => {
    //     api.getHttpRequest("/parameters/roles/load?groupe=2", data, 'GET').then((response) => {
    //         const json = response.data;
    //         if (json.status == 200) {
    //             setRole(json.data);
    //         }
    //     }).catch((error) => {
    //         // setLoading(false);
    //         if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
    //             toast.error("Please connect your device to the internet");
    //         } else if (error.response && error.response.data && error.response.data.message) {
    //             toast.error(error.response.data.message);
    //         } else {
    //             toast.error(error.message);
    //         }
    //     })
    // }

    const loadMinicipality = async () => {
        api.getHttpRequest("/zone/municipality/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setMinicipality(json.data);
            }
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    const loadCategorie_or_Role = async () => {
        api.getHttpRequest("/parameters/roles/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCategoriRole(json.data);
            }
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    const loadTva = async () => {
        api.getHttpRequest("/parameters/constant", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setTva(json.data.rows);
                setRole(json.data.rows);
            }
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    const loadOperator = async () => {
        api.getHttpRequest("/parameters/mobile-operator/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setOperator(json.data);
            }
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateDistrict, setStateDistrict] = useState<requestManagerAccount[]>([])
    const [stateWard, setStateWard] = useState<requestManagerAccount[]>([])
    const GetRegion = (id: any) => {
        const IdRegion = getDistrict.filter(x => x.LocationRegionId === id);
        setStateDistrict(IdRegion)
    }

    const GetWard = (id: any) => {
        const IdDistrict = getWard.filter(x => x.LocationDistricId === id);
        setStateWard(IdDistrict)
    }


    //Attribute voucher
    const AttributeVoucher = () => {
        let data = JSON.stringify({
            "serie_from": serieFrom,
            "serie_to": serieTo,
            "id_user_to": id_sellers,
            "observation": description,
            "amount": amount,
            "currency": getConstant,
            "id_user_from": jvId
        });

        if (serieFrom === '' || serieTo === '' || amount === '') {
            setShowAlertDanger(true)
            setMessage('Make sure you have filled in all the red star fields');
        }
        else {

            api.getHttpRequest("/vouchers/share", data, 'POST').then((response: any) => {
                const json = response.data;
                if (json.status == 200) {
                    setShowAlertSucess(true)
                    setMessage(json.message);
                    navigate(`#tansactions_tab`)
                } else {
                    setShowAlertDanger(true)
                    setMessage(json.message);
                }
            }).catch((error: any) => {
                setShowAlertDanger(true)
                setMessage(api.getMessageError(error))
            })
        }


        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    function onClickTr(element: any) {
        setSerieFrom(element.serie_from);
        setSerieTo(element.serie_to);
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0]; // Récupère le premier fichier sélectionné

        if (selectedFile) {
            const fileName = selectedFile.name; // Récupère le nom du fichier
            setAvatar(fileName); // Met à jour l'état avec le nom du fichier
        } else {
            setAvatar(null); // Aucun fichier sélectionné, met à jour l'état avec null
        }
    };

    return (
        <>
            <div id="wrapper">

                <Header />
                <div className="content-page">
                    <div className="content">
                        {/** Start container-fluid */}

                        <div className="container-fluid1">


                            <div className="row">

                                {/** main-tabs */}
                                <div className="col-12 seller-tabs">

                                    <ul className="nav nav-tabs tabs-bordered mt-4 ">

                                        <li className="nav-item">
                                            <a href="#tansactions_tab" data-toggle="tab" aria-expanded="true" className="nav-link active">
                                                <span className="d-block d-sm-none"><i
                                                    className="mdi mdi-swap-vertical font-18"></i></span>
                                                <span className="d-none d-sm-block"><SwapOutlined className="font-22" />
                                                    Stock</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#add_Voucher_tab" data-toggle="tab" aria-expanded="false" className="nav-link">
                                                <span className="d-block d-sm-none"><i
                                                    className="mdi mdi-ticket-outline font-18"></i></span>
                                                <span className="d-none d-sm-block">
                                                    <DiffOutlined className="font-22" />
                                                    Share Voucher</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#edit_profile" data-toggle="tab" aria-expanded="false" className="nav-link ">
                                                <span className="d-block d-sm-none"><i
                                                    className="mdi mdi-account-edit-outline font-18"></i></span>
                                                <span className="d-none d-sm-block"><UnorderedListOutlined className="font-22" /> Profile</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div> {/** main-tabs */}

                                <div className="col-xl-12">
                                    <div className="card stacked-2 seller-account-tab">
                                        <div className="row">
                                            <div className="col-12">

                                                <div className="tab-content">


                                                    <div className="tab-pane active" id="tansactions_tab">

                                                        <div className="col-md-12">
                                                            <div className="card-1">
                                                                <div className="card-header d-block d-sm-flex border-0">
                                                                    <div>
                                                                        <h4 className="fs-20 text-black pb-2">My Stock</h4>
                                                                        <div className="d-flex">
                                                                            <div className="mr-2 d-flex" style={{ backgroundColor: '#5BBDF6', color: 'white', width: 250, padding: 10, borderRadius: 5, justifyContent: 'space-between' }}>
                                                                                <span>Total : </span>
                                                                                <span>{countTotal}</span>
                                                                            </div>

                                                                            <div className="mr-2 d-flex" style={{ backgroundColor: '#12B9C3', color: 'white', width: 250, padding: 10, borderRadius: 5, justifyContent: 'space-between' }}>
                                                                                <span >Assign : </span>
                                                                                <span>{countAssign}</span>
                                                                            </div>

                                                                            <div className="mr-2 d-flex" style={{ backgroundColor: '#8BC17B', color: 'white', width: 250, padding: 10, borderRadius: 5, justifyContent: 'space-between' }}>
                                                                                <span>Used :</span>
                                                                                <span>{countUsed}</span>
                                                                            </div>

                                                                            <div className="mr-2 d-flex" style={{ backgroundColor: '#AEC7EA', color: 'white', width: 250, padding: 10, borderRadius: 5, justifyContent: 'space-between' }}>
                                                                                <span>In Stocke :</span>
                                                                                <span>{totalPinInStock}</span>
                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                                <div className="card-body payment-bx tab-content p-0">
                                                                    <div className="tab-pane active show fade" role="tabpanel">
                                                                        <div className="card-body">
                                                                            <div className="table-responsive">
                                                                                <table className="table header-border table-responsive-sm tbl-common">
                                                                                    <thead>
                                                                                        <tr style={{ height: 5 }}>
                                                                                            {/* <th style={{ width: 40, paddingLeft: 2, }}>#</th> */}
                                                                                            <th >Inventory# </th>
                                                                                            <th >Series</th>
                                                                                            <th>Amount</th>
                                                                                            <th >Operators</th>
                                                                                            <th >Total Vouchers</th>
                                                                                            <th>Status</th>
                                                                                            <th >User From</th>
                                                                                            {/* <th style={{ borderLeft: '2px solid #ffffff' }}>More</th> */}

                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody id="tab_">
                                                                                        {
                                                                                            stock.map((item, i) => (
                                                                                                <tr key={i} style={{ borderBottom: '1px solid #E8E8E8' }}>
                                                                                                    {/* <td scope="row" style={{ width: 10, paddingLeft: 2, paddingRight: 8, color: "black", fontSize: 20 }}>
                                    1
                                </td> */}
                                                                                                    <td >
                                                                                                        <div style={{ marginBottom: 10 }}><strong >{item.operation.reference}</strong></div><br />
                                                                                                        {api.formatDate(item.operation.createdAt)}<br />
                                                                                                        {api.geDateFormat(item.operation.createdAt)}

                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span><span style={{ fontSize: 16 }}>Serie From :</span> {item.operation.serie_from}</span> <br />
                                                                                                        <span><span style={{ fontSize: 16 }}>Serie To :</span>  {item.operation.serie_to}</span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {item.vouchers.currency}.{item.operation.amount}
                                                                                                    </td>
                                                                                                    <td>

                                                                                                        {item.operator.map((operator, x) => (
                                                                                                            <>
                                                                                                                <span key={x} className="" style={{ marginBottom: '10px' }}>
                                                                                                                    <img

                                                                                                                        src={operator.icon}
                                                                                                                        className="rounded-pill bg-light"
                                                                                                                        style={{ width: 40, height: 35, }}
                                                                                                                        alt=""
                                                                                                                    />
                                                                                                                </span><br />
                                                                                                            </>
                                                                                                        ))}

                                                                                                    </td>
                                                                                                    <td  >
                                                                                                        <div>
                                                                                                            <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Currency:</strong> {item.vouchers.currency}.{item.vouchers.amount}</p>
                                                                                                            <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Donimination:</strong><br />{item.vouchers.donimination} </p>
                                                                                                            <p><strong style={{ fontSize: 16 }}>Inventory Order Total:</strong><br /> {item.vouchers.currency}.{item.vouchers.inventory_order_total}</p>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                    <td  >
                                                                                                        <div >
                                                                                                            <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Used:</strong> {item.vouchers_status.used}</p>
                                                                                                            <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Asigned:</strong><br /> {item.vouchers_status.assign}</p>
                                                                                                            <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Available:</strong><br /> {item.vouchers_status.available}</p>
                                                                                                        </div>

                                                                                                    </td>

                                                                                                    <td style={{ maxWidth: '230px' }}>
                                                                                                        <div>
                                                                                                            <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Names:</strong> {item.operation.user_from.name}</p>

                                                                                                            <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Adress:</strong> <br />
                                                                                                                {item.operation.user_from.address === 'null' ? '-' : item.operation.user_from.address}
                                                                                                            </p>
                                                                                                            <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Phone:</strong> {item.operation.user_from.phone}</p>
                                                                                                            <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Email:</strong> {item.operation.user_from.email}</p>
                                                                                                        </div>
                                                                                                    </td>

                                                                                                    {/* <td>
                                            <button style={{
                                                marginTop: 65,
                                                backgroundColor: '#F3F6F9',
                                                color: 'black', borderColor: '#F3F6F9',
                                                borderRadius: 5, height: 30, paddingBottom: 5,
                                                width: 80

                                            }}>More</button>
                                        </td> */}
                                                                                                </tr>
                                                                                            ))
                                                                                        }



                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <nav>
                                                                                <Pagination
                                                                                    style={{
                                                                                        paddingTop: 10
                                                                                    }}
                                                                                    pageSize={10}
                                                                                    total={totalPage}
                                                                                    onChange={(page: any) => {
                                                                                        LoadStock(page)
                                                                                    }}
                                                                                />
                                                                            </nav>
                                                                        </div>

                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tab-pane" id="add_Voucher_tab">
                                                        <div className="col-md-12">
                                                            <Card style={{ width: '100%', marginBottom: 15 }}>
                                                                <div className="m-0">
                                                                    <h4 className="fs-18 text-black mb-0">Share To : {name + ' ' + lastname + ' ' + middlename}</h4>
                                                                    <small><strong>Adress:</strong> {region + ' ' + district + ' ' + wardName}</small><br />
                                                                    <small><strong>Phone:</strong> {phone}</small>
                                                                </div>
                                                                {
                                                                    message && (
                                                                        <div className='col-md-12 mb-1'>
                                                                            {/* <label style={{color:'white'}}>vouchers</label> */}
                                                                            <Space direction="vertical" style={{ width: '100%' }}>
                                                                                {
                                                                                    showAlertSucess &&
                                                                                    <Alert type="success" message={message} showIcon closable />
                                                                                } {
                                                                                    showAlertDanger &&
                                                                                    <Alert type="error" message={message} showIcon closable />
                                                                                }
                                                                            </Space>


                                                                        </div>
                                                                    )
                                                                }

                                                                <table style={{
                                                                    border: '1px solid black',
                                                                    width: '100%',
                                                                    borderColor: '#F2F2F2'
                                                                }}>
                                                                    {
                                                                        isMatch ? (
                                                                            <>
                                                                                <tbody style={{ display: 'row' }}>

                                                                                    <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                                                                        <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                                                            <div className="inputBox m-1">
                                                                                                <label htmlFor="serie_from" className="col-">Serie From</label>
                                                                                                <input type="text" id="serie_from" className="form-control" aria-describedby="passwordHelpInline"
                                                                                                    value={serieFrom}
                                                                                                    onChange={(e) => setSerieFrom(e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </td >
                                                                                    </tr>
                                                                                    <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                                                                        <div className="inputBox m-1">
                                                                                            <label htmlFor="serie_to" className="col-">Serie To</label>
                                                                                            <input type="text" id="serie_to" className="form-control" aria-describedby="passwordHelpInline"
                                                                                                value={serieTo}
                                                                                                onChange={(e) => setSerieTo(e.target.value)}
                                                                                            />
                                                                                        </div>
                                                                                    </tr>
                                                                                    <tr style={{ border: '1px solid #F0F0F0', display: 'row', }} >
                                                                                        <div style={{ border: '1px solid #F0F0F0' }}>
                                                                                            <div className="inputBox m-1">
                                                                                                <label className="form-label text-white">Find</label>

                                                                                                <button onClick={() => LoadFindVocher()} className="btn btn-primary form-control text-white" data-bs-toggle="dropdown" aria-expanded="false" >
                                                                                                    Find<i className="ri-arrow-down-s-line align-middle ms-1 d-inline-block"></i>
                                                                                                </button>


                                                                                            </div>

                                                                                        </div>
                                                                                    </tr>


                                                                                    <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                                                                        <div className="inputBox m-1">
                                                                                            <label htmlFor="serie_from" className="col-">Description</label>
                                                                                            <textarea id="serie_from" className="form-control" aria-describedby="passwordHelpInline"
                                                                                                value={description}
                                                                                                onChange={(e) => setDescription(e.target.value)}
                                                                                            />
                                                                                        </div>
                                                                                    </tr>

                                                                                    <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                                                                        <div style={{ border: '1px solid #F0F0F0' }}>
                                                                                            <div className="inputBox m-1">
                                                                                                <label className="form-label text-white">Find</label>
                                                                                                {!Loading && (
                                                                                                    <button type="submit" className="form-control btn btn-primary text-white" onClick={AttributeVoucher}>
                                                                                                        Save to Attribute
                                                                                                    </button>
                                                                                                )}

                                                                                                {Loading && (

                                                                                                    <button type="submit" className="form-control btn btn-primary text-white" disabled>
                                                                                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 20 }}></i> <span></span>
                                                                                                    </button>
                                                                                                )}
                                                                                            </div>

                                                                                        </div>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <tbody >
                                                                                    <tr style={{ border: '1px solid #F0F0F0' }}>

                                                                                        <td colSpan={10} style={{ border: '1px solid #F0F0F0' }}>
                                                                                            <div className="inputBox m-1">
                                                                                                <label htmlFor="serie_from" className="col-">Serie From</label>
                                                                                                <input type="text" id="serie_from" className="form-control" aria-describedby="passwordHelpInline"
                                                                                                    value={serieFrom}
                                                                                                    onChange={(e) => setSerieFrom(e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td colSpan={10} style={{ border: '1px solid #F0F0F0' }}>
                                                                                            <div className="inputBox m-1">
                                                                                                <label htmlFor="serie_to" className="col-">Serie To</label>
                                                                                                <input type="text" id="serie_to" className="form-control" aria-describedby="passwordHelpInline"
                                                                                                    value={serieTo}
                                                                                                    onChange={(e) => setSerieTo(e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td >
                                                                                            <div style={{ border: '1px solid #F0F0F0' }}>
                                                                                                <div className="inputBox m-1">
                                                                                                    <label className="form-label text-white">Find</label>

                                                                                                    <button onClick={() => LoadFindVocher()} className="btn btn-primary form-control text-white" data-bs-toggle="dropdown" aria-expanded="false" >
                                                                                                        Find<i className="ri-arrow-down-s-line align-middle ms-1 d-inline-block"></i>
                                                                                                    </button>

                                                                                                </div>

                                                                                            </div>
                                                                                        </td>
                                                                                        <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                                                            <div className="inputBox m-1">
                                                                                                <label htmlFor="serie_from" className="col-">Description</label>
                                                                                                <textarea id="serie_from" className="form-control" aria-describedby="passwordHelpInline"
                                                                                                    value={description}
                                                                                                    onChange={(e) => setDescription(e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </td>

                                                                                        <td >
                                                                                            <div style={{ border: '1px solid #F0F0F0' }}>
                                                                                                <div className="">
                                                                                                    <label className="form-label text-white">Find</label>
                                                                                                    {!Loading && (
                                                                                                        <button type="submit" className="form-control btn btn-primary text-white" onClick={AttributeVoucher}>
                                                                                                            Attribute
                                                                                                        </button>
                                                                                                    )}

                                                                                                    {Loading && (

                                                                                                        <button type="submit" className="form-control btn btn-primary text-white" disabled>
                                                                                                            <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 20 }}></i> <span></span>
                                                                                                        </button>
                                                                                                    )}
                                                                                                </div>

                                                                                            </div>
                                                                                        </td>

                                                                                    </tr>

                                                                                </tbody>
                                                                            </>
                                                                        )
                                                                    }

                                                                </table>

                                                                <div className='mt-4'>

                                                                    <div className="table-responsive">
                                                                        <table className="table header-border table-responsive-sm tbl-common text-center" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">Count</th>
                                                                                    <th scope="col">Used</th>
                                                                                    <th scope="col">Vendor Transfered</th>
                                                                                    <th>Avalaible</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Currency</th>
                                                                                    <th>Voucher Active</th>
                                                                                    <th>Voucher Deleted</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody id="tab_" >
                                                                                <tr >
                                                                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                                                                        <div className="inputBox m-1" style={{ width: 100 }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                aria-describedby="passwordHelpInline"
                                                                                                defaultValue={countTotal1}
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                                                                        <div className="inputBox m-1" style={{ width: 100 }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                aria-describedby="passwordHelpInline"
                                                                                                defaultValue={countUsed1}
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                                                                        <div className="inputBox m-1" style={{ width: 100 }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                aria-describedby="passwordHelpInline"
                                                                                                defaultValue={countTransfered}
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </td>

                                                                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                                                                        <div className="inputBox m-1" style={{ width: 100 }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                aria-describedby="passwordHelpInline"
                                                                                                defaultValue={countAvailable}
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                                                                        <div className="inputBox m-1" style={{ width: 100 }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                aria-describedby="passwordHelpInline"
                                                                                                defaultValue={amount}
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                                                                        <div className="inputBox m-1" style={{ width: 100 }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                aria-describedby="passwordHelpInline"
                                                                                                defaultValue={getConstant}
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </td>

                                                                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                                                                        <div className="inputBox m-1" style={{ width: 100 }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                aria-describedby="passwordHelpInline"
                                                                                                defaultValue={countActive}
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </td>

                                                                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                                                                        <div className="inputBox m-1" style={{ width: 100 }}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                aria-describedby="passwordHelpInline"
                                                                                                defaultValue={totalVouchersDeleted}
                                                                                                disabled
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                    <div className="mb-1 d-flex d-block" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>

                                                                        <div className="" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Total Voucher</strong> <br />
                                                                                    <span>{total}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Voucher Used</strong> <br />
                                                                                    <span>{totalUsed}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Vendor Transfer</strong> <br />
                                                                                    <span>{totalVendorTransfer}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Voucher Available</strong> <br />
                                                                                    <span>{totalAvailable}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Vouchers Actived</strong> <br />
                                                                                    <span>{totalVouchersActived}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Vouchers Deleted</strong> <br />
                                                                                    <span>{totalVouchersDeleted1}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Available Amount</strong> <br />
                                                                                    <span>{totalAvailableAmount}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="table-responsive">
                                                                        <table className="table header-border table-responsive-sm tbl-common text-center" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">ID</th>
                                                                                    <th scope="col">SERIE FROM</th>
                                                                                    <th scope="col">SERIE TO</th>
                                                                                    <th scope="col">TOTAL VOUCHER</th>
                                                                                    <th scope="col">USER FROM</th>
                                                                                    <th scope="col">PHONE</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    allFromSerie?.map((item, i) => (
                                                                                        <tr onClick={() => onClickTr(item)} style={{ cursor: 'pointer' }} key={i}>
                                                                                            <td>{i + 1}</td>
                                                                                            <td>{item.serie_from}</td>
                                                                                            <td>{item.serie_to}</td>
                                                                                            <td><strong style={{ backgroundColor: '#E4F5EB', color: '#59937D', borderRadius: 5, padding: 8, }}>{item.total_vouchers}</strong></td>
                                                                                            <td>{item.name + ' ' + item.middlename + ' ' + item.lastname}</td>
                                                                                            <td>{item.phone}</td>

                                                                                        </tr>
                                                                                    ))
                                                                                }

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>

                                                            </Card>
                                                        </div>
                                                    </div>

                                                    <div className="tab-pane" id="edit_profile">

                                                        <div className="card-header flex-wrap border-0 pb-0 align-items-end profile ">
                                                            <div className="profile-head">
                                                                <h4 className="fs-20 text-black mb-2">Profile
                                                                </h4>
                                                                <div className="photo-content">

                                                                    <div className="row">

                                                                        <div className='col-md-12 row'>
                                                                            <div className='col-md-2 '>
                                                                                <div className="pic-holder ">
                                                                                    <img id="profilePic" className="pic" src={`${avatarUser === 'default.png' ? `${avatar1}` : `${api.var_.url_avatar + avatarUser}`}`} />

                                                                                    <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{ opacity: 0 }} />
                                                                                    <label htmlFor="newProfilePhoto" className="upload-file-block">
                                                                                        <div className="text-center">
                                                                                            <div className="mb-1">
                                                                                                <i className="fa fa-camera fa-2x"></i>
                                                                                            </div>
                                                                                            <div className="text-uppercase">
                                                                                                Update <br /> Profile Photo
                                                                                            </div>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-md-10 row'>
                                                                                <div className="mb-1 col-md-3 ">
                                                                                    <label className="form-label">Name</label>
                                                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                                                        name='name'
                                                                                        value={name}
                                                                                        onChange={(e) => { setName(e.target.value) }}
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-1 col-md-3">
                                                                                    <label className="form-label">LastName</label>
                                                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                                                        name='lastname'
                                                                                        value={lastname}
                                                                                        onChange={(e) => { setLastName(e.target.value) }}
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-1 col-md-3">
                                                                                    <label className="form-label">MiddleName</label>
                                                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                                                        name='middlename'
                                                                                        value={middlename}
                                                                                        onChange={(e) => { setMiddlename(e.target.value); }}
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-1 col-md-3">
                                                                                    <label className="form-label">Gender</label>
                                                                                    <select className="form-control" id="gender"
                                                                                        name='gender'
                                                                                        value={gender}
                                                                                        onChange={(e) => { setGender(e.target.value) }}
                                                                                    >
                                                                                        <option>Choose...</option>
                                                                                        <option value="Male">Male</option>
                                                                                        <option value="Female">Female</option>
                                                                                        <option value="Other">Other</option>
                                                                                    </select>
                                                                                </div>

                                                                                <div className="mb-1 col-md-3">
                                                                                    <p className="form-label">Civil status</p>
                                                                                    <select className="form-control" id="gender"
                                                                                        name='civilStatus'
                                                                                        value={civilStatus}
                                                                                        onChange={(e) => { setCivilStatus(e.target.value) }}
                                                                                    >
                                                                                        <option>Choose...</option>
                                                                                        <option value="Married">Married</option>
                                                                                        <option value="Single">Single</option>
                                                                                        <option value="Widow">Widow</option>
                                                                                        <option value="Widower">Widower</option>
                                                                                        <option value="Other">Other</option>
                                                                                    </select>
                                                                                </div>

                                                                                <div className="mb-1 col-md-3">
                                                                                    <label className="form-label">Date of birth</label>
                                                                                    <input type="date" className="form-control" placeholder="" id="datenaiss"
                                                                                        name='dateOfBirth'
                                                                                        value={dateOfBirth}
                                                                                        onChange={(e) => { setDateOfBirth(e.target.value) }}
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-1  col-md-3">
                                                                                    <label className="form-label">Place of Birth</label>
                                                                                    <input type="text" className="form-control" placeholder="" id="phone"
                                                                                        name='placeOfBirth'
                                                                                        value={placeOfBirth}
                                                                                        onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-1  col-md-3">
                                                                                    <label className="form-label">Phone</label>
                                                                                    <PhoneInput
                                                                                        inputStyle={{
                                                                                            width: '100%',

                                                                                        } as React.CSSProperties}
                                                                                        inputClass={'form-control'}
                                                                                        country={'tz'}
                                                                                        value={phone}
                                                                                        onChange={changePhoneNumber}
                                                                                        enableSearch={true}
                                                                                        countryCodeEditable={true}
                                                                                    />
                                                                                </div>

                                                                                <div className="mb-1  col-md-3">
                                                                                    <label className="form-label">Adress Mail</label>
                                                                                    <input type="email" className="form-control" placeholder="" id="email"
                                                                                        name='email'
                                                                                        value={email}
                                                                                        onChange={(e) => { setEmail(e.target.value); }}
                                                                                    />
                                                                                </div>

                                                                                <div className="mb-1 col-md-3">
                                                                                    <label className="form-label">Region</label>

                                                                                    <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                                                                        className="form-control"
                                                                                        placeholder="Select country" optionFilterProp="children" value={region}
                                                                                        onChange={(value: any) => {
                                                                                            GetRegion(value)
                                                                                            setRegion(value)
                                                                                        }}
                                                                                        filterOption={(input: string, option: any) =>
                                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }>
                                                                                        <Option selected value="">Select Region
                                                                                        </Option>
                                                                                        {
                                                                                            getRegion &&
                                                                                                getRegion !== undefined ?
                                                                                                getRegion.map((item, i) => (
                                                                                                    <Option key={i} value={item.id}>{item.name}</Option>
                                                                                                ))
                                                                                                : "No Province"
                                                                                        }
                                                                                    </Select>
                                                                                </div>
                                                                                <div className="mb-1  col-md-3">
                                                                                    <label className="form-label">District</label>

                                                                                    <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 200 }}
                                                                                        className="form-control"
                                                                                        placeholder="Select country" optionFilterProp="children" value={district}
                                                                                        onChange={(value: any) => {
                                                                                            GetWard(value)
                                                                                            setDistric(value)
                                                                                        }}
                                                                                        filterOption={(input: string, option: any) =>
                                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }
                                                                                    >
                                                                                        <Option selected value="">Select district
                                                                                        </Option>
                                                                                        {
                                                                                            stateDistrict &&
                                                                                                stateDistrict !== undefined || stateDistrict !== null ?
                                                                                                stateDistrict.map((item, i) => (
                                                                                                    <Option key={i} value={item.id}>{item.name}</Option>
                                                                                                ))
                                                                                                : "No City"
                                                                                        }
                                                                                    </Select>
                                                                                </div>

                                                                                <div className="mb-1  col-md-3">
                                                                                    <label className="form-label">Ward/Kata</label>

                                                                                    <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                                                                        className="form-control"
                                                                                        placeholder="Select minicipality" optionFilterProp="children" value={wardName}
                                                                                        onChange={(value: any) => {
                                                                                            setWard(value)
                                                                                            setWardName(value)

                                                                                        }}
                                                                                        filterOption={(input: string, option: any) =>
                                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }>
                                                                                        <Option selected value="">Select ward/kata
                                                                                        </Option>
                                                                                        {
                                                                                            stateWard &&
                                                                                                stateWard !== undefined || stateWard !== null ?
                                                                                                stateWard.map((item, i) => (
                                                                                                    <Option key={i} value={item.id}>{item.name}</Option>
                                                                                                ))
                                                                                                : "No minicipality"
                                                                                        }
                                                                                    </Select>
                                                                                </div>

                                                                                <div className="mb-2  col-md-12 ">
                                                                                    <label className="form-label">Adresse</label>
                                                                                    <textarea className="form-control mr-5" placeholder="" id="adresse"
                                                                                        name='adress'
                                                                                        value={adress}
                                                                                        onChange={(e) => { setAddress(e.target.value) }}
                                                                                    ></textarea>
                                                                                </div>

                                                                                {
                                                                                    message1 && (
                                                                                        <>
                                                                                            <div className='col-md-12 mb-0 '>
                                                                                                {/* <label style={{color:'white'}}>vouchers</label> */}
                                                                                                <Space direction="vertical" style={{ width: '100%' }}>
                                                                                                    {
                                                                                                        showAlertDanger1 &&
                                                                                                        <Alert type="error" message={message1} showIcon closable />
                                                                                                    },
                                                                                                    {
                                                                                                        showAlertSucess1 &&
                                                                                                        <Alert type="success" message={message1} showIcon closable />
                                                                                                    }
                                                                                                </Space>


                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }

                                                                                <div className="form-group col-md-4">
                                                                                    {!Loading && (
                                                                                        <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={() => NewUpdateProfile()}>Update profile</button>
                                                                                    )}

                                                                                    {Loading && (

                                                                                        <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                                                                            <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i>
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                                <div className="form-group col-md-4 mt-2">
                                                                                    <Button
                                                                                        style={{
                                                                                            borderColor: '#3d8ef7',
                                                                                            color: '#3d8ef7',
                                                                                            backgroundColor: '#f3f3f3',
                                                                                        }}
                                                                                        
                                                                                        data-toggle="modal" data-target="#exampleModal"
                                                                                    >
                                                                                        Import Photo
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                            {/** Update file */}


                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>



                        {/** end container-fluid */}


                        {/** add new seller Modal */}
                        <div className="modal fade" id="add_new_seller" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Add or Update reseller </h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="alert alert-info" role="alert">
                                            Before creating a reseller account you must make sure you know the person well, especially their
                                            real identity, have their photo and physical address and must be able to assume the role of
                                            seller of Tiva products.
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <input type="hidden" id="id" value="00" />
                                                <div className="mb-3 col-md-8">
                                                    <label className="form-label">Fullname</label>
                                                    <input type="text" className="form-control" placeholder="" id="fullname" />
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Category</label>
                                                    <select className="form-control" id="categorie">
                                                        <option value="">Select category</option>
                                                        <option value="23f07d9b335f7d9bc34f4">Supervisor</option>
                                                        <option value="637d9bc35f7d9bc35fa">Agent</option>
                                                        <option value="63f07d9bc35f7d9bc35fa">Dealer</option>
                                                        <option value="637d9bc35df7d9bc35fa">Distributor</option>
                                                        <option value="4637d9bc35df7d9bc35fa23">Vendor</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Gender</label>
                                                    <select className="form-control" id="gender"
                                                        name='gender'
                                                        value={gender}
                                                        onChange={(e) => { setGender(e.target.value) }}
                                                    >
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Civil status</label>
                                                    <select className="form-control" id="civil_status">
                                                        <option value="Married">Married</option>
                                                        <option value="Single">Single</option>
                                                        <option value="Divorced">Divorced</option>
                                                        <option value="Widowed">Widowed</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Date of birth</label>
                                                    <input type="date" className="form-control" placeholder="" id="datenaiss" />
                                                </div>
                                                <div className="mb-3  col-md-6">
                                                    <label className="form-label">Phone</label>
                                                    <input type="text" className="form-control" placeholder="" id="phone" />
                                                </div>
                                                <div className="mb-3  col-md-6">
                                                    <label className="form-label">Adress Mail</label>
                                                    <input type="email" className="form-control" placeholder="" id="email" />
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">State/Province</label>
                                                    <input type="text" className="form-control" placeholder="" id="stat-province" />
                                                </div>
                                                <div className="mb-3  col-md-4">
                                                    <label className="form-label">City</label>
                                                    <input type="text" className="form-control" placeholder="" id="city" />
                                                </div>
                                                <div className="mb-3  col-md-4">
                                                    <label className="form-label">Street</label>
                                                    <input type="text" className="form-control" placeholder="" id="street" />
                                                </div>
                                                <div className="mb-3  col-md-12">
                                                    <label className="form-label">Adresse</label>
                                                    <textarea className="form-control" placeholder=""
                                                        id="adresse"></textarea>
                                                </div>
                                                <div className="col-md-12" id="error">

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary font-16 font-weight-500">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>


            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Profile Photo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" >

                            <div className="pic-holder " style={{ marginLeft: '28%' }}>
                                <img id="profilePic" className="pic" src={`${avatarUser === 'default.png' ? `${avatar1}` : `${api.var_.url_avatar + avatarUser}`}`} />

                                <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto1" accept="image/*" style={{ opacity: 0 }}
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="newProfilePhoto1" className="upload-file-block">
                                    <div className="text-center">
                                        <div className="mb-1">
                                            <i className="fa fa-camera fa-2x"></i>
                                        </div>
                                        <div className="text-uppercase">
                                            Update <br /> Profile Photo
                                        </div>
                                    </div>
                                </label>
                            </div>
                            {
                                message && (
                                    <>
                                        <div className='col-md-12 mb-1 mt-1'>
                                            {/* <label style={{color:'white'}}>vouchers</label> */}
                                            <Space direction="vertical" style={{ width: '100%' }}>
                                                {
                                                    showAlertDanger2 &&
                                                    <Alert type="error" message={message} showIcon closable />

                                                }
                                                {
                                                    showAlertSuccess2 &&
                                                    <Alert type="success" message={message} showIcon closable />

                                                }
                                            </Space>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => UpdateAvatar()} type="button" className="btn btn-primary agree-link font-18" disabled={false}
                                id="btn-Withdraw">Update</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SellerAccount
