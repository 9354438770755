import React, { useEffect, useState } from 'react'
import { avatar1, avatar3, b1, default1 } from '../../../components/icons'
import { useNavigate, useParams } from 'react-router-dom';
import requestRow from '../../../controller/RequestRow';
import axios from 'axios';
import api from '../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import { Alert, Pagination, Space, Select } from 'antd';
import {
    Card, Row, Col, Badge, Drawer, List, Input, Button,
    Checkbox
} from "antd";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import {
    DiffOutlined, SwapOutlined, HomeOutlined, UnorderedListOutlined
} from "@ant-design/icons"
import PhoneInput from 'react-phone-input-2'
import Footer from '../../../components/footer';
import Header from '../../../components/header';
import { MdOutlineSwapVert } from "react-icons/md";
const { Option } = Select;

const JvWithdraw = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const { id_sellers } = useParams();
    const { transaction_by_id } = useParams();
    const jvId = api.initIdUserConnect();

    const navigate = useNavigate();
    const data = '';

    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');
    const regexEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');

    const [Loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [gender, setGender] = useState('');
    const [userRoleId, setUserRoleId] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [id, setId] = useState('');

    // Add telecom fields

    // Variables Load elements
    const [minicipality, setMinicipality] = useState<requestRow[]>([])
    const [categoriRole, setCategoriRole] = useState<requestRow[]>([])
    const [sellerLoad, setSellerLoad] = useState<requestRow[]>([])
    const [tva, setTva] = useState<requestRow[]>([])
    const [operator, setOperator] = useState<requestRow[]>([])

    //Load transaction by id 
    const [getRegion, setGetRegion] = useState<requestManagerAccount[]>([]);
    const [getDistrict, setGetDistrict] = useState<requestManagerAccount[]>([]);
    const [getWard, setGetWard] = useState<requestManagerAccount[]>([]);
    const [role, setRole] = useState<requestRow[]>([])
    const [stock, setStock] = useState<requestProfile[]>([]);
    const [loadConstant, setLoadConstant] = useState<requestVoucher[]>([]);
    const [allFromSerie, setAllFromSerie] = useState<requestVoucher[]>([]);
    const [seriesNumber, setSeriesNumber] = useState('')
    const [vouchersValid, setVoucherValid] = useState('')
    const [vouchersUsed, setVouchersUsed] = useState('')
    const [voucherDifference, setVoucherDifference] = useState(0)

    const [avatarUser, setAvatarUser] = useState('');
    const [sommePins, setSommesPins] = useState('')
    const [sommeTotal, setSommeTotal] = useState('')
    const [sommeAvailablePins, setSommeAvailablePins] = useState('')
    const [sommeDiscount, setSommeDiscount] = useState('')
    const [sommeVatAmount, setSommeVatAmount] = useState('')

    const [showAlertDanger, setShowAlertDanger] = useState(false);
    const [showAlertSucess, setShowAlertSucess] = useState(false);

    const [message, setMessage] = useState("");
    const [showAlertDanger1, setShowAlertDanger1] = useState(false);
    const [showAlertSucess1, setShowAlertSucess1] = useState(false);
    const [message1, setMessage1] = useState("");
    const [region, setRegion] = useState('');
    const [district, setDistric] = useState('');
    const [ward, setWard] = useState('');
    const [wardName, setWardName] = useState('');
    const [description, setDescription] = useState('');
    //Payement textField
    const [getcurrency, setGetCurrency] = useState('');

    //Var Stock 
    const [countAssign, setCountAssign] = useState('');
    const [countTotal, setCountTotal] = useState('');
    const [totalPinInStock, setTotalPinInStock] = useState('');
    const [countUsed, setCountUsed] = useState('');

    //filter to serie
    const [serieFrom, setSerieFrom] = useState('')
    const [serieTo, setSerieTo] = useState('')

    const [countTotal1, setCountTotal1] = useState('');
    const [countUsed1, setcountUsed] = useState('');
    const [countActive, setCountActive] = useState('')
    const [countTransfered, setCountTransfered] = useState('')
    const [countAvailable, setCountAvailable] = useState('')
    const [amount, setAmount] = useState('');
    const [totalVouchersDeleted, setTotalVouchersDeleted] = useState('');

    const [total, setTotal] = useState('');
    const [totalUsed, setTotalUsed] = useState('');
    const [totalVendorTransfer, setTotalVendorTransfer] = useState('');
    const [totalAvailable, setTotalAvailable] = useState('');
    const [totalVouchersActived, setTotalVouchersActived] = useState('');
    const [totalVouchersDeleted1, setTotalVouchersDeleted1] = useState('');
    const [totalAvailableAmount, setTotalAvailableAmount] = useState('');
    //Paginate
    const [totalPage, setTotalPage] = useState(1);

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }

        LoadRegion()
        LoadDistrict()
        LoadWard()
        loadMinicipality()
        loadCategorie_or_Role()
        // loadSeller()
        loadTva()
        loadOperator()
        LoadPrile();
        LoadStock(1);
        LoadVocher();
        LoadConstant();
        LoadFindVocher()
    }, [jvId]);

    const LoadPrile = async () => {
        api.getHttpRequest(`/user/admin/load/${id_sellers}`, data, 'GET').then((response) => {

            const json = response.data.data;

            const startDate = new Date(json.date_of_birth);
            const formattedStartDate = startDate.toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            setDateOfBirth(formattedStartDate)
            setId(json.id)
            setName(json.name);
            setMiddlename(json.middlename);
            setLastName(json.lastname);
            setEmail(json.email);
            setPhone(json.phone);
            setGender(json.gender)
            setAddress(json.address);
            setAvatarUser(json.avatar);
            setUserRoleId(json.UserRoleId);
            setRegion(json.LocationWard.LocationDistric.LocationRegion.name)
            setDistric(json.LocationWard.LocationDistric.name)
            setWardName(json.LocationWard.name)
            setWard(json.LocationWard.id)
            setPlaceOfBirth(json.place_of_birth)
        }).catch((error) => {
            api.getMessageError(error)
        })
    }

    const LoadStock = async (page: any) => {
        api.getHttpRequest(`/vouchers/in-stock?id_user=${id_sellers}&page=${page}`, data, 'GET').then((response) => {

            const json = response.data.data;
            setStock(json.data);
            // setCount(json.count);
            setCountAssign(json.count_assign);
            setCountTotal(json.count_total);
            setTotalPinInStock(json.total_pin_in_stock)
            setCountUsed(json.count_used)
            setTotalPage(json.count)
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    const LoadFindVocher = () => {

        api.getHttpRequest(`/vouchers/find-vouchers/${serieFrom}/${serieTo}/${jvId}`, data, 'GET').then((response) => {
            const json = response.data;
            // setLoadVoucher(json.results.rows);
            if (json.status === 200) {
                setCountTotal1(json.data.total);
                setcountUsed(json.data.total_used);
                setCountActive(json.data.total_vouchers_actived);
                setCountTransfered(json.data.total_vendor_transfer);
                setCountAvailable(json.data.total_available)
                setAmount(json.data.total_available_amount)
                setTotalVouchersDeleted(json.data.total_vouchers_deleted);
                setShowAlertSucess(true);
                setMessage(json.message);
                // setAssignedVouchers(json.total_vouchers);
                // setLoading1(false);
            } else {
                setShowAlertDanger(true);
                setMessage(json.message);
            }

        }).catch((error) => {
            setMessage(api.getMessageError(error.message))
        })

        setTimeout(() => {
            setShowAlertDanger(false);
            setShowAlertSucess(false);
            setLoading(false);
        }, 5000);
    }

    const LoadVocher = () => {
        api.getHttpRequest(`/vouchers/find-vouchers-account/${jvId}`, data, 'GET').then((response) => {
            const json = response.data;
            // setLoadVoucher(json.results.rows);
            if (json.status == 200) {
                setTotal(json.data.total)
                setTotalUsed(json.data.total_used)
                setAllFromSerie(json.data.assigned_vouchers);
                setTotalVendorTransfer(json.data.total_vendor_transfer)
                setTotalAvailable(json.data.total_available)
                setTotalVouchersActived(json.data.total_vouchers_actived)
                setTotalVouchersDeleted1(json.data.total_vouchers_deleted)
                setTotalAvailableAmount(json.data.total_available_amount)
                // setAssignedVouchers(json.total_vouchers);
                // setLoading1(false);
            } else {
                setShowAlertDanger(true);
                setMessage(json.message);
            }

        }).catch((error) => {
            setMessage(api.getMessageError(error.message))
        })

        setTimeout(() => {
            setShowAlertDanger(false);
            setShowAlertSucess(false);
            setLoading(false);
        }, 5000);
    }

    const LoadConstant = () => {
        api.getHttpRequest("/parameters/constant", data, 'GET').then((response) => {
            const json = response.data;
            setLoadConstant(json.data.rows)

        }).catch((error) => {
            setMessage(api.getMessageError(error.message))
        })
    }

    const getConstant = loadConstant.find((c) => c.name === 'currency')?.value;

    const NewUpdateProfile = () => {
        let data = JSON.stringify({
            "name": name,
            "lastname": lastname,
            "middlename": middlename,
            "email": email,
            "address": adress,
            "phone": phone,
            "id_ward": ward,
            "avatar": "",
            "gender": gender,
            "UserRoleId": id_sellers,
            "civil_status": civilStatus,
            "date_of_birth": dateOfBirth,
            "place_of_birth": placeOfBirth,
            "id_partner": api.initPartnerId(),
            "default_password": '1111111',
        });

        setLoading(true);

        if (name === '' || phone === '') {
            setShowAlertDanger1(true)
            setMessage1('Make sure you have filled in all the red star fields');
        } else if (!regexTelephone.test(phone)) {
            setShowAlertDanger1(true)
            setMessage1('The entry number is incoreact');
        }
        else {
            api.getHttpRequest(`/partners/app/members/${id_sellers}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    LoadPrile()
                    setShowAlertSucess1(true)
                    setMessage1(json.message);
                } else {
                    setShowAlertDanger1(true)
                    setMessage1(json.message);
                }
            }).catch((error) => {
                setMessage1(api.getMessageError(error))
            })

        }

        setTimeout(() => {
            setLoading(false);
            setShowAlertSucess1(false)
            setShowAlertDanger1(false)
        }, 3000);
    }


    //Load zones
    const LoadRegion = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/region/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetRegion(json.rows)

        }).catch((error: any) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }
    const LoadDistrict = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/district/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetDistrict(json.rows)

        }).catch((error: any) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }
    const LoadWard = () => {
        // setLoading(true);
        api.getHttpRequest(`/parameters/location/ward/load?limit=10000`, data, 'GET').then((response) => {
            const json = response.data.data;
            setGetWard(json.rows)

        }).catch((error: any) => {
            console.log(error.message);
        })

        setTimeout(() => {
            // setLoading(false);
        }, 3000);
    }


    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    // const loadRole = async () => {
    //     api.getHttpRequest("/parameters/roles/load?groupe=2", data, 'GET').then((response) => {
    //         const json = response.data;
    //         if (json.status == 200) {
    //             setRole(json.data);
    //         }
    //     }).catch((error) => {
    //         // setLoading(false);
    //         if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
    //             toast.error("Please connect your device to the internet");
    //         } else if (error.response && error.response.data && error.response.data.message) {
    //             toast.error(error.response.data.message);
    //         } else {
    //             toast.error(error.message);
    //         }
    //     })
    // }

    const loadMinicipality = async () => {
        api.getHttpRequest("/zone/municipality/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setMinicipality(json.data);
            }
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    const loadCategorie_or_Role = async () => {
        api.getHttpRequest("/parameters/roles/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCategoriRole(json.data);
            }
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    const loadTva = async () => {
        api.getHttpRequest("/parameters/constant", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setTva(json.data.rows);
                setRole(json.data.rows);
            }
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    const loadOperator = async () => {
        api.getHttpRequest("/parameters/mobile-operator/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setOperator(json.data);
            }
        }).catch((error) => {
            setMessage(api.getMessageError(error))
        })
    }

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateDistrict, setStateDistrict] = useState<requestManagerAccount[]>([])
    const [stateWard, setStateWard] = useState<requestManagerAccount[]>([])
    const GetRegion = (id: any) => {
        const IdRegion = getDistrict.filter(x => x.LocationRegionId === id);
        setStateDistrict(IdRegion)
    }

    const GetWard = (id: any) => {
        const IdDistrict = getWard.filter(x => x.LocationDistricId === id);
        setStateWard(IdDistrict)
    }


    //Attribute voucher
    const AttributeVoucher = () => {
        let data = JSON.stringify({
            "serie_from": serieFrom,
            "serie_to": serieTo,
            "id_user_to": id_sellers,
            "observation": description,
            "amount": amount,
            "currency": getConstant,
            "id_user_from": jvId
        });

        if (serieFrom === '' || serieTo === '' || amount === '') {
            setShowAlertDanger(true)
            setMessage('Make sure you have filled in all the red star fields');
        }
        else {

            api.getHttpRequest("/vouchers/share", data, 'POST').then((response: any) => {
                const json = response.data;
                if (json.status == 200) {
                    setShowAlertSucess(true)
                    setMessage(json.message);
                    navigate(`#tansactions_tab`)
                } else {
                    setShowAlertDanger(true)
                    setMessage(json.message);
                }
            }).catch((error: any) => {
                setShowAlertDanger(true)
                setMessage(api.getMessageError(error))
            })
        }


        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    function onClickTr(element: any) {
        setSerieFrom(element.serie_from);
        setSerieTo(element.serie_to);
    }

    return (
        <>
            <div id="wrapper">

                <Header />
                <div className="content-page">
                    <div className="content">
                        {/** Start container-fluid */}

                        <div className="container-fluid1">


                            <div className="row">


                                <div className="col-xl-12">
                                    <div className="card stacked-2 seller-account-tab">
                                        <div className="row">
                                            <div className="col-12">

                                            <div className="tab-content">
                                                    <div className="tab-pane active" id="add_Voucher_tab">
                                                        <div className="col-md-12">
                                                            <Card style={{ width: '100%', marginBottom: 15 }}>
                                                                <div className="m-0">
                                                                    <h4 className="fs-18 text-black mb-0">Withdrawal history</h4>
                                                                </div>

                                                                <div className=''>

                                                                    {/* <div className="mb-1 d-flex d-block" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>

                                                                        <div className="" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Total Voucher</strong> <br />
                                                                                    <span>{10000}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Voucher Used</strong> <br />
                                                                                    <span>{2003}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Vendor Transfer</strong> <br />
                                                                                    <span>{20390}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Voucher Available</strong> <br />
                                                                                    <span>{10101}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Vouchers Actived</strong> <br />
                                                                                    <span>{10234}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Vouchers Deleted</strong> <br />
                                                                                    <span>{10209}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="ml-1" style={{
                                                                            display: 'flex', justifyContent: 'space-between',
                                                                            backgroundColor: 'white', border: '1px solid #F0F0F0', padding: 5,
                                                                            borderRadius: 5, cursor: 'pointer'
                                                                        }}>
                                                                            <div className="d-flex" style={{ marginTop: 5, textAlign: 'center', }}>
                                                                                <div><MdOutlineSwapVert style={{ backgroundColor: '#E3EBF9', color: '#1576ED', fontSize: 35, padding: 5, borderRadius: 10 }} /></div>
                                                                                <div className="ml-1">
                                                                                    <strong>Available Amount</strong> <br />
                                                                                    <span>{90834}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                    </div> */}

                                                                    <div className="table-responsive">
                                                                        <table className="table header-border table-responsive-sm tbl-common text-center" >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">No</th>
                                                                                    <th scope="col">Dates</th>
                                                                                    <th scope="col">Transaction ID</th>
                                                                                    <th scope="col">Withdrawn <br/>Amount</th>
                                                                                    <th scope="col">Transaction fee Mobile</th>
                                                                                    <th scope="col">Transaction fee Tiva</th>
                                                                                    <th scope="col">VAT</th>
                                                                                    <th scope="col">Total</th>
                                                                                    <th scope="col">Balance</th>
                                                                                    <th scope="col">Telecom</th>
                                                                                    <th scope="col">Telecom<br/>Reference</th>
                                                                                    <th scope="col">Receipt #</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                
                                                                                        <tr  style={{ cursor: 'pointer' }}>
                                                                                            <td>1</td>
                                                                                            <td>Jan 16, 2024 10:13 AM <br/>
                                                                                                4 Minutes Ago
                                                                                            </td>
                                                                                            <td>T25663525</td>
                                                                                            <td>
                                                                                                {/* <strong style={{ backgroundColor: '#E4F5EB', color: '#59937D', borderRadius: 5, padding: 8, }}>8000</strong> */}
                                                                                                Tsh.5000
                                                                                            </td>
                                                                                            <td>Tsh.50</td>
                                                                                            <td>Tsh.150</td>
                                                                                            <td>Tsh.300</td>
                                                                                            <td>Tsh.50550</td>
                                                                                            <td>Tsh.100</td>
                                                                                            <td>Airtel Money <br/>
                                                                                                +2556365226332 <br/>
                                                                                                Josephat Beka
                                                                                            </td>
                                                                                            <td>22566636668552</td>
                                                                                            <td>TW5266333652</td>
                                                                                        </tr>
                                    
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>

                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>



                        {/** end container-fluid */}


                        {/** add new seller Modal */}
                        <div className="modal fade" id="add_new_seller" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Add or Update reseller </h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="alert alert-info" role="alert">
                                            Before creating a reseller account you must make sure you know the person well, especially their
                                            real identity, have their photo and physical address and must be able to assume the role of
                                            seller of Tiva products.
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <input type="hidden" id="id" value="00" />
                                                <div className="mb-3 col-md-8">
                                                    <label className="form-label">Fullname</label>
                                                    <input type="text" className="form-control" placeholder="" id="fullname" />
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Category</label>
                                                    <select className="form-control" id="categorie">
                                                        <option value="">Select category</option>
                                                        <option value="23f07d9b335f7d9bc34f4">Supervisor</option>
                                                        <option value="637d9bc35f7d9bc35fa">Agent</option>
                                                        <option value="63f07d9bc35f7d9bc35fa">Dealer</option>
                                                        <option value="637d9bc35df7d9bc35fa">Distributor</option>
                                                        <option value="4637d9bc35df7d9bc35fa23">Vendor</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Gender</label>
                                                    <select className="form-control" id="gender"
                                                        name='gender'
                                                        value={gender}
                                                        onChange={(e) => { setGender(e.target.value) }}
                                                    >
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Civil status</label>
                                                    <select className="form-control" id="civil_status">
                                                        <option value="Married">Married</option>
                                                        <option value="Single">Single</option>
                                                        <option value="Divorced">Divorced</option>
                                                        <option value="Widowed">Widowed</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Date of birth</label>
                                                    <input type="date" className="form-control" placeholder="" id="datenaiss" />
                                                </div>
                                                <div className="mb-3  col-md-6">
                                                    <label className="form-label">Phone</label>
                                                    <input type="text" className="form-control" placeholder="" id="phone" />
                                                </div>
                                                <div className="mb-3  col-md-6">
                                                    <label className="form-label">Adress Mail</label>
                                                    <input type="email" className="form-control" placeholder="" id="email" />
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">State/Province</label>
                                                    <input type="text" className="form-control" placeholder="" id="stat-province" />
                                                </div>
                                                <div className="mb-3  col-md-4">
                                                    <label className="form-label">City</label>
                                                    <input type="text" className="form-control" placeholder="" id="city" />
                                                </div>
                                                <div className="mb-3  col-md-4">
                                                    <label className="form-label">Street</label>
                                                    <input type="text" className="form-control" placeholder="" id="street" />
                                                </div>
                                                <div className="mb-3  col-md-12">
                                                    <label className="form-label">Adresse</label>
                                                    <textarea className="form-control" placeholder=""
                                                        id="adresse"></textarea>
                                                </div>
                                                <div className="col-md-12" id="error">

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary font-16 font-weight-500">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

        </>
    )
}

export default JvWithdraw
